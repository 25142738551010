import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IInsulation } from '@theia-cc/ht/core';
import { IHighDrainSystems } from '@theia-cc/pv/state';
import { BuildingType } from '@theia-cc/pv/core';
import {
  caseAgnosticQueryParam,
  cleanEmptyProperties,
  getRestQueryParams,
  getUserAddressFromQueryParams,
  parseFormString,
} from '@theia-cc/shared/helpers';
import { IBuildingSummary, IUserAddressParams, IUserInfoParams } from '@theia-cc/shared/models';
import { ShareLinkService, USER_INFO_FIELDS } from '@theia-cc/shared/services';
import { IAppStatePrimeo, ICollectedDataPrimeo } from '../store/reducers';

export type ShareLinkQueryParams = Partial<ICollectedDataPrimeo> &
  Partial<IUserInfoParams> &
  Partial<IUserAddressParams> &
  Partial<IInsulation> &
  Partial<IBuildingSummary> &
  Partial<IHighDrainSystems> & { Language?: string; partnerId?: string };

@Injectable()
export class ShareLinkServicePrimeo extends ShareLinkService {
  constructor(
    private store: NgRedux<IAppStatePrimeo>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  urlLinkQueryParamsSelector(state: IAppStatePrimeo): any {
    const {
      currentLanguage: Language,
      collectedData: {
        nboLeadIdEm,
        variantIdEm,
        nboLeadIdPv,
        variantIdPv,
        nboLeadIdHt,
        variantIdHt,
      },
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      Language,
      nboLeadIdEm,
      variantIdEm,
      nboLeadIdPv,
      variantIdPv,
      nboLeadIdHt,
      variantIdHt,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  shareLinkQueryParamsSelector(state: IAppStatePrimeo): ShareLinkQueryParams {
    const {
      collectedData: {
        chargingPower,
        connectpv,
        distanceFuseboxToChargingstation,
        fuseBox,
        manufacturerselection,
        numberOfParkingspacePreparedToBeElectrified,
        numberOfParkingspaceToBeEquiped,
        orderType,
        parkingspace,
        poweroutlet,
        totalNumberOfParkingspaces,
        selectedTemplateIdEm,

        buildingAge,
        buildingData: {
          aboveSea,
          buildingGroundArea,
          buildingLevels,
          buildingRoofArea,
          buildingRoofOverhangArea,
          buildingRoofShape,
          buildingVolume,
          buildingWallArea,
          buildingEavesHeight,
          buildingRidgeHeight,
        },
        dismantleTank,
        familyMemberCount,
        heatingArea,
        heatingEmission,
        heatingType,
        heatPumpType,
        insulation: { WINDOWS, FACADE, ROOF, BASEMENT },
        nboSelectedTemplateIdHt,
        powerConsumptionCalculated,
        powerConsumptionSelected,
        smartEnergy,
        userAddress: { egid, lat, lon, place: City, street: Street, zip: ZipCode },
        user: {
          email: Email,
          firstName: FirstName,
          lastName: LastName,
          telephone: PhoneNumber,
          title: Title,
          companyName: CompanyName,
        },

        apartmentCount,
        averagePowerUsageKwh,
        buildingType,
        highDrainSystems: {
          HEATPUMP,
          ELECTRIC_HEATING,
          ELECTROBOILER,
          EMOBILITY_CHARGING,
          POOL_SAUNA,
        },
        highDrainSystemsKwh,
        isPvx,
        roofArea,
        maxPvCoveredRoofArea,
        roofAreaChf,
        roofAreaKwh,
        roofAreaLifetimeChf,
        roofMaterial,
        roofShadow,
        roofTiltAngle,
        selectedRoofArea,
        totalPowerRequirementKwh,
        heatPump,
        battery,
        electroBoiler,
        chargingStationEmobility,
        heatPumpBoiler,
        nboSelectedTemplateIdPv,
        pvEarningsKwhM2,
        roofOrientation,
        nboLeadIdEm,
        variantIdEm,
        nboLeadIdPv,
        variantIdPv,
        nboLeadIdHt,
        variantIdHt,
        largeSystem,
      },
      currentLanguage: Language,
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      chargingPower,
      connectpv,
      distanceFuseboxToChargingstation,
      fuseBox,
      manufacturerselection,
      numberOfParkingspacePreparedToBeElectrified,
      numberOfParkingspaceToBeEquiped,
      orderType,
      parkingspace,
      poweroutlet,
      totalNumberOfParkingspaces,
      Language,
      selectedTemplateIdEm,
      buildingAge,
      aboveSea,
      buildingGroundArea,
      buildingLevels,
      buildingRoofArea,
      buildingRoofOverhangArea,
      buildingRoofShape,
      buildingVolume,
      buildingWallArea,
      buildingEavesHeight,
      buildingRidgeHeight,
      dismantleTank,
      familyMemberCount,
      heatingArea,
      heatingEmission,
      heatingType,
      heatPumpType,
      WINDOWS,
      FACADE,
      ROOF,
      BASEMENT,
      nboSelectedTemplateIdHt,
      powerConsumptionCalculated,
      powerConsumptionSelected,
      smartEnergy,
      egid,
      lat,
      lon,
      City,
      Street,
      ZipCode,
      Email,
      FirstName,
      LastName,
      PhoneNumber,
      Title,
      CompanyName,
      apartmentCount,
      averagePowerUsageKwh,
      buildingType,
      HEATPUMP,
      ELECTRIC_HEATING,
      ELECTROBOILER,
      EMOBILITY_CHARGING,
      POOL_SAUNA,
      highDrainSystemsKwh,
      isPvx,
      roofArea,
      maxPvCoveredRoofArea,
      roofAreaChf,
      roofAreaKwh,
      roofAreaLifetimeChf,
      roofMaterial,
      roofShadow,
      roofTiltAngle,
      selectedRoofArea,
      totalPowerRequirementKwh,
      heatPump,
      battery,
      electroBoiler,
      chargingStationEmobility,
      heatPumpBoiler,
      nboSelectedTemplateIdPv,
      pvEarningsKwhM2,
      roofOrientation,
      nboLeadIdEm,
      variantIdEm,
      nboLeadIdPv,
      variantIdPv,
      nboLeadIdHt,
      variantIdHt,
      largeSystem,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  splitQueryParams<
    T = {
      collectedData: Partial<ICollectedDataPrimeo>;
      partnerId: string;
      Language: string;
      restQueryParams: Partial<ShareLinkQueryParams>;
    }
  >(queryParams: ShareLinkQueryParams): T {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams as any);

    const collectedData: Partial<ICollectedDataPrimeo> = cleanEmptyProperties({
      apartmentCount: parseFormString.number(getCaseAgnosticQueryParam('apartmentCount')),
      averagePowerUsageKwh: parseFormString.number(
        getCaseAgnosticQueryParam('averagePowerUsageKwh')
      ),
      buildingType: BuildingType.FAMILY_HOME,
      familyMemberCount: parseFormString.number(getCaseAgnosticQueryParam('familyMemberCount')),
      highDrainSystems: {
        HEATPUMP: parseFormString.boolean(getCaseAgnosticQueryParam('HEATPUMP')),
        ELECTRIC_HEATING: parseFormString.boolean(getCaseAgnosticQueryParam('ELECTRIC_HEATING')),
        ELECTROBOILER: parseFormString.boolean(getCaseAgnosticQueryParam('ELECTROBOILER')),
        EMOBILITY_CHARGING: parseFormString.boolean(
          getCaseAgnosticQueryParam('EMOBILITY_CHARGING')
        ),
        POOL_SAUNA: parseFormString.boolean(getCaseAgnosticQueryParam('POOL_SAUNA')),
      },

      highDrainSystemsKwh: parseFormString.number(getCaseAgnosticQueryParam('highDrainSystemsKwh')),
      isPvx: parseFormString.boolean(getCaseAgnosticQueryParam('isPvx')),
      roofArea: parseFormString.number(getCaseAgnosticQueryParam('roofArea')),
      maxPvCoveredRoofArea: parseFormString.number(
        getCaseAgnosticQueryParam('maxPvCoveredRoofArea')
      ),
      roofAreaChf: parseFormString.number(getCaseAgnosticQueryParam('roofAreaChf')),
      roofAreaKwh: parseFormString.number(getCaseAgnosticQueryParam('roofAreaKwh')),
      roofAreaLifetimeChf: parseFormString.number(getCaseAgnosticQueryParam('roofAreaLifetimeChf')),
      roofMaterial: getCaseAgnosticQueryParam('roofMaterial'),
      roofShadow: getCaseAgnosticQueryParam('roofShadow'),
      roofTiltAngle: parseFormString.number(getCaseAgnosticQueryParam('roofTiltAngle')),
      selectedRoofArea: parseFormString.number(getCaseAgnosticQueryParam('selectedRoofArea')),
      totalPowerRequirementKwh: parseFormString.number(
        getCaseAgnosticQueryParam('totalPowerRequirementKwh')
      ),

      userAddress: getUserAddressFromQueryParams(queryParams),
      user: {
        email: getCaseAgnosticQueryParam('Email'),
        firstName: getCaseAgnosticQueryParam('FirstName'),
        lastName: getCaseAgnosticQueryParam('LastName'),
        telephone: getCaseAgnosticQueryParam('PhoneNumber'),
        title: getCaseAgnosticQueryParam('Title'),
        companyName: getCaseAgnosticQueryParam('CompanyName'),
      },

      heatPump: parseFormString.boolean(getCaseAgnosticQueryParam('heatPump')),
      battery: parseFormString.boolean(getCaseAgnosticQueryParam('battery')),
      electroBoiler: parseFormString.boolean(getCaseAgnosticQueryParam('electroBoiler')),
      chargingStationEmobility: parseFormString.boolean(
        getCaseAgnosticQueryParam('chargingStationEmobility')
      ),
      heatPumpBoiler: parseFormString.boolean(getCaseAgnosticQueryParam('heatPumpBoiler')),
      nboSelectedTemplateIdPv: getCaseAgnosticQueryParam('nboSelectedTemplateIdPv'),

      buildingData: {
        aboveSea: parseFormString.number(getCaseAgnosticQueryParam('aboveSea')),
        buildingGroundArea: parseFormString.number(getCaseAgnosticQueryParam('buildingGroundArea')),
        buildingLevels: parseFormString.number(getCaseAgnosticQueryParam('buildingLevels')),
        buildingRoofArea: parseFormString.number(getCaseAgnosticQueryParam('buildingRoofArea')),
        buildingRoofOverhangArea: parseFormString.number(
          getCaseAgnosticQueryParam('buildingRoofOverhangArea')
        ),
        buildingRoofShape: parseFormString.number(getCaseAgnosticQueryParam('buildingRoofShape')),
        buildingVolume: parseFormString.number(getCaseAgnosticQueryParam('buildingVolume')),
        buildingWallArea: parseFormString.number(getCaseAgnosticQueryParam('buildingWallArea')),
        buildingEavesHeight: parseFormString.number(
          getCaseAgnosticQueryParam('buildingEavesHeight')
        ),
        buildingRidgeHeight: parseFormString.number(
          getCaseAgnosticQueryParam('buildingRidgeHeight')
        ),
      },
      pvEarningsKwhM2: parseFormString.number(getCaseAgnosticQueryParam('pvEarningsKwhM2')),
      roofOrientation: parseFormString.number(getCaseAgnosticQueryParam('roofOrientation')),
      buildingAge: getCaseAgnosticQueryParam('buildingAge'),
      dismantleTank: getCaseAgnosticQueryParam('dismantleTank'),
      heatingArea: parseFormString.number(getCaseAgnosticQueryParam('heatingArea')),
      heatingEmission: getCaseAgnosticQueryParam('heatingEmission'),
      heatingType: getCaseAgnosticQueryParam('heatingType'),
      heatPumpType: getCaseAgnosticQueryParam('heatPumpType'),
      nboSelectedTemplateIdHt: getCaseAgnosticQueryParam('nboSelectedTemplateIdHt'),
      powerConsumptionCalculated: parseFormString.number(
        getCaseAgnosticQueryParam('powerConsumptionCalculated')
      ),
      powerConsumptionSelected: parseFormString.number(
        getCaseAgnosticQueryParam('powerConsumptionSelected')
      ),
      smartEnergy: getCaseAgnosticQueryParam('smartEnergy'),
      insulation: {
        WINDOWS: parseFormString.boolean(getCaseAgnosticQueryParam('WINDOWS')),
        FACADE: parseFormString.boolean(getCaseAgnosticQueryParam('FACADE')),
        ROOF: parseFormString.boolean(getCaseAgnosticQueryParam('ROOF')),
        BASEMENT: parseFormString.boolean(getCaseAgnosticQueryParam('BASEMENT')),
      },
      chargingPower: getCaseAgnosticQueryParam('chargingPower'),
      connectpv: getCaseAgnosticQueryParam('connectpv'),
      distanceFuseboxToChargingstation: parseFormString.number(
        getCaseAgnosticQueryParam('distanceFuseboxToChargingstation')
      ),
      fuseBox: getCaseAgnosticQueryParam('fuseBox'),
      manufacturerselection: getCaseAgnosticQueryParam('manufacturerselection'),
      numberOfParkingspacePreparedToBeElectrified: parseFormString.number(
        getCaseAgnosticQueryParam('numberOfParkingspacePreparedToBeElectrified')
      ),
      numberOfParkingspaceToBeEquiped: parseFormString.number(
        getCaseAgnosticQueryParam('numberOfParkingspaceToBeEquiped')
      ),
      orderType: getCaseAgnosticQueryParam('orderType'),
      parkingspace: getCaseAgnosticQueryParam('parkingspace'),
      poweroutlet: getCaseAgnosticQueryParam('poweroutlet'),
      totalNumberOfParkingspaces: parseFormString.number(
        getCaseAgnosticQueryParam('totalNumberOfParkingspaces')
      ),
      selectedTemplateIdEm: getCaseAgnosticQueryParam('selectedTemplateIdEm'),
      nboLeadIdEm: getCaseAgnosticQueryParam('nboLeadIdEm'),
      variantIdEm: getCaseAgnosticQueryParam('variantIdEm'),
      nboLeadIdPv: getCaseAgnosticQueryParam('nboLeadIdPv'),
      variantIdPv: getCaseAgnosticQueryParam('variantIdPv'),
      nboLeadIdHt: getCaseAgnosticQueryParam('nboLeadIdHt'),
      variantIdHt: getCaseAgnosticQueryParam('variantIdHt'),
      largeSystem: parseFormString.boolean(getCaseAgnosticQueryParam('largeSystem')),
    });

    return {
      collectedData,
      Language: getCaseAgnosticQueryParam('Language'),
      restQueryParams: getRestQueryParams(queryParams as any),
    } as unknown as T; // @TODO fix typing
  }
}
