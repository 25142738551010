export enum ManufacturerEnum {
  Generic = 'Generic',
  Audi = 'Audi',
  Nissan = 'Nissan',
  Renault = 'Renault',
  Volkswagen = 'Volkswagen',
  Bmw = 'Bmw',
  Citroen = 'Citroen',
  DsAutomobiles = 'DsAutomobiles',
  HarleyDavidson = 'HarleyDavidson',
  Honda = 'Honda',
  Hyundai = 'Hyundai',
  Jaguar = 'Jaguar',
  Kia = 'Kia',
  Mitsubishi = 'Mitsubishi',
  Opel = 'Opel',
  Peugeot = 'Peugeot',
  Seat = 'SEAT',
  Skoda = 'ŠKODA',
  Fiat = 'Fiat',
  Ford = 'Ford',
  Smart = 'Smart',
  Tesla = 'Tesla',
  LandRover = 'LandRover',
  Mini = 'Mini',
  Porsche = 'Porsche',
  Toyota = 'Toyota',
  Volvo = 'Volvo',
  Mercedes = 'Mercedes',
  Lexus = 'Lexus',
}
