import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { NAVIGATE_NEXT } from '@theia-cc/shared/helpers';
import { INavbarButton, IWizardBase } from '@theia-cc/shared/models';
import { IAppStateBase } from '../shared-store.model';

@Injectable()
export class WizardAction {
  static readonly SET_ALLOW_NEXT_STEP = 'SET_ALLOW_NEXT_STEP';
  static readonly SET_CURRENT_STEP = 'SET_CURRENT_STEP';
  static readonly SET_LAST_STEP = 'SET_LAST_STEP';
  static readonly SET_LOADING_SPINNER = 'SET_LOADING_SPINNER';
  static readonly SET_NAVBAR_PRIMARY_BUTTON = 'SET_NAVBAR_PRIMARY_BUTTON';
  static readonly SET_NAVBAR_SECONDARY_BUTTON = 'SET_NAVBAR_SECONDARY_BUTTON';
  static readonly SET_TOTAL_STEPS = 'SET_TOTAL_STEPS';
  static readonly SET_OFFER_PREVIEW_LOADING_SPINNER = 'SET_OFFER_PREVIEW_LOADING_SPINNER';
  static readonly SET_IS_SHARED_OFFER_PREVIEW = 'SET_IS_SHARED_OFFER_PREVIEW';
  static readonly SET_VERSION = 'SET_VERSION';
  static readonly SET_SHARE_BUTTON_VISIBILITY = 'SET_SHARE_BUTTON_VISIBILITY';
  static readonly SET_WIZARD_PROPS = 'SET_WIZARD_PROPS';

  constructor() {}

  @dispatch()
  showLoadingSpinner = () => ({
    type: WizardAction.SET_LOADING_SPINNER,
    payload: true,
  });

  @dispatch()
  hideLoadingSpinner = () => ({
    type: WizardAction.SET_LOADING_SPINNER,
    payload: false,
  });

  @dispatch()
  showOfferPreviewLoadingSpinner = () => ({
    type: WizardAction.SET_OFFER_PREVIEW_LOADING_SPINNER,
    payload: true,
  });

  @dispatch()
  hideOfferPreviewLoadingSpinner = () => ({
    type: WizardAction.SET_OFFER_PREVIEW_LOADING_SPINNER,
    payload: false,
  });

  @dispatch()
  allowNextStep = () => thDispatch => {
    thDispatch({
      type: WizardAction.SET_ALLOW_NEXT_STEP,
      payload: true,
    });
    thDispatch({
      type: WizardAction.SET_NAVBAR_PRIMARY_BUTTON,
      payload: {
        disabled: false,
      },
    });
  };

  @dispatch()
  disallowNextStep = () => thDispatch => {
    thDispatch({
      type: WizardAction.SET_ALLOW_NEXT_STEP,
      payload: false,
    });
    thDispatch({
      type: WizardAction.SET_NAVBAR_PRIMARY_BUTTON,
      payload: {
        disabled: true,
      },
    });
  };

  @dispatch()
  setNavbarPrimaryButtonDefault = () => ({
    type: WizardAction.SET_NAVBAR_PRIMARY_BUTTON,
    payload: {
      name: 'COMPONENT.NAVBAR.NEXT',
      action: NAVIGATE_NEXT,
      facet: 'outline-primary',
      disabled: true,
      hidden: false,
    },
  });

  @dispatch()
  setNavbarSecondaryButtonDefault = () => ({
    type: WizardAction.SET_NAVBAR_SECONDARY_BUTTON,
    payload: {
      name: 'COMPONENT.NAVBAR.NEXT',
      action: NAVIGATE_NEXT,
      facet: 'secondary',
      disabled: false,
      hidden: true,
    },
  });

  @dispatch()
  setNavbarPrimaryButtonProperties = (props: INavbarButton) => ({
    type: WizardAction.SET_NAVBAR_PRIMARY_BUTTON,
    payload: props,
  });

  @dispatch()
  setNavbarSecondaryButtonProperties = (props: INavbarButton) => ({
    type: WizardAction.SET_NAVBAR_SECONDARY_BUTTON,
    payload: props,
  });

  @dispatch()
  setCurrentStep = (currentStep: string, currentStepIdx: number) => (thDispatch, getState) => {
    const state: IAppStateBase = getState();
    const previousStep = state.wizard.currentStep;
    thDispatch({
      type: WizardAction.SET_CURRENT_STEP,
      payload: { previousStep, currentStep, currentStepIdx },
    });
  };

  @dispatch()
  setLastStep = (step: string) => thDispatch => {
    thDispatch({
      type: WizardAction.SET_LAST_STEP,
      payload: { step },
    });
  };

  @dispatch()
  setTotalSteps = (totalSteps: number) => thDispatch => {
    thDispatch({
      type: WizardAction.SET_TOTAL_STEPS,
      payload: { totalSteps },
    });
  };

  @dispatch()
  setIsSharedOfferPreview = payload => ({
    payload,
    type: WizardAction.SET_IS_SHARED_OFFER_PREVIEW,
  });

  @dispatch()
  setVersion = payload => ({
    payload,
    type: WizardAction.SET_VERSION,
  });

  @dispatch()
  setShareButtonVisibility = (payload: boolean) => ({
    payload,
    type: WizardAction.SET_SHARE_BUTTON_VISIBILITY,
  });

  @dispatch()
  setWizardProps = (payload: Partial<IWizardBase>) => ({
    payload,
    type: WizardAction.SET_WIZARD_PROPS,
  });
}
