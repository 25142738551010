import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'confirmation-modal',
  template: `
    <div class="modal-header">
      <div>{{ title }}</div>
      <button
        type="button"
        class="close pull-right"
        aria-label="Cancel"
        (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body modal-images__modal-body">
      <div [innerHTML]="question"></div>
      <div class="d-flex justify-content-between mt-5">
        <button type="button" class="btn btn-outline-primary with-shadow" (click)="onConfirm()">
          {{ confirmButtonName }}
        </button>
        <button type="button" class="btn btn-outline-secondary with-shadow" (click)="onCancel()">
          {{ closeButtonName }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmationModalComponent {
  @Input() public question = '';
  @Input() public title = '';
  @Input() public confirmButtonName = '';
  @Input() public closeButtonName = '';

  constructor(public bsModalRef: BsModalRef) {}

  public onConfirm(): void {
    this.bsModalRef.content.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.bsModalRef.content.onClose.next(false);
    this.bsModalRef.hide();
  }
}
