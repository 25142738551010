import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH } from '@api-cc';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { IPartnerConfigEm, PARTNER_CONFIG_EM } from '@theia-cc/em/core';
import { EmStateAction, EmStateEffect } from '@theia-cc/em/state';
import { HtStateAction, HtStateEffect } from '@theia-cc/ht/state';
import { IPartnerConfigPv, PARTNER_CONFIG_PV, ROOF_AREA_ITEMS } from '@theia-cc/pv/core';
import { PvStateAction, PvStateEffect } from '@theia-cc/pv/state';
import {
  caseAgnosticQueryParamFromLocation,
  CONSTANTS,
  ENVIRONMENT,
  ILocaleConfig,
  LOCALE_CONFIG,
  parseFormString,
  WIZARD_CONFIG,
} from '@theia-cc/shared/helpers';
import { SharedRootStepUiModule } from '@theia-cc/shared/root-step-ui';
import {
  getAppInitConfig,
  HttpErrorInterceptor,
  initAppWizardSettings,
  PartnerIdService,
  ShareLinkService,
  TranslateWithPartnerIdParser,
} from '@theia-cc/shared/services';
import { RelatedLeadAction, SharedStoreModule, WizardAction } from '@theia-cc/shared/store';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { WIZARD_CONFIG_PRIMEO } from './configs';
import { CONSTANTS_PRIMEO } from './configs/constants';
import { EntryStepGuard } from './core/entry-step-guard';
import { ParamsToCollectedDataResolverPrimeo } from './core/params-to-collected-data.resolver';
import { RedirectGuard } from './core/redirect.guard';
import { ShareLinkServicePrimeo } from './core/share-link.service';
import { PrimeoStateEffect } from './store/effects';
import { IConfigPrimeo } from './store/reducers';
import { StoreModule } from './store/store.module';

const LOCALE_CONFIG_PRIMEO: ILocaleConfig = {
  LOCALES: ['de', 'fr'],
  LOCALE_STRINGS_FORMAL: {
    de: 'assets/locales/de-formal.json',
    fr: 'assets/locales/fr-formal.json',
  },
  LOCALE_STRINGS_INFORMAL: {
    de: 'assets/locales/de-informal.json',
    fr: 'assets/locales/de-informal.json',
  },
};

export function modifyConfig(config: IConfigPrimeo): IConfigPrimeo {
  const getCaseAgnosticQueryParam = caseAgnosticQueryParamFromLocation();
  const tradesFromParams = parseFormString.array(getCaseAgnosticQueryParam('trade'));
  const b2b = parseFormString.boolean(getCaseAgnosticQueryParam('b2b'));
  const trades = b2b
    ? ['pv']
    : ['pv', 'ht', 'em'].filter(
        type =>
          !tradesFromParams.length ||
          tradesFromParams.includes(type) ||
          (type === 'ht' && tradesFromParams.includes('hp'))
      );
  config.highDrainSystemItems = config.highDrainSystemItems.filter(({ value }) =>
    trades.includes('pv')
      ? value === 'HEATPUMP'
        ? !trades.includes('ht')
        : value === 'EMOBILITY_CHARGING'
        ? !trades.includes('em')
        : true
      : true
  );
  config.trades = trades;
  config.roofAreaItems = b2b
    ? [
        ...ROOF_AREA_ITEMS,
        {
          value: 'GOOGLE_MAPS',
          caption: 'PV.ROOFAREA.INDIVIDUAL',
          icon: 'roof-big',
        },
      ]
    : ROOF_AREA_ITEMS;
  return config;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('./steps/steps.module').then(m => m.StepsModule),
          resolve: { collectedData: ParamsToCollectedDataResolverPrimeo },
          canActivate: [EntryStepGuard],
        },
      ],
      { scrollPositionRestoration: 'top' }
    ),
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      parser: {
        provide: TranslateParser,
        useClass: TranslateWithPartnerIdParser,
        deps: [PartnerIdService],
      },
    }),
    SharedRootStepUiModule,
    SharedStoreModule,
    StoreModule,
    DeviceDetectorModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    ScullyLibModule,
  ],
  providers: [
    getAppInitConfig(1, false, modifyConfig),
    {
      provide: APP_INITIALIZER,
      useFactory: initAppWizardSettings,
      multi: true,
      deps: [WizardAction],
    },
    ParamsToCollectedDataResolverPrimeo,
    CookieService,
    { provide: WIZARD_CONFIG, useValue: WIZARD_CONFIG_PRIMEO },
    { provide: LOCALE_CONFIG, useValue: LOCALE_CONFIG_PRIMEO },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: BASE_PATH, useValue: environment.apiEndpoint },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },

    {
      provide: PARTNER_CONFIG_EM,
      useValue: <IPartnerConfigEm>{
        CONFIG_PARTNER_KEYS: { default: 'DEFAULT' },
      },
    },
    {
      provide: PARTNER_CONFIG_PV,
      useValue: <IPartnerConfigPv>{
        CONFIG_PARTNER_KEYS: { default: 'DEFAULT' },
      },
    },

    {
      provide: CONSTANTS,
      useValue: CONSTANTS_PRIMEO,
    },
    { provide: ShareLinkService, useClass: ShareLinkServicePrimeo },
    EmStateEffect,
    PvStateEffect,
    HtStateEffect,
    EmStateAction,
    HtStateAction,
    PvStateAction,
    RelatedLeadAction,
    PrimeoStateEffect,
    RedirectGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
