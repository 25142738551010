import { ICar } from '../..';

export const CARS_HYUNDAI: ICar[] = [
  {
    value: 'HyundaiIoniq',
    caption: 'Hyundai Ioniq',
    icon: 'hyundai-ioniq.jpg',
  },
  {
    value: 'HyundaiKona',
    caption: 'Hyundai Kona',
    icon: 'hyundai-kona.jpg',
  },
];
