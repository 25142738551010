/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboEnergyConversionViewModel {
  /**
   * The used unit of this heat generator
   */
  Unit?: string;
  /**
   * \"Caloric value\" or the conversion factor for this unit to convert from kwh  [unit] * ConversionFactorFromKwh = kwh  kwh = [unit] / ConversionFactorFromKwh
   */
  ConversionFactorFromKwh?: number;
  /**
   * Cost to generate 1 kwh of heating energy output, in CHF
   */
  EnergyCost?: number;
}
