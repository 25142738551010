import { ICar } from '../..';

export const CARS_PEUGEOT: ICar[] = [
  {
    value: 'PeugeotiOn',
    caption: 'Peugeot iOn',
    icon: 'peugeot-ion.jpg',
    bidirectional: true,
  },
  {
    value: 'Peugeot208',
    caption: 'Peugeot 208',
    icon: 'peugeot-208.jpg',
  },
  {
    value: 'Peugeot508',
    caption: 'Peugeot 508',
    icon: 'peugeot-508.jpg',
  },
  {
    value: 'Peugeot2008',
    caption: 'Peugeot 2008',
    icon: 'peugeot-2008.jpg',
  },
  {
    value: 'Peugeot3008',
    caption: 'Peugeot 3008',
    icon: 'peugeot-3008.jpg',
  },
];
