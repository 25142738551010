/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboComparisonInvestmentRequest {
  /**
   * Investment in CHF for the new Heatpump
   */
  InvestmentNewHeatpump?: number;
  /**
   * The heating power in KW  (It's unused for now but it will be used later)
   */
  HeatingPower?: number;
}
