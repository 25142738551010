import { NgModule } from '@angular/core';
import { ConfigAction } from './config/config.action';
import { CurrentLanguageAction } from './current-language/current-language.action';
import { DeviceAction } from './device/device.action';
import { LeadMetaAction } from './lead-meta/lead-meta.action';
import { SharedStoreEffect } from './shared-store.effect';
import { UserInfoAction } from './user-info/user-info.action';
import { WizardAction } from './wizard/wizard.action';

@NgModule({
  providers: [
    ConfigAction,
    CurrentLanguageAction,
    DeviceAction,
    LeadMetaAction,
    UserInfoAction,
    WizardAction,
    SharedStoreEffect,
  ],
})
export class SharedStoreModule {}
