import { Pipe, PipeTransform } from '@angular/core';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { SanityImageService } from '@theia-cc/shared/services';

@Pipe({ name: 'sanityImage' })
export class SanityImagePipe implements PipeTransform {
  constructor(private sanityImageService: SanityImageService) {}

  transform(value: SanityImageSource, width?: number): string {
    if (width) {
      return this.sanityImageService.getImageUrlBuilder(value).width(width).auto('format').url();
    }
    return this.sanityImageService.getImageUrlBuilder(value).auto('format').url();
  }
}
