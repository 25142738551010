import { OfferStatusEnum } from '@theia-cc/pv/core';
import { IAppStatePv } from '@theia-cc/pv/state';

export const offerPreviewConfigSelector = (state: IAppStatePv) => state.config.offerPreview;

export const isFlatRoofSelector = (appState: IAppStatePv): boolean => {
  return appState.collectedData.roofTiltAngle !== null &&
    Number.isFinite(+appState.collectedData.roofTiltAngle)
    ? +appState.collectedData.roofTiltAngle <= 5
    : // default RoofType = SlopedRoof https://theia-helion.atlassian.net/browse/HP-3727
      false;
};

export const isOfferFinishedSelector = (state: IAppStatePv) => {
  return state.collectedData && state.collectedData.offerStatus === OfferStatusEnum.FINISHED;
};
