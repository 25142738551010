import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppStateBase, UserInfoAction } from '@theia-cc/shared/store';
import { BackendService } from './backend.service';
import { caseAgnosticQueryParam, cleanEmptyProperties } from '@theia-cc/shared/helpers';
import { NboLeadAddressViewModel, NboPostContactViewModel } from '@api-cc';

export const USER_INFO_FIELDS = [
  'lat',
  'lon',
  'egid',
  'City',
  'Street',
  'ZipCode',
  'Email',
  'FirstName',
  'LastName',
  'PhoneNumber',
  'Title',
  'CompanyName',
  'Debtor_ThirdPartyCustomerNumber',
  'Debtor_FirstName',
  'Debtor_LastName',
  'Debtor_PhoneNumber',
  'Debtor_Email',
  'Debtor_ZipCode',
  'Debtor_City',
  'Debtor_Street',
  'CarDealer_ThirdPartyCustomerNumber',
  'CarDealer_FirstName',
  'CarDealer_LastName',
  'CarDealer_PhoneNumber',
  'CarDealer_Email',
  'CarDealer_ZipCode',
  'CarDealer_City',
  'CarDealer_Street',
  'ServiceTechnician_ThirdPartyCustomerNumber',
  'ServiceTechnician_FirstName',
  'ServiceTechnician_LastName',
  'ServiceTechnician_PhoneNumber',
  'ServiceTechnician_Email',
  'ServiceTechnician_Region',
];

@Injectable()
export class UserInfoService {
  constructor(
    private backend: BackendService,
    private store: NgRedux<IAppStateBase>,
    private userInfoAction: UserInfoAction
  ) {}

  sendAdditionalAddresses(nboLeadId: string): Promise<void> {
    const { collectedData } = this.store.getState();
    const debitorAddress = collectedData?.debitorAddress;
    const serviceTechnicianAddress = collectedData?.serviceTechnicianAddress;
    const carDealerAddress = collectedData?.carDealerAddress;

    return this.backend
      .postAdditionalAddressToLead(
        nboLeadId,
        serviceTechnicianAddress,
        NboLeadAddressViewModel.AddressTypeEnum.ServiceTechnician
      )
      .then(() =>
        this.backend.postAdditionalAddressToLead(
          nboLeadId,
          carDealerAddress,
          NboLeadAddressViewModel.AddressTypeEnum.CarDealer
        )
      )
      .then(() => this.backend.postDebitorAddressToLead(nboLeadId, debitorAddress))
      .then(() => {
        this.userInfoAction.storeCarDealerAddress(null);
        this.userInfoAction.storeDebitorAddress(null);
        this.userInfoAction.storeServiceTechnicianAddress(null);
      });
  }

  storeAdditionalAddresses(queryParams: any): void {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams);

    this.userInfoAction.storeDebitorAddress(
      cleanEmptyProperties(this.getAddressFromQueryParams(queryParams, 'Debtor'))
    );

    this.userInfoAction.storeServiceTechnicianAddress(
      cleanEmptyProperties(this.getAddressFromQueryParams(queryParams, 'ServiceTechnician'))
    );

    const carDealerAddress: Partial<NboPostContactViewModel> = this.getAddressFromQueryParams(
      queryParams,
      'CarDealer'
    );
    const affiliateId = getCaseAgnosticQueryParam('affiliateId');
    if (affiliateId) {
      carDealerAddress.ThirdPartyCustomerNumber = affiliateId;
    }

    this.userInfoAction.storeCarDealerAddress(cleanEmptyProperties(carDealerAddress));
  }

  getAddressFromQueryParams(queryParams: any, type: string): Partial<NboPostContactViewModel> {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams);

    return [
      'ThirdPartyCustomerNumber',
      'FirstName',
      'LastName',
      'PhoneNumber',
      'Email',
      'ZipCode',
      'City',
      'Street',
      'Region',
    ].reduce((res, key) => {
      const value = getCaseAgnosticQueryParam(`${type}_${key}`);
      if (value) {
        res[key] = value;
      }
      return res;
    }, {});
  }
}
