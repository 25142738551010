export const GO_TO_CONTACT_FORM = 'GO_TO_CONTACT_FORM';
export const REQUEST_REMOTE_CHECK = 'REQUEST_REMOTE_CHECK';
export const NBO_TEMPLATE_HOME_INTELLIGENT = 'HOME_INTELLIGENT';
export const NBO_TEMPLATE_HOME_ADVANCED = '1 - Eleven';
export const FINISH_EM_CONTACT_COMMERCIAL = 'FINISH_EM_CONTACT_COMMERCIAL';
export const FINISH_CALLBACK_CONTACT = 'FINISH_CALLBACK_CONTACT';
export const FINISH_EM_CONTACT_PRIVATE = 'FINISH_EM_CONTACT_PRIVATE';
export const UNKNOWN = 'Unknown';
export const DOWNLOAD_OFFER_EM = 'DOWNLOAD_OFFER_EM';
export const FINISH_DEALER_CONTACT = 'FINISH_DEALER_CONTACT';
export const FINISH_DEALER_CALLBACK_CONTACT = 'FINISH_DEALER_CALLBACK_CONTACT';

export const AMAG_RESIDENTIAL_CHARGING_STATION_ALFEN_SINGLE_EVE_PRO =
  'AMAG_Resi_Alfen_Single-Eve-Pro-Line';
export const AMAG_RESIDENTIAL_CHARGING_STATION_GOE = 'AMAG_Resi_GO-E_GO-E-Charger';
export const AMAG_COMMERCIAL_CHARGING_STATION_ALFEN_SINGLE_EVE_PRO =
  'AMAG_Comm_Alfen_Single-Eve-Pro-Line';
export const AMAG_COMMERCIAL_CHARGING_STATION_GOE = 'AMAG_Comm_GO-E_GO-E-Charger';
export const AMAG_RESIDENTIAL_CHARGING_STATION_WALLBOX_BASIC = 'AMAG_Resi_WallboxBasic';
export const AMAG_RESIDENTIAL_CHARGING_STATION_WALLBOX_PLUS = 'AMAG_Resi_WallboxPlus';
export const AMAG_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC = 'AMAG_Resi_ZaptecGo_Basic';
export const AMAG_COMMERCIAL_CHARGING_STATION_WALLBOX_BASIC = 'AMAG_Comm_WallboxBasic';
export const AMAG_COMMERCIAL_CHARGING_STATION_WALLBOX_PLUS = 'AMAG_Comm_WallboxPlus';
export const AMAG_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC = 'AMAG_Comm_ZaptecGo_Basic';

export const BYES_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION = 'BYES_Resi_Green-Motion_Home-Two';
export const BYES_COMMERCIAL_CHARGING_STATION_GREEN_MOTION = 'BYES_Comm_Green-Motion_Home-Two';
export const BYES_RESIDENTIAL_CHARGING_STATION_WALLBOX_BASIC = 'BYES_Resi_WallboxBasic';
export const BYES_RESIDENTIAL_CHARGING_STATION_WALLBOX_PLUS = 'BYES_Resi_WallboxPlus';
export const BYES_COMMERCIAL_CHARGING_STATION_WALLBOX_BASIC = 'BYES_Comm_WallboxBasic';
export const BYES_COMMERCIAL_CHARGING_STATION_WALLBOX_PLUS = 'BYES_Comm_WallboxPlus';

export const CARVOL_RESIDENTIAL_CHARGING_STATION_KEBA_P30 = 'Carvol_Resi_Keba_P30-c-series';
export const CARVOL_RESIDENTIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE =
  'Carvol_Resi_Keba_P30-c-series-cable';
export const CARVOL_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE = 'Carvol_Resi_Alfen_Eve Pro-line';
export const CARVOL_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION = 'Carvol_Resi_Green-Motion_Home-Two';
export const CARVOL_RESIDENTIAL_CHARGING_STATION_MENNEKES = 'Carvol_Resi_Mennekes_Amtron-Compact';
export const CARVOL_COMMERCIAL_CHARGING_STATION_KEBA_P30 = 'Carvol_Comm_Keba_P30-c-series';
export const CARVOL_COMMERCIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE =
  'Carvol_Comm_Keba_P30-c-series-cable';
export const CARVOL_COMMERCIAL_CHARGING_STATION_ALFEN_EVE = 'Carvol_Comm_Alfen_Eve Pro-line';
export const CARVOL_COMMERCIAL_CHARGING_STATION_GREEN_MOTION = 'Carvol_Comm_Green-Motion_Home-Two';
export const CARVOL_COMMERCIAL_CHARGING_STATION_MENNEKES = 'Carvol_Comm_Mennekes_Amtron-Compact';

export const FORD_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX = 'Ford_Resi_ConnectedWallbox';
export const FORD_RESIDENTIAL_CHARGING_STATION_EASEE_HOME_PLUS = 'Ford_Resi_EaseeHomePlus';
export const FORD_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC = 'Ford_Resi_ZaptecGo_Basic';
export const FORD_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX = 'Ford_Comm_ConnectedWallbox';
export const FORD_COMMERCIAL_CHARGING_STATION_EASEE_HOME_PLUS = 'Ford_Comm_EaseeHomePlus';
export const FORD_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC = 'Ford_Comm_ZaptecGo_Basic';

export const HELION_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC = 'Helion_Comm_ZaptecGo_Basic';
export const HELION_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC = 'Helion_Resi_ZaptecGo_Basic';

export const TCS_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE_SINGLE = 'E360_Resi_Alfen_Eve-Single';
export const TCS_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE_SINGLE_DISPLAY =
  'E360_Resi_Alfen_Eve-Single-Display';
export const TCS_COMMERCIAL_CHARGING_STATION_ALFEN_EVE_SINGLE = 'E360_Comm_Alfen_Eve-Single';
export const TCS_COMMERCIAL_CHARGING_STATION_ALFEN_EVE_SINGLE_DISPLAY =
  'E360_Comm_Alfen_Eve-Single-Display';

export const EATON_RESIDENTIAL_CHARGING_STATION_X_CHARGE_IN_HOME =
  'Green-Motion_Resi_Green-motion_Home-Two';
export const EATON_COMMERCIAL_CHARGING_STATION_X_CHARGE_IN_HOME =
  'Green-Motion_Comm_Green-motion_Home-Two';

export const HONDA_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION = 'Honda_Resi_Green-Motion_Home-Two';
export const HONDA_COMMERCIAL_CHARGING_STATION_GREEN_MOTION = 'Honda_Comm_Green-Motion_Home-Two';

export const RENAULT_RESIDENTIAL_CHARGING_STATION_KEBA_CONTACT =
  'Renault_Resi_KEBA_KeContact-P30-Wallbox';
export const RENAULT_COMMERCIAL_CHARGING_STATION_KEBA_CONTACT =
  'Renault_Comm_KEBA_KeContact-P30-Wallbox';

export const NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_STANDARD = 'New-Motion_Resi_Home_Standard';
export const NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_FAST = 'New-Motion_Resi_Home_Fast';
export const NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_ADVANCED = 'New-Motion_Resi_Home_Advanced';
export const NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_STANDARD = 'New-Motion_Comm_Home_Standard';
export const NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_FAST = 'New-Motion_Comm_Home_Fast';
export const NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_ADVANCED = 'New-Motion_Comm_Home_Advanced';

export const PRIMEO_RESIDENTIAL_CHARGING_STATION_KEBA_P30 = 'EcoEstate_Resi_Keba_P30-c-series';
export const PRIMEO_RESIDENTIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE =
  'EcoEstate_Resi_Keba_P30-c-series-cable';
export const PRIMEO_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE = 'EcoEstate_Resi_Alfen_Eve Pro-line';
export const PRIMEO_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION =
  'EcoEstate_Resi_Green-Motion_Home-Two';
export const PRIMEO_RESIDENTIAL_CHARGING_STATION_MENNEKES =
  'EcoEstate_Resi_Mennekes_Amtron-Compact';
export const PRIMEO_COMMERCIAL_CHARGING_STATION_KEBA_P30 = 'EcoEstate_Comm_Keba_P30-c-series';
export const PRIMEO_COMMERCIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE =
  'EcoEstate_Comm_Keba_P30-c-series-cable';
export const PRIMEO_COMMERCIAL_CHARGING_STATION_ALFEN_EVE = 'EcoEstate_Comm_Alfen_Eve Pro-line';
export const PRIMEO_COMMERCIAL_CHARGING_STATION_GREEN_MOTION =
  'EcoEstate_Comm_Green-Motion_Home-Two';
export const PRIMEO_COMMERCIAL_CHARGING_STATION_MENNEKES = 'EcoEstate_Comm_Mennekes_Amtron-Compact';

export const NISSAN_RESIDENTIAL_CHARGING_STATION_PULSAR_PLUS = 'Nissan_Resi_Wallbox_Pulsar-Plus';
export const NISSAN_COMMERCIAL_CHARGING_STATION_PULSAR_PLUS = 'Nissan_Comm_Wallbox_Pulsar-Plus';
export const NISSAN_RESIDENTIAL_CHARGING_STATION_PULSAR_PLUS_POWERBOOST =
  'Nissan_Resi_Wallbox_Pulsar-Plus-Powerboost';
export const NISSAN_COMMERCIAL_CHARGING_STATION_PULSAR_PLUS_POWERBOOST =
  'Nissan_Comm_Wallbox_Pulsar-Plus-Powerboost';

export const PORSCHE_RESIDENTIAL_INSTALLATION_ONLY = 'Porsche_Resi_InstallationOnly';
export const PORSCHE_COMMERCIAL_INSTALLATION_ONLY = 'Porsche_Comm_InstallationOnly';

export const LEXUS_RESIDENTIAL_CHARGING_STATION_ENTRY_WALLBOX = 'Lexus_Resi_EntryWallbox';
export const LEXUS_COMMERCIAL_CHARGING_STATION_ENTRY_WALLBOX = 'Lexus_Comm_EntryWallbox';
export const LEXUS_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX = 'Lexus_Resi_ConnectedWallbox';
export const LEXUS_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX = 'Lexus_Comm_ConnectedWallbox';
export const LEXUS_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS =
  'Lexus_Resi_ConnectedWallboxPlus';
export const LEXUS_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS =
  'Lexus_Comm_ConnectedWallboxPlus';
export const LEXUS_RESIDENTIAL_CHARGING_STATION_DOUBLE_WALLBOX = 'Lexus_Resi_DoubleWallbox';
export const LEXUS_COMMERCIAL_CHARGING_STATION_DOUBLE_WALLBOX = 'Lexus_Comm_DoubleWallbox';

export const TOYOTA_RESIDENTIAL_CHARGING_STATION_ENTRY_WALLBOX = 'Toyota_Resi_EntryWallbox';
export const TOYOTA_COMMERCIAL_CHARGING_STATION_ENTRY_WALLBOX = 'Toyota_Comm_EntryWallbox';
export const TOYOTA_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX = 'Toyota_Resi_ConnectedWallbox';
export const TOYOTA_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX = 'Toyota_Comm_ConnectedWallbox';
export const TOYOTA_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS =
  'Toyota_Resi_ConnectedPlusWallbox';
export const TOYOTA_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS =
  'Toyota_Comm_ConnectedPlusWallbox';
export const TOYOTA_RESIDENTIAL_CHARGING_STATION_DOUBLE_WALLBOX = 'Toyota_Resi_DoubleWallbox';
export const TOYOTA_COMMERCIAL_CHARGING_STATION_DOUBLE_WALLBOX = 'Toyota_Comm_DoubleWallbox';
