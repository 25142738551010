/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PublicLeadProjectAdditionalComponentsViewModel } from './publicLeadProjectAdditionalComponentsViewModel';
import { PublicLeadProjectTypeViewModel } from './publicLeadProjectTypeViewModel';
import { PublicProjectChecklistStepViewModel } from './publicProjectChecklistStepViewModel';
import { PublicUserViewModel } from './publicUserViewModel';

export interface PublicLeadProjectViewModel {
  OrderNumber?: string;
  Sales?: PublicUserViewModel;
  ProjectManager?: PublicUserViewModel;
  Planner?: PublicUserViewModel;
  PlannerAc?: PublicUserViewModel;
  SiteManager?: string;
  SiteManagerPhoneNumber?: string;
  TimeConstraints?: boolean;
  ReasonTimeConstraints?: string;
  ProjectType?: PublicLeadProjectTypeViewModel;
  ProjectSize?: number;
  ProjectSapNumber?: string;
  ProjectName?: string;
  AdditionalComponents?: Array<PublicLeadProjectAdditionalComponentsViewModel>;
  OrderType?: string;
  PreferredCalendarWeek?: string;
  InstallationStartDate?: Date;
  InstallationEndDate?: Date;
  CommissioningDate?: Date;
  EstimatedInvoiceDate?: Date;
  AcceptanceDate?: Date;
  LogisticsDate?: Date;
  ChecklistSteps?: Array<PublicProjectChecklistStepViewModel>;
  ProjectPhase?: PublicLeadProjectViewModel.ProjectPhaseEnum;
}
export namespace PublicLeadProjectViewModel {
  export type ProjectPhaseEnum =
    | 'Definition'
    | 'Planning'
    | 'Sourcing'
    | 'Preparation'
    | 'Installation'
    | 'Closure';
  export const ProjectPhaseEnum = {
    Definition: 'Definition' as ProjectPhaseEnum,
    Planning: 'Planning' as ProjectPhaseEnum,
    Sourcing: 'Sourcing' as ProjectPhaseEnum,
    Preparation: 'Preparation' as ProjectPhaseEnum,
    Installation: 'Installation' as ProjectPhaseEnum,
    Closure: 'Closure' as ProjectPhaseEnum,
  };
}
