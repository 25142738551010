import { ICar } from '../..';

export const CARS_MERCEDES_BENZ: ICar[] = [
  {
    value: 'MercedesC',
    caption: 'Mercedes-Benz C',
    icon: 'mercedes-benz-c.jpg',
  },
  {
    value: 'MercedesE',
    caption: 'Mercedes-Benz E',
    icon: 'mercedes-benz-e.jpg',
  },
  {
    value: 'MercedesGLE',
    caption: 'Mercedes-Benz GLE',
    icon: 'mercedes-benz-gle.jpg',
  },
  {
    value: 'MercedesS',
    caption: 'Mercedes-Benz S',
    icon: 'mercedes-benz-s.jpg',
  },
  {
    value: 'MercedesEQC',
    caption: 'Mercedes-Benz EQC',
    icon: 'mercedes-benz-eqc.jpg',
  },
  {
    value: 'MercedesGLC',
    caption: 'Mercedes-Benz GLC',
    icon: 'mercedes-benz-glc.jpg',
  },
  {
    value: 'MercedesA',
    caption: 'Mercedes-Benz A',
    icon: 'mercedes-benz-a.jpg',
  },
];
