/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PublicLeadProjectViewModel } from './publicLeadProjectViewModel';

export interface NboLeadViewModel {
  LeadId?: number;
  PublicLeadId?: string;
  ShareUrl?: string;
  Stage?: string;
  Status?: string;
  Mandator?: string;
  ComplexityType?: string;
  Origin?: string;
  Tag?: string;
  ManualProjectInfo?: string;
  PartnerId?: string;
  TradeTypeCode?: string;
  ProviderLeadId?: string;
  Language?: NboLeadViewModel.LanguageEnum;
  Phase?: NboLeadViewModel.PhaseEnum;
  ChecklistStepCompletedNotificationEnabled?: boolean;
  Project?: PublicLeadProjectViewModel;
}
export namespace NboLeadViewModel {
  export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
  export const LanguageEnum = {
    DE: 'DE' as LanguageEnum,
    FR: 'FR' as LanguageEnum,
    IT: 'IT' as LanguageEnum,
    EN: 'EN' as LanguageEnum,
  };
  export type PhaseEnum = 'Nbo' | 'Bo' | 'Project' | 'Break';
  export const PhaseEnum = {
    Nbo: 'Nbo' as PhaseEnum,
    Bo: 'Bo' as PhaseEnum,
    Project: 'Project' as PhaseEnum,
    Break: 'Break' as PhaseEnum,
  };
}
