<ng-container *ngIf="notDigitalUploadStep$ | async">
  <div
    *ngIf="(isPhoneDevice && !(isFirstStep$ | async)) || !isPhoneDevice"
    class="footer-floating"
    [class.footer-floating_with-links]="!!(footerLinks$ | async)">
    <div
      class="container footer-floating__inner"
      [ngClass]="
        (footerLinks$ | async) ? 'footer-floating__inner_h_70' : 'footer-floating__inner_h_100'
      ">
      <div class="footer-floating__left">
        <ng-container *ngIf="!(newDesign$ | async)">
          <app-back-arrow *ngIf="isShowBackButton$ | async"></app-back-arrow>
        </ng-container>

        <div *ngIf="newDesign$ | async" class="d-flex align-items-center h-100">
          <img src="assets/img/header/fws.png" style="max-height: 75%" class="mr-4" />
          <img src="assets/img/header/swissolar.png" style="max-height: 75%" />
          <img src="assets/img/header/solar-profis.png" style="max-height: 75%" class="mr-2" />
        </div>
      </div>
      <div class="footer-floating__right">
        <div
          *ngIf="(isFirstStep$ | async) && !primaryButtonDisabled"
          class="footer-floating__arrow bounce">
          <app-icon [name]="'arrow-right'" [size]="72" [resize]="48"></app-icon>
        </div>

        <ng-container *ngFor="let button of buttons$ | async">
          <app-button
            *ngIf="!button.hidden"
            [facet]="button.facet"
            (buttonClicked)="buttonClick(button.action, button.disabled)"
            [disabled]="button.disabled"
            [shadow]="button.shadow !== false"
            [icon]="button.icon"
            [hidden]="button.hidden"
            [attr.data-test]="button.action">
            {{ button.name | translate }}
          </app-button>
        </ng-container>

        <a
          *ngIf="photoProtocolLinkSelector$ | async as photoProtocolLinkSelector"
          (click)="buttonClick(photoProtocolLinkSelector.action, false)"
          target="_blank"
          [href]="photoProtocolLinkSelector.href"
          class="btn btn-secondary with-shadow d-none d-md-block text-decoration-none ml-3"
          [attr.data-test]="'Foto-Protokoll'">
          {{ 'HT.OFFERPREVIEW.PHOTO_PROTOCOL' | translate }}
        </a>

        <ng-container *ngIf="isShowOfferPreviewButton$ | async">
          <app-share-button></app-share-button>
        </ng-container>
      </div>
    </div>

    <div class="footer-floating__links" *ngIf="footerLinks$ | async as footerLinks">
      <a
        class="footer-floating__link"
        *ngIf="footerLinks.privacyStatement"
        [href]="footerLinks.privacyStatement"
        target="_blank"
        >{{ 'COMPONENT.FOOTER.LINK.PRIVACY_STATEMENT' | translate }}
      </a>
      <a
        class="footer-floating__link"
        *ngIf="footerLinks.legalNotices"
        [href]="footerLinks.legalNotices"
        target="_blank">
        {{ 'COMPONENT.FOOTER.LINK.LEGAL_NOTICES' | translate }}
      </a>
      <a
        class="footer-floating__link"
        *ngIf="footerLinks.GC"
        [href]="footerLinks.GC"
        target="_blank">
        {{ 'COMPONENT.FOOTER.LINK.GC' | translate }}
      </a>
    </div>
  </div>
</ng-container>
