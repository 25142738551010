import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'interpolateStringWithObjectValues' })
export class InterpolateStringWithObjectValuesPipe implements PipeTransform {
  transform(value: string, args: any): string {
    if (!value || !args) {
      return value;
    }

    return value.replace(/\{\{\s*(\w+)\s*\}\}/g, (match, token) => {
      return args[token] || match;
    });
  }
}
