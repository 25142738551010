/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EmobilityVariantSummaryViewModel {
  /**
   * The objectdata template id that has been used to create this variant.
   */
  ObjectdataTemplateId?: number;
  /**
   * The variant's id. It will be null if the variant has not been persisted.
   */
  VariantId?: number;
  /**
   * The variant's title. It may be empty if the variant is not persisted.
   */
  VariantTitle?: string;
  /**
   * HoursToFullChargeAc of the car, depends on charging power too
   */
  HoursToFullChargeAc?: number;
  /**
   * HoursToFullChargeDc of the car, depends on charging power too
   */
  HoursToFullChargeDc?: number;
  /**
   * MaxChargingPowerAc of the car
   */
  MaxChargingPowerAc?: number;
  /**
   * MaxChargingPowerDc of the car
   */
  MaxChargingPowerDc?: number;
  /**
   * BatteryCapacity of the car
   */
  BatteryCapacity?: number;
  /**
   * Charging power of the chosen charging station
   */
  ChargingPower?: number;
  /**
   * The AC charging plug of the car, translated
   */
  ChargingPlugAc?: string;
  /**
   * The DC charging plug of the car, translated
   */
  ChargingPlugDc?: string;
  /**
   * The AC charging plug position of the car, translated
   */
  ChargingPlugPositionAc?: string;
  /**
   * The DC charging plug position of the car, translated
   */
  ChargingPlugPositionDc?: string;
  /**
   * The range after 20min
   */
  RangeAfterTwentyMinutes?: number;
  /**
   * The range after 8h
   */
  RangeAfterEightHours?: number;
  /**
   * The price of the charging station, includes cables and other stuff  It is the total netto price of the group \"ChargingStationHardware\"
   */
  ChargingStationHardwarePrice?: number;
  /**
   * The price of the installation  It is the total netto price of the group \"Installation\"
   */
  InstallationPrice?: number;
  /**
   * The VAT (mwst)
   */
  Vat?: number;
  /**
   * The Subsidy Amount is dependent on the car manufacturer.
   */
  CarManufacturerSubsidy?: number;
  /**
   * Total Price = Brutto - CarManufacturerSubsidy
   */
  TotalPrice?: number;
  /**
   * The type of the dynamic billing system.
   */
  DynamicBillingSystemType?: string;
}
