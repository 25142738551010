/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { EmobilityVariantSummaryViewModel } from '../model/emobilityVariantSummaryViewModel';
import { EmobilityVariantSummaryViewModelLeadSummaryViewModel } from '../model/emobilityVariantSummaryViewModelLeadSummaryViewModel';
import { NboEmobilityCalculateRequest } from '../model/nboEmobilityCalculateRequest';
import { NboEmobilityLeadViewModel } from '../model/nboEmobilityLeadViewModel';
import { SaveNboEmobilityRequest } from '../model/saveNboEmobilityRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class NboEmobilityService {
  protected basePath = 'https://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Calculates the variant summaries of an NBO given the object data  A summary is created for each matching template
   *
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param body Object Data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboEmobilityCalculateNboForTemplate(
    appKey?: string,
    body?: NboEmobilityCalculateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmobilityVariantSummaryViewModel>;
  public nboEmobilityCalculateNboForTemplate(
    appKey?: string,
    body?: NboEmobilityCalculateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmobilityVariantSummaryViewModel>>;
  public nboEmobilityCalculateNboForTemplate(
    appKey?: string,
    body?: NboEmobilityCalculateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmobilityVariantSummaryViewModel>>;
  public nboEmobilityCalculateNboForTemplate(
    appKey?: string,
    body?: NboEmobilityCalculateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<EmobilityVariantSummaryViewModel>(
      `${this.basePath}/api/nbo/em/calculate-template`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This is a dummy calculation method for search engines
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboEmobilityGetCalculateNboForTemplate(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboEmobilityGetCalculateNboForTemplate(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboEmobilityGetCalculateNboForTemplate(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboEmobilityGetCalculateNboForTemplate(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/api/nbo/em/calculate-template`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Gets the matching template names.
   *
   * @param appKey The application key.
   * @param whereToInstallCharger Type of where to install the charger - decides which product line is used (Wtic_IndividualSolution, Wtic_CommunitySolution) - former ProductlineType
   * @param orderType Type of order (AccordingToCost, FlatRate) - for IndividualSolutions the OrderType can be set via CC - for CommunitySolutions it defaults to AccordingToCost
   * @param smartEnergyConnection The smart energy connection.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboEmobilityGetMatchingTemplateNames(
    appKey: string,
    whereToInstallCharger?: 'Wtic_IndividualSolution' | 'Wtic_CommunitySolution',
    orderType?: 'AccordingToCost' | 'FlatRate',
    smartEnergyConnection?: 'Desired' | 'Undesired',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public nboEmobilityGetMatchingTemplateNames(
    appKey: string,
    whereToInstallCharger?: 'Wtic_IndividualSolution' | 'Wtic_CommunitySolution',
    orderType?: 'AccordingToCost' | 'FlatRate',
    smartEnergyConnection?: 'Desired' | 'Undesired',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public nboEmobilityGetMatchingTemplateNames(
    appKey: string,
    whereToInstallCharger?: 'Wtic_IndividualSolution' | 'Wtic_CommunitySolution',
    orderType?: 'AccordingToCost' | 'FlatRate',
    smartEnergyConnection?: 'Desired' | 'Undesired',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public nboEmobilityGetMatchingTemplateNames(
    appKey: string,
    whereToInstallCharger?: 'Wtic_IndividualSolution' | 'Wtic_CommunitySolution',
    orderType?: 'AccordingToCost' | 'FlatRate',
    smartEnergyConnection?: 'Desired' | 'Undesired',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (appKey === null || appKey === undefined) {
      throw new Error(
        'Required parameter appKey was null or undefined when calling nboEmobilityGetMatchingTemplateNames.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }
    if (whereToInstallCharger !== undefined && whereToInstallCharger !== null) {
      queryParameters = queryParameters.set('whereToInstallCharger', <any>whereToInstallCharger);
    }
    if (orderType !== undefined && orderType !== null) {
      queryParameters = queryParameters.set('orderType', <any>orderType);
    }
    if (smartEnergyConnection !== undefined && smartEnergyConnection !== null) {
      queryParameters = queryParameters.set('smartEnergyConnection', <any>smartEnergyConnection);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(`${this.basePath}/api/nbo/em/templates`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Gets the nbo lead by public lead id.
   *
   * @param publicId The public identifier.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboEmobilityGetNboEmobilityLead(
    publicId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboEmobilityLeadViewModel>;
  public nboEmobilityGetNboEmobilityLead(
    publicId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboEmobilityLeadViewModel>>;
  public nboEmobilityGetNboEmobilityLead(
    publicId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboEmobilityLeadViewModel>>;
  public nboEmobilityGetNboEmobilityLead(
    publicId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (publicId === null || publicId === undefined) {
      throw new Error(
        'Required parameter publicId was null or undefined when calling nboEmobilityGetNboEmobilityLead.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<NboEmobilityLeadViewModel>(
      `${this.basePath}/api/nbo/em/leads/${encodeURIComponent(String(publicId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Generates a PDF preview of a variant
   *
   * @param leadId Id of the lead to which the variant belongs.
   * @param variantId Variant Id of the variant for which the preview is generated
   * @param appKey App key for authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboEmobilityGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboEmobilityGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboEmobilityGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboEmobilityGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboEmobilityGetVariantPreview.'
      );
    }

    if (variantId === null || variantId === undefined) {
      throw new Error(
        'Required parameter variantId was null or undefined when calling nboEmobilityGetVariantPreview.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(
      `${this.basePath}/api/nbo/em/leads/${encodeURIComponent(
        String(leadId)
      )}/variants/${encodeURIComponent(String(variantId))}/preview`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Save an NBO for EM.
   *
   * @param appKey App key which corresponds to the mandant for which this lead is created
   * @param body Object and metadata for variants which shall be created and              contact data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboEmobilitySaveNbo(
    appKey?: string,
    body?: SaveNboEmobilityRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmobilityVariantSummaryViewModelLeadSummaryViewModel>;
  public nboEmobilitySaveNbo(
    appKey?: string,
    body?: SaveNboEmobilityRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmobilityVariantSummaryViewModelLeadSummaryViewModel>>;
  public nboEmobilitySaveNbo(
    appKey?: string,
    body?: SaveNboEmobilityRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmobilityVariantSummaryViewModelLeadSummaryViewModel>>;
  public nboEmobilitySaveNbo(
    appKey?: string,
    body?: SaveNboEmobilityRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<EmobilityVariantSummaryViewModelLeadSummaryViewModel>(
      `${this.basePath}/api/nbo/em`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
