import { ICar } from '../..';

export const CARS_OPEL: ICar[] = [
  {
    value: 'OpelAmpera',
    caption: 'Opel Ampera',
    icon: 'opel-ampera.jpg',
  },
  {
    value: 'OpelGrandlandX',
    caption: 'Opel Grandland X',
    icon: 'opel-grandland-x.jpg',
  },
  {
    value: 'OpelCorsaE',
    caption: 'Opel Corsa-e',
    icon: 'opel-corsa-e.jpg',
  },
];
