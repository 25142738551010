import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      [className]="'position-relative btn btn-' + facet"
      [ngClass]="{
        'with-shadow': shadow,
        'btn-block': block,
        'd-flex': icon
      }"
      type="button"
      [disabled]="disabled"
      (click)="buttonClicked.emit($event)">
      <app-loading-spinner *ngIf="loading" [facet]="'button'"></app-loading-spinner>
      <app-icon *ngIf="icon" [name]="icon" [size]="32"></app-icon>
      <span><ng-content></ng-content></span>
    </button>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() buttonSpecialLabel: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() shadow: boolean;
  @Input() block: boolean;
  @Input() icon: string;
  @Input() facet: 'outline-primary' | 'primary' | 'outline-secondary' | 'secondary' | 'link' =
    'primary';
  @Output() buttonClicked = new EventEmitter<any>();
}
