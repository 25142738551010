import { IWizardBase } from '../../../../models/src/lib/shared.models';
import { WizardAction } from './wizard.action';

export const INITIAL_WIZARD: IWizardBase = {
  version: 1,
  isSharedOfferPreview: undefined,
  showLoadingSpinner: false,
  showOfferPreviewLoadingSpinner: false,
  allowNextStep: false,
  currentStep: null,
  previousStep: null,
  currentStepIdx: null,
  lastStep: null,
  totalSteps: null,
  currentStepData: null,
  navbar: {
    primaryButton: {
      name: null,
      action: null,
      facet: null,
      disabled: null,
      hidden: null,
    },
    secondaryButton: {
      name: null,
      action: null,
      facet: null,
      disabled: null,
      hidden: null,
    },
    showShareButton: true,
  },
};

export const wizardReducer = (state: IWizardBase = INITIAL_WIZARD, action): IWizardBase => {
  switch (action.type) {
    case WizardAction.SET_LOADING_SPINNER:
      return {
        ...state,
        showLoadingSpinner: action.payload,
      };

    case WizardAction.SET_OFFER_PREVIEW_LOADING_SPINNER:
      return {
        ...state,
        showOfferPreviewLoadingSpinner: action.payload,
      };

    case WizardAction.SET_ALLOW_NEXT_STEP:
      return {
        ...state,
        allowNextStep: action.payload,
      };

    case WizardAction.SET_NAVBAR_PRIMARY_BUTTON:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          primaryButton: { ...state.navbar.primaryButton, ...action.payload },
        },
      };

    case WizardAction.SET_NAVBAR_SECONDARY_BUTTON:
      const secondaryButton = {
        ...state.navbar.secondaryButton,
        ...action.payload,
      };

      return {
        ...state,
        navbar: {
          ...state.navbar,
          secondaryButton: {
            ...secondaryButton,
            facet: state.footerSecondaryButtonClass ?? secondaryButton.facet,
          },
        },
      };

    case WizardAction.SET_CURRENT_STEP:
      return {
        ...state,
        ...action.payload,
      };

    case WizardAction.SET_LAST_STEP:
      return {
        ...state,
        lastStep: action.payload.step,
      };

    case WizardAction.SET_TOTAL_STEPS:
      return {
        ...state,
        totalSteps: action.payload.totalSteps,
      };

    case WizardAction.SET_IS_SHARED_OFFER_PREVIEW:
      return {
        ...state,
        isSharedOfferPreview: action.payload,
      };

    case WizardAction.SET_VERSION:
      return {
        ...state,
        version: action.payload,
      };

    case WizardAction.SET_SHARE_BUTTON_VISIBILITY:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          showShareButton: action.payload,
        },
      };

    case WizardAction.SET_WIZARD_PROPS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
