import { NgRedux } from '@angular-redux/store';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  caseAgnosticQueryParamFromLocation,
  ILocaleConfig,
  isOnScullyRenderer,
  LOCALE_CONFIG,
} from '@theia-cc/shared/helpers';
import { ILanguageSpecific } from '@theia-cc/shared/models';
import { CurrentLanguageAction, IAppStateBase } from '@theia-cc/shared/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private locales: ILocaleConfig = {
    LOCALES: this.localeConfig.LOCALES,
    LOCALE_STRINGS_FORMAL: { de: null },
    LOCALE_STRINGS_INFORMAL: { de: null },
  };

  constructor(
    private store: NgRedux<IAppStateBase>,
    private currentLanguageAction: CurrentLanguageAction,
    private translate: TranslateService,
    private titleService: Title,
    private http: HttpClient,
    @Inject(LOCALE_CONFIG) private localeConfig: ILocaleConfig
  ) {}
  setInitialLanguage(): Promise<void> {
    const appState: IAppStateBase = this.store.getState();
    const getCaseAgnosticQueryParam = caseAgnosticQueryParamFromLocation();
    const langQParam = getCaseAgnosticQueryParam('Language')?.toLowerCase();

    const browserLanguage =
      window.navigator.language && window.navigator.language.split(/[-_]/).shift();

    let currentLanguage;

    if (this.localeConfig.LOCALES.includes(langQParam as keyof ILanguageSpecific<object>)) {
      currentLanguage = langQParam;
    }

    if (
      !currentLanguage &&
      this.localeConfig.LOCALES.includes(browserLanguage as keyof ILanguageSpecific<object>)
    ) {
      currentLanguage = browserLanguage;
    }

    if (!currentLanguage || appState.config.envVariables.disableLanguageChoice) {
      currentLanguage = appState.config.defaultLanguage;
    }

    return this.setCurrentLanguage(currentLanguage);
  }

  setCurrentLanguage(localeToSet: string): Promise<void> {
    const appState: IAppStateBase = this.store.getState();
    const { org } = appState.config;
    const { localeStyle } = appState.config.envVariables;

    const locale = appState.config.envVariables.disableLanguageChoice
      ? appState.config.defaultLanguage
      : localeToSet;

    const translationsType =
      localeStyle === 'informal' ? 'LOCALE_STRINGS_INFORMAL' : 'LOCALE_STRINGS_FORMAL';
    const locale$ = this.locales[translationsType][locale]
      ? of(this.locales[translationsType][locale])
      : this.http.get(this.localeConfig[translationsType][locale]).pipe(
          // map((res: any) => res),
          tap(translations => (this.locales[translationsType][locale] = translations))
        );

    return locale$
      .pipe(
        tap(translations => {
          this.translate.setTranslation(locale, translations);

          this.translate.use(locale);

          const appName = this.translate.instant('APP_NAME')
            ? ` | ${this.translate.instant('APP_NAME')}`
            : '';
          this.titleService.setTitle(isOnScullyRenderer() ? '' : `${org.name}${appName}`);
          this.currentLanguageAction.currentLanguageSet(locale);
        })
      )
      .toPromise();
  }
}
