import { Component, Inject, NgModule, ViewEncapsulation } from '@angular/core';
import { AlertService, ShareLinkService } from '@theia-cc/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@angular-redux/store';
import { IAppStateBase } from '@theia-cc/shared/store';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';

@Component({
  selector: ' app-share-button',
  template: `
    <app-icon
      class="share-button"
      [name]="shareIconName"
      size="32"
      (click)="shareOfferPreview($event, shareLink)"></app-icon>
    <input type="hidden" value="" #shareLink id="shareLink" />
  `,
  host: { class: 'd-flex' },
  encapsulation: ViewEncapsulation.None,
})
export class ShareButtonComponent {
  public shareIconName: string = 'share';

  constructor(
    private alertService: AlertService,
    private translate: TranslateService,
    private store: NgRedux<IAppStateBase>,
    private shareLinkService: ShareLinkService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    const { shareIconName } = this.store.getState().config.envVariables;
    if (shareIconName) {
      this.shareIconName = shareIconName;
    }
  }

  shareOfferPreview(event: MouseEvent, shareLinkRef: HTMLInputElement) {
    try {
      shareLinkRef.value = this.generateShareLink();
      shareLinkRef.type = 'text';

      /* Select the text field */
      shareLinkRef.select();
      shareLinkRef.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy');

      shareLinkRef.type = 'hidden';

      const translation = this.translate.instant('OFFERPREVIEW.SHARE.SUCCESS');
      this.alertService.alert({
        type: 'success',
        dismissible: true,
        dismissOnTimeout: 5000,
        content: translation,
      });
    } catch (e) {
      this.alertService.alert({
        type: 'danger',
        dismissible: true,
        dismissOnTimeout: 3000,
        content: 'Some error occurred',
      });
    }
  }

  generateShareLink() {
    return this.shareLinkService.generateShareLink(this.document.location, this.store.getState());
  }
}

@NgModule({
  declarations: [ShareButtonComponent],
  imports: [CommonModule, SharedUtilUiModule, TranslateModule],
  exports: [ShareButtonComponent],
})
export class ShareButtonModule {}
