import { IUserInfo } from '@theia-cc/shared/models';
import { UserInfoAction } from './user-info.action';

export const USER_INFO_INITIAL: IUserInfo = {
  userAddress: {
    zip: null,
    place: null,
    street: null,
    lat: null,
    lon: null,
    x: null,
    y: null,
    addressString: null,
    hasGeometry: null,
  },
  user: {
    companyName: null,
    email: null,
    emailDisclaimer: null,
    firstName: null,
    lastName: null,
    telephone: null,
    title: null,
  },
  debitorAddress: null,
  serviceTechnicianAddress: null,
  carDealerAddress: null,
};

export const userInfoReducer = (state: IUserInfo = USER_INFO_INITIAL, action) => {
  const { payload, type } = action;

  switch (type) {
    case UserInfoAction.STORE_USER_ADDRESS:
      return {
        ...state,
        userAddress: { ...state.userAddress, ...payload },
      };

    case UserInfoAction.STORE_USER:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };

    case UserInfoAction.STORE_DEBITOR_ADDRESS:
      return {
        ...state,
        debitorAddress: payload,
      };

    case UserInfoAction.STORE_CAR_DEALER_ADDRESS:
      return {
        ...state,
        carDealerAddress: payload,
      };

    case UserInfoAction.STORE_SERVICE_TECHNICIAN_ADDRESS:
      return {
        ...state,
        serviceTechnicianAddress: payload,
      };

    default:
      return state;
  }
};
