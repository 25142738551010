/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboComparisonCo2ViewModel {
  /**
   * Co2 output for gas heating
   */
  Co2OutputGasHeating?: number;
  /**
   * Co2 output for oil heating
   */
  Co2OutputOilHeating?: number;
  /**
   * Co2 output for pellets heating
   */
  Co2OutputPelletsHeating?: number;
  /**
   * Co2 output for an air water heatpump
   */
  Co2OutputAirWaterHeatpump?: number;
  /**
   * Co2 output for a geothermal heatpump
   */
  Co2OutputGeothermalHeatpump?: number;
}
