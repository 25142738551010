/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PublicUserViewModel {
  Id?: string;
  LastName?: string;
  FirstName?: string;
  JobTitle?: string;
  AdditionalName?: string;
  Street?: string;
  ZipCode?: string;
  City?: string;
  Email?: string;
  PhoneNumber?: string;
  PictureFilename?: string;
  SignatureFilename?: string;
  OrganizationalUnit?: string;
  CompanyName?: string;
}
