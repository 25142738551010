import { ICar } from '../models';
import { CARS_AUDI } from './cars-audi';
import { CARS_BMW } from './cars-bmw';
import { CARS_CITROEN } from './cars-citroen';
import { CARS_CUPRA } from './cars-cupra';
import { CARS_DACIA } from './cars-dacia';
import { CARS_DSAUTOMOBILES } from './cars-ds-automobiles';
import { CARS_FIAT } from './cars-fiat';
import { CARS_FORD } from './cars-ford';
import { CARS_HARLEY_DAVIDSON } from './cars-harley-davidson';
import { CARS_HONDA } from './cars-honda';
import { CARS_HYUNDAI } from './cars-hyundai';
import { CARS_JAGUAR } from './cars-jaguar';
import { CARS_KIA } from './cars-kia';
import { CARS_LAND_ROVER } from './cars-land-rover';
import { CARS_LEXUS } from './cars-lexus';
import { CARS_MERCEDES_BENZ } from './cars-mercedes-benz';
import { CARS_MINI } from './cars-mini';
import { CARS_MITSUBISHI } from './cars-mitsubishi';
import { CARS_NISSAN } from './cars-nissan';
import { CARS_OPEL } from './cars-opel';
import { CARS_PEUGEOT } from './cars-peugeot';
import { CARS_PORSCHE } from './cars-porsche';
import { CARS_RENAULT } from './cars-renault';
import { CARS_SEAT } from './cars-seat';
import { CARS_SKODA } from './cars-skoda';
import { CARS_SMART } from './cars-smart';
import { CARS_TESLA } from './cars-tesla';
import { CARS_TOYOTA } from './cars-toyota';
import { CARS_VOLVO } from './cars-volvo';
import { CARS_VW } from './cars-vw';

export * from './cars-audi';
export * from './cars-bmw';
export * from './cars-citroen';
export * from './cars-cupra';
export * from './cars-dacia';
export * from './cars-ds-automobiles';
export * from './cars-fiat';
export * from './cars-ford';
export * from './cars-harley-davidson';
export * from './cars-honda';
export * from './cars-hyundai';
export * from './cars-jaguar';
export * from './cars-kia';
export * from './cars-land-rover';
export * from './cars-lexus';
export * from './cars-mercedes-benz';
export * from './cars-mini';
export * from './cars-mitsubishi';
export * from './cars-nissan';
export * from './cars-opel';
export * from './cars-peugeot';
export * from './cars-porsche';
export * from './cars-renault';
export * from './cars-seat';
export * from './cars-skoda';
export * from './cars-smart';
export * from './cars-tesla';
export * from './cars-toyota';
export * from './cars-volvo';
export * from './cars-vw';

export const ALL_CARS: ICar[] = [
  ...CARS_AUDI,
  ...CARS_BMW,
  ...CARS_CITROEN,
  ...CARS_CUPRA,
  ...CARS_DACIA,
  ...CARS_DSAUTOMOBILES,
  ...CARS_FIAT,
  ...CARS_FORD,
  ...CARS_HARLEY_DAVIDSON,
  ...CARS_HONDA,
  ...CARS_HYUNDAI,
  ...CARS_JAGUAR,
  ...CARS_KIA,
  ...CARS_LAND_ROVER,
  ...CARS_LEXUS,
  ...CARS_MERCEDES_BENZ,
  ...CARS_MINI,
  ...CARS_MITSUBISHI,
  ...CARS_NISSAN,
  ...CARS_OPEL,
  ...CARS_PEUGEOT,
  ...CARS_PORSCHE,
  ...CARS_RENAULT,
  ...CARS_SEAT,
  ...CARS_SKODA,
  ...CARS_SMART,
  ...CARS_TESLA,
  ...CARS_TOYOTA,
  ...CARS_VOLVO,
  ...CARS_VW,
];
