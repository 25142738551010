/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PatchNboViewModel {
  LeadComment?: string;
  LeadTag?: string;
  LeadStatus?: PatchNboViewModel.LeadStatusEnum;
  LeadTracers?: string;
  ShareUrl?: string;
  LeadRating?: PatchNboViewModel.LeadRatingEnum;
}
export namespace PatchNboViewModel {
  export type LeadStatusEnum =
    | 'New'
    | 'OfferDownload'
    | 'Callback'
    | 'Incomplete'
    | 'Complete'
    | 'AutoOffer';
  export const LeadStatusEnum = {
    New: 'New' as LeadStatusEnum,
    OfferDownload: 'OfferDownload' as LeadStatusEnum,
    Callback: 'Callback' as LeadStatusEnum,
    Incomplete: 'Incomplete' as LeadStatusEnum,
    Complete: 'Complete' as LeadStatusEnum,
    AutoOffer: 'AutoOffer' as LeadStatusEnum,
  };
  export type LeadRatingEnum = 'Zero' | 'One' | 'Two' | 'Three' | 'Four' | 'Five';
  export const LeadRatingEnum = {
    Zero: 'Zero' as LeadRatingEnum,
    One: 'One' as LeadRatingEnum,
    Two: 'Two' as LeadRatingEnum,
    Three: 'Three' as LeadRatingEnum,
    Four: 'Four' as LeadRatingEnum,
    Five: 'Five' as LeadRatingEnum,
  };
}
