import { ICar } from '../..';

export const CARS_SMART: ICar[] = [
  {
    value: 'SmartForfour',
    caption: 'Smart Forfour',
    icon: 'smart-forfour.jpg',
  },
  {
    value: 'SmartFortwo',
    caption: 'Smart Fortwo',
    icon: 'smart-fortwo.jpg',
  },
];
