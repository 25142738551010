import { IStep } from '@theia-cc/shared/models';
import {
  geometryAvailable,
  geometryNotAvailable,
  newVersion,
  notPvxRoof,
  pvxRoof,
} from './skipIfs';
import { BuildingType } from '@theia-cc/pv/core';

export const STEPS_DEFAULT: IStep[] = [
  {
    name: 'buildingtype',
  },
  {
    name: 'buildingmultiparty',
    skipIf: [
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'familymembercount',
    skipIf: [
      { buildingType: BuildingType.APARTMENT_BUILDING },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'familyhighdrain',
    skipIf: [
      { buildingType: BuildingType.APARTMENT_BUILDING },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'apartmentcount',
    skipIf: [
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'apartmenthighdrain',
    skipIf: [
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'commercialpowerusage',
    skipIf: [
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.APARTMENT_BUILDING },
    ],
  },
  {
    name: 'addresslookup',
  },
  {
    name: 'roofarea3d',
    skipIf: [geometryNotAvailable],
  },
  {
    name: 'roofarea',
    skipIf: [geometryAvailable, { buildingType: BuildingType.COMMERCIAL_BUILDING }],
  },
  {
    name: 'rooftiltangle',
    skipIf: [geometryAvailable],
  },
  {
    name: 'roofmaterialpvx',
    skipIf: [notPvxRoof],
  },
  {
    name: 'roofmaterial',
    skipIf: [pvxRoof],
    requires: ['rooftiltangle || roofarea3d'],
  },
  {
    name: 'contactemail',
    skipIf: [newVersion],
  },
  {
    name: 'contactcondensed',
    skipIf: [pvxRoof, newVersion],
  },
  {
    name: 'contactinformation',
    skipIf: [notPvxRoof, newVersion],
  },
  {
    name: 'offerpreviewpvx',
    skipIf: [notPvxRoof],
  },
  {
    name: 'offerpreview',
    skipIf: [pvxRoof],
    requires: [
      'roofarea || roofarea3d',
      'rooftiltangle || roofarea3d',
      'roofmaterial',
      'household',
      'powerconsumption || coverage',
    ],
  },
  {
    name: 'consulting',
    skipIf: [newVersion],
  },
];
