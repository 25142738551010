import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';

@Injectable()
export class RelatedLeadAction {
  static readonly STORE_RELATED_LEAD_ID = 'STORE_RELATED_LEAD_ID';
  static readonly STORE_SHARE_URL = 'STORE_SHARE_URL';

  @dispatch()
  storeRelatedLeadId = payload => ({
    payload,
    type: RelatedLeadAction.STORE_RELATED_LEAD_ID,
  });

  @dispatch()
  storeShareUrl = payload => ({
    payload,
    type: RelatedLeadAction.STORE_SHARE_URL,
  });
}
