import { ICollectedDataPv } from '@theia-cc/pv/state';
import { IWizardBase } from '@theia-cc/shared/models';

export function notPvxRoof({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return !collectedData.isPvx;
}

export function pvxRoof({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.isPvx;
}

export function geometryNotAvailable({
  collectedData,
}: {
  collectedData: ICollectedDataPv;
}): boolean {
  return !collectedData.userAddress || !collectedData.userAddress.hasGeometry;
}

export function geometryAvailable({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.userAddress && collectedData.userAddress.hasGeometry;
}

export function newVersion({ wizard }: { wizard: IWizardBase }): boolean {
  return +wizard?.version > 1;
}
