import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { NgModule } from '@angular/core';
import { reduceReducers } from '@theia-cc/shared/helpers';
import { BackendService, CantonService, UserInfoService } from '@theia-cc/shared/services';
import {
  ReducerRegistry,
  configReducer,
  deviceReducer,
  errorHandler,
  languageReducer,
  persistMiddleware,
  wizardReducer,
} from '@theia-cc/shared/store';
import reduxThunk from 'redux-thunk';
import { ICollectedDataPrimeo, INITIAL_STATE, INITIAL_STATE_CORE, StateCore } from './reducers';

export const coreReducers = {
  config: reduceReducers(INITIAL_STATE.config, configReducer),
  currentLanguage: languageReducer,
  wizard: wizardReducer,
  device: deviceReducer,
};

export const getItemId = (collectedData: Partial<ICollectedDataPrimeo>) => {
  const { nboLeadIdHt, variantIdHt, nboLeadIdPv, variantIdPv, nboLeadIdEm, variantIdEm } =
    collectedData || {
      nboLeadIdHt: undefined,
      variantIdHt: undefined,
      nboLeadIdPv: undefined,
      variantIdPv: undefined,
      nboLeadIdEm: undefined,
      variantIdEm: undefined,
    };
  return (nboLeadIdHt && variantIdHt) ||
    (nboLeadIdPv && variantIdPv) ||
    (nboLeadIdEm && variantIdEm)
    ? `${nboLeadIdHt || ''}-${variantIdHt || ''}-${nboLeadIdPv || ''}-${variantIdPv || ''}-${
        nboLeadIdEm || ''
      }-${variantIdEm || ''}`
    : undefined;
};

@NgModule({
  imports: [NgReduxModule],
  providers: [BackendService, CantonService, UserInfoService, ReducerRegistry],
})
export class StoreModule {
  constructor(
    private store: NgRedux<StateCore>,
    private reducerRegistry: ReducerRegistry<StateCore>,
    devTools: DevToolsExtension
  ) {
    store.configureStore(
      state => state,
      INITIAL_STATE_CORE,
      [reduxThunk, errorHandler, persistMiddleware(getItemId)],
      devTools.isEnabled() ? [devTools.enhancer()] : []
    );
    reducerRegistry.registerReducers(coreReducers);
  }
}
