import { NboAddressViewModel, NboPostContactViewModel, PatchNboRequest } from '@api-cc';

export interface INavbarButton {
  name?: string | null;
  action?: string | null;
  facet?: string | null;
  disabled?: boolean;
  hidden?: boolean;
  shadow?: boolean;
  icon?: string;
}

export interface IFooterLinks {
  privacyStatement?: string;
  legalNotices?: string;
  GC?: string;
}

export interface ILanguageSpecific<T> {
  de: T;
  fr?: T;
  it?: T;
  en?: T;
}

export type TranslationType = 'textOnly' | 'htmlString';

export interface ILanguageSpecificStringWithType {
  type: TranslationType;
  translations: ILanguageSpecific<string>;
  overrideDefaultTranslation: boolean;
}

export interface IDeviceInfo {
  device: string;
  browser: string;
  [deviceProperty: string]: any;
}

export interface IEnvVariablesBase {
  appKey: string;
  enablePhoneHeader?: boolean;
  localeStyle?: 'formal' | 'informal';
  disableLanguageChoice?: boolean;
  enableCallbackHeader?: boolean;
  backIconName?: string;
  choiceButtonChevronRightIconName?: string;
  headerPhoneIconName?: string;
  shareIconName?: string;
  heroHeaderImageUrl?: string;
  disableSSLPicture?: boolean;
}

export interface INavBarBase {
  primaryButton: INavbarButton;
  secondaryButton: INavbarButton;
  showShareButton: boolean;
}

export interface IStepsSkipIfCondition {
  [variable: string]: string | boolean;
}

export type SkipIfFn = (ICollectedData) => boolean;

export interface IWizardBase {
  showLoadingSpinner: boolean;
  showOfferPreviewLoadingSpinner: boolean;
  previousStep: string;
  currentStep: string;
  currentStepIdx: number;
  currentStepData: any | null;
  lastStep: string;
  totalSteps: number;
  allowNextStep: boolean;
  navbar: INavBarBase;
  isSharedOfferPreview: boolean;
  version?: number;
  footerSecondaryButtonClass?: string;
}

export interface IStep {
  name: string;
  skipIf?: (IStepsSkipIfCondition | SkipIfFn)[];
  stepValueDefault?: string;
  requires?: string[];
  redirectTo?: ILanguageSpecific<string>;
  showLinks?: boolean;
  allowPdfDownload?: boolean;
}

export interface IConfigOrgBase {
  name: string;
  contactPhone?: ILanguageSpecific<string>;
  contactEmail?: string;
  logoImageUrl?: string | string[];
  logoLinkUrl?: ILanguageSpecific<string>;
  faviconUrl?: string;
  successPageUrls?: ILanguageSpecific<string>;
  contactPageUrls?: ILanguageSpecific<string>;
  contactOfferImageUrls?: ILanguageSpecific<string>;
  stylesheetUrl?: string;
}

export interface IConfigQueryParamsBase {
  [param: string]: string;
  partnerId: string;
  mode?: string;
}

export interface IConfigBase {
  envVariables: IEnvVariablesBase;
  queryParams?: IConfigQueryParamsBase;
  styles?: string;
  org: IConfigOrgBase;
  defaultLanguage: string;
  steps?: IStep[];
  configLoaded?: boolean;
  versionNumber?: string;
  hubspotTrackingId?: string;
  footerLinks?: ILanguageSpecific<IFooterLinks>;
  theme?: Theme;
  browserCallback?: string;
}

export interface ILeadMeta {
  leadStatus: PatchNboRequest.LeadStatusEnum;
  leadComment: string;
  leadCallbackTime: {
    IsCallbackTimeMorning: boolean;
    IsCallbackTimeAfternoon: boolean;
    IsCallbackTimeEvening: boolean;
    IsCallbackTimeWeekend: boolean;
  };
  leadTag: string;
  leadTracers: string;
}

export interface IUser {
  email?: string;
  emailDisclaimer?: boolean;
  firstName?: string;
  lastName?: string;
  telephone?: string;
  title?: NboAddressViewModel.TitleEnum;
  companyName?: string;
}

export interface IUserAddress {
  zip: string;
  place: string;
  street: string;
  lat: number;
  lon: number;
  x: number;
  y: number;
  addressString: string;
  hasGeometry: boolean;
  egid?: string;
}

export interface IUserInfo {
  user: IUser;
  userAddress: IUserAddress;
  debitorAddress?: NboPostContactViewModel;
  serviceTechnicianAddress?: NboPostContactViewModel;
  carDealerAddress?: NboPostContactViewModel;
}

export interface IUserAddressParams {
  lat: string;
  lon: string;
  City: string;
  Street: string;
  ZipCode: string;
  egid?: string;
}

export interface IUserInfoParams {
  Email: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  Title: string;
  CompanyName: string;
}

export interface IBuildingSummary {
  aboveSea: number;
  buildingEavesHeight: number;
  buildingGroundArea: number;
  buildingLevels: number;
  buildingRidgeHeight: number;
  buildingRoofArea: number;
  buildingRoofOverhangArea: number;
  buildingRoofShape: number;
  buildingVolume: number;
  buildingWallArea: number;
}

export interface IRelatedLead {
  relatedLeadPublicId?: string;
  shareUrl?: string;
}

export interface GTMEvent {
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: string;
  virtualPageURL?: string;
  virtualPageTitle?: string;
}

export interface Color {
  predefinedColor?: string;
}

export interface Theme {
  customComponentStyles: {
    [propName: string]: Color;
  };
}

export interface ThemeProperty {
  property: string;
  cssVariable: string;
}

export interface ArModel {
  name: string;
  location: {
    qrCode: {
      url: string;
    };
    url: string;
  };
}

export enum LeadComplexityTypeEnum {
  Commercial = 'Commercial',
  Residential = 'Residential',
  Enterprise = 'Enterprise',
}
