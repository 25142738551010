import { IRelatedLead } from '@theia-cc/shared/models';
import { RelatedLeadAction } from './related-lead.action';

export const RELATED_LEAD_INITIAL: IRelatedLead = {
  relatedLeadPublicId: null,
  shareUrl: null,
};

export const relatedLeadReducer = (state: IRelatedLead = RELATED_LEAD_INITIAL, action) => {
  const { payload, type } = action;

  switch (type) {
    case RelatedLeadAction.STORE_RELATED_LEAD_ID:
      return {
        ...state,
        relatedLeadPublicId: payload,
      };

    case RelatedLeadAction.STORE_SHARE_URL:
      return {
        ...state,
        shareUrl: payload,
      };

    default:
      return state;
  }
};
