export const SAVE_PV = 'SAVE_PV';
export const SAVE_PV_AND_NAVIGATE = 'SAVE_PV_AND_NAVIGATE';
export const REQUEST_CALLBACK = 'REQUEST_CALLBACK';
export const FINISH_PV = 'FINISH_PV';
export const FINISH_PV_NO_DOWNLOAD = 'FINISH_PV_NO_DOWNLOAD';
export const FINISH_PV_AND_DOWNLOAD = 'FINISH_PV_AND_DOWNLOAD';
export const FINISH_SHARED_PV = 'FINISH_SHARED_PV';
export const GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW =
  'GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW';
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const DOWNLOAD_OFFER_PV = 'DOWNLOAD_OFFER_PV';
