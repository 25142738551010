/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboHeatingLeadRequest {
  ProjectType: NboHeatingLeadRequest.ProjectTypeEnum;
  CurrentHeatGeneratorType?: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum;
  CurrentDomesticWaterHeating?: NboHeatingLeadRequest.CurrentDomesticWaterHeatingEnum;
  CurrentHeatDissipation?: NboHeatingLeadRequest.CurrentHeatDissipationEnum;
  BuildingYearOfConstruction?: NboHeatingLeadRequest.BuildingYearOfConstructionEnum;
  /**
   * Was haben Sie in den letzten 10 Jahren isoliert oder erneuert?
   */
  BuildingPreviousRenovationLastTenYears?: Array<NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum>;
  /**
   * Errechneter Jahresenergiebedarf in kWh
   */
  AveragedCurrentEnergyDemandKwh?: number;
  /**
   * Personen im Haushalt
   */
  NumberInhabitants?: number;
  MapImage?: string;
  BuildingGroundArea?: number;
  BuildingRoofArea?: number;
  BuildingRoofOverhangArea?: number;
  BuildingWallArea?: number;
  BuildingVolume?: number;
  /**
   * Optional BuildingEnergyReferenceArea (overrides calculated value)
   */
  BuildingEnergyReferenceArea?: number;
  BuildingLevels?: number;
  BuildingRoofShape?: string;
  BuildingAboveSea?: number;
  LatLonCoordinates?: string;
  Egid?: string;
  Canton?: string;
}
export namespace NboHeatingLeadRequest {
  export type ProjectTypeEnum = 'Replacement' | 'NewBuilding';
  export const ProjectTypeEnum = {
    Replacement: 'Replacement' as ProjectTypeEnum,
    NewBuilding: 'NewBuilding' as ProjectTypeEnum,
  };
  export type CurrentHeatGeneratorTypeEnum =
    | 'OilBoiler'
    | 'OilBoilerCondensing'
    | 'GasBoiler'
    | 'GasBoilerCondensing'
    | 'LumberBoilerOld'
    | 'LumberBoilerNew'
    | 'WoodChipsBoiler'
    | 'CentralStorageHeating'
    | 'DeCentralStorageHeating'
    | 'HeatPump'
    | 'Other';
  export const CurrentHeatGeneratorTypeEnum = {
    OilBoiler: 'OilBoiler' as CurrentHeatGeneratorTypeEnum,
    OilBoilerCondensing: 'OilBoilerCondensing' as CurrentHeatGeneratorTypeEnum,
    GasBoiler: 'GasBoiler' as CurrentHeatGeneratorTypeEnum,
    GasBoilerCondensing: 'GasBoilerCondensing' as CurrentHeatGeneratorTypeEnum,
    LumberBoilerOld: 'LumberBoilerOld' as CurrentHeatGeneratorTypeEnum,
    LumberBoilerNew: 'LumberBoilerNew' as CurrentHeatGeneratorTypeEnum,
    WoodChipsBoiler: 'WoodChipsBoiler' as CurrentHeatGeneratorTypeEnum,
    CentralStorageHeating: 'CentralStorageHeating' as CurrentHeatGeneratorTypeEnum,
    DeCentralStorageHeating: 'DeCentralStorageHeating' as CurrentHeatGeneratorTypeEnum,
    HeatPump: 'HeatPump' as CurrentHeatGeneratorTypeEnum,
    Other: 'Other' as CurrentHeatGeneratorTypeEnum,
  };
  export type CurrentDomesticWaterHeatingEnum = 'NotSpecified' | 'HeatingBoiler' | 'AutonomousDwh';
  export const CurrentDomesticWaterHeatingEnum = {
    NotSpecified: 'NotSpecified' as CurrentDomesticWaterHeatingEnum,
    HeatingBoiler: 'HeatingBoiler' as CurrentDomesticWaterHeatingEnum,
    AutonomousDwh: 'AutonomousDwh' as CurrentDomesticWaterHeatingEnum,
  };
  export type CurrentHeatDissipationEnum =
    | 'RadiatorWithFeedBelow60'
    | 'RadiatorWithFeedAbove60'
    | 'FloorHeating'
    | 'RadiatorAndFloorHeating';
  export const CurrentHeatDissipationEnum = {
    RadiatorWithFeedBelow60: 'RadiatorWithFeedBelow60' as CurrentHeatDissipationEnum,
    RadiatorWithFeedAbove60: 'RadiatorWithFeedAbove60' as CurrentHeatDissipationEnum,
    FloorHeating: 'FloorHeating' as CurrentHeatDissipationEnum,
    RadiatorAndFloorHeating: 'RadiatorAndFloorHeating' as CurrentHeatDissipationEnum,
  };
  export type BuildingYearOfConstructionEnum =
    | 'NewBuilding'
    | 'Before1972'
    | 'Between1972And1997'
    | 'Between1998And2004'
    | 'After2004';
  export const BuildingYearOfConstructionEnum = {
    NewBuilding: 'NewBuilding' as BuildingYearOfConstructionEnum,
    Before1972: 'Before1972' as BuildingYearOfConstructionEnum,
    Between1972And1997: 'Between1972And1997' as BuildingYearOfConstructionEnum,
    Between1998And2004: 'Between1998And2004' as BuildingYearOfConstructionEnum,
    After2004: 'After2004' as BuildingYearOfConstructionEnum,
  };
  export type BuildingPreviousRenovationLastTenYearsEnum =
    | 'Windows'
    | 'Roof'
    | 'Facades'
    | 'BasementFloor';
  export const BuildingPreviousRenovationLastTenYearsEnum = {
    Windows: 'Windows' as BuildingPreviousRenovationLastTenYearsEnum,
    Roof: 'Roof' as BuildingPreviousRenovationLastTenYearsEnum,
    Facades: 'Facades' as BuildingPreviousRenovationLastTenYearsEnum,
    BasementFloor: 'BasementFloor' as BuildingPreviousRenovationLastTenYearsEnum,
  };
}
