import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon',
  template: `
    <div class="{{ iconClass }}">
      <svg>
        <use [attr.xlink:href]="iconLocation"></use>
      </svg>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent implements OnInit {
  @Input() size;
  @Input() name;
  @Input() resize;
  @Input() inline;
  @Input() inheritWidth;

  public iconLocation = null;
  public iconSize = null;
  public iconClass = null;
  public inlineClass = null;

  constructor() {}
  ngOnInit() {
    this.iconLocation = `assets/icons${this.size}.svg#ic-${this.name}-${this.size}`;

    if (this.resize) {
      this.iconSize = `${this.resize}px`;
      this.iconClass = `icon icon__${this.resize}`;
    } else {
      this.iconSize = `${this.size}px`;
      this.iconClass = `icon icon__${this.size}`;
    }

    // var icons can be any size, so we scale them to 100% of the parent
    if (this.size === 'var') {
      this.iconLocation = `assets/iconsvar.svg#${this.name}`;
      this.iconClass = `${this.iconClass} icon icon__var`;
    }

    if (this.inline) {
      this.iconClass = `${this.iconClass} icon__inline`;
    }

    if (this.inheritWidth) {
      this.iconClass = `${this.iconClass} icon__inherit-width`;
    }
  }
}
