/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboEmobilityLeadRequest {
  FuseboxAge?: NboEmobilityLeadRequest.FuseboxAgeEnum;
}
export namespace NboEmobilityLeadRequest {
  export type FuseboxAgeEnum = 'Fba_Available' | 'Fba_NotAvailable';
  export const FuseboxAgeEnum = {
    Available: 'Fba_Available' as FuseboxAgeEnum,
    NotAvailable: 'Fba_NotAvailable' as FuseboxAgeEnum,
  };
}
