import { CurrentLanguageAction } from './current-language.action';

export const languageReducer = (state: string = null, action) => {
  switch (action.type) {
    case CurrentLanguageAction.SET_CURRENT_LANGUAGE:
      return action.payload.currentLanguage;

    default:
      return state;
  }
};
