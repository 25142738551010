import { ICar } from '../..';

export const CARS_TESLA: ICar[] = [
  {
    value: 'TeslaModel3',
    caption: 'Tesla Model 3',
    icon: 'tesla-model-3.jpg',
  },
  {
    value: 'TeslaModelX',
    caption: 'Tesla Model X',
    icon: 'tesla-model-x.jpg',
  },
  {
    value: 'TeslaModelS',
    caption: 'Tesla Model S',
    icon: 'tesla-model-s.jpg',
  },
];
