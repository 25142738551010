import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmStateEffect } from '@theia-cc/em/state';
import { SAVE_HT_AND_NAVIGATE } from '@theia-cc/ht/core';
import { HtStateEffect } from '@theia-cc/ht/state';
import { UPDATE_CONTACT_INFO } from '@theia-cc/pv/core';
import { PvStateEffect } from '@theia-cc/pv/state';
import { FINISH_CONTACT, GO_TO_SUCCESS_URL, NAVIGATE_NEXT } from '@theia-cc/shared/helpers';
import { BackendService, ShareLinkService } from '@theia-cc/shared/services';
import {
  LeadMetaAction,
  RelatedLeadAction,
  SharedStoreEffect,
  WizardAction,
} from '@theia-cc/shared/store';
import { DOWNLOAD_OFFERS_CC } from '../configs/constants';
import { IAppStatePrimeo } from './reducers';
import { PatchNboRequest } from '@api-cc';
import { ConfirmationModalComponent } from '@theia-cc/shared/util-ui';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PrimeoStateEffect {
  constructor(
    private backend: BackendService,
    private store: NgRedux<IAppStatePrimeo>,
    private effect: SharedStoreEffect,
    private effectEm: EmStateEffect,
    private effectPv: PvStateEffect,
    private effectHt: HtStateEffect,
    private wizardAction: WizardAction,
    private leadMetaAction: LeadMetaAction,
    private relatedLeadAction: RelatedLeadAction,
    private shareLinkService: ShareLinkService,
    private router: Router,
    private modalService: BsModalService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  buttonActions(action) {
    if (!action) return;
    const appState: IAppStatePrimeo = this.store.getState();
    const {
      config: { trades },
    } = appState;

    const { largeSystem, variantIdEm, nboLeadIdEm, nboLeadIdPv } = appState.collectedData;

    switch (action) {
      case NAVIGATE_NEXT:
        this.effect.navigateNext();
        break;
      case GO_TO_SUCCESS_URL:
        const addFinalVariantsAndDownloadOffers = {
          em: (isFinalVariant: boolean) =>
            variantIdEm && nboLeadIdEm
              ? Promise.resolve()
              : this.effectEm.postEmNboRequest.bind(this.effectEm)(false, isFinalVariant),
          ht: (isFinalVariant: boolean) =>
            largeSystem
              ? Promise.resolve()
              : isFinalVariant
              ? this.effectHt.addVariantToLead.bind(this.effectHt)({
                  isFinalVariant: true,
                })
              : Promise.resolve(),
          pv: (isFinalVariant: boolean) =>
            isFinalVariant
              ? this.effectPv.addVariantToLead.bind(this.effectPv)({
                  isFinalVariant: true,
                })
              : Promise.resolve(),
        };

        this.openConfirmationModal()
          .then(isFinalVariant => {
            this.wizardAction.showLoadingSpinner();
            return Promise.all(
              trades.map((type, index) =>
                new Promise<void>(resolve => setTimeout(() => resolve(), index * 1000)).then(() =>
                  addFinalVariantsAndDownloadOffers[type](isFinalVariant)
                )
              )
            );
          })
          .then(() => this.downloadOffersCC())
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          });
        break;
      case FINISH_CONTACT:
        const { offerStatus } = appState.collectedData;

        const actionsFinishContact = {
          em: () =>
            this.effectEm.postEmNboRequest(true).then((data: any) => {
              this.effectEm.handleGoogleTagsAfterInternalLeadIdIsPresent(
                data.InternalLeadId.toString(),
                data.LeadId
              );

              return Promise.resolve(true);
            }),
          ht: () =>
            this.effectHt.createContactNboLead().then(data => {
              this.effectHt.handleGoogleTagsAfterInternalLeadIdIsPresent(
                data.InternalLeadId.toString(),
                data.LeadId
              );

              return Promise.resolve(true);
            }),
          pv: () => {
            const finishContactRequest: Promise<unknown> = nboLeadIdPv
              ? this.effectPv.updatePvNboPersonData()
              : this.effectPv.createContactNboLead();
            return finishContactRequest
              .then(() => {
                offerStatus !== undefined || nboLeadIdPv
                  ? this.effectPv.addVariantToLead({ isFinalVariant: true })
                  : Promise.resolve();
              })
              .then(() => {
                const { internalLeadIdPv, nboLeadIdPv } = this.store.getState().collectedData;
                this.effectPv.handleGoogleTagsAfterInternalLeadIdIsPresent(
                  internalLeadIdPv.toString(),
                  nboLeadIdPv
                );

                return Promise.resolve(true);
              });
          },
        };

        this.wizardAction.showLoadingSpinner();

        Promise.all(
          trades.map((type, index) =>
            new Promise<void>(resolve => setTimeout(() => resolve(), index * 1000)).then(() =>
              actionsFinishContact[type]()
            )
          )
        ).then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.goToSuccessURL();
        });
        break;
      case SAVE_HT_AND_NAVIGATE:
        this.shareLinkService.setOfferReviewStepPathName('/offerpreview');

        const actionsCreateLead = {
          em: () => Promise.resolve(),
          ht: largeSystem
            ? () => Promise.resolve()
            : this.effectHt.createHtNboLead.bind(this.effectHt),
          pv: this.effectPv.createPvNboLead.bind(this.effectPv),
        };

        this.wizardAction.showLoadingSpinner();

        const requests: Array<() => Promise<any>> = trades.map(type => actionsCreateLead[type]);

        requests[0]()
          .then(result => {
            if (
              requests.length > 1 &&
              (trades.includes('pv') || (trades.includes('ht') && !largeSystem))
            ) {
              this.relatedLeadAction.storeRelatedLeadId(result?.LeadId);
              return Promise.all(
                requests
                  .slice(1)
                  .map((action, index) =>
                    new Promise<void>(resolve => setTimeout(() => resolve(), index * 1000)).then(
                      () => action()
                    )
                  )
              );
            }
          })
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.effect.navigateNext();
          });

        break;
      case UPDATE_CONTACT_INFO:
        this.leadMetaAction.storeLeadStatus(PatchNboRequest.LeadStatusEnum.OfferDownload);
        this.relatedLeadAction.storeShareUrl(
          this.shareLinkService.generateShareLink(this.document.location, appState)
        );

        const actionsUpdateUserInfo = {
          em: () => Promise.resolve(),
          ht: () =>
            largeSystem
              ? this.effectHt.createContactNboLead.bind(this.effectHt)()
              : this.effectHt.updateHtNboPersonData
                  .bind(this.effectHt)(false, PatchNboRequest.LeadStatusEnum.OfferDownload)
                  .then(() =>
                    this.effectHt.addVariantToLead.bind(this.effectHt)({
                      isFinalVariant: false,
                    })
                  ),
          pv: () =>
            this.effectPv.updatePvNboPersonData
              .bind(this.effectPv)(false, PatchNboRequest.LeadStatusEnum.OfferDownload)
              .then(() =>
                this.effectPv.addVariantToLead.bind(this.effectPv)({
                  isFinalVariant: false,
                })
              ),
        };

        this.wizardAction.showLoadingSpinner();
        Promise.all(
          trades.map((type, index) =>
            new Promise<void>(resolve => setTimeout(() => resolve(), index * 1000)).then(() =>
              actionsUpdateUserInfo[type]()
            )
          )
        ).then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.effect.navigateNext();
        });
        break;
      case DOWNLOAD_OFFERS_CC:
        this.downloadOffersCC().then(() => {
          this.wizardAction.hideLoadingSpinner();
        });
        break;
      default:
        break;
    }
  }

  private downloadOffersCC() {
    const appState: IAppStatePrimeo = this.store.getState();
    const {
      config: { trades },
    } = appState;
    const isEmOnly = trades?.every(trade => trade === 'em');

    const {
      largeSystem,
      variantIdEm,
      variantIdHt,
      variantIdPv,
      nboLeadIdHt,
      nboLeadIdEm,
      nboLeadIdPv,
    } = appState.collectedData;

    const downloadOfferActions = {
      em: () =>
        nboLeadIdEm && variantIdEm
          ? this.backend.getEmOfferPreviewPDF(nboLeadIdEm, variantIdEm)
          : isEmOnly
          ? this.effectEm
              .postEmNboRequest()
              .then((data: any) =>
                this.backend.getEmOfferPreviewPDF(data.LeadId, data.Variants[0]?.VariantId)
              )
          : Promise.resolve(),
      ht: () =>
        largeSystem || !nboLeadIdHt || !variantIdHt
          ? Promise.resolve()
          : this.backend.getHtNboOfferPreviewPDF(nboLeadIdHt, variantIdHt),
      pv: () =>
        nboLeadIdPv && variantIdPv
          ? this.backend.getPvNboOfferPreviewPdf(nboLeadIdPv, variantIdPv)
          : Promise.resolve(),
    };

    this.wizardAction.showLoadingSpinner();

    return Promise.all(
      trades.map((type, index) =>
        new Promise<void>(resolve => setTimeout(() => resolve(), index * 2000)).then(() =>
          downloadOfferActions[type]()
        )
      )
    );
  }

  private goToSuccessURL(): void {
    const state: IAppStatePrimeo = this.store.getState();
    const urls = state.config.org.successPageUrls;
    const url = `${urls[state.currentLanguage]}`;

    window.location.href = url;
  }

  public setOfferPreviewCurrentStep(): void {
    this.wizardAction.setCurrentStep(
      'offerpreview',
      this.store.getState().config.steps.findIndex(({ name }) => name === 'offerpreview')
    );
  }

  public openConfirmationModal(): Promise<boolean> {
    return new Promise(resolve => {
      let modalRef;
      // eslint-disable-next-line prefer-const
      modalRef = this.modalService.show(ConfirmationModalComponent, {
        initialState: {
          modalRef,
          question: this.translate.instant('PV.OFFERPREVIEW.DOWNLOAD_OFFER_CONFIRMATION'),
          closeButtonName: this.translate.instant(
            'COMPONENT.MODAL_OFFERPREVIEW_AREA.BUTTON_CANCEL'
          ),
          confirmButtonName: this.translate.instant('COMPONENT.BUTTON_CONFIRM'),
        } as any,
        class: 'modal-images__modal modal-dialog-centered',
      });

      modalRef.content.onClose = new Subject<boolean>();

      const subscription = modalRef.content.onClose.pipe(first()).subscribe(reason => {
        resolve(reason);
        subscription.unsubscribe();
      });
    });
  }
}
