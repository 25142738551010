// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { NboEmobilityCalculateRequest } from '@api-cc';
import { ICollectedDataEm, IConfigEm } from '@theia-cc/em/state';
import { caseAgnosticQueryParam } from '@theia-cc/shared/helpers';

export function notDealerOrigin({ config }: { config: IConfigEm }): boolean {
  return caseAgnosticQueryParam(config.queryParams)('origin') !== 'dealer';
}

// IndividualSolution = Residential
export function notIndividualSolution({
  collectedData,
}: {
  collectedData: ICollectedDataEm;
}): boolean {
  return (
    collectedData['whereToInstallCharger'] !==
    NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution
  );
}

// CommunitySolution = Commercial
export function notCommunitySolution({
  collectedData,
}: {
  collectedData: ICollectedDataEm;
}): boolean {
  return (
    collectedData['whereToInstallCharger'] !==
    NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution
  );
}
