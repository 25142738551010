/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboEnergyConversionRequest {
  CurrentHeatGeneratorType: NboEnergyConversionRequest.CurrentHeatGeneratorTypeEnum;
}
export namespace NboEnergyConversionRequest {
  export type CurrentHeatGeneratorTypeEnum =
    | 'OilBoiler'
    | 'OilBoilerCondensing'
    | 'GasBoiler'
    | 'GasBoilerCondensing'
    | 'LumberBoilerOld'
    | 'LumberBoilerNew'
    | 'WoodChipsBoiler'
    | 'CentralStorageHeating'
    | 'DeCentralStorageHeating'
    | 'HeatPump'
    | 'Other';
  export const CurrentHeatGeneratorTypeEnum = {
    OilBoiler: 'OilBoiler' as CurrentHeatGeneratorTypeEnum,
    OilBoilerCondensing: 'OilBoilerCondensing' as CurrentHeatGeneratorTypeEnum,
    GasBoiler: 'GasBoiler' as CurrentHeatGeneratorTypeEnum,
    GasBoilerCondensing: 'GasBoilerCondensing' as CurrentHeatGeneratorTypeEnum,
    LumberBoilerOld: 'LumberBoilerOld' as CurrentHeatGeneratorTypeEnum,
    LumberBoilerNew: 'LumberBoilerNew' as CurrentHeatGeneratorTypeEnum,
    WoodChipsBoiler: 'WoodChipsBoiler' as CurrentHeatGeneratorTypeEnum,
    CentralStorageHeating: 'CentralStorageHeating' as CurrentHeatGeneratorTypeEnum,
    DeCentralStorageHeating: 'DeCentralStorageHeating' as CurrentHeatGeneratorTypeEnum,
    HeatPump: 'HeatPump' as CurrentHeatGeneratorTypeEnum,
    Other: 'Other' as CurrentHeatGeneratorTypeEnum,
  };
}
