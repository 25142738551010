/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BuildingDetailsNboViewModel {
  BuildingGroundArea?: number;
  BuildingLevels?: number;
  BuildingAboveSea?: number;
  BuildingYearOfConstruction?: BuildingDetailsNboViewModel.BuildingYearOfConstructionEnum;
}
export namespace BuildingDetailsNboViewModel {
  export type BuildingYearOfConstructionEnum =
    | 'NewBuilding'
    | 'Before1972'
    | 'Between1972And1997'
    | 'Between1998And2004'
    | 'After2004';
  export const BuildingYearOfConstructionEnum = {
    NewBuilding: 'NewBuilding' as BuildingYearOfConstructionEnum,
    Before1972: 'Before1972' as BuildingYearOfConstructionEnum,
    Between1972And1997: 'Between1972And1997' as BuildingYearOfConstructionEnum,
    Between1998And2004: 'Between1998And2004' as BuildingYearOfConstructionEnum,
    After2004: 'After2004' as BuildingYearOfConstructionEnum,
  };
}
