import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-offer-preview-loading-spinner',
  templateUrl: './offer-preview-loading-spinner.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OfferPreviewLoadingSpinnerComponent implements OnInit {
  @Input() image: string;
  percentage = 0;

  ngOnInit() {
    setTimeout(() => {
      this.percentage = 100;
    }, 2400);
  }
}
