import { ICar } from '../..';

export const CARS_LAND_ROVER: ICar[] = [
  {
    value: 'LandRoverRangeRover',
    caption: 'Land Rover Range Rover',
    icon: 'land-rover-range-rover.jpg',
  },
  {
    value: 'LandRoverRRSport',
    caption: 'Land Rover RR Sport',
    icon: 'land-rover-rr-sport.jpg',
  },
];
