import { NboPvLeadRequest } from '@api-cc';
import { BuildingType, IOfferPreviewConfig, OfferStatusEnum } from '@theia-cc/pv/core';
import {
  IBuildingSummary,
  IConfigBase,
  ILeadMeta,
  IRelatedLead,
  IUserInfo,
} from '@theia-cc/shared/models';
import { IChoiceButton } from '@theia-cc/shared/step-ui';
import { IAppStateBase } from '@theia-cc/shared/store';
import { PvStateAction } from './pv-state.action';

interface ICollectedData {
  apartmentCount: number;
  averagePowerUsageKwh: number;
  buildingData: IBuildingSummary;
  buildingType: BuildingType;
  familyMemberCount: number;
  highDrainSystems: any | IHighDrainSystems;
  highDrainSystemsKwh: number;
  isPvx: boolean;
  nboFetchedPv: any | object[];
  nboLeadIdPv: string;
  internalLeadIdPv: number;
  previewImage: string;
  roofArea: number;
  maxPvCoveredRoofArea: number;
  roofAreaChf: number;
  roofAreaKwh: number;
  roofAreaLifetimeChf: number;
  roofMaterial: NboPvLeadRequest.RoofCoverTypeEnum;
  roofShadow: NboPvLeadRequest.IsRoofShadowEnum;
  roofTiltAngle: string;
  selectedRoofArea: number;
  totalPowerRequirementKwh: number;
  heatPump: boolean;
  battery: boolean;
  electroBoiler: boolean;
  chargingStationEmobility: boolean;
  heatPumpBoiler: boolean;
  nboSelectedTemplateIdPv: string;
  selectedTemplatePv: any;
  pvEarningsKwhM2: number;
  roofOrientation: string;
  showAddressFields3dFailsPv: boolean;
  offerStatus?: OfferStatusEnum;
  variantIdPv?: number;
}

export interface IHighDrainSystems {
  HEATPUMP: boolean;
  ELECTRIC_HEATING: boolean;
  ELECTROBOILER: boolean;
  EMOBILITY_CHARGING: boolean;
  POOL_SAUNA: boolean;
}

export type ICollectedDataPv = ICollectedData & IUserInfo & ILeadMeta & IRelatedLead;

export interface IConfigPv extends IConfigBase {
  templatesFlat: {
    active: string[];
    inactive: string[];
    default: string;
  };
  templatesSloped: {
    active: string[];
    inactive: string[];
    default: string;
  };
  offerPreview: IOfferPreviewConfig;
  highDrainSystemItems: IChoiceButton[];
  buildingTypeItems?: IChoiceButton[];
  roofAreaItems?: IChoiceButton[];
  equippedWithEnergyManagementSystemForResidential?: boolean;
}

export interface IAppStatePv extends IAppStateBase {
  config: IConfigPv;
  collectedData: ICollectedDataPv;
}

const INITIAL_STATE_COLLECTED_DATA: ICollectedData = {
  nboFetchedPv: [],
  apartmentCount: null,
  averagePowerUsageKwh: null,
  buildingType: null,
  familyMemberCount: null,
  highDrainSystems: {
    HEATPUMP: null,
    ELECTRIC_HEATING: null,
    ELECTROBOILER: null,
    EMOBILITY_CHARGING: null,
    POOL_SAUNA: null,
  },
  battery: null,
  electroBoiler: null,
  heatPump: null,
  chargingStationEmobility: null,
  heatPumpBoiler: null,
  highDrainSystemsKwh: null,
  isPvx: null,
  nboLeadIdPv: null,
  internalLeadIdPv: null,
  previewImage: null,
  roofArea: null,
  maxPvCoveredRoofArea: null,
  roofAreaChf: null,
  roofAreaKwh: null,
  roofAreaLifetimeChf: null,
  roofMaterial: null,
  roofShadow: null,
  roofTiltAngle: null,
  selectedRoofArea: null,
  totalPowerRequirementKwh: null,
  nboSelectedTemplateIdPv: null,
  buildingData: {
    aboveSea: null,
    buildingRoofShape: null,
    buildingVolume: null,
    buildingGroundArea: null,
    buildingRoofArea: null,
    buildingEavesHeight: null,
    buildingRidgeHeight: null,
    buildingRoofOverhangArea: null,
    buildingWallArea: null,
    buildingLevels: null,
  },
  selectedTemplatePv: null,
  pvEarningsKwhM2: null,
  roofOrientation: null,
  showAddressFields3dFailsPv: false,
};

export const collectedDataReducerPv = (
  state: ICollectedData = INITIAL_STATE_COLLECTED_DATA,
  action: { type: any; payload: any | IHighDrainSystems }
): ICollectedData => {
  const { payload, type } = action;

  switch (type) {
    case PvStateAction.CALC_POWER_REQUIREMENT_KWH:
      return {
        ...state,
        totalPowerRequirementKwh: state.averagePowerUsageKwh + state.highDrainSystemsKwh,
      };

    case PvStateAction.PV_STORE_HEAT_PUMP:
      return {
        ...state,
        heatPump: payload,
      };

    case PvStateAction.STORE_ELECTRO_BOILER:
      return {
        ...state,
        electroBoiler: payload,
      };

    case PvStateAction.STORE_BATTERY:
      return {
        ...state,
        battery: payload,
      };

    case PvStateAction.STORE_CHARGINGSTATIONEMOBILITY:
      return {
        ...state,
        chargingStationEmobility: payload,
      };

    case PvStateAction.STORE_HEATPUMPBOILER:
      return {
        ...state,
        heatPumpBoiler: payload,
      };

    case PvStateAction.PV_STORE_NBO_LEAD_ID:
      return {
        ...state,
        nboLeadIdPv: payload,
      };
    case PvStateAction.PV_STORE_INTERNAL_LEAD_ID:
      return {
        ...state,
        internalLeadIdPv: payload,
      };
    case PvStateAction.PV_STORE_NBO_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        nboSelectedTemplateIdPv: payload,
      };

    case PvStateAction.STORE_ROOF_SHADOW:
      return {
        ...state,
        roofShadow: payload,
      };

    case PvStateAction.STORE_HIGH_DRAIN_SYSTEMS:
      return {
        ...state,
        highDrainSystems: payload,
      };

    case PvStateAction.STORE_HIGH_DRAIN_SYSTEMS_KWH:
      return {
        ...state,
        highDrainSystemsKwh: payload,
      };

    case PvStateAction.STORE_BUILDING_TYPE:
      return {
        ...state,
        buildingType: payload,
      };

    case PvStateAction.STORE_ROOF_TILT_ANGLE:
      return {
        ...state,
        roofTiltAngle: payload,
      };

    case PvStateAction.STORE_FAMILY_MEMBER_COUNT:
      return {
        ...state,
        familyMemberCount: payload,
      };

    case PvStateAction.STORE_APARTMENT_COUNT:
      return {
        ...state,
        apartmentCount: payload,
      };

    case PvStateAction.STORE_AVERAGE_POWER_USAGE_KWH:
      return {
        ...state,
        averagePowerUsageKwh: payload,
      };

    case PvStateAction.STORE_ROOF_AREA:
      return {
        ...state,
        roofArea: payload,
      };

    case PvStateAction.STORE_SELECTED_ROOF_AREA:
      return {
        ...state,
        selectedRoofArea: payload,
      };

    case PvStateAction.STORE_ROOF_AREA_CHF:
      return {
        ...state,
        roofAreaChf: payload,
      };

    case PvStateAction.STORE_ROOF_AREA_LIFETIME_CHF:
      return {
        ...state,
        roofAreaLifetimeChf: payload,
      };

    case PvStateAction.STORE_ROOF_AREA_KWH:
      return {
        ...state,
        roofAreaKwh: payload,
      };

    case PvStateAction.STORE_ROOF_MATERIAL:
      return {
        ...state,
        roofMaterial: payload,
      };

    case PvStateAction.PV_NBO_FETCHING_STORE:
      return {
        ...state,
        nboFetchedPv: [...state.nboFetchedPv, payload],
      };

    case PvStateAction.PV_NBO_FETCHING_CLEAR:
      return {
        ...state,
        nboFetchedPv: [],
      };

    case PvStateAction.PV_STORE_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplatePv: payload,
      };

    case PvStateAction.STORE_PVEARNINGSKWHM2:
      return {
        ...state,
        pvEarningsKwhM2: payload,
      };

    case PvStateAction.STORE_ROOFORIENTATION:
      return {
        ...state,
        roofOrientation: payload,
      };

    case PvStateAction.PV_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS:
      return {
        ...state,
        showAddressFields3dFailsPv: payload,
      };

    case PvStateAction.PV_SET_CONFIG_SETTINGS:
      return {
        ...state,
        ...payload,
      };

    case PvStateAction.PV_STORE_ROOF_3D_IMAGE:
      return {
        ...state,
        previewImage: payload,
      };

    case PvStateAction.SET_OFFER_STATUS:
      return {
        ...state,
        offerStatus: payload,
      };

    case PvStateAction.PV_STORE_VARIANT_ID:
      return {
        ...state,
        variantIdPv: payload,
      };

    case PvStateAction.STORE_IS_PVX:
      return {
        ...state,
        isPvx: payload,
      };

    case PvStateAction.STORE_MAX_PV_COVERED_ROOF_AREA:
      return {
        ...state,
        maxPvCoveredRoofArea: payload,
      };

    default:
      return state;
  }
};
