import { Inject, Injectable } from '@angular/core';
import { ClientConfig, SanityClient, createClient } from '@sanity/client';
import { ITemplateEm } from '@theia-cc/em/core';
import { IConfigEm, IEmTranslations } from '@theia-cc/em/state';
import { ENVIRONMENT, IEnvironment, populateCustomComponentStyles } from '@theia-cc/shared/helpers';
import { TransformSanityDataService } from './transform-sanity-data.service';
import { SanityImageService } from './sanity-image.service';

@Injectable({
  providedIn: 'root',
})
export class SanityService {
  private client: SanityClient;
  private clientConfig: ClientConfig;

  constructor(
    private emTransformSanityDataService: TransformSanityDataService,
    private sanityImageService: SanityImageService,
    @Inject(ENVIRONMENT) private environment: IEnvironment
  ) {
    if (this.environment.sanity) {
      this.clientConfig = {
        projectId: this.environment.sanity.projectId,
        dataset: this.environment.sanity.dataset,
        apiVersion: this.environment.sanity.apiVersion,
        useCdn: this.environment.sanity.useCdn,
      };
      this.client = this.sanityClient();
    }
  }

  // Get all emobility templates by partnerId
  public async getAllEmTemplatesByPartnerId(partnerId: string): Promise<ITemplateEm[]> {
    return await this.client.fetch(
      `*[_type == "emTemplate" && mandator->key->key == $partnerId]|order(orderRank){
      ...,
      "chargingPower": chargingPower->value,
      "mandatorKey": mandator->key->key,
      "enums": enums[]{
        ...,
        "enumName": enumName->{
          ...
        }
      }
    }`,
      {
        partnerId,
      }
    );
  }

  // Get a single emobility template by its id
  public async getEmTemplateById(id: string): Promise<ITemplateEm> {
    return await this.client.fetch(
      `*[_type == "emTemplate" && id == $id][0]{
        ...,
        "chargingPower": chargingPower->value,
        "enums": enums[]{
          ...,
          "enumName": enumName->{
            ...
          }
        }
    }`,
      {
        id,
      }
    );
  }

  // Get the emobility app configuration by partnerId
  public async getEmConfiguration(
    partnerId: string
  ): Promise<{ config: { [key: string]: IConfigEm }; wizardProps: Record<string, any> }> {
    const config: any[] = await this.client.fetch(
      `*[_type == "emMandator" && key->key == $partnerId]{
        ...,
        "key": key->key,
        "faviconUrl": faviconUrl.asset->url,
        "styleOverridesFileLink": styleOverridesFile.asset->url,
      }`,
      {
        partnerId,
      }
    );

    return this.emTransformSanityDataService.transformEmAppConfiguration(config);
  }

  // Get the emobility general translations (default for all partners)
  public async getEmGeneralTranslations(): Promise<IEmTranslations> {
    return await this.client.fetch(
      `*[_type == "emGeneralTranslation"][0]{
        ...
      }`
    );
  }

  // Get the emobility partner specific translations (overrides the general translations)
  public async getEmPartnerSpecificTranslations(partnerId: string): Promise<IEmTranslations> {
    return await this.client.fetch(
      `*[_type == "emTranslation" && mandator->key->key == $partnerId][0]{
        ...
      }`,
      {
        partnerId,
      }
    );
  }

  // Get the track and trace general translations (default for all partners)
  public async getTtGeneralTranslations<T>(): Promise<T> {
    return await this.client.fetch(
      `*[_type == "ttGeneralTranslation"][0]{
        ...
      }`
    );
  }

  // Get the track and trace app configuration by partnerId
  public async getTtConfiguration<T>(partnerId: string, lang: string): Promise<T> {
    const config: any = await this.client.fetch(
      `*[_type == "ttMandator" && key->name == $partnerId][0]{
        ...,
        "key": key->name,
        "faviconUrl": faviconUrl.asset->url,
      }`,
      {
        partnerId,
      }
    );

    const theme: any = await this.client
      .fetch(
        `*[_type=="ttBaseTheme" && key->name == $partnerId][0]{
          ...,
          "colors": colors.color[]{
            name,
            "hex": color.hex
          }
        }`,
        {
          partnerId,
        }
      )
      .catch(() => ({}));

    return {
      contactEmail: config.contactEmail,
      contactPhone: config.contactPhone,
      faviconUrl: config.faviconUrl,
      fullName: config.fullName,
      logoImageUrl: this.sanityImageService
        .getImageUrlBuilder(config.logoImageUrl.asset._ref)
        .url(),
      heroHeaderImageUrl: config.heroHeaderImageUrl
        ? this.sanityImageService.getImageUrlBuilder(config.heroHeaderImageUrl.asset._ref).url()
        : undefined,
      organizationalUnit: config.organizationalUnit,
      logoLinkUrl: config.logoLinkUrl[lang],
      theme: theme?.colors ?? [],
      customComponentStyles: {
        ...populateCustomComponentStyles(config),
      },
    } as T;
  }

  // Function to get any document inside sanity by the document id
  public async getDocumentById(documentId: string): Promise<any> {
    return await this.client.fetch(
      `*[_id == $documentId]{
        ...
      }`,
      { documentId }
    );
  }

  public async getDigitalUpload(tadeType: string, language: string): Promise<any> {
    const blocks = await this.client
      .fetch(`*[_type == "${tadeType}DigitalUploadBlock"]`)
      .then(data => {
        return data
          .sort((a, b) => a.orderRank.localeCompare(b.orderRank))
          .map(block => {
            return {
              ...block,
              images: block?.images?.map(image =>
                this.sanityImageService.getImageUrlBuilder(image.asset._ref).url()
              ),
              title: block.title[language],
              description: block.description[language],
            };
          });
      });

    const customTranslations = await this.client
      .fetch(`*[_type == "${tadeType}DigitalUploadTranslation"]`)
      .then(data => {
        return data.sort(
          (a, b) => new Date(b._updatedAt).getTime() - new Date(a._updatedAt).getTime()
        )[0];
      });

    const generalTranslations = await this.client.fetch(`*[_type == "duGeneralTranslation"]`);

    const translations = { ...generalTranslations?.[0], ...customTranslations };

    for (const key in translations) {
      if (translations[key] && translations[key][language]) {
        translations[key] = translations[key][language];
      }
    }

    return { blocks, translations };
  }

  private sanityClient(): SanityClient {
    return createClient(this.clientConfig);
  }
}
