import { IStep } from '@theia-cc/shared/models';
import { notCommunitySolution, notDealerOrigin, notIndividualSolution } from './skipIfs';

export const STEPS_GENERIC: IStep[] = [
  {
    name: 'where-to-install-charger',
  },
  {
    name: 'number-of-parkingspaces-to-be-equipped-residential',
    skipIf: [notIndividualSolution],
  },
  {
    name: 'number-of-parkingspaces',
    skipIf: [notCommunitySolution],
  },
  {
    name: 'parkingspace',
    skipIf: [notIndividualSolution],
  },
  {
    name: 'distance-fusebox-to-chargingstation',
  },
  {
    name: 'parking-space-arrangement',
    skipIf: [notCommunitySolution],
  },
  {
    name: 'contact-email',
    skipIf: [notCommunitySolution],
  },
  {
    name: 'contact-condensed',
    skipIf: [notCommunitySolution],
  },
  {
    name: 'poweroutlet',
    skipIf: [notIndividualSolution],
  },
  {
    name: 'fusebox',
    skipIf: [notIndividualSolution],
  },
  {
    name: 'connectpv',
    skipIf: [notIndividualSolution],
  },
  {
    name: 'offerpreview',
  },
  {
    name: 'contact',
    skipIf: [notIndividualSolution],
  },
  {
    name: 'contact-dealer',
    skipIf: [notDealerOrigin],
  },
];
