import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getLocalizedItemFromILanguageSpecificStringWithTypeObject } from '@theia-cc/shared/helpers';
import { ILanguageSpecificStringWithType } from '@theia-cc/shared/models';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appLocalizedText]',
})
export class LocalizedTextDirective implements OnInit, OnDestroy {
  @Input('appLocalizedText') textData: ILanguageSpecificStringWithType;
  @Input('appLocalizedInterpolateData') interpolateData: Record<string, string | number>;

  private subscription: Subscription = new Subscription();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.updateText(this.translate.currentLang);

    this.subscription.add(
      this.translate.onLangChange.subscribe(event => {
        this.updateText(event.lang);
      })
    );
  }

  private updateText(currentLanguage: string): void {
    let translatedText: string = getLocalizedItemFromILanguageSpecificStringWithTypeObject(
      this.textData,
      currentLanguage
    );

    if (this.interpolateData) {
      translatedText = translatedText.replace(/\{\{\s*(\w+)\s*\}\}/g, (match, token) => {
        return this.interpolateData[token]?.toString() || match;
      });
    }

    if (this.textData.type === 'htmlString') {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', translatedText);
    } else {
      // For 'textOnly' or other types
      this.renderer.setProperty(this.el.nativeElement, 'innerText', translatedText);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
