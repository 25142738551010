import { NgRedux } from '@angular-redux/store';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cleanEmptyProperties, isOfferPreviewStep } from '@theia-cc/shared/helpers';
import { IConfigBase, IStep } from '@theia-cc/shared/models';
// to avoid circular dependencies
import { AppInitService } from '@theia-cc/shared/services';
import { ShareLinkService } from '../../../services/src/lib/share-link.service';
import { IAppStateBase } from './shared-store.model';
import { WizardAction } from './wizard/wizard.action';

@Injectable()
export class SharedStoreEffect {
  constructor(
    private router: Router,
    private store: NgRedux<IAppStateBase>,
    private wizardAction: WizardAction,
    private shareLinkService: ShareLinkService,
    private location: Location,
    private appInitService: AppInitService
  ) {}

  setCurrentStep = (currentStep: string) => {
    const appState: IAppStateBase = this.store.getState();
    const productConfig = appState.config;
    const currentStepIdx =
      productConfig &&
      productConfig.steps &&
      productConfig.steps.findIndex(el => el.name === currentStep);

    this.wizardAction.setCurrentStep(currentStep, currentStepIdx);
  };

  setNavbarDefaultWithContinue = (): void => {
    this.wizardAction.disallowNextStep();
    this.wizardAction.setNavbarPrimaryButtonDefault();
    this.wizardAction.setNavbarSecondaryButtonDefault();
    this.wizardAction.setNavbarPrimaryButtonProperties({ hidden: false });
    this.wizardAction.setNavbarSecondaryButtonProperties({ hidden: true });
  };

  setNavbarDefaultEmpty = (): void => {
    this.wizardAction.allowNextStep();
    this.wizardAction.setNavbarPrimaryButtonDefault();
    this.wizardAction.setNavbarSecondaryButtonDefault();
    this.wizardAction.setNavbarPrimaryButtonProperties({ hidden: true });
    this.wizardAction.setNavbarSecondaryButtonProperties({ hidden: true });
  };

  navigateNext(): void {
    const appState: IAppStateBase = this.store.getState();
    const { currentStep, currentStepIdx, totalSteps } = appState.wizard;
    const { steps, queryParams } = appState.config;

    if (!appState.wizard.allowNextStep) return;

    for (let i = 1; i < totalSteps; i += 1) {
      let nextStep;

      if (currentStepIdx + i <= totalSteps) {
        nextStep = currentStepIdx + i;
      } else {
        nextStep = currentStep;
      }

      const nextStepObject = steps[nextStep];

      if (SharedStoreEffect.canNavigate(nextStepObject, appState)) {
        const navigate =
          (appState as any).collectedData?.isPvx && queryParams?.partnerId === 'ikea'
            ? nextStep => {
                this.router
                  .navigate([nextStep], {
                    queryParamsHandling: 'merge',
                    queryParams: { partnerId: '' },
                  })
                  .then(() => {
                    setTimeout(() => {
                      this.appInitService.loadConfig(2, false, (config: IConfigBase) => config);
                    }, 0);
                  });
              }
            : isOfferPreviewStep(currentStep)
            ? this.navigateWithoutRestfullOfferPreviewParams(appState)
            : this.navigate.bind(this);

        navigate(nextStepObject.name);
        break;
      }
    }
  }

  static canNavigate = (step: IStep, state: IAppStateBase): boolean => {
    if (!step.skipIf) {
      return true;
    }

    let canNavigate = true;

    if (step.skipIf.length <= 0) {
      canNavigate = true;
    } else {
      for (const skipCondition of step.skipIf) {
        if (typeof skipCondition === 'function') {
          canNavigate = !skipCondition(state);
          if (!canNavigate) {
            break;
          }

          continue;
        }

        if (Object.keys(skipCondition).length > 1) {
          console.error('A skipIf object must contain one property only');
        }

        const conditionalProperty = Object.keys(skipCondition)[0];

        const isWizardStoreProperty = typeof state.wizard[conditionalProperty] !== 'undefined';

        const isQueryParameter =
          typeof state.config.queryParams[conditionalProperty] !== 'undefined';

        const isCollectedProperty = typeof state.collectedData[conditionalProperty] !== 'undefined';

        const isForcedSkip = conditionalProperty === 'forcedSkip';

        if (!isQueryParameter && !isForcedSkip && !isWizardStoreProperty && !isCollectedProperty) {
          console.warn(
            'skipIf contains a property, but property is neither available in the store nor a query parameter',
            conditionalProperty
          );
        }

        const propValue = skipCondition[conditionalProperty];

        if (isCollectedProperty) {
          canNavigate = state.collectedData[conditionalProperty] !== propValue;
        }

        if (isWizardStoreProperty) {
          canNavigate = state.wizard[conditionalProperty] === propValue;
        }

        if (isQueryParameter) {
          canNavigate = state.config.queryParams[conditionalProperty] !== propValue;
        }

        if (isForcedSkip) {
          canNavigate = false;
        }

        if (!canNavigate) {
          break;
        }
      }
    }

    return canNavigate;
  };

  navigateWithoutRestfullOfferPreviewParams(appState: IAppStateBase) {
    return (step: string): Promise<boolean | void> => {
      const { partnerId, restQueryParams } = this.shareLinkService.splitQueryParams(
        appState.config.queryParams
      );
      const params = cleanEmptyProperties({ ...restQueryParams, partnerId });

      return this.router.navigate([step], { queryParams: params }).catch(err => console.error(err));
    };
  }

  navigate(nextStep: string): Promise<boolean> {
    return this.router.navigate([nextStep], { queryParamsHandling: 'merge' }).catch(err => {
      console.error(err);
      return false;
    });
  }

  resetWizard(): void {
    const appState: IAppStateBase = this.store.getState();

    const { partnerId, restQueryParams } = this.shareLinkService.splitQueryParams(
      appState.config.queryParams
    );
    const params = cleanEmptyProperties({ ...restQueryParams, partnerId });
    const paramsArray = Object.entries(params).map(
      ([key, value]: [string, string]) => `${key}=${value}`
    );

    (window as any).location = paramsArray.length ? `/?${paramsArray.join('&')}` : '';
  }

  navigateBack(): void {
    this.location.back();
  }
}
