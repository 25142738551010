/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AddNboPvRequest } from '../model/addNboPvRequest';
import { NboPvAutoOfferRequest } from '../model/nboPvAutoOfferRequest';
import { NboPvCalculateRequest } from '../model/nboPvCalculateRequest';
import { PatchNboRequest } from '../model/patchNboRequest';
import { PvVariantSummaryViewModel } from '../model/pvVariantSummaryViewModel';
import { PvVariantSummaryViewModelLeadSummaryViewModel } from '../model/pvVariantSummaryViewModelLeadSummaryViewModel';
import { SaveNboPvRequest } from '../model/saveNboPvRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class NboPvService {
  protected basePath = 'https://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Adds one or more variants to an existing lead
   *
   * @param leadId The lead id, a guid (string)
   * @param appKey Appkey which corresponds to the mandant for which this variant is created
   * @param body Object and metadata for variants which shall be added to the lead
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddNboPvRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<PvVariantSummaryViewModel>>;
  public nboPvAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddNboPvRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<PvVariantSummaryViewModel>>>;
  public nboPvAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddNboPvRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<PvVariantSummaryViewModel>>>;
  public nboPvAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddNboPvRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboPvAddVariantsToLead.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<PvVariantSummaryViewModel>>(
      `${this.basePath}/api/nbo/pv/${encodeURIComponent(String(leadId))}/variants`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Save an NBO PV lead with a calculated variant in stage NEW with status AUTO_OFFER and generate a PDF preview.  Combines SaveNbo, CalculateNbo and GetVariantPreview without sending emails to customer.
   *
   * @param appKey Appkey which corresponds to the mandant for which this AUTO_OFFER lead is created
   * @param body Objectdata to calculate the variant and address data for the contact.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvAutoOfferPreview(
    appKey?: string,
    body?: NboPvAutoOfferRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboPvAutoOfferPreview(
    appKey?: string,
    body?: NboPvAutoOfferRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboPvAutoOfferPreview(
    appKey?: string,
    body?: NboPvAutoOfferRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboPvAutoOfferPreview(
    appKey?: string,
    body?: NboPvAutoOfferRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/nbo/pv/auto-offer-preview`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Calculates the variant summary of an NBO given the object data
   *
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param body Object Data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvCalculateNbo(
    appKey?: string,
    body?: NboPvCalculateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PvVariantSummaryViewModel>;
  public nboPvCalculateNbo(
    appKey?: string,
    body?: NboPvCalculateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PvVariantSummaryViewModel>>;
  public nboPvCalculateNbo(
    appKey?: string,
    body?: NboPvCalculateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PvVariantSummaryViewModel>>;
  public nboPvCalculateNbo(
    appKey?: string,
    body?: NboPvCalculateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<PvVariantSummaryViewModel>(
      `${this.basePath}/api/nbo/pv/calculate`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This is a dummy calculation method for search engines
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvGetCalculateNbo(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public nboPvGetCalculateNbo(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboPvGetCalculateNbo(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboPvGetCalculateNbo(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/api/nbo/pv/calculate`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Generates a PDF preview of a variant
   *
   * @param leadId Id of the lead to which the variant belongs.
   * @param variantId Variant Id of the variant for which the preview is generated
   * @param appKey App key for authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboPvGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboPvGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboPvGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboPvGetVariantPreview.'
      );
    }

    if (variantId === null || variantId === undefined) {
      throw new Error(
        'Required parameter variantId was null or undefined when calling nboPvGetVariantPreview.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(
      `${this.basePath}/api/nbo/pv/leads/${encodeURIComponent(
        String(leadId)
      )}/variants/${encodeURIComponent(String(variantId))}/preview`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Allows to patch a lead and contact data. Only works for newly created nbo leads.
   *
   * @param leadId The Id (GUID) of the lead
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param body Lead and contact data for that lead to be updated
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboPvPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboPvPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboPvPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboPvPatchNboLead.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(
      `${this.basePath}/api/nbo/pv/${encodeURIComponent(String(leadId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Save an NBO for a PV.
   *
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param body Object and metadata for variants which shall be created and  contact data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboPvSaveNbo(
    appKey?: string,
    body?: SaveNboPvRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PvVariantSummaryViewModelLeadSummaryViewModel>;
  public nboPvSaveNbo(
    appKey?: string,
    body?: SaveNboPvRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PvVariantSummaryViewModelLeadSummaryViewModel>>;
  public nboPvSaveNbo(
    appKey?: string,
    body?: SaveNboPvRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PvVariantSummaryViewModelLeadSummaryViewModel>>;
  public nboPvSaveNbo(
    appKey?: string,
    body?: SaveNboPvRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<PvVariantSummaryViewModelLeadSummaryViewModel>(
      `${this.basePath}/api/nbo/pv`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
