import {
  AllTranslationOptionKeysUnionEm,
  IAppStateEm,
  TranslationPropertyEm,
} from '@theia-cc/em/state';
import { getLocalizedItemFromILanguageSpecificStringWithTypeObject } from '@theia-cc/shared/helpers';

export const getTranslationCaption = (
  appState: IAppStateEm,
  translationProperty: TranslationPropertyEm,
  optionKey: AllTranslationOptionKeysUnionEm,
  currentLang: string
): string => {
  const translation = appState.config.generalTranslations[translationProperty];

  // Provide an empty object as a fallback if specificTranslations is null
  const specificTranslations = appState.config.specificTranslations ?? {};
  const specificTranslation = specificTranslations[translationProperty];

  const caption =
    specificTranslation && specificTranslation[optionKey]?.overrideDefaultTranslation
      ? specificTranslation[optionKey]
      : translation[optionKey];

  return getLocalizedItemFromILanguageSpecificStringWithTypeObject(caption, currentLang);
};
