/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PvVariantSummaryViewModel {
  /**
   * The variant's id. It will be null if the variant has not been persisted.
   */
  VariantId?: number;
  /**
   * The variant's title. It may be empty if the variant is not persisted.
   */
  VariantTitle?: string;
  TaxSaving?: number;
  BruttoPrice?: number;
  NettoPrice?: number;
  Vat?: number;
  Investment?: number;
  EivOneOffPayment?: number;
  MunicipalSubsidies?: number;
  CantonalSubsidies?: number;
  FederalSubsidies?: number;
  OtherSubsidies?: number;
  TotalSubsidies?: number;
  FinancialLifetimeYieldWithoutStorage?: number;
  FinancialLifetimeYieldWithStorage?: number;
  FinancialFeedInYieldPerYearWithStorage?: number;
  FinancialFeedInYieldPerYearWithoutStorage?: number;
  FinancialYearlyYieldPerYearWithoutStorage?: number;
  FinancialYearlyYieldPerYearWithStorage?: number;
  NumberOfModules?: number;
  KwhProducedPerYear?: number;
  TotalKwp?: number;
  CoveredRoofArea?: number;
  EnergyBalance?: number;
  EnergyProductionCosts?: number;
  EivOneOffPaymentExclVat?: number;
  InvestmentExclVatEiv?: number;
}
