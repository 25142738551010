import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  GTMEvent,
  IBuildingSummary,
  ILanguageSpecific,
  ILanguageSpecificStringWithType,
  IUserAddress,
  Theme,
} from '@theia-cc/shared/models';
import lodashIsempty from 'lodash.isempty';
import { injectDynamicThemeTokensIntoHtmlHead } from './dynamic-styles-helpers';
import { THEME_TOKENS } from '@theia-cc/em/core';

export function googleTagManagerPush<IGTMEvent = GTMEvent>(event: IGTMEvent) {
  const gtm = (window as any).dataLayerGTM || [];
  if (!event) {
    console.error('Empty event object, aborting', 'gtm:', gtm);
    return;
  }
  gtm.push(event);
}

export const camelCaseReplacer = (str: string, match1: string, match2: string) => {
  return match1 === ''
    ? str.replace(match1, '').replace(match2, match2.toLowerCase())
    : str.replace(match1, '').replace(match2, match2.toUpperCase());
};

export const reduceToObjectByKey = (array: any[], key: string) => {
  return array.reduce((result, item) => {
    return {
      ...result,
      [item[key]]: item,
    };
  }, {});
};

export const makeObjectKeysCamelCase = object => {
  if (!object || typeof object !== 'object') {
    return object;
  }
  if (object.constructor === Array) {
    return object.map(i => makeObjectKeysCamelCase(i));
  }
  return Object.keys(object).reduce((result, key) => {
    return {
      ...result,
      [key.replace(/(^|[-_]+)([A-Za-z1-9])/g, camelCaseReplacer)]: makeObjectKeysCamelCase(
        object[key]
      ),
    };
  }, {});
};

export const getScript = src => {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.async = true;
    s.defer = true;
    s.onload = () => {
      resolve(true);
    };
    s.addEventListener('error', reject);
    s.addEventListener('load', resolve);
    s.onerror = () => reject(true);
    document.querySelector('head').appendChild(s);
  });
};

export const objDeepTrim = (obj: object) => {
  for (const prop in obj) {
    const value = obj[prop];
    const type = typeof value;
    if (value != null && (type === 'string' || type === 'object') && obj.hasOwnProperty(prop)) {
      if (type === 'object') {
        objDeepTrim(obj[prop]);
      } else {
        obj[prop] = obj[prop].trim();
      }
    }
  }
  return obj;
};

export const sleep = milliseconds => {
  return new Promise<void>(resolve => setTimeout(resolve, milliseconds));
};

export const objectToQueryParamString = (params = {}): string => {
  let string = '';
  Object.keys(params).forEach(key => {
    const value = params[key] !== 'undefined' && params[key] ? encodeURI(params[key]) : '';
    string = string.concat(`${key}=${value}&`);
  });
  return string.slice(0, -1);
};

export const isOfferPreviewStep = (step: string): boolean =>
  step && step.indexOf('offerpreview') !== -1;

export const isObject = obj => obj === Object(obj) && !Array.isArray(obj);

export const isEmptyValue = value =>
  value === null ||
  value === undefined ||
  value === '' ||
  (isObject(value) && lodashIsempty(value)) ||
  (Array.isArray(value) && !value.length);

export function cleanEmptyProperties(payload: any, additionalEmptyValue?: any): any {
  return isObject(payload)
    ? Object.entries(payload).reduce((res, [key, value]) => {
        if (!isEmptyValue(value) && value !== additionalEmptyValue) {
          if (Array.isArray(value)) {
            res[key] = value.map(item => cleanEmptyProperties(item, additionalEmptyValue));
          } else if (isObject(value)) {
            res[key] = cleanEmptyProperties(value, additionalEmptyValue);
          } else {
            res[key] = value;
          }
        }

        return res;
      }, {})
    : Array.isArray(payload)
    ? payload.map(item => cleanEmptyProperties(item, additionalEmptyValue))
    : payload;
}

export const validators = {
  email: email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },
};

export const getRoofAreaChf = (kwhYield: number) => {
  return kwhYield * (1 - 0.3) * 0.09 * 1.25 + kwhYield * 0.3 * 0.2 * 1.25;
};

export const getRoofAreaLifetimeChf = (kwhYield: number) => {
  return (kwhYield * (1 - 0.3) * 0.09 * 1.25 + kwhYield * 0.3 * 0.2 * 1.25) * 30;
};

export const parseIntRound = (val: string) => Math.round(parseInt(val, 10));

export const transformSearchAddressToUserAddress = (
  term: string,
  obj: any
): { id: string; searchResult: string; data: IUserAddress } => {
  const { id, attrs } = obj;
  const { x, y, label, lat, lon, featureId } = attrs;
  const regex = /(.*)\s(\d{4})\s(.*)/gm;

  const address: string = label.replace(/<[^>]+>/gi, '').replace('  ', ' ');

  const addressFragments = regex.exec(address);

  const structuredAddress = {
    street: addressFragments[1],
    zip: addressFragments[2],
    place: addressFragments[3],
  };

  const addressString = `${structuredAddress.street}, ${structuredAddress.zip} ${structuredAddress.place}`;

  const egid = featureId && featureId.split('_')[0];

  const searchResult = addressString.replace(
    new RegExp(term, 'ig'),
    `<span class="address-typeahead__search-string">${term}</span>`
  );

  return {
    id,
    searchResult,
    data: {
      x,
      y,
      lat,
      lon,
      addressString,
      egid,
      zip: structuredAddress.zip,
      place: structuredAddress.place,
      street: structuredAddress.street,
      hasGeometry: false,
    },
  };
};

export const transform3dPluginDataToBuildingData = (
  data: any,
  maxBuildingArea: number
): IBuildingSummary => {
  return {
    aboveSea: parseIntRound(data.aboveSea),
    buildingEavesHeight: parseIntRound(data.buildingEavesHeight),
    buildingGroundArea: Math.min(parseIntRound(data.buildingGroundArea), maxBuildingArea),
    buildingLevels: parseIntRound(data.buildingLevels),
    buildingRidgeHeight: parseIntRound(data.buildingRidgeHeight),
    buildingRoofArea: parseIntRound(data.buildingRoofArea),
    buildingRoofOverhangArea: parseIntRound(data.buildingRoofOverhangArea),
    buildingRoofShape: parseIntRound(data.buildingRoofShape),
    buildingVolume: parseIntRound(data.buildingVolume),
    buildingWallArea: parseIntRound(data.buildingWallArea),
  };
};

export const parseFormString = {
  array: (arr: string | any[], defaultValue = []): any[] =>
    Array.isArray(arr) ? arr : (arr && arr.split(',')) || defaultValue,
  number: (num: string | number): number =>
    num !== null && Number.isFinite(+num) ? +num : undefined,
  boolean: (bool: string | boolean): boolean =>
    bool === 'true' || bool === true
      ? true
      : bool === 'false' || bool === false
      ? false
      : undefined,
};

const _combine = (acc, cur, prop) => Math.round(parseInt(acc[prop], 10) + parseInt(cur[prop], 10));

const _biggerThan = (acc, cur, prop) =>
  Math.round(acc[prop] < cur[prop] ? parseInt(cur[prop], 10) : parseInt(acc[prop], 10));

const surfacesSummaryReset = {
  area: 0,
  pvEarningsChf: 0,
  pvEarningsLifetimeChf: 0,
  pvEarningsKwh: 0,
  pvEarningsKwhM2: 0,
  solarheatEarningsKwh: 0,
  tilt: 0,
  previewImage: null,
  orientation: 0,
};

export const transform3dPluginSurfaceToBuildingRoofSurface = (data: any) => {
  const surfacesSummary =
    data.length > 0
      ? data.reduce(
          (acc, cur) => {
            return {
              area: _combine(acc, cur, 'area'),
              pvEarningsChf: _combine(acc, cur, 'pvEarningsChf'),
              pvEarningsKwh: _combine(acc, cur, 'pvEarningsKwh'),
              pvEarningsKwhM2: _combine(acc, cur, 'pvEarningsKwhM2'),
              solarheatEarningsKwh: _combine(acc, cur, 'solarheatEarningsKwh'),
              tilt: _biggerThan(acc, cur, 'tilt'),
              orientation: Math.round(cur['orientation']),
            };
          },
          { ...surfacesSummaryReset }
        )
      : { ...surfacesSummaryReset };
  surfacesSummary.pvEarningsChf = getRoofAreaChf(surfacesSummary.pvEarningsKwh);
  surfacesSummary.pvEarningsLifetimeChf = getRoofAreaLifetimeChf(surfacesSummary.pvEarningsKwh);

  return surfacesSummary;
};

export function calcPvEarningsKwhM2(objectData: any, pvEarningsKwhM2: number): number {
  if (objectData) {
    const buildingRoofSurfaceArray: any[] = objectData['buildingRoofSurface'];
    if (buildingRoofSurfaceArray) {
      const totalArea = buildingRoofSurfaceArray.reduce((prev, cur) => {
        return prev + cur.area;
      }, 0);

      return buildingRoofSurfaceArray.reduce(
        (result, cur) => result + (cur.area / totalArea) * cur.pvEarningsKwhM2 || pvEarningsKwhM2,
        0
      );
    }
  }
  return 0;
}

export const whitespacesHandler = inputString => {
  // Check if input string only contains whitespaces
  if (inputString) {
    if (!inputString.replace(/\s/g, '').length) {
      return 'unknown';
    }
  }
  return inputString;
};

export function last(array) {
  const length = array == null ? 0 : array.length;
  return length ? array[length - 1] : undefined;
}

export function buildUpLeadComment(
  leadComment: string,
  versionNumber: string,
  userAgent: string,
  callbackTime: string,
  collectedData: any,
  queryParams: any,
  offerPreviewRestfullLink: string,
  isForCallBack: boolean = false
): string {
  const collectedDataWithoutEmpty = cleanEmptyProperties(collectedData);

  collectedDataWithoutEmpty.nboFetched = undefined;
  collectedDataWithoutEmpty.selectedTemplate = undefined;
  collectedDataWithoutEmpty.previewImage = undefined;
  collectedDataWithoutEmpty.nboFetched = undefined;

  const getCaseAgnosticQueryParam =
    queryParams && isObject(queryParams) && caseAgnosticQueryParam(queryParams);

  const contactFormComment = `Contact Form Comment: \n${leadComment || '-'} \n \n `;

  const callbackTimePart = isForCallBack ? `Callback Time: \n ${callbackTime} \n \n` : '';

  const noteFomQueryParams =
    queryParams && isObject(queryParams) && getCaseAgnosticQueryParam('note')
      ? `URL comment: \n${getCaseAgnosticQueryParam('note')}  \n \n `
      : '';

  const offerPreviewRestfullUrl = offerPreviewRestfullLink
    ? `Offer Preview Restfull Url:  \n \n ${offerPreviewRestfullLink}  \n \n `
    : '';

  const dealerEmail = collectedData.dealerEmail
    ? `Dealer-Email: ${collectedData.dealerEmail} \n \n`
    : '\n';

  const porscheCenterInfo = collectedData.centerInfo
    ? `Dealer: \n ${collectedData.centerInfo['Dealer Name']} \n Street: ${collectedData.centerInfo['Strasse']} \n PLZ Place: ${collectedData.centerInfo['Postal Code']} \n E-Mail: ${collectedData.centerInfo['E-mail']} \n Phone number: ${collectedData.centerInfo['Telefon']} \n`
    : '';

  return `${contactFormComment}${noteFomQueryParams}${callbackTimePart}${porscheCenterInfo}${dealerEmail}${offerPreviewRestfullUrl}Lead generated on ${new Date()} with ${versionNumber}. \n \nUser History: ${JSON.stringify(
    cleanEmptyProperties(collectedDataWithoutEmpty),
    null,
    2
  )} \n \nUser Agent: ${userAgent}`;
}

export function calculateBestFitArea(
  roofArea: number,
  optimalRoofArea: number,
  pvxThreshold: number,
  totalPowerRequirementKwh: number,
  roofAreaKwh?: number
): number {
  if (totalPowerRequirementKwh > pvxThreshold) {
    const neededRoofArea = (roofArea * totalPowerRequirementKwh) / roofAreaKwh;
    return neededRoofArea > roofArea ? roofArea : neededRoofArea;
  }
  const bestFitArea = roofArea < optimalRoofArea ? roofArea : optimalRoofArea;
  return bestFitArea;
}

export function getAddressString({ street, zipcode, city }) {
  return street || zipcode || city ? `${street || ''}, ${zipcode || ''} ${city || ''}` : '';
}

export const getAddressComponent = (address_components, type) =>
  address_components.find(item => item.types.includes(type))?.short_name || '';

export const ORIGINS_TO_IGNORE_THAT_SCULLY_USES = [
  'localhost:1864',
  'localhost:1865',
  'localhost:1866',
  'localhost:1867',
  'localhost:1868',
  'localhost:1869',
];

export function isOnScullyRenderer() {
  return ORIGINS_TO_IGNORE_THAT_SCULLY_USES.some(
    origin => window.location.origin === `http://${origin}`
  );
}

// 'Legacy' way of applying a hardcoded stylesheet
export function applyConfigStylesheetUrl(url: string, domSanitizer: DomSanitizer) {
  const links = document.head.getElementsByTagName('link');
  // @ts-ignore
  const isAlreadyAddedByScullyPrerendering = [...links].find(
    el => el.getAttribute('href')?.indexOf(url) !== -1
  );
  if (lodashIsempty(url) || isAlreadyAddedByScullyPrerendering || isOnScullyRenderer()) {
    return;
  }
  const head = document.getElementsByTagName('head')[0];
  const href = `${domSanitizer.sanitize(SecurityContext.URL, url)}?a=stylesheet-${Math.random()}`;
  const linkElPreload = document.createElement('link');
  linkElPreload.setAttribute('rel', 'preload');
  linkElPreload.setAttribute('as', 'style');
  linkElPreload.setAttribute('href', href);
  head.appendChild(linkElPreload);

  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('href', href);
  head.appendChild(linkEl);
}

export function applyDynamicCmsStyles(
  domSanitizer: DomSanitizer,
  theme: Theme,
  styleOverridesFileLink: string,
  useCustomThemeOverrideStylesheet: boolean,
  parnterId: string
) {
  // Inject dynamic theme tokens into the head of the html document
  injectDynamicThemeTokensIntoHtmlHead(theme, THEME_TOKENS);

  // Inject the base stylesheet into the head - only if the useCustomThemeOverrideStylesheet flag is set to false
  if (!useCustomThemeOverrideStylesheet) {
    injectStylesheetIntoHead('base.css', domSanitizer);
  }

  // If the useCustomThemeOverrideStylesheet flag is set to true, inject the custom theme stylesheet into the head
  if (useCustomThemeOverrideStylesheet) {
    injectStylesheetIntoHead(`${parnterId}.css`, domSanitizer);
  }

  // If a custom stylesheet is provided, inject it into the head (for overriding the default stylesheet)
  if (styleOverridesFileLink) {
    injectStylesheetIntoHead(styleOverridesFileLink, domSanitizer);
  }
}

const injectStylesheetIntoHead = (url: string, domSanitizer: DomSanitizer): void => {
  const head = document.getElementsByTagName('head')[0];
  const href = `${domSanitizer.sanitize(SecurityContext.URL, url)}`;
  const linkElPreload = document.createElement('link');
  linkElPreload.setAttribute('rel', 'preload');
  linkElPreload.setAttribute('as', 'style');
  linkElPreload.setAttribute('href', href);
  head.appendChild(linkElPreload);

  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('href', href);
  head.appendChild(linkEl);
};

export const appendCustomFaviconToHead = (url: string, domSanitizer: DomSanitizer) => {
  if (lodashIsempty(url)) {
    return;
  }
  const head = document.getElementsByTagName('head')[0];
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'shortcut icon');
  linkEl.setAttribute('type', 'image/x-icon');
  linkEl.setAttribute(
    'href',
    `${domSanitizer.sanitize(SecurityContext.URL, url)}?a=favicon-${Math.random()}`
  );
  head.appendChild(linkEl);
};

export const getCurrentStepFromUrl = (url: string): string => {
  const pathSegments = url.split('/');
  const currentStep = last(pathSegments);
  return currentStep && currentStep.includes('?')
    ? currentStep.substring(0, currentStep.indexOf('?'))
    : currentStep;
};

export const convertKeysToLowerCase = (object: { [key: string]: string }) => {
  return Object.entries(object).reduce((res, [key, value]) => {
    res[key.toLowerCase()] = value;
    return res;
  }, {});
};

export const caseAgnosticQueryParam = (queryParams: { [key: string]: string }) => {
  const lowerCaseParams = convertKeysToLowerCase(queryParams);
  return (param: string) => lowerCaseParams[param?.toLowerCase()];
};

export const getRestQueryParams = (queryParams: { [key: string]: string } = {}) =>
  Object.entries({ ...queryParams }).reduce((res, [key, value]) => {
    const usefulKey = [
      'compareTechnologies',
      'address',
      'note',
      'origin',
      'affiliateId',
      'ThirdPartyCustomerNumber',
      'leadTracers',
      'mode',
      'b2b',
      'trade',
    ].find(k => k.toLowerCase() === key.toLowerCase());
    if (usefulKey !== undefined) {
      res[key] = value;
    }

    return res;
  }, {});

export const caseAgnosticQueryParamFromLocation = () => {
  return caseAgnosticQueryParam(
    // @ts-ignore
    [...new URLSearchParams(window.location.search)].reduce((o, i) => ({ ...o, [i[0]]: i[1] }), {})
  );
};

export const underscoreStringToSpace = (s: string, mapFn = v => v): string => {
  return s.split('_').map(mapFn).join(' ');
};

export const getUserAddressFromQueryParams = queryParams => {
  const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams);

  const place = getCaseAgnosticQueryParam('City');
  const street = getCaseAgnosticQueryParam('Street');
  const zip = getCaseAgnosticQueryParam('ZipCode');

  const streetAddressPart = street ? `${street},` : '';
  const zipAddressPart = zip ? ` ${zip}` : '';
  const placeAddressPart = place ? ` ${place}` : '';
  const addressString = `${streetAddressPart}${zipAddressPart}${placeAddressPart}`;

  return {
    egid: getCaseAgnosticQueryParam('egid'),
    lat: parseFormString.number(getCaseAgnosticQueryParam('lat')),
    lon: parseFormString.number(getCaseAgnosticQueryParam('lon')),
    place,
    street,
    zip,
    addressString,
  };
};

export const getLocalizedItemFromILanguageSpecificObject = (
  object: ILanguageSpecific<string>,
  locale: string
): string => {
  return object[locale] || object['de'];
};

export const getLocalizedItemFromILanguageSpecificStringWithTypeObject = (
  object: ILanguageSpecificStringWithType,
  locale: string
): string => {
  return object.translations[locale] || object.translations['de'];
};
