import { Inject, Injectable } from '@angular/core';
import {
  A2C_PLUGIN_API_URL,
  getScript,
  ROOF_3D_PLUGIN_COMMON_SETTINGS,
} from '@theia-cc/shared/helpers';
import { BackendService } from './backend.service';
import { DOCUMENT } from '@angular/common';
import { from } from 'rxjs';
import { WizardAction } from '@theia-cc/shared/store';

@Injectable()
export class A2CBuildingLookupService {
  private pluginLoaded = false;
  private ID = 'plugin-viewer-check';

  constructor(
    private backend: BackendService,
    private wizardAction: WizardAction,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public find({ lat, lon }) {
    return from(this.apiLookup({ lat, lon }));
  }

  private apiLookup({ lat, lon }): Promise<{ hasGeometry: boolean }> {
    if (!this.document.getElementById(this.ID)) {
      const div = document.createElement('div');
      div.id = this.ID;
      div.style.position = 'absolute';
      div.style.left = '-9999px';
      document.body.appendChild(div);
    }

    return new Promise((resolve, reject) => {
      if (!lat || !lon) {
        return resolve({ hasGeometry: false });
      }
      // /exists return hasGeometry: false for Rötelstrasse 14, 8006 Zürich, but it exists in the player, that's why we use /buildingPlayer here
      const loadedPlugin = this.pluginLoaded
        ? Promise.resolve()
        : this.backend
            .getBuildingPlayerAccessToken()
            .then(accessToken => getScript(`${A2C_PLUGIN_API_URL}/?token=${accessToken}`))
            .then(() => {
              this.pluginLoaded = true;
            });

      loadedPlugin
        .then(() => {
          this.wizardAction.showLoadingSpinner();
          const myBuilding = new (window as any).buildingPlayer({
            container: this.ID,
            ...ROOF_3D_PLUGIN_COMMON_SETTINGS,
            opacity: 0,
            loadCallback: data => {
              this.wizardAction.hideLoadingSpinner();
              resolve({ hasGeometry: data.status === 'ok' });
            },
          });

          myBuilding.getBuilding(lat, lon);
        })
        .catch(err => {
          resolve({ hasGeometry: false });
        });
    });
    // return this.http.get(`${A2C_HAS_GEOMETRY_API_URL}/?lat=${lat}&lon=${lon}`);
  }
}
