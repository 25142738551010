/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AdditionalPhoneViewModel {
  PhoneType: AdditionalPhoneViewModel.PhoneTypeEnum;
  PhoneNumber: string;
}
export namespace AdditionalPhoneViewModel {
  export type PhoneTypeEnum =
    | 'Phone'
    | 'Mobile'
    | 'Work'
    | 'Private'
    | 'Switchboard'
    | 'Other'
    | 'Primary'
    | 'Direct';
  export const PhoneTypeEnum = {
    Phone: 'Phone' as PhoneTypeEnum,
    Mobile: 'Mobile' as PhoneTypeEnum,
    Work: 'Work' as PhoneTypeEnum,
    Private: 'Private' as PhoneTypeEnum,
    Switchboard: 'Switchboard' as PhoneTypeEnum,
    Other: 'Other' as PhoneTypeEnum,
    Primary: 'Primary' as PhoneTypeEnum,
    Direct: 'Direct' as PhoneTypeEnum,
  };
}
