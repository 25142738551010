/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboProductlineViewModel {
  HeatingManufacturer?: NboProductlineViewModel.HeatingManufacturerEnum;
  HeatingType?: NboProductlineViewModel.HeatingTypeEnum;
}
export namespace NboProductlineViewModel {
  export type HeatingManufacturerEnum =
    | 'Stiebel'
    | 'Hoval'
    | 'Bosch'
    | 'Oertli'
    | 'Viessmann'
    | 'Cta'
    | 'AlphaInnotec'
    | 'Vaillant'
    | 'Mitsubishi'
    | 'Ctc'
    | 'Nibe'
    | 'Ovum'
    | 'Froeling';
  export const HeatingManufacturerEnum = {
    Stiebel: 'Stiebel' as HeatingManufacturerEnum,
    Hoval: 'Hoval' as HeatingManufacturerEnum,
    Bosch: 'Bosch' as HeatingManufacturerEnum,
    Oertli: 'Oertli' as HeatingManufacturerEnum,
    Viessmann: 'Viessmann' as HeatingManufacturerEnum,
    Cta: 'Cta' as HeatingManufacturerEnum,
    AlphaInnotec: 'AlphaInnotec' as HeatingManufacturerEnum,
    Vaillant: 'Vaillant' as HeatingManufacturerEnum,
    Mitsubishi: 'Mitsubishi' as HeatingManufacturerEnum,
    Ctc: 'Ctc' as HeatingManufacturerEnum,
    Nibe: 'Nibe' as HeatingManufacturerEnum,
    Ovum: 'Ovum' as HeatingManufacturerEnum,
    Froeling: 'Froeling' as HeatingManufacturerEnum,
  };
  export type HeatingTypeEnum =
    | 'AirWaterHeatPumpOutside'
    | 'BrineWaterHeatPump'
    | 'AirWaterHeatPumpSplit'
    | 'AirWaterHeatPumpInside'
    | 'NotSpecified'
    | 'OilBoiler'
    | 'GasBoilerWall'
    | 'GasBoilerFloor'
    | 'PelletsBoiler';
  export const HeatingTypeEnum = {
    AirWaterHeatPumpOutside: 'AirWaterHeatPumpOutside' as HeatingTypeEnum,
    BrineWaterHeatPump: 'BrineWaterHeatPump' as HeatingTypeEnum,
    AirWaterHeatPumpSplit: 'AirWaterHeatPumpSplit' as HeatingTypeEnum,
    AirWaterHeatPumpInside: 'AirWaterHeatPumpInside' as HeatingTypeEnum,
    NotSpecified: 'NotSpecified' as HeatingTypeEnum,
    OilBoiler: 'OilBoiler' as HeatingTypeEnum,
    GasBoilerWall: 'GasBoilerWall' as HeatingTypeEnum,
    GasBoilerFloor: 'GasBoilerFloor' as HeatingTypeEnum,
    PelletsBoiler: 'PelletsBoiler' as HeatingTypeEnum,
  };
}
