import { NgRedux, select } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { EmStateEffect, GTMEventEm } from '@theia-cc/em/state';
import { HtStateEffect } from '@theia-cc/ht/state';
import { PvStateEffect } from '@theia-cc/pv/state';
import { getCurrentStepFromUrl, googleTagManagerPush } from '@theia-cc/shared/helpers';
import { AlertService, BackendService, ShareLinkService } from '@theia-cc/shared/services';
import {
  ConfigAction,
  IAppStateBase,
  SharedStoreEffect,
  WizardAction,
} from '@theia-cc/shared/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import packageJson from '../../../../package.json';
import { APP_ALLOWED_ENTRY_OFFER_PREVIEW_STEPS } from './configs';
import { PrimeoStateEffect } from './store/effects';
import { IAppStatePrimeo } from './store/reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy, OnInit {
  public subscription: Subscription = new Subscription();
  public spinnerOptions = {
    fullScreenBackdrop: true,
  };
  private previousStep = '';
  public offerPreviewSpinnerOptions = {
    fullScreenBackdrop: true,
    backdropBackgroundColour: 'rgb(255,255,255)',
  };

  @ViewChild('alertContainer', { read: ViewContainerRef, static: true })
  alertContainer;

  @select((state: IAppStateBase) => state.wizard.showLoadingSpinner)
  showLoadingSpinner$;

  @select((state: IAppStateBase) => state.wizard.showOfferPreviewLoadingSpinner)
  showOfferPreviewLoadingSpinner$: Observable<boolean>;

  @select(['collectedData', 'previewImage'])
  public previewImageData$: Observable<string>;

  trade: string;

  constructor(
    private backend: BackendService,
    private effectPrimeo: PrimeoStateEffect,
    private effectEm: EmStateEffect,
    private effectPv: PvStateEffect,
    private effectHt: HtStateEffect,
    private effect: SharedStoreEffect,
    private wizardAction: WizardAction,
    public configAction: ConfigAction,
    private activeRoute: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    private alertService: AlertService,
    public shareLinkService: ShareLinkService,
    private store: NgRedux<IAppStatePrimeo>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  buttonActions(action: string) {
    switch (this.trade) {
      case 'em':
        this.effectEm.buttonActions(action);
        break;
      case 'ht':
        this.effectHt.buttonActions(action);
        break;
      case 'pv':
        this.effectPv.buttonActions(action);
        break;
      default:
        this.effectPrimeo.buttonActions(action);
        break;
    }
  }

  ngOnInit() {
    this.setTrade();
    this.logCurrentVersionNumberToConsole();
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .subscribe((event: RoutesRecognized) => {
        this.configAction.parseQParams(event.state.root.queryParams);

        const currentStep = getCurrentStepFromUrl(event.urlAfterRedirects);
        if (currentStep) {
          this.renderer.addClass(document.body, currentStep);
        }
        if (this.previousStep) {
          this.renderer.removeClass(document.body, this.previousStep);
        }
        this.effect.setCurrentStep(
          APP_ALLOWED_ENTRY_OFFER_PREVIEW_STEPS.includes(currentStep) ? 'offerpreview' : currentStep
        );
        this.previousStep = currentStep;
      });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      googleTagManagerPush<GTMEventEm>({
        event: 'VirtualPageview',
        virtualPageURL: `${window.location.pathname}${window.location.search}`,
        virtualPageTitle: window.location.pathname.replace('/', ''),
      });
    });

    this.alertService.setViewContainerRef(this.alertContainer);
  }

  logCurrentVersionNumberToConsole() {
    console.log(
      '%c%s',
      'color: #03518c; font-size: 14px;',
      `${packageJson.name}-primeo version: ${packageJson.version}`
    );

    this.configAction.storeVersionNumber(
      `${packageJson.name}-primeo version: ${packageJson.version}`
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  generateShareLink() {
    return (): string =>
      this.shareLinkService.generateShareLink(this.document.location, this.store.getState());
  }

  setTrade(): void {
    this.subscription.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            let route = this.activeRoute.firstChild;
            let child = route;
            while (child) {
              if (child.firstChild) {
                child = child.firstChild;
                route = child;
              } else {
                child = null;
              }
            }
            return route;
          }),
          mergeMap(route => route.data)
        )
        .subscribe(data => {
          this.trade = data.trade;
        })
    );
  }
}
