import {
  TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_STANDARD,
  TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_INROOF,
  TEMPLATE_IKEA_ADVANCED_FLATROOF_STANDARD,
} from '@theia-cc/pv/core';
// Templates config Ikea for Sloped Roof
export const CONFIG_IKEA_TEMPLATE_SLOPED_ROOF = {
  active: [TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_STANDARD, TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_INROOF],
  default: TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_STANDARD,
  inactive: [],
};

// Templates config Ikea for Flat Roof
export const CONFIG_IKEA_TEMPLATE_FLAT_ROOF = {
  active: [TEMPLATE_IKEA_ADVANCED_FLATROOF_STANDARD],
  default: TEMPLATE_IKEA_ADVANCED_FLATROOF_STANDARD,
  inactive: [],
};
