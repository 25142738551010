import { NboPvLeadRequest } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { IChoiceButton } from '@theia-cc/shared/step-ui';

export enum BuildingType {
  FAMILY_HOME = 'FAMILY_HOME',
  APARTMENT_BUILDING = 'APARTMENT_BUILDING',
  COMMERCIAL_BUILDING = 'COMMERCIAL_BUILDING',
  OFFICE_BUILDING = 'OFFICE_BUILDING',
}

export enum OfferStatusEnum {
  REQUEST_CONSULTATION,
  REQUEST_QUOTE,
  FINISHED,
}

export const ROOF_MATERIAL_DEF = [
  {
    key: NboPvLeadRequest.RoofCoverTypeEnum.Gravel,
    icon: 'roof-gravel.jpg',
    name: _('PV.ROOFMATERIAL.GRAVEL.MATERIAL'),
    type: _('PV.ROOFMATERIAL.GRAVEL.TYPE'),
    flat: true,
  },
  {
    key: NboPvLeadRequest.RoofCoverTypeEnum.SubstrateSlightlyOvergrown,
    icon: 'roof-substrate.jpg',
    name: _('PV.ROOFMATERIAL.SUBSTRATE.MATERIAL'),
    type: _('PV.ROOFMATERIAL.SUBSTRATE.TYPE'),
    flat: true,
  },
  {
    key: NboPvLeadRequest.RoofCoverTypeEnum.ClayTiles,
    icon: 'roof-brick.jpg',
    name: _('PV.ROOFMATERIAL.BRICK.MATERIAL'),
    type: _('PV.ROOFMATERIAL.BRICK.TYPE'),
    flat: false,
  },
  {
    key: NboPvLeadRequest.RoofCoverTypeEnum.SlateAsbestosTiles,
    icon: 'roof-eternit.jpg',
    name: _('PV.ROOFMATERIAL.SHALE.MATERIAL'),
    type: _('PV.ROOFMATERIAL.SHALE.TYPE'),
  },
  {
    key: NboPvLeadRequest.RoofCoverTypeEnum.TrapezoidalSheetMetal,
    icon: 'roof-sheet.jpg',
    name: _('PV.ROOFMATERIAL.SHEET.MATERIAL'),
    type: _('PV.ROOFMATERIAL.SHEET.TYPE'),
    flat: false,
  },
];

export const OFFER_PREVIEW_PV_SWITCHES = [
  {
    key: 'BATTERY',
    icon: 'energy-electricity',
    caption: _('PV.OFFERPREVIEW.OPTIONS.BATTERY'),
    tooltipContent: _('PV.OFFERPREVIEW.OPTIONS.BATTERY_TOOLTIP'),
    listItem: _('PV.OFFERPREVIEW.OPTIONS.BATTERY_LIST_ITEM'),
  },
  {
    key: 'CHARGING_STATION_EMOBILITY',
    icon: 'car',
    caption: _('PV.OFFERPREVIEW.OPTIONS.CHARGING_STATION_EMOBILITY'),
    tooltipContent: _('PV.OFFERPREVIEW.OPTIONS.CHARGING_STATION_EMOBILITY_TOOLTIP'),
    listItem: _('PV.OFFERPREVIEW.OPTIONS.CHARGING_STATION_EMOBILITY_LIST_ITEM'),
  },
  {
    key: 'HEAT_PUMP',
    icon: 'energy-heatpump',
    caption: _('PV.OFFERPREVIEW.OPTIONS.HEAT_PUMP'),
    tooltipContent: _('PV.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TOOLTIP'),
    listItem: _('PV.OFFERPREVIEW.OPTIONS.HEAT_PUMP_LIST_ITEM'),
  },
  {
    key: 'ELECTRO_BOILER',
    icon: 'energy-electroboiler',
    caption: _('PV.OFFERPREVIEW.OPTIONS.ELECTRO_BOILER'),
    tooltipContent: _('PV.OFFERPREVIEW.OPTIONS.ELECTRO_BOILER_TOOLTIP'),
    listItem: _('PV.OFFERPREVIEW.OPTIONS.ELECTRO_BOILER_LIST_ITEM'),
  },
  {
    key: 'HEAT_PUMP_BOILER',
    icon: 'smartenergyboiler',
    caption: _('PV.OFFERPREVIEW.OPTIONS.HEAT_PUMP_BOILER'),
    tooltipContent: _('PV.OFFERPREVIEW.OPTIONS.HEAT_PUMP_BOILER_TOOLTIP'),
    listItem: _('PV.OFFERPREVIEW.OPTIONS.HEAT_PUMP_BOILER_LIST_ITEM'),
  },
];

export const HIGH_DRAIN_SYSTEM_ITEMS: IChoiceButton[] = [
  {
    value: 'HEATPUMP',
    icon: 'energy-heatpump',
    caption: 'PV.HIGH_DRAIN.HEATPUMP',
  },
  {
    value: 'ELECTRIC_HEATING',
    icon: 'electric-heating',
    caption: 'PV.HIGH_DRAIN.ELECTRIC_HEATING',
  },
  {
    value: 'ELECTROBOILER',
    icon: 'smartenergyboiler',
    caption: 'PV.HIGH_DRAIN.ELECTROBOILER',
  },
  {
    value: 'EMOBILITY_CHARGING',
    icon: 'car',
    caption: 'PV.HIGH_DRAIN.EMOBILITY_CHARGING',
  },
  {
    value: 'POOL_SAUNA',
    icon: 'pool',
    caption: 'PV.HIGH_DRAIN.POOL_SAUNA',
  },
];

export const ROOF_AREA_ITEMS: IChoiceButton[] = [
  {
    value: 45,
    caption: 'PV.ROOFAREA.MEDIUM',
    icon: 'roof-medium',
  },
  {
    value: 90,
    caption: 'PV.ROOFAREA.LARGE',
    icon: 'roof-big',
  },
];
