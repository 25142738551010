<div
  class="header"
  [class.hero-header]="heroHeaderImageUrl$ | async"
  *ngIf="notDigitalUploadStep$ | async">
  <div class="container header__container">
    <div class="header__left">
      <div class="header__logo d-flex">
        <a [href]="logoLinkUrl$ | async" class="d-flex">
          <ng-container *ngIf="logoImageUrl$ | async as logoImageUrl">
            <div *ngFor="let image of logoImageUrl">
              <img [src]="image" />
            </div>
          </ng-container>
        </a>
      </div>
    </div>

    <div class="header__center" *ngIf="!(heroHeaderImageUrl$ | async)">
      <ng-container *ngTemplateOutlet="progressBar"></ng-container>
    </div>

    <div class="header__right">
      <div
        class="header__phone"
        *ngIf="
          ((enablePhoneHeader$ | async) &&
            !(isFirstStep$ | async) &&
            !(isCallbackDisabled$ | async)) ||
          (contactLink$ | async)
        ">
        <app-icon
          class="header__phone--icon"
          [name]="headerPhoneIconName"
          [size]="24"
          [inline]="true"></app-icon>

        <div>
          <div *ngIf="enableCallbackHeader$ | async" class="header__phone--questions">
            <div>
              <small> {{ 'COMPONENT.HEADER.HELP' | translate }}</small>
            </div>
            <div>
              <small>
                <a
                  *ngIf="!(contactLink$ | async)"
                  routerLink="/contact"
                  [queryParams]="queryParams$ | async"
                  (click)="goToContactForm()"
                  >{{ 'COMPONENT.HEADER.REQUEST_CALLBACK' | translate }}
                </a>

                <a *ngIf="contactLink$ | async as contactLink" [href]="contactLink"
                  >{{ 'COMPONENT.HEADER.REQUEST_CALLBACK' | translate }}
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>

      <div
        class="header__language"
        *ngIf="(isFirstStep$ | async) && !(disableLanguageChoice$ | async)">
        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-primary-outline btn-sm dropdown-toggle"
            aria-controls="dropdown-basic">
            {{ currentLanguage$ | async }}<span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem" *ngFor="let locale of locale.LOCALES">
              <div class="dropdown-item" (click)="selectLanguage(locale)">
                {{ locale | uppercase }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="header__callback-mobile"
        *ngIf="
          ((enablePhoneHeader$ | async) &&
            !(isFirstStep$ | async) &&
            !(isCallbackDisabled$ | async)) ||
          (contactLink$ | async)
        ">
        <a
          *ngIf="!(contactLink$ | async)"
          routerLink="/contact"
          [queryParams]="queryParams$ | async"
          (click)="goToContactForm()">
          <app-icon
            class="header__phone--icon"
            [name]="headerPhoneIconName"
            [size]="24"
            [inline]="true"></app-icon>
        </a>
        <a *ngIf="contactLink$ | async as contactLink" [href]="contactLink">
          <app-icon
            class="header__phone--icon"
            [name]="headerPhoneIconName"
            [size]="24"
            [inline]="true"></app-icon>
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="heroHeaderImageUrl$ | async as heroHeaderImageUrl">
    <img [src]="heroHeaderImageUrl" class="hero-image" />
    <div class="header__hero__progress_wrapper">
      <ng-container *ngTemplateOutlet="progressBar"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #progressBar>
  <div
    class="header__progress"
    [hidden]="(progress$ | async) >= 100 || (isCurrentStepCallback$ | async)">
    <app-progress-bar [progress]="progress$ | async"></app-progress-bar>
  </div>
  <div class="header__offer-preview-title" [hidden]="(progress$ | async) < 100">
    <h1>{{ 'COMPONENT.HEADER.OFFER_PREVIEW_TITLE' | translate }}</h1>
  </div>
</ng-template>
