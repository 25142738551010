import { ILeadMeta } from '@theia-cc/shared/models';
import { LeadMetaAction } from './lead-meta.action';

export const LEAD_META_INITIAL: ILeadMeta = {
  leadStatus: null,
  leadTag: null,
  leadTracers: null,
  leadComment: null,
  leadCallbackTime: {
    IsCallbackTimeMorning: null,
    IsCallbackTimeAfternoon: null,
    IsCallbackTimeEvening: null,
    IsCallbackTimeWeekend: null,
  },
};

export const leadMetaReducer = (state: ILeadMeta = LEAD_META_INITIAL, action) => {
  const { payload, type } = action;

  switch (type) {
    case LeadMetaAction.STORE_LEAD_TAG:
      return {
        ...state,
        leadTag: payload,
      };

    case LeadMetaAction.STORE_LEAD_TRACERS:
      return {
        ...state,
        leadTracers: payload,
      };

    case LeadMetaAction.STORE_LEAD_CALLBACK_TIME:
      return {
        ...state,
        leadCallbackTime: { ...state.leadCallbackTime, ...payload },
      };

    case LeadMetaAction.STORE_LEAD_COMMENT:
      return {
        ...state,
        leadComment: payload,
      };

    case LeadMetaAction.STORE_LEAD_STATUS:
      return {
        ...state,
        leadStatus: payload,
      };

    default:
      return state;
  }
};
