import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { NboHeatingVariantRequest } from '@api-cc';
import { IInsulation, INboFetched } from '@theia-cc/ht/core';
import { ICollectedDataHt } from './ht-state.reducer';

@Injectable()
export class HtStateAction {
  static readonly HT_NBO_FETCHING_STORE = 'HT_NBO_FETCHING_STORE';
  static readonly HT_NBO_COMPARISON_STORE = 'HT_NBO_COMPARISON_STORE';
  static readonly HT_NBO_FETCHING_CLEAR = 'HT_NBO_FETCHING_CLEAR';
  static readonly HT_NBO_COMPARISON_CLEAR = 'HT_NBO_FETCHING_CLEAR';
  static readonly HT_SET_CONFIG_SETTINGS = 'HT_SET_CONFIG_SETTINGS';
  static readonly HT_STORE_NBO_LEAD_ID = 'HT_STORE_NBO_LEAD_ID';
  static readonly HT_STORE_INTERNAL_LEAD_ID = 'HT_STORE_INTERNAL_LEAD_ID';
  static readonly STORE_HEATING_TYPE = 'STORE_HEATING_TYPE';
  static readonly STORE_HEAT_EMISSION = 'STORE_HEAT_EMISSION';
  static readonly STORE_FAMILY_MEMBER_COUNT = 'STORE_FAMILY_MEMBER_COUNT';
  static readonly STORE_BUILDING_AGE = 'STORE_BUILDING_AGE';
  static readonly STORE_INSULATION = 'STORE_INSULATION';
  static readonly STORE_POWER_CONSUMPTION_SELECTED = 'STORE_POWER_CONSUMPTION_SELECTED';
  static readonly STORE_POWER_CONSUMPTION_CALCULATED = 'STORE_POWER_CONSUMPTION_CALCULATED';
  static readonly STORE_SMART_ENERGY = 'STORE_SMART_ENERGY';
  static readonly STORE_RESPONSIBLE_CONSTRUCTION_WORK = 'STORE_RESPONSIBLE_CONSTRUCTION_WORK';
  static readonly STORE_RESPONSIBLE_ELECTRICAL_CONNECTION =
    'STORE_RESPONSIBLE_ELECTRICAL_CONNECTION';
  static readonly STORE_GEOTHERMAL_PROBE = 'STORE_GEOTHERMAL_PROBE';
  static readonly HT_STORE_HEAT_PUMP_TYPE = 'HT_STORE_HEAT_PUMP_TYPE';
  static readonly STORE_DISMANTLE_TANK = 'STORE_DISMANTLE_TANK';
  static readonly STORE_HEATING_AREA = 'STORE_HEATING_AREA';
  static readonly HT_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS = 'HT_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS';
  static readonly HT_STORE_ROOF_3D_IMAGE = 'HT_STORE_ROOF_3D_IMAGE';
  static readonly HT_STORE_VARIANT_ID = 'HT_STORE_VARIANT_ID';
  static readonly HT_STORE_SELECTED_TEMPLATE = 'HT_STORE_SELECTED_TEMPLATE';
  static readonly STORE_LARGE_SYSTEM = 'STORE_LARGE_SYSTEM';
  static readonly STORE_HEATING_POWER = 'STORE_HEATING_POWER';
  static readonly STORE_PHOTO_PROTOCOL_ACTION = 'STORE_PHOTO_PROTOCOL_ACTION';

  @dispatch()
  storeHeatingType = (type: string) => ({
    type: HtStateAction.STORE_HEATING_TYPE,
    payload: type,
  });

  @dispatch()
  storeHeatEmission = (type: string) => ({
    type: HtStateAction.STORE_HEAT_EMISSION,
    payload: type,
  });

  @dispatch()
  storeFamilyMemberCount = (persons: number) => ({
    type: HtStateAction.STORE_FAMILY_MEMBER_COUNT,
    payload: persons,
  });

  @dispatch()
  storeBuildingAge = (type: string) => ({
    type: HtStateAction.STORE_BUILDING_AGE,
    payload: type,
  });

  @dispatch()
  storeInsulation = (type: IInsulation) => ({
    type: HtStateAction.STORE_INSULATION,
    payload: type,
  });

  @dispatch()
  storeHeatingArea = (value: number) => ({
    type: HtStateAction.STORE_HEATING_AREA,
    payload: value,
  });

  @dispatch()
  storeSelectedPowerConsumption = value => ({
    type: HtStateAction.STORE_POWER_CONSUMPTION_SELECTED,
    payload: value,
  });

  @dispatch()
  storeCalculatedPowerConsumption = value => ({
    type: HtStateAction.STORE_POWER_CONSUMPTION_CALCULATED,
    payload: value,
  });

  @dispatch()
  storeSmartEnergy = value => ({
    type: HtStateAction.STORE_SMART_ENERGY,
    payload: value,
  });

  @dispatch()
  storeResponsibleConstructionWork = value => ({
    type: HtStateAction.STORE_RESPONSIBLE_CONSTRUCTION_WORK,
    payload: value,
  });

  @dispatch()
  storeResponsibleElectricalConnection = value => ({
    type: HtStateAction.STORE_RESPONSIBLE_ELECTRICAL_CONNECTION,
    payload: value,
  });

  @dispatch()
  storeGeothermalProbe = value => ({
    type: HtStateAction.STORE_GEOTHERMAL_PROBE,
    payload: value,
  });

  @dispatch()
  storeHeatPumpType = (value: NboHeatingVariantRequest.HeatingTypeEnum) => ({
    type: HtStateAction.HT_STORE_HEAT_PUMP_TYPE,
    payload: value,
  });

  @dispatch()
  storeDismantleTank = value => ({
    type: HtStateAction.STORE_DISMANTLE_TANK,
    payload: value,
  });

  @dispatch()
  storeNboSelectedTemplate = value => ({
    type: HtStateAction.HT_STORE_SELECTED_TEMPLATE,
    payload: value,
  });

  @dispatch()
  storeNboLeadId = value => ({
    type: HtStateAction.HT_STORE_NBO_LEAD_ID,
    payload: value,
  });

  @dispatch()
  storeInternalLeadId = value => ({
    type: HtStateAction.HT_STORE_INTERNAL_LEAD_ID,
    payload: value,
  });

  @dispatch()
  nboClear = () => ({
    type: HtStateAction.HT_NBO_FETCHING_CLEAR,
  });

  @dispatch()
  nboStoreResult = (data: INboFetched) => ({
    type: HtStateAction.HT_NBO_FETCHING_STORE,
    payload: data,
  });

  @dispatch()
  nboComparisonClear = () => ({
    type: HtStateAction.HT_NBO_COMPARISON_CLEAR,
  });

  @dispatch()
  nboStoreComparisonResult = (data: INboFetched[]) => ({
    type: HtStateAction.HT_NBO_COMPARISON_STORE,
    payload: data,
  });

  @dispatch()
  storeShowAddressFields3dFails = value => ({
    type: HtStateAction.HT_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS,
    payload: value,
  });

  @dispatch()
  setConfigSettings = (payload: Partial<ICollectedDataHt>) => ({
    payload,
    type: HtStateAction.HT_SET_CONFIG_SETTINGS,
  });

  @dispatch()
  storePreviewImage = (payload: string) => ({
    payload,
    type: HtStateAction.HT_STORE_ROOF_3D_IMAGE,
  });

  @dispatch()
  storeVariantId = (payload: number) => ({
    payload,
    type: HtStateAction.HT_STORE_VARIANT_ID,
  });

  @dispatch()
  storeLargeSystem = (value: boolean) => ({
    type: HtStateAction.STORE_LARGE_SYSTEM,
    payload: value,
  });

  @dispatch()
  storeHeatingPower = (value: number) => ({
    type: HtStateAction.STORE_HEATING_POWER,
    payload: value,
  });

  @dispatch()
  storePhotoProtocolAction = (action: string) => ({
    type: HtStateAction.STORE_PHOTO_PROTOCOL_ACTION,
    payload: action,
  });
}
