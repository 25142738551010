export const FINISH_HT = 'FINISH_HT';
export const FINISH_HT_ONLY_EMAIL_SENDING = 'FINISH_HT_ONLY_EMAIL_SENDING';
export const FINISH_SHARED_HT = 'FINISH_SHARED_HT';
export const SAVE_HT_AND_NAVIGATE = 'SAVE_HT';
export const GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW =
  'GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW';
export const CONTACT_LARGE_SYSTEM = 'CONTACT_LARGE_SYSTEM';
export const CHECK_LARGE_SYSTEM_AND_NAVIGATE = 'CHECK_LARGE_SYSTEM_AND_NAVIGATE';
export const PELLETS_BOILER = 'pellets';
export const BUILDING_AGE_UNKNOWN = 'building-age unknown';
export const DOWNLOAD_OFFER_HT = 'DOWNLOAD_OFFER_HT';
export const ADD_VARIANT_AND_DOWNLOAD_OFFER_HT = 'ADD_VARIANT_AND_DOWNLOAD_OFFER_HT';
export const ADD_VARIANT_HT = 'ADD_VARIANT_HT';
export const DIFFERENT_SOLUTION = 'DIFFERENT_SOLUTION';
