import { NboHeatingLeadRequest } from '@api-cc';
import { IStep } from '@theia-cc/shared/models';
import {
  b2bWithPvx,
  geometryAvailable,
  geometryNotAvailable,
  identicalStepWithTrade,
  isLargeSystem,
  notB2b,
  notPvxRoof,
  notTrade,
  pvxRoof,
  roofAreaPreset,
} from './skipIfs';
import { BuildingType } from '@theia-cc/pv/core';
import { skipCompareTechnologies } from '@theia-cc/shared/helpers';

export const STEPS_DEFAULT: IStep[] = [
  {
    name: 'familymembercount-pv',
    skipIf: [
      notTrade(['pv']),
      { buildingType: BuildingType.APARTMENT_BUILDING },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'familyhighdrain',
    skipIf: [
      notTrade(['pv']),
      { buildingType: BuildingType.APARTMENT_BUILDING },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'apartmentcount',
    skipIf: [
      notTrade(['pv']),
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'apartmenthighdrain',
    skipIf: [
      notTrade(['pv']),
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.COMMERCIAL_BUILDING },
      { buildingType: BuildingType.OFFICE_BUILDING },
    ],
  },
  {
    name: 'commercialpowerusage',
    skipIf: [
      notTrade(['pv']),
      { buildingType: BuildingType.FAMILY_HOME },
      { buildingType: BuildingType.APARTMENT_BUILDING },
    ],
  },
  {
    name: 'addresslookup-pv',
    skipIf: [notTrade(['pv'])],
  },
  {
    name: 'roofarea3d',
    skipIf: [notTrade(['pv']), geometryNotAvailable],
  },
  {
    name: 'roofarea',
    skipIf: [notTrade(['pv']), geometryAvailable],
  },
  {
    name: 'roofareamap',
    skipIf: [notTrade(['pv']), geometryAvailable, roofAreaPreset, notB2b],
  },
  {
    name: 'rooftiltangle',
    skipIf: [notTrade(['pv']), geometryAvailable],
  },
  {
    name: 'roofmaterialpvx',
    skipIf: [notTrade(['pv']), notPvxRoof],
  },
  {
    name: 'roofmaterial',
    skipIf: [notTrade(['pv']), pvxRoof],
    requires: ['rooftiltangle || roofarea3d'],
  },
  {
    name: 'offerpreviewpvx',
    skipIf: [notTrade(['pv']), notPvxRoof],
  },
  {
    name: 'offerpreview-pv',
    skipIf: [notTrade(['pv']), pvxRoof],
    requires: [
      'roofarea || roofarea3d',
      'rooftiltangle || roofarea3d',
      'roofmaterial',
      'household',
      'powerconsumption || coverage',
    ],
  },

  // ht
  {
    name: 'heatingtype',
    skipIf: [notTrade(['ht'])],
  },
  {
    name: 'heatemission',
    skipIf: [notTrade(['ht'])],
  },
  {
    name: 'familymembercount-ht',
    skipIf: [notTrade(['ht']), identicalStepWithTrade(['pv'])],
  },
  {
    name: 'insulation',
    skipIf: [notTrade(['ht'])],
  },
  {
    name: 'addresslookup-ht',
    skipIf: [notTrade(['ht']), identicalStepWithTrade(['pv'])],
  },
  {
    name: 'consumption',
    skipIf: [notTrade(['ht'])],
  },
  {
    name: 'heatingarea',
    skipIf: [
      notTrade(['ht']),
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.OilBoiler,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.GasBoiler,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
      },
      { heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.Other },
    ],
  },
  {
    name: 'comparison',
    skipIf: [notTrade(['ht']), skipCompareTechnologies],
  },
  {
    name: 'offerpreview-ht',
    skipIf: [notTrade(['ht']), isLargeSystem],
  },
  {
    name: 'number-of-parkingspaces',
    skipIf: [notTrade(['em'])],
  },
  {
    name: 'parkingspace',
    skipIf: [notTrade(['em'])],
  },
  {
    name: 'distance-fusebox-to-chargingstation',
    skipIf: [notTrade(['em'])],
  },
  {
    name: 'poweroutlet',
    skipIf: [notTrade(['em'])],
  },
  {
    name: 'fusebox',
    skipIf: [notTrade(['em'])],
  },
  {
    name: 'connectpv',
    skipIf: [notTrade(['em']), identicalStepWithTrade(['pv'])],
  },
  {
    name: 'offerpreview-em',
    skipIf: [notTrade(['em'])],
  },
  {
    name: 'contactemail',
  },
  {
    name: 'contactcondensed',
  },

  // b2b with pvx
  {
    name: 'offerpreviewpvx-b2b',
    skipIf: [notTrade(['pv']), notPvxRoof, notB2b],
  },
  {
    name: 'offerpreview',
    skipIf: [b2bWithPvx],
  },
];
