/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NboAddressViewModel } from './nboAddressViewModel';

export interface PatchNboRequest {
  Address?: NboAddressViewModel;
  /**
   * The comment that will be set on the lead if not empty
   */
  LeadComment?: string;
  /**
   * The tag that will be set on the lead if not empty
   */
  LeadTag?: string;
  /**
   * The tracers that will be set on the lead if not empty
   */
  LeadTracers?: string;
  /**
   * The url to share the nbo
   */
  ShareUrl?: string;
  PlannedImplementationTime?: PatchNboRequest.PlannedImplementationTimeEnum;
  LeadRating?: PatchNboRequest.LeadRatingEnum;
  /**
   * Whether the customer has selected callback time \"morning\". Default is false.
   */
  IsCallbackTimeMorning?: boolean;
  /**
   * Whether the customer has selected callback time \"afternoon\". Default is false.
   */
  IsCallbackTimeAfternoon?: boolean;
  /**
   * Whether the customer has selected callback time \"evening\". Default is false.
   */
  IsCallbackTimeEvening?: boolean;
  /**
   * Whether the customer has selected callback time \"weekend\". Default is false.
   */
  IsCallbackTimeWeekend?: boolean;
  ThirdPartyCustomerNumber?: string;
  LeadStatus?: PatchNboRequest.LeadStatusEnum;
}
export namespace PatchNboRequest {
  export type PlannedImplementationTimeEnum =
    | 'AsSoonAsPossible'
    | 'FourToTwelveMonths'
    | 'OneToTwoYears'
    | 'Unknown';
  export const PlannedImplementationTimeEnum = {
    AsSoonAsPossible: 'AsSoonAsPossible' as PlannedImplementationTimeEnum,
    FourToTwelveMonths: 'FourToTwelveMonths' as PlannedImplementationTimeEnum,
    OneToTwoYears: 'OneToTwoYears' as PlannedImplementationTimeEnum,
    Unknown: 'Unknown' as PlannedImplementationTimeEnum,
  };
  export type LeadRatingEnum = 'Zero' | 'One' | 'Two' | 'Three' | 'Four' | 'Five';
  export const LeadRatingEnum = {
    Zero: 'Zero' as LeadRatingEnum,
    One: 'One' as LeadRatingEnum,
    Two: 'Two' as LeadRatingEnum,
    Three: 'Three' as LeadRatingEnum,
    Four: 'Four' as LeadRatingEnum,
    Five: 'Five' as LeadRatingEnum,
  };
  export type LeadStatusEnum =
    | 'New'
    | 'OfferDownload'
    | 'Callback'
    | 'Incomplete'
    | 'Complete'
    | 'AutoOffer';
  export const LeadStatusEnum = {
    New: 'New' as LeadStatusEnum,
    OfferDownload: 'OfferDownload' as LeadStatusEnum,
    Callback: 'Callback' as LeadStatusEnum,
    Incomplete: 'Incomplete' as LeadStatusEnum,
    Complete: 'Complete' as LeadStatusEnum,
    AutoOffer: 'AutoOffer' as LeadStatusEnum,
  };
}
