import { NgRedux, select } from '@angular-redux/store';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  cleanEmptyProperties,
  CONSTANTS,
  ILocaleConfig,
  isOfferPreviewStep,
  isOnScullyRenderer,
  LOCALE_CONFIG,
} from '@theia-cc/shared/helpers';
import { LanguageService, ShareLinkService } from '@theia-cc/shared/services';
import {
  contactPhoneSelector,
  disableLanguageChoiceSelector,
  enableCallbackHeaderSelector,
  enablePhoneHeaderSelector,
  goToCallbackDisabledSelector,
  heroHeaderImageUrlSelector,
  IAppStateBase,
  isCurrentStepCallbackSelector,
  isFirstStepSelector,
  languageSelector,
  LeadMetaAction,
  logoLinkUrlSelector,
  logoSelector,
  progressStateSelector,
} from '@theia-cc/shared/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PatchNboRequest } from '@api-cc';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @select(languageSelector)
  language$;

  @select(progressStateSelector)
  readonly progress$: Observable<number>;

  @select(isFirstStepSelector)
  readonly isFirstStep$: Observable<boolean>;

  @select(enableCallbackHeaderSelector)
  readonly enableCallbackHeader$: Observable<boolean>;

  @select(enablePhoneHeaderSelector)
  readonly enablePhoneHeader$: Observable<boolean>;

  @select(heroHeaderImageUrlSelector)
  readonly heroHeaderImageUrl$: Observable<boolean>;

  @select(disableLanguageChoiceSelector)
  readonly disableLanguageChoice$: Observable<boolean>;

  @select(goToCallbackDisabledSelector)
  readonly isCallbackDisabled$: Observable<boolean>;

  @select(isCurrentStepCallbackSelector)
  readonly isCurrentStepCallback$: Observable<boolean>;

  @select(logoSelector)
  readonly logoImageUrl$: Observable<string[]>;

  @select((state: IAppStateBase) => state)
  private readonly state$: Observable<IAppStateBase>;
  // @ts-ignore
  readonly logoLinkUrl$: Observable<string> = this.state$.pipe(
    map(state =>
      isOnScullyRenderer() ? '' : logoLinkUrlSelector(this.constants.wizardProductKey)(state)
    ),
    distinctUntilChanged()
  );

  @select(contactPhoneSelector)
  readonly contactPhone$: Observable<string>;

  @select(languageSelector)
  readonly currentLanguage$: Observable<string>;
  // @ts-ignore
  public queryParams$: Observable<any> = this.state$.pipe(
    map((state: IAppStateBase) => {
      const { partnerId, restQueryParams } = this.shareLinkService.splitQueryParams(
        state.config.queryParams
      );
      return cleanEmptyProperties({ ...restQueryParams, partnerId });
    }),
    distinctUntilChanged()
  );

  // @ts-ignore
  public contactLink$: Observable<any> = this.state$.pipe(
    map((state: IAppStateBase) => {
      const collectedData = state?.collectedData || {};
      const { internalLeadIdPv, internalLeadIdHt, user = {} } = collectedData;

      const queryParams =
        cleanEmptyProperties({
          email: user?.email,
          phone: user?.telephone,
          lead: internalLeadIdPv || internalLeadIdHt,
        }) || {};
      const queryParamsString = Object.entries(queryParams).reduce((res, [key, value]) => {
        return res ? `${res}&${key}=${value}` : `${key}=${value}`;
      }, '');

      const url =
        isOfferPreviewStep(state.wizard.currentStep) ||
        state.wizard?.currentStep?.indexOf('contact') !== -1
          ? state.config?.org?.contactPageUrls?.[state.currentLanguage]
          : '';

      return url ? `${url}?${queryParamsString}` : '';
    }),
    distinctUntilChanged()
  );

  public notDigitalUploadStep$: Observable<boolean> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.urlAfterRedirects),
    map((url: string) => !url.includes('digital-upload'))
  );

  constructor(
    private shareLinkService: ShareLinkService,
    private leadMetaAction: LeadMetaAction,
    private languageService: LanguageService,
    private store: NgRedux<IAppStateBase>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(CONSTANTS) public constants: any,
    @Inject(LOCALE_CONFIG) public locale: ILocaleConfig
  ) {
    const { headerPhoneIconName } = this.store.getState().config.envVariables;
    if (headerPhoneIconName) {
      this.headerPhoneIconName = headerPhoneIconName;
    }
  }

  public headerPhoneIconName: string = 'phone-2';

  selectLanguage(lang: string) {
    this.languageService.setCurrentLanguage(lang);
    const queryParams = Object.entries(this.activatedRoute.snapshot.queryParams).reduce(
      (res, [key, value]) => {
        if (key.toLocaleLowerCase() !== 'language') {
          res[key] = value;
        }
        return res;
      },
      { Language: lang }
    );
    this.router.navigate([], {
      queryParams,
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
  }

  goToContactForm() {
    this.leadMetaAction.storeLeadStatus(PatchNboRequest.LeadStatusEnum.Callback);
    this.leadMetaAction.storeLeadTracers(PatchNboRequest.LeadStatusEnum.Callback);
  }
}
