import { dispatch } from '@angular-redux/store';
import { Inject, Injectable } from '@angular/core';
import { OfferStatusEnum } from '@theia-cc/pv/core';
import { CONSTANTS } from '@theia-cc/shared/helpers';
import { ICollectedDataPv } from './pv-state.reducer';

@Injectable()
export class PvStateAction {
  constructor(@Inject(CONSTANTS) private constants: any) {}

  static readonly PV_NBO_FETCHING_STORE = 'PV_NBO_FETCHING_STORE';
  static readonly PV_NBO_FETCHING_CLEAR = 'PV_NBO_FETCHING_CLEAR';

  static readonly PV_STORE_NBO_LEAD_ID = 'PV_STORE_NBO_LEAD_ID';
  static readonly PV_STORE_INTERNAL_LEAD_ID = 'PV_STORE_INTERNAL_LEAD_ID';
  static readonly PV_STORE_NBO_SELECTED_TEMPLATE_ID = 'PV_STORE_NBO_SELECTED_TEMPLATE_ID';
  static readonly STORE_HIGH_DRAIN_SYSTEMS = 'STORE_HIGH_DRAIN_SYSTEMS';
  static readonly STORE_HIGH_DRAIN_SYSTEMS_KWH = 'STORE_HIGH_DRAIN_SYSTEMS_KWH';
  static readonly STORE_BUILDING_TYPE = 'STORE_BUILDING_TYPE';
  static readonly STORE_ROOF_AREA = 'STORE_ROOF_AREA';
  static readonly STORE_SELECTED_ROOF_AREA = 'STORE_SELECTED_ROOF_AREA';
  static readonly STORE_ROOF_AREA_KWH = 'STORE_ROOF_AREA_KWH';
  static readonly STORE_ROOF_AREA_CHF = 'STORE_ROOF_AREA_CHF';
  static readonly STORE_ROOF_AREA_LIFETIME_CHF = 'STORE_ROOF_AREA_LIFETIME_CHF';
  static readonly STORE_ROOF_TILT_ANGLE = 'STORE_ROOF_TILT_ANGLE';
  static readonly STORE_FAMILY_MEMBER_COUNT = 'STORE_FAMILY_MEMBER_COUNT';
  static readonly STORE_APARTMENT_COUNT = 'STORE_APARTMENT_COUNT';
  static readonly STORE_AVERAGE_POWER_USAGE_KWH = 'STORE_AVERAGE_POWER_USAGE_KWH';
  static readonly STORE_ROOF_MATERIAL = 'STORE_ROOF_MATERIAL';
  static readonly STORE_ROOF_SHADOW = 'STORE_ROOF_SHADOW';
  static readonly STORE_BATTERY = 'STORE_BATTERY';
  static readonly PV_STORE_HEAT_PUMP = 'PV_STORE_HEAT_PUMP';
  static readonly STORE_ELECTRO_BOILER = 'STORE_ELECTRO_BOILER';
  static readonly STORE_CHARGINGSTATIONEMOBILITY = 'STORE_CHARGINGSTATIONEMOBILITY';
  static readonly STORE_HEATPUMPBOILER = 'STORE_HEATPUMPBOILER';
  static readonly CALC_POWER_REQUIREMENT_KWH = 'CALC_POWER_REQUIREMENT_KWH';
  static readonly STORE_PVEARNINGSKWHM2 = 'STORE_PVEARNINGSKWHM2';
  static readonly STORE_ROOFORIENTATION = 'STORE_ROOFORIENTATION';
  static readonly PV_STORE_ROOF_3D_IMAGE = 'PV_STORE_ROOF_3D_IMAGE';
  static readonly PV_STORE_SELECTED_TEMPLATE = 'PV_STORE_SELECTED_TEMPLATE';
  static readonly PV_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS = 'PV_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS';
  static readonly PV_SET_CONFIG_SETTINGS = 'PV_SET_CONFIG_SETTINGS';
  static readonly SET_OFFER_STATUS = 'SET_OFFER_STATUS';
  static readonly PV_STORE_VARIANT_ID = 'PV_STORE_VARIANT_ID';
  static readonly STORE_IS_PVX = 'STORE_IS_PVX';
  static readonly STORE_MAX_PV_COVERED_ROOF_AREA = 'STORE_MAX_PV_COVERED_ROOF_AREA';

  @dispatch()
  storeNboSelectedTemplateId = value => ({
    type: PvStateAction.PV_STORE_NBO_SELECTED_TEMPLATE_ID,
    payload: value,
  });

  @dispatch()
  storeElectroBoiler = value => ({
    type: PvStateAction.STORE_ELECTRO_BOILER,
    payload: value,
  });

  @dispatch()
  storeHeatPump = value => ({
    type: PvStateAction.PV_STORE_HEAT_PUMP,
    payload: value,
  });

  @dispatch()
  storeBattery = value => ({
    type: PvStateAction.STORE_BATTERY,
    payload: value,
  });

  @dispatch()
  storeChargingStationEmobility = value => ({
    type: PvStateAction.STORE_CHARGINGSTATIONEMOBILITY,
    payload: value,
  });

  @dispatch()
  storeHeatPumpBoiler = value => ({
    type: PvStateAction.STORE_HEATPUMPBOILER,
    payload: value,
  });

  @dispatch()
  storeNboLeadId = value => ({
    type: PvStateAction.PV_STORE_NBO_LEAD_ID,
    payload: value,
  });

  @dispatch()
  storeInternalLeadId = value => ({
    type: PvStateAction.PV_STORE_INTERNAL_LEAD_ID,
    payload: value,
  });

  @dispatch()
  storeHighDrainSystemsKwh = (kwh: number) => async thDispatch => {
    thDispatch({
      type: PvStateAction.STORE_HIGH_DRAIN_SYSTEMS_KWH,
      payload: kwh,
    });
    return this.calculatePowerRequirementKwh();
  };

  @dispatch()
  storeHighDrainSystems = options => ({
    type: PvStateAction.STORE_HIGH_DRAIN_SYSTEMS,
    payload: options,
  });

  @dispatch()
  storeBuildingType = (type: string) => ({
    type: PvStateAction.STORE_BUILDING_TYPE,
    payload: type,
  });

  @dispatch()
  storeFamilyMemberCount = (persons: number) => ({
    type: PvStateAction.STORE_FAMILY_MEMBER_COUNT,
    payload: persons,
  });

  @dispatch()
  storeApartmentCount = (apartments: number) => ({
    type: PvStateAction.STORE_APARTMENT_COUNT,
    payload: apartments,
  });

  @dispatch()
  storeAveragePowerUsageKwh = (kwh: number) => thDispatch => {
    thDispatch({
      type: PvStateAction.STORE_AVERAGE_POWER_USAGE_KWH,
      payload: kwh,
    });
    this.calculatePowerRequirementKwh();
  };

  @dispatch()
  storeRoofShadow = (value: boolean) => ({
    type: PvStateAction.STORE_ROOF_SHADOW,
    payload: value,
  });

  @dispatch()
  storeRoofTiltAngle = (angle: string) => ({
    type: PvStateAction.STORE_ROOF_TILT_ANGLE,
    payload: angle,
  });

  @dispatch()
  storeRoofArea = (value: number) => thDispatch => {
    thDispatch({
      type: PvStateAction.STORE_ROOF_AREA,
      payload: value,
    });
    // thDispatch({
    //   type: PvStateAction.STORE_IS_PVX,
    //   payload: selectedRoofArea === null ? 0 : selectedRoofArea >= CONSTANTS.maxRoofArea,
    // });
    thDispatch({
      type: PvStateAction.STORE_MAX_PV_COVERED_ROOF_AREA,
      payload: value ? Math.min(value, this.constants.maxRoofArea - 1) : undefined,
    });
  };

  @dispatch()
  storeSelectedRoofArea = (value: number) => thDispatch => {
    thDispatch({
      type: PvStateAction.STORE_SELECTED_ROOF_AREA,
      payload: value,
    });
    thDispatch({
      type: PvStateAction.STORE_IS_PVX,
      payload: value === null ? 0 : value >= this.constants.maxRoofArea,
    });
  };

  @dispatch()
  storeRoofAreaKwh = (kwh: number) => ({
    type: PvStateAction.STORE_ROOF_AREA_KWH,
    payload: kwh,
  });

  @dispatch()
  storeRoofAreaChf = (chf: number) => ({
    type: PvStateAction.STORE_ROOF_AREA_CHF,
    payload: chf,
  });

  @dispatch()
  storeRoofAreaLifetimeChf = (chf: number) => ({
    type: PvStateAction.STORE_ROOF_AREA_LIFETIME_CHF,
    payload: chf,
  });

  @dispatch()
  storeRoofMaterial = (material: string) => ({
    type: PvStateAction.STORE_ROOF_MATERIAL,
    payload: material,
  });

  @dispatch()
  calculatePowerRequirementKwh = () => ({
    type: PvStateAction.CALC_POWER_REQUIREMENT_KWH,
    payload: null,
  });

  @dispatch()
  nboClear = () => ({
    type: PvStateAction.PV_NBO_FETCHING_CLEAR,
  });

  @dispatch()
  nboStoreResult = payload => ({
    payload,
    type: PvStateAction.PV_NBO_FETCHING_STORE,
  });

  @dispatch()
  storeSelectedTemplate = value => ({
    type: PvStateAction.PV_STORE_SELECTED_TEMPLATE,
    payload: value,
  });

  @dispatch()
  storeRoofOrientation = (value: string) => ({
    type: PvStateAction.STORE_ROOFORIENTATION,
    payload: value,
  });

  @dispatch()
  storePvEarningsKwhM2 = value => ({
    type: PvStateAction.STORE_PVEARNINGSKWHM2,
    payload: value,
  });

  @dispatch()
  storeShowAddressfields3dFails = value => ({
    type: PvStateAction.PV_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS,
    payload: value,
  });

  @dispatch()
  setConfigSettings = (payload: Partial<ICollectedDataPv>) => ({
    payload,
    type: PvStateAction.PV_SET_CONFIG_SETTINGS,
  });

  @dispatch()
  storePreviewImage = (payload: string) => ({
    payload,
    type: PvStateAction.PV_STORE_ROOF_3D_IMAGE,
  });

  @dispatch()
  storeOfferStatus = (payload: OfferStatusEnum) => ({
    payload,
    type: PvStateAction.SET_OFFER_STATUS,
  });

  @dispatch()
  storeVariantId = (payload: number) => ({
    payload,
    type: PvStateAction.PV_STORE_VARIANT_ID,
  });
}
