/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HeatingVariantSummaryViewModel {
  /**
   * The template id that has been used to create this variant.
   */
  TemplateId?: number;
  /**
   * The variant's id. It will be null if the variant has not been persisted.
   */
  VariantId?: number;
  /**
   * The variant's title. It may be empty if the variant is not persisted.
   */
  VariantTitle?: string;
  /**
   * Heizleistung
   */
  HeatingPower?: number;
  /**
   * Heizleistung
   */
  AveragedCurrentEnergyDemandKwh?: number;
  /**
   * Kaufpreis
   */
  Brutto?: number;
  /**
   * Building Energy Reference Area (calculated or override)
   */
  BuildingEnergyReferenceArea?: number;
  /**
   * Expected subsidy based on Canton info.
   */
  ExpectedSubsidy?: number;
  /**
   * AR viewer app url for the heat generator.
   */
  HeatGeneratorArViewerUrl?: string;
  /**
   * AR viewer app url for the domestic water heating.
   */
  DomesticWaterHeatingArViewerUrl?: string;
  /**
   * AR viewer app url for the water tank.
   */
  WaterTankArViewerUrl?: string;
}
