import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrentLanguageAction {
  static readonly SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';

  @dispatch()
  currentLanguageSet = (currentLanguage: string) => ({
    type: CurrentLanguageAction.SET_CURRENT_LANGUAGE,
    payload: { currentLanguage },
  });
}
