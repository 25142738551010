/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboHeatingVariantRequest {
  /**
   * The title of the variant that will be created
   */
  VariantTitle: string;
  /**
   * If this is true and the variant is persisted, the user gets an email with the offerte
   */
  IsSendMail?: boolean;
  ProjectType: NboHeatingVariantRequest.ProjectTypeEnum;
  HeatingType: NboHeatingVariantRequest.HeatingTypeEnum;
  /**
   * EnergyDemandKwh
   */
  EnergyDemandKwh?: number;
  HeatingManufacturer: NboHeatingVariantRequest.HeatingManufacturerEnum;
  SmartEnergyConnection?: NboHeatingVariantRequest.SmartEnergyConnectionEnum;
  DismantlingHeating?: NboHeatingVariantRequest.DismantlingHeatingEnum;
  DismantlingTank?: NboHeatingVariantRequest.DismantlingTankEnum;
  DismantlingChimney?: NboHeatingVariantRequest.DismantlingChimneyEnum;
  DomesticWaterHeatingVariant?: NboHeatingVariantRequest.DomesticWaterHeatingVariantEnum;
  HeatingOilQualityPrevious?: NboHeatingVariantRequest.HeatingOilQualityPreviousEnum;
  LocationExhaustSystem?: NboHeatingVariantRequest.LocationExhaustSystemEnum;
  ResponsibleConstructionWorkType?: NboHeatingVariantRequest.ResponsibleConstructionWorkTypeEnum;
  GeothermalProbe?: NboHeatingVariantRequest.GeothermalProbeEnum;
  ResponsibleElectricalConnection?: NboHeatingVariantRequest.ResponsibleElectricalConnectionEnum;
}
export namespace NboHeatingVariantRequest {
  export type ProjectTypeEnum = 'Replacement' | 'NewBuilding';
  export const ProjectTypeEnum = {
    Replacement: 'Replacement' as ProjectTypeEnum,
    NewBuilding: 'NewBuilding' as ProjectTypeEnum,
  };
  export type HeatingTypeEnum =
    | 'AirWaterHeatPumpOutside'
    | 'BrineWaterHeatPump'
    | 'AirWaterHeatPumpSplit'
    | 'AirWaterHeatPumpInside'
    | 'NotSpecified'
    | 'OilBoiler'
    | 'GasBoilerWall'
    | 'GasBoilerFloor'
    | 'PelletsBoiler';
  export const HeatingTypeEnum = {
    AirWaterHeatPumpOutside: 'AirWaterHeatPumpOutside' as HeatingTypeEnum,
    BrineWaterHeatPump: 'BrineWaterHeatPump' as HeatingTypeEnum,
    AirWaterHeatPumpSplit: 'AirWaterHeatPumpSplit' as HeatingTypeEnum,
    AirWaterHeatPumpInside: 'AirWaterHeatPumpInside' as HeatingTypeEnum,
    NotSpecified: 'NotSpecified' as HeatingTypeEnum,
    OilBoiler: 'OilBoiler' as HeatingTypeEnum,
    GasBoilerWall: 'GasBoilerWall' as HeatingTypeEnum,
    GasBoilerFloor: 'GasBoilerFloor' as HeatingTypeEnum,
    PelletsBoiler: 'PelletsBoiler' as HeatingTypeEnum,
  };
  export type HeatingManufacturerEnum =
    | 'Stiebel'
    | 'Hoval'
    | 'Bosch'
    | 'Oertli'
    | 'Viessmann'
    | 'Cta'
    | 'AlphaInnotec'
    | 'Vaillant'
    | 'Mitsubishi'
    | 'Ctc'
    | 'Nibe'
    | 'Ovum'
    | 'Froeling';
  export const HeatingManufacturerEnum = {
    Stiebel: 'Stiebel' as HeatingManufacturerEnum,
    Hoval: 'Hoval' as HeatingManufacturerEnum,
    Bosch: 'Bosch' as HeatingManufacturerEnum,
    Oertli: 'Oertli' as HeatingManufacturerEnum,
    Viessmann: 'Viessmann' as HeatingManufacturerEnum,
    Cta: 'Cta' as HeatingManufacturerEnum,
    AlphaInnotec: 'AlphaInnotec' as HeatingManufacturerEnum,
    Vaillant: 'Vaillant' as HeatingManufacturerEnum,
    Mitsubishi: 'Mitsubishi' as HeatingManufacturerEnum,
    Ctc: 'Ctc' as HeatingManufacturerEnum,
    Nibe: 'Nibe' as HeatingManufacturerEnum,
    Ovum: 'Ovum' as HeatingManufacturerEnum,
    Froeling: 'Froeling' as HeatingManufacturerEnum,
  };
  export type SmartEnergyConnectionEnum = 'Desired' | 'Undesired' | 'NotSpecified';
  export const SmartEnergyConnectionEnum = {
    Desired: 'Desired' as SmartEnergyConnectionEnum,
    Undesired: 'Undesired' as SmartEnergyConnectionEnum,
    NotSpecified: 'NotSpecified' as SmartEnergyConnectionEnum,
  };
  export type DismantlingHeatingEnum = 'Desired' | 'Undesired';
  export const DismantlingHeatingEnum = {
    Desired: 'Desired' as DismantlingHeatingEnum,
    Undesired: 'Undesired' as DismantlingHeatingEnum,
  };
  export type DismantlingTankEnum =
    | 'SteelOilTank'
    | 'PlasticOilTank'
    | 'Shutdown'
    | 'NoDismantlement';
  export const DismantlingTankEnum = {
    SteelOilTank: 'SteelOilTank' as DismantlingTankEnum,
    PlasticOilTank: 'PlasticOilTank' as DismantlingTankEnum,
    Shutdown: 'Shutdown' as DismantlingTankEnum,
    NoDismantlement: 'NoDismantlement' as DismantlingTankEnum,
  };
  export type DismantlingChimneyEnum = 'SealChimney' | 'NoDismantlement';
  export const DismantlingChimneyEnum = {
    SealChimney: 'SealChimney' as DismantlingChimneyEnum,
    NoDismantlement: 'NoDismantlement' as DismantlingChimneyEnum,
  };
  export type DomesticWaterHeatingVariantEnum =
    | 'Present'
    | 'ViaHeatpumpBoiler'
    | 'ViaHeaterWithoutElectricHeating'
    | 'ViaHeaterWithElectricHeating'
    | 'ViaSubSetBoiler';
  export const DomesticWaterHeatingVariantEnum = {
    Present: 'Present' as DomesticWaterHeatingVariantEnum,
    ViaHeatpumpBoiler: 'ViaHeatpumpBoiler' as DomesticWaterHeatingVariantEnum,
    ViaHeaterWithoutElectricHeating:
      'ViaHeaterWithoutElectricHeating' as DomesticWaterHeatingVariantEnum,
    ViaHeaterWithElectricHeating: 'ViaHeaterWithElectricHeating' as DomesticWaterHeatingVariantEnum,
    ViaSubSetBoiler: 'ViaSubSetBoiler' as DomesticWaterHeatingVariantEnum,
  };
  export type HeatingOilQualityPreviousEnum = 'LowSulfur' | 'OtherOilQuality';
  export const HeatingOilQualityPreviousEnum = {
    LowSulfur: 'LowSulfur' as HeatingOilQualityPreviousEnum,
    OtherOilQuality: 'OtherOilQuality' as HeatingOilQualityPreviousEnum,
  };
  export type LocationExhaustSystemEnum = 'InsideBuilding' | 'AlongFacade';
  export const LocationExhaustSystemEnum = {
    InsideBuilding: 'InsideBuilding' as LocationExhaustSystemEnum,
    AlongFacade: 'AlongFacade' as LocationExhaustSystemEnum,
  };
  export type ResponsibleConstructionWorkTypeEnum = 'Desired' | 'Undesired';
  export const ResponsibleConstructionWorkTypeEnum = {
    Desired: 'Desired' as ResponsibleConstructionWorkTypeEnum,
    Undesired: 'Undesired' as ResponsibleConstructionWorkTypeEnum,
  };
  export type GeothermalProbeEnum = 'OnSite' | 'PartOfOffer' | 'Present';
  export const GeothermalProbeEnum = {
    OnSite: 'OnSite' as GeothermalProbeEnum,
    PartOfOffer: 'PartOfOffer' as GeothermalProbeEnum,
    Present: 'Present' as GeothermalProbeEnum,
  };
  export type ResponsibleElectricalConnectionEnum = 'Desired' | 'Undesired';
  export const ResponsibleElectricalConnectionEnum = {
    Desired: 'Desired' as ResponsibleElectricalConnectionEnum,
    Undesired: 'Undesired' as ResponsibleElectricalConnectionEnum,
  };
}
