import { NboPvLeadRequest, NboPvVariantRequest } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

// Template-id configs for ikea
export const TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_STANDARD =
  'TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_STANDARD';
export const TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_INROOF = 'TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_INROOF';
export const TEMPLATE_IKEA_ADVANCED_FLATROOF_STANDARD = 'TEMPLATE_IKEA_ADVANCED_FLATROOF_STANDARD';

// All Ikea templates
export const TEMPLATES_IKEA_SLOPED_ROOF = [
  {
    id: TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_STANDARD,
    productline: NboPvVariantRequest.ProductlineTypeEnum.Advanced,
    tab: _('PV.IKEA.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TITLE'),
    icon: 'assets/img/pv/thumbnail-ikea-plus.jpg',
    description: _('PV.IKEA.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_DESCRIPTION'),
    data: {},
    images: ['assets/img/pv/ikea-plus.jpg'],
    mountingType: NboPvLeadRequest.MountingTypeEnum.Standard,
  },
  {
    id: TEMPLATE_IKEA_ADVANCED_SLOPEDROOF_INROOF,
    productline: NboPvVariantRequest.ProductlineTypeEnum.Advanced,
    tab: _('PV.IKEA.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_INROOF_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_INROOF_TITLE'),
    icon: 'assets/img/pv/thumbnail-ikea-inroof.jpg',
    description: _('PV.IKEA.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_INROOF_DESCRIPTION'),
    data: {},
    images: ['assets/img/pv/ikea-inroof.jpg'],
    mountingType: NboPvLeadRequest.MountingTypeEnum.InRoof,
  },
];

// Ikea templates for flat roofs
export const TEMPLATES_IKEA_FLAT_ROOF = [
  {
    id: TEMPLATE_IKEA_ADVANCED_FLATROOF_STANDARD,
    productline: NboPvVariantRequest.ProductlineTypeEnum.Advanced,
    tab: _('PV.IKEA.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TITLE'),
    icon: 'assets/img/pv/thumbnail-plus-flat.jpg',
    description: _('PV.IKEA.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_DESCRIPTION'),
    data: {},
    images: [
      'assets/img/pv/onroof-flat-1.jpg',
      'assets/img/pv/onroof-flat-2.jpg',
      'assets/img/pv/onroof-flat-3.jpg',
      'assets/img/pv/onroof-flat-4.jpg',
    ],
    mountingType: NboPvLeadRequest.MountingTypeEnum.Standard,
  },
];
