/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboPvLeadRequest {
  MotivationForPv?: Array<NboPvLeadRequest.MotivationForPvEnum>;
  BuildingTypeOfUse?: Array<NboPvLeadRequest.BuildingTypeOfUseEnum>;
  BuildingAccessibility?: NboPvLeadRequest.BuildingAccessibilityEnum;
  MapImage?: string;
  LayoutAugmentedImage?: string;
  BuildingRoofShape?: string;
  BuildingVolume?: number;
  BuildingGroundArea?: number;
  BuildingRoofArea?: number;
  BuildingEavesHeight?: number;
  BuildingRidgeHeight?: number;
  BuildingRoofOverhangArea?: number;
  BuildingWallArea?: number;
  BuildingAboveSea?: number;
  LatLonCoordinates?: string;
  BuildingNumberApartments?: number;
  BuildingNumberInhabitants?: number;
  BuildingLevels?: number;
  Egid?: string;
  /**
   * RoofArea (default = 40 m2 for small scale requests and 400 m2 for large scale)
   */
  RoofArea?: number;
  RoofSlope?: string;
  RoofOrientation?: string;
  IsRoofShadow?: NboPvLeadRequest.IsRoofShadowEnum;
  RoofType: NboPvLeadRequest.RoofTypeEnum;
  RoofCoverType: NboPvLeadRequest.RoofCoverTypeEnum;
  MountingType: NboPvLeadRequest.MountingTypeEnum;
  MajorEnergyConsumers?: Array<NboPvLeadRequest.MajorEnergyConsumersEnum>;
  EnergyConsumptionHt?: number;
  EnergyConsumptionLt?: number;
  ElectricityRateHt?: number;
  ElectricityRateLt?: number;
  OwnConsumptionRatioWithoutStorage?: number;
  OwnConsumptionRatioWithStorage?: number;
  FeedInTarifHt?: number;
  FeedInTarifLt?: number;
  LeadId?: string;
}
export namespace NboPvLeadRequest {
  export type MotivationForPvEnum =
    | 'Independence'
    | 'CostSavings'
    | 'EnergyProduction'
    | 'Environment'
    | 'IncreasePropertyValue'
    | 'Other';
  export const MotivationForPvEnum = {
    Independence: 'Independence' as MotivationForPvEnum,
    CostSavings: 'CostSavings' as MotivationForPvEnum,
    EnergyProduction: 'EnergyProduction' as MotivationForPvEnum,
    Environment: 'Environment' as MotivationForPvEnum,
    IncreasePropertyValue: 'IncreasePropertyValue' as MotivationForPvEnum,
    Other: 'Other' as MotivationForPvEnum,
  };
  export type BuildingTypeOfUseEnum = 'Apartment' | 'Office' | 'Commerce' | 'Agriculture' | 'Other';
  export const BuildingTypeOfUseEnum = {
    Apartment: 'Apartment' as BuildingTypeOfUseEnum,
    Office: 'Office' as BuildingTypeOfUseEnum,
    Commerce: 'Commerce' as BuildingTypeOfUseEnum,
    Agriculture: 'Agriculture' as BuildingTypeOfUseEnum,
    Other: 'Other' as BuildingTypeOfUseEnum,
  };
  export type BuildingAccessibilityEnum = 'Flat' | 'Sloping' | 'Difficult' | 'Unknown';
  export const BuildingAccessibilityEnum = {
    Flat: 'Flat' as BuildingAccessibilityEnum,
    Sloping: 'Sloping' as BuildingAccessibilityEnum,
    Difficult: 'Difficult' as BuildingAccessibilityEnum,
    Unknown: 'Unknown' as BuildingAccessibilityEnum,
  };
  export type IsRoofShadowEnum = 'Yes' | 'No' | 'Unknown';
  export const IsRoofShadowEnum = {
    Yes: 'Yes' as IsRoofShadowEnum,
    No: 'No' as IsRoofShadowEnum,
    Unknown: 'Unknown' as IsRoofShadowEnum,
  };
  export type RoofTypeEnum = 'FlatRoof' | 'SlopedRoof';
  export const RoofTypeEnum = {
    FlatRoof: 'FlatRoof' as RoofTypeEnum,
    SlopedRoof: 'SlopedRoof' as RoofTypeEnum,
  };
  export type RoofCoverTypeEnum =
    | 'ClayTiles'
    | 'SlateAsbestosTiles'
    | 'CorrugatedAsbestosTiles'
    | 'TrapezoidalSheetMetal'
    | 'ConcreteTiles'
    | 'PlainTiles'
    | 'Gravel'
    | 'SubstrateSlightlyOvergrown'
    | 'SubstrateHeavilyOvergrown'
    | 'Other';
  export const RoofCoverTypeEnum = {
    ClayTiles: 'ClayTiles' as RoofCoverTypeEnum,
    SlateAsbestosTiles: 'SlateAsbestosTiles' as RoofCoverTypeEnum,
    CorrugatedAsbestosTiles: 'CorrugatedAsbestosTiles' as RoofCoverTypeEnum,
    TrapezoidalSheetMetal: 'TrapezoidalSheetMetal' as RoofCoverTypeEnum,
    ConcreteTiles: 'ConcreteTiles' as RoofCoverTypeEnum,
    PlainTiles: 'PlainTiles' as RoofCoverTypeEnum,
    Gravel: 'Gravel' as RoofCoverTypeEnum,
    SubstrateSlightlyOvergrown: 'SubstrateSlightlyOvergrown' as RoofCoverTypeEnum,
    SubstrateHeavilyOvergrown: 'SubstrateHeavilyOvergrown' as RoofCoverTypeEnum,
    Other: 'Other' as RoofCoverTypeEnum,
  };
  export type MountingTypeEnum = 'Standard' | 'InRoof';
  export const MountingTypeEnum = {
    Standard: 'Standard' as MountingTypeEnum,
    InRoof: 'InRoof' as MountingTypeEnum,
  };
  export type MajorEnergyConsumersEnum =
    | 'ElectricHeating'
    | 'Heatpump'
    | 'ElectricBoiler'
    | 'ElectricVehicle'
    | 'HeatpumpBoiler'
    | 'Pool'
    | 'Sauna'
    | 'Other';
  export const MajorEnergyConsumersEnum = {
    ElectricHeating: 'ElectricHeating' as MajorEnergyConsumersEnum,
    Heatpump: 'Heatpump' as MajorEnergyConsumersEnum,
    ElectricBoiler: 'ElectricBoiler' as MajorEnergyConsumersEnum,
    ElectricVehicle: 'ElectricVehicle' as MajorEnergyConsumersEnum,
    HeatpumpBoiler: 'HeatpumpBoiler' as MajorEnergyConsumersEnum,
    Pool: 'Pool' as MajorEnergyConsumersEnum,
    Sauna: 'Sauna' as MajorEnergyConsumersEnum,
    Other: 'Other' as MajorEnergyConsumersEnum,
  };
}
