import {
  NboEmobilityCalculateRequest,
  NboEmobilityLeadRequest,
  NboEmobilityRequest,
  NboHeatingLeadRequest,
  NboHeatingVariantRequest,
  NboPvLeadRequest,
} from '@api-cc';
import { IManufacturer, ITemplateEmWithoutCms, ManufacturerEnum } from '@theia-cc/em/core';
import { IAppStateEm } from '@theia-cc/em/state';
import { IInsulation, INboFetched, ITemplateHt } from '@theia-cc/ht/core';
import { BuildingType, IOfferPreviewConfig, OfferStatusEnum } from '@theia-cc/pv/core';
import { IHighDrainSystems } from '@theia-cc/pv/state';
import {
  IBuildingSummary,
  IConfigBase,
  IConfigQueryParamsBase,
  IEnvVariablesBase,
  ILeadMeta,
  IRelatedLead,
  IUserInfo,
} from '@theia-cc/shared/models';
import { IChoiceButton } from '@theia-cc/shared/step-ui';
import { IAppStateBase } from '@theia-cc/shared/store';

interface ICollectedData {
  variantIdEm: number;
  manufacturerselection: ManufacturerEnum;
  parkingspace: NboEmobilityCalculateRequest.ParkingTypeEnum;
  poweroutlet: NboEmobilityCalculateRequest.AvailablePowerOutletEnum | any;
  connectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum | any;
  fuseBox: NboEmobilityLeadRequest.FuseboxAgeEnum;
  chargingPower: NboEmobilityRequest.ChargingPowerEnum;
  orderType: NboEmobilityRequest.OrderTypeEnum;
  totalNumberOfParkingspaces: number;
  numberOfParkingspaceToBeEquiped: number;
  numberOfParkingspacePreparedToBeElectrified: number;
  distanceFuseboxToChargingstation: any;
  nboFetchedEm: any | object[];
  nboLeadIdEm: string;
  selectedTemplateEm: any;
  selectedTemplateIdEm: string;
  apartmentCount: number;
  averagePowerUsageKwh: number;
  buildingData: IBuildingSummary;
  buildingType: BuildingType;
  familyMemberCount: number;
  highDrainSystems: IHighDrainSystems;
  highDrainSystemsKwh: number;
  isPvx: boolean;
  nboFetchedPv: any | object[];
  nboLeadIdPv: string;
  internalLeadIdPv: number;
  roofArea: number;
  maxPvCoveredRoofArea: number;
  roofAreaChf: number;
  roofAreaKwh: number;
  roofAreaLifetimeChf: number;
  roofMaterial: NboPvLeadRequest.RoofCoverTypeEnum;
  roofShadow: NboPvLeadRequest.IsRoofShadowEnum;
  roofTiltAngle: string;
  selectedRoofArea: number;
  totalPowerRequirementKwh: number;
  heatPump: boolean;
  battery: boolean;
  electroBoiler: boolean;
  chargingStationEmobility: boolean;
  heatPumpBoiler: boolean;
  nboSelectedTemplateIdPv: string;
  selectedTemplatePv: any;
  pvEarningsKwhM2: number;
  roofOrientation: string;
  showAddressFields3dFailsPv: boolean;
  offerStatus?: OfferStatusEnum;
  variantIdPv?: number;
  heatingType: any;
  heatingEmission: NboHeatingLeadRequest.CurrentHeatDissipationEnum;
  buildingAge: NboHeatingLeadRequest.BuildingYearOfConstructionEnum;
  insulation: IInsulation;
  nboFetchedHt: INboFetched[];
  nboLeadIdHt: string | number;
  internalLeadIdHt: number;
  variantIdHt: number;
  previewImage: string;
  powerConsumptionSelected: number;
  powerConsumptionCalculated: number;
  smartEnergy: NboHeatingVariantRequest.SmartEnergyConnectionEnum;
  heatPumpType: NboHeatingVariantRequest.HeatingTypeEnum;
  dismantleTank: NboHeatingVariantRequest.DismantlingTankEnum;
  heatingArea: number;
  nboSelectedTemplateIdHt: NboHeatingVariantRequest.HeatingManufacturerEnum;
  selectedTemplateHt: any;
  showAddressFields3dFailsHt: boolean;
  largeSystem: boolean;
}

export interface IConfigQueryParamsPrimeo extends IConfigQueryParamsBase {
  compareTechnologies: string;
}

export interface IConfigPrimeo extends IConfigBase {
  envVariables: IEnvVariablesBase;
  queryParams?: IConfigQueryParamsPrimeo;
  templatesHt?: ITemplateHt[];
  templatesEm?: ITemplateEmWithoutCms[];
  constants: {
    manufacturer: IManufacturer[];
    [key: string]: any;
  };
  fixPriceInfoBox?: string;
  disableResidentialBottomBox?: boolean;
  templatesFlat: {
    active: string[];
    inactive: string[];
    default: string;
  };
  templatesSloped: {
    active: string[];
    inactive: string[];
    default: string;
  };
  offerPreview: IOfferPreviewConfig;
  highDrainSystemItems: IChoiceButton[];
  roofAreaItems: IChoiceButton[];
  trades: string[];
}

export type ICollectedDataPrimeo = ICollectedData & IUserInfo & ILeadMeta & IRelatedLead;

export interface IAppStatePrimeo extends IAppStateBase {
  config: IConfigPrimeo;
  collectedData: ICollectedDataPrimeo;
}

export const INITIAL_STATE: IAppStatePrimeo = {
  config: {
    envVariables: {
      appKey: null,
      localeStyle: null,
      enableCallbackHeader: true,
      enablePhoneHeader: true,
    },
    queryParams: {
      partnerId: null,
      origin: null,
      compareTechnologies: null,
    },
    org: {
      name: null,
      contactPhone: null,
      contactEmail: null,
      logoImageUrl: null,
      logoLinkUrl: {
        de: null,
        fr: null,
        it: null,
        en: null,
      },
      stylesheetUrl: null,
      faviconUrl: null,
      contactOfferImageUrls: null,
      successPageUrls: {
        de: null,
        fr: null,
        it: null,
        en: null,
      },
    },
    styles: null,
    defaultLanguage: null,
    steps: [null],
    configLoaded: false,
    constants: null,
    versionNumber: null,
    hubspotTrackingId: null,
    offerPreview: null,
    highDrainSystemItems: [],
    roofAreaItems: [],
    trades: [],
    templatesSloped: null,
    templatesFlat: null,
  },
  currentLanguage: null,
  wizard: {
    showLoadingSpinner: false,
    showOfferPreviewLoadingSpinner: false,
    allowNextStep: false,
    currentStep: null,
    previousStep: null,
    currentStepIdx: null,
    lastStep: null,
    totalSteps: null,
    currentStepData: null,
    navbar: {
      primaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      secondaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      showShareButton: true,
    },
    isSharedOfferPreview: undefined,
  },
  device: {
    device: null,
    browser: null,
  },
  collectedData: {
    variantIdEm: null,
    manufacturerselection: null,
    parkingspace: null,
    poweroutlet: null,
    connectpv: null,
    nboFetchedEm: [],
    fuseBox: null,
    nboLeadIdEm: null,
    chargingPower: null,
    orderType: NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
    selectedTemplateIdEm: null,
    selectedTemplateEm: null,
    totalNumberOfParkingspaces: null,
    numberOfParkingspaceToBeEquiped: null,
    numberOfParkingspacePreparedToBeElectrified: null,
    distanceFuseboxToChargingstation: null,
    nboFetchedPv: [],
    apartmentCount: null,
    averagePowerUsageKwh: null,
    buildingType: BuildingType.FAMILY_HOME,
    familyMemberCount: null,
    highDrainSystems: {
      HEATPUMP: null,
      ELECTRIC_HEATING: null,
      ELECTROBOILER: null,
      EMOBILITY_CHARGING: null,
      POOL_SAUNA: null,
    },
    battery: null,
    electroBoiler: null,
    heatPump: null,
    chargingStationEmobility: null,
    heatPumpBoiler: null,
    highDrainSystemsKwh: null,
    isPvx: null,
    nboLeadIdPv: null,
    internalLeadIdPv: null,
    previewImage: null,
    roofArea: null,
    maxPvCoveredRoofArea: null,
    roofAreaChf: null,
    roofAreaKwh: null,
    roofAreaLifetimeChf: null,
    roofMaterial: null,
    roofShadow: null,
    roofTiltAngle: null,
    selectedRoofArea: null,
    totalPowerRequirementKwh: null,
    nboSelectedTemplateIdPv: null,
    selectedTemplatePv: null,
    pvEarningsKwhM2: null,
    roofOrientation: null,
    showAddressFields3dFailsPv: false,
    variantIdPv: null,
    heatingType: null,
    heatingEmission: null,
    buildingAge: NboHeatingLeadRequest.BuildingYearOfConstructionEnum.Between1972And1997,
    powerConsumptionSelected: null,
    powerConsumptionCalculated: null,
    smartEnergy: null,
    heatPumpType: null,
    dismantleTank: null,
    heatingArea: null,
    nboFetchedHt: [],
    insulation: {
      WINDOWS: null,
      FACADE: null,
      ROOF: null,
      BASEMENT: null,
    },
    nboLeadIdHt: null,
    internalLeadIdHt: null,
    variantIdHt: null,
    nboSelectedTemplateIdHt: null,
    selectedTemplateHt: null,
    showAddressFields3dFailsHt: false,
    buildingData: {
      aboveSea: null,
      buildingEavesHeight: null,
      buildingGroundArea: null,
      buildingLevels: null,
      buildingRidgeHeight: null,
      buildingRoofArea: null,
      buildingRoofOverhangArea: null,
      buildingRoofShape: null,
      buildingVolume: null,
      buildingWallArea: null,
    },
    user: {
      companyName: null,
      email: null,
      emailDisclaimer: null,
      firstName: null,
      lastName: null,
      telephone: null,
      title: null,
    },
    userAddress: {
      zip: null,
      place: null,
      street: null,
      lat: null,
      lon: null,
      x: null,
      y: null,
      addressString: null,
      hasGeometry: null,
    },
    leadStatus: null,
    leadTag: null,
    leadTracers: null,
    leadComment: null,
    leadCallbackTime: {
      IsCallbackTimeMorning: null,
      IsCallbackTimeAfternoon: null,
      IsCallbackTimeEvening: null,
      IsCallbackTimeWeekend: null,
    },
    largeSystem: false,
    relatedLeadPublicId: null,
  },
};

const { collectedData, ...rest } = INITIAL_STATE;
export const INITIAL_STATE_CORE = rest;

export type StateCore = Omit<IAppStateEm, 'collectedData'>;
