/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboYearlyCostsViewModel {
  /**
   * Yearly Energy Cost in CHF (includes the Co2 tax cost)
   */
  YearlyEnergyCost?: number;
  /**
   * Yearly Maintenance Cost in CHF
   */
  YearlyMaintenanceCost?: number;
  /**
   * Yearly Amortisation Cost in CHF
   */
  YearlyAmortisationCost?: number;
  /**
   * Yearly Total Cost (Energy Cost + Maintenance Cost + Lifetime Amortisation)
   */
  YearlyTotalCost?: number;
  LifetimeYears?: number;
}
