/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EmobilityVariantSummaryViewModel } from './emobilityVariantSummaryViewModel';

export interface EmobilityVariantSummaryViewModelLeadSummaryViewModel {
  /**
   * The lead's public id.
   */
  LeadId?: string;
  /**
   * The lead's internal id.
   */
  InternalLeadId?: number;
  /**
   * The provider's lead id.
   */
  ProviderLeadId?: string;
  LeadRating?: EmobilityVariantSummaryViewModelLeadSummaryViewModel.LeadRatingEnum;
  /**
   * The variants.
   */
  Variants?: Array<EmobilityVariantSummaryViewModel>;
}
export namespace EmobilityVariantSummaryViewModelLeadSummaryViewModel {
  export type LeadRatingEnum = 'Zero' | 'One' | 'Two' | 'Three' | 'Four' | 'Five';
  export const LeadRatingEnum = {
    Zero: 'Zero' as LeadRatingEnum,
    One: 'One' as LeadRatingEnum,
    Two: 'Two' as LeadRatingEnum,
    Three: 'Three' as LeadRatingEnum,
    Four: 'Four' as LeadRatingEnum,
    Five: 'Five' as LeadRatingEnum,
  };
}
