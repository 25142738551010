import { Pipe, PipeTransform } from '@angular/core';

export function formatChargingStationHardwarePrice(
  price: number,
  formatter: FormatNumberPipe
): string {
  return price > 0 ? formatter.transform(price) : '-';
}

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  transform(value, decimalSeparator = '.', thousandsSeparator = "$1'"): string {
    const [main, decimal] = (value || 0).toString().split('.');
    const mainFormatted = parseInt(
      (main || 0).toString().replace(new RegExp(thousandsSeparator.replace('$1', ''), 'g'), ''),
      10
    )
      .toFixed(0)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, thousandsSeparator);
    const decimalFormatted = decimal ? `${decimalSeparator}${decimal}` : '';
    return `${mainFormatted}${decimalFormatted}`;
  }
}
