import { ICar } from '../..';

export const CARS_BMW: ICar[] = [
  {
    value: 'Bmw2ActiveTourer',
    caption: 'BMW 2 Active Tourer',
    icon: 'bmw-2-active-tourer.jpg',
  },
  {
    value: 'Bmwi3',
    caption: 'BMW i3',
    icon: 'bmw-i3.jpg',
  },
  {
    value: 'Bmw3Limousine',
    caption: 'BMW 3 Limousine',
    icon: 'bmw-3-limousine.jpg',
  },
  {
    value: 'Bmw5Limousine',
    caption: 'BMW 5 Limousine',
    icon: 'bmw-5-limousine.jpg',
  },
  {
    value: 'Bmw7Limousine',
    caption: 'BMW 7 Limousine',
    icon: 'bmw-7-limousine.jpg',
  },
  {
    value: 'Bmwi8',
    caption: 'BMW i8',
    icon: 'bmw-i8.jpg',
  },
  {
    value: 'BmwiX3',
    caption: 'BMW iX3',
    icon: 'bmw-ix3.jpg',
  },
  {
    value: 'BmwX5',
    caption: 'BMW X5',
    icon: 'bmw-x5.jpg',
  },
  {
    value: 'BmwCEvolution',
    caption: 'C Evolution',
    icon: 'bmw-c-evolution.jpg',
  },
];
