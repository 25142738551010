// Some constants for the wizard - will be read into the store on app-load
import { IConstants, WizardProductKeyEnum } from '@theia-cc/shared/helpers';

export const CONSTANTS_PRIMEO = <IConstants>{
  wizardProductKey: WizardProductKeyEnum.primeo,
  APP_MAIN_ENTRY_STEP: '',
  APP_ALLOWED_ENTRY_STEPS: [],
  moduleLifetimeYears: 30,
  minRoofArea: 23,
  maxRoofArea: 150,
  basePricePerInstallation: 7906,
  pricePerAdditionalKwp: 900,
  roofAreaPrices: {
    large: "32'000",
    medium: "21'000",
    small: "13'000",
  },
  maxPowerPerModule: 0.305,
  areaPerModule: 1.7,
  basePowerConsumption: 1850,
  powerConsumptionPerPerson: 850,
  powerConsumptionPerApartment: 3300,
  kwhPerRoofM2: 178,
  slopeKwpPerM2: 0.165,
  flatKwpPerM2: 0.148,
  minKwp: 30,
  powerCostLow: 0.1,
  powerCostHigh: 0.17,
  averageProductionKwp: 950,
  maxBuildingArea: 158,
  pvxKamLowerThreshold: 600,
  defaultHighDrainSystemsKwh: 7000,
  apartmentBuildingHighDrainSystemsKwh: {
    ELECTRIC_HEATING: 15000,
    HEATPUMP: 3000,
    ELECTROBOILER: 3000,
    EMOBILITY_CHARGING: 10000,
    POOL_SAUNA: 5000,
    HEATPUMP_OR_ELECTROBOILER: 6000,
  },
  pvEarningsKwhM2: 180,
  pvEarningsChf: 700,
  pvEarningsKwh: 7000,
};

export const DOWNLOAD_OFFERS_CC = 'DOWNLOAD_OFFERS_CC';
