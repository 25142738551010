/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboComparisonCostsRequest {
  /**
   * The Energy Demand in Kwh
   */
  EnergyDemand?: number;
  /**
   * The Cost in CHF per liter oil
   */
  CostLiterOil?: number;
  /**
   * The Cost in CHF per kwh electricity
   */
  CostKwhElectricity?: number;
  /**
   * The CO2 tax cost in CHF per tonne Co2
   */
  TaxCostTonneCo2?: number;
}
