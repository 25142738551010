import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PartnerIdService {
  private PARTNER_ID;

  set partnerId(id: string) {
    this.PARTNER_ID = id;
  }

  get partnerId() {
    return this.PARTNER_ID;
  }
}
