/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalEmailViewModel } from './additionalEmailViewModel';
import { AdditionalPhoneViewModel } from './additionalPhoneViewModel';

export interface NboPostContactViewModel {
  Title?: NboPostContactViewModel.TitleEnum;
  CompanyName?: string;
  AdditionalCompanyName?: string;
  FirstName?: string;
  LastName?: string;
  Street?: string;
  ZipCode?: string;
  City?: string;
  Email: string;
  PhoneNumber?: string;
  Notes?: string;
  AdditionalEmails?: Array<AdditionalEmailViewModel>;
  AdditionalPhones?: Array<AdditionalPhoneViewModel>;
  Language: NboPostContactViewModel.LanguageEnum;
  ThirdPartyCustomerNumber?: string;
}
export namespace NboPostContactViewModel {
  export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
  export const TitleEnum = {
    None: 'None' as TitleEnum,
    Sir: 'Sir' as TitleEnum,
    Madam: 'Madam' as TitleEnum,
    Company: 'Company' as TitleEnum,
    Family: 'Family' as TitleEnum,
  };
  export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
  export const LanguageEnum = {
    DE: 'DE' as LanguageEnum,
    FR: 'FR' as LanguageEnum,
    IT: 'IT' as LanguageEnum,
    EN: 'EN' as LanguageEnum,
  };
}
