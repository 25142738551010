<div class="offer-preview-loading-spinner">
  <div
    *ngIf="image"
    class="offer-preview-loading-spinner__image"
    [style.background-image]="'url(' + image + ')'"></div>
  <div class="offer-preview-loading-spinner__scanner"></div>

  <div class="offer-preview-loading-spinner__spinner">
    <circle-progress
      [percent]="percentage"
      [radius]="105"
      [outerStrokeWidth]="8"
      [outerStrokeColor]="'#2889cf'"
      [innerStrokeWidth]="8"
      [innerStrokeColor]="'#ebebeb'"
      [space]="-8"
      [animation]="true"
      [animationDuration]="1200"
      [showTitle]="false"
      [showSubtitle]="false"
      [showUnits]="false"
      [backgroundPadding]="0"
      [responsive]="true"
      [showZeroOuterStroke]="false"></circle-progress>
  </div>
  <div
    class="offer-preview-loading-spinner__caption"
    [innerHTML]="'OFFERPREVIEW.LOADING_SCREEN_CAPTION' | translate"></div>
</div>
