import {
  NboEmobilityCalculateRequest,
  NboEmobilityLeadRequest,
  NboEmobilityRequest,
} from '@api-cc';
import {
  IConfigBase,
  ILanguageSpecific,
  ILanguageSpecificStringWithType,
  ILeadMeta,
  IRelatedLead,
  IUser,
  IUserAddress,
  IUserInfo,
} from '@theia-cc/shared/models';
import { IAppStateBase } from '@theia-cc/shared/store';
import { CheckTypeEnum } from '../../../core/src/lib/consts/global-const';
import { EmStateAction } from './em-state.action';

export interface IDealerData {
  dealershipNumber?: string;
  leaseNumber?: string;
  vin?: string;
}

interface ICollectedData {
  whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum | string;
  numberOfParkingspacesToBeEquippedResidential: number;
  variantIdEm: number;
  parkingspace: NboEmobilityCalculateRequest.ParkingTypeEnum;
  poweroutlet: NboEmobilityCalculateRequest.AvailablePowerOutletEnum | string;
  connectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum;
  fuseBox: NboEmobilityLeadRequest.FuseboxAgeEnum | string;
  chargingPower: NboEmobilityRequest.ChargingPowerEnum;
  orderType?: NboEmobilityRequest.OrderTypeEnum;
  totalNumberOfParkingspaces: number;
  numberOfParkingspacesToBeEquippedCommercial: number;
  numberOfParkingspacesPreparedToBeElectrified: number;
  distanceFuseboxToChargingstation: number | string;
  distanceFuseboxToFlatRibbonCable: number | string;
  nboFetchedEm: any | object[];
  nboLeadIdEm: string;
  selectedTemplateEm: any;
  selectedTemplateIdEm: string;
  dealer?: ICarDealer;
  numberOfRibbonCables: number;
  meterConnectionBetweenRibbonCable: number;
  parkingSpaceArrangement: string;
  checkType: CheckTypeEnum;
}

export type ICarDealer = Partial<IUser> &
  Partial<IUserAddress> &
  IDealerData & { comment?: string };

export type ICollectedDataEm = ICollectedData & IUserInfo & ILeadMeta & IRelatedLead;

export interface IEmTranslations {
  whereToInstallCharger: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionIndividualSolution: ILanguageSpecificStringWithType;
    optionCommunitySolution: ILanguageSpecificStringWithType;
    optionMoreThan60: ILanguageSpecificStringWithType;
  };
  numberOfParkingspacesToBeEquippedResidential: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionOneSpace: ILanguageSpecificStringWithType;
    optionTwoSpaces: ILanguageSpecificStringWithType;
  };
  parkingSpace: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionAdjacent: ILanguageSpecificStringWithType;
    optionParkingOpenSpace: ILanguageSpecificStringWithType;
    optionNotAdjacent: ILanguageSpecificStringWithType;
    optionOther: ILanguageSpecificStringWithType;
  };
  distanceFuseboxToChargingstation: {
    title: ILanguageSpecificStringWithType;
    subtitleIndividualSolution: ILanguageSpecificStringWithType;
    subtitleCommunitySolution: ILanguageSpecificStringWithType;
    optionTen: ILanguageSpecificStringWithType;
    optionTwenty: ILanguageSpecificStringWithType;
    optionMoreThanTwenty: ILanguageSpecificStringWithType;
    optionUnknown: ILanguageSpecificStringWithType;
  };
  poweroutlet: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionCEE1632400V: ILanguageSpecificStringWithType;
    optionT25: ILanguageSpecificStringWithType;
    optionChargingStationConsisting: ILanguageSpecificStringWithType;
    optionUnknown: ILanguageSpecificStringWithType;
  };
  fusebox: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionNotAvailable: ILanguageSpecificStringWithType;
    optionAvailable: ILanguageSpecificStringWithType;
    optionUnknown: ILanguageSpecificStringWithType;
  };
  connectPv: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionDesired: ILanguageSpecificStringWithType;
    optionDesiredTwo: ILanguageSpecificStringWithType;
    optionUndesired: ILanguageSpecificStringWithType;
  };
  parkingSpaceArrangement: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    optionOneParkingRow: ILanguageSpecificStringWithType;
    optionTwoParkingRows: ILanguageSpecificStringWithType;
    optionThreeOrMoreParkingRows: ILanguageSpecificStringWithType;
  };
  numberOfParkingspaces: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    labelTotalNumberOfParkingspaces: ILanguageSpecificStringWithType;
    labelNumberOfParkingspacesPreparedToBeElectrified: ILanguageSpecificStringWithType;
    labelNumberOfParkingspacesToBeEquippedCommercial: ILanguageSpecificStringWithType;
  };
  offerPreview: {
    residentialBottomBoxTitle: ILanguageSpecificStringWithType;
    residentialBottomBoxRequestRemoteCheckButton: ILanguageSpecificStringWithType;
    residentialBottomBoxRequestRemoteCheckContent: ILanguageSpecificStringWithType;
    residentialBottomBoxRequestOnsiteCheckButton: ILanguageSpecificStringWithType;
    residentialBottomBoxRequestOnsiteCheckContent: ILanguageSpecificStringWithType;
    residentialBottomBoxDecideLaterButton: ILanguageSpecificStringWithType;
    residentialBottomBoxDecideLaterContent: ILanguageSpecificStringWithType;
    pricingTableChargingStationResidentialSmartEnergyConnection: ILanguageSpecificStringWithType;
    commercialBottomBoxRequestOnsiteCheckContent: ILanguageSpecificStringWithType;
    commercialBottomBoxRequestOnsiteCheckButton: ILanguageSpecificStringWithType;
    commercialBottomBoxGoToContactContent: ILanguageSpecificStringWithType;
    commercialBottomBoxGoToContactButton: ILanguageSpecificStringWithType;
    commercialSelectTemplate: ILanguageSpecificStringWithType;
    residentialSelectTemplate: ILanguageSpecificStringWithType;
    offerPreviewSummaryTitle: ILanguageSpecificStringWithType;
    offerPreviewSummaryTotalNumberOfParkingspaces: ILanguageSpecificStringWithType;
    offerPreviewSummaryNumberOfParkingspacesPreparedToBeElectrified: ILanguageSpecificStringWithType;
    offerPreviewSummaryNumberOfParkingspacesToBeEquippedCommercial: ILanguageSpecificStringWithType;
  };
  contactEmail: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
    privacyUserAgreement: ILanguageSpecificStringWithType;
  };
  contactCondensed: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
  };
  contactForm: {
    title: ILanguageSpecificStringWithType;
    titleDealerMode: ILanguageSpecificStringWithType;
    titleIndividualModeAndNotCallbackMode: ILanguageSpecificStringWithType;
    subtitleNotIndividualModeAndCallbackMode: ILanguageSpecificStringWithType;
    subtitleIndividualModeAndNotCallbackMode: ILanguageSpecificStringWithType;
    privacyUserAgreement: ILanguageSpecificStringWithType;
  };
  offerPreviewCustom: {
    title: ILanguageSpecificStringWithType;
    subtitle: ILanguageSpecificStringWithType;
  };
}

export type TranslationPropertyEm = keyof IEmTranslations;

// Utility type to extract keys from each property of IEmTranslations
type OptionKeys<T> = {
  [P in keyof T]: keyof T[P];
};

// Creating a type for all option keys in IEmTranslations
export type AllTranslationOptionKeysEm = OptionKeys<IEmTranslations>;

// Union of all option keys in IEmTranslations
export type AllTranslationOptionKeysUnionEm =
  AllTranslationOptionKeysEm[keyof AllTranslationOptionKeysEm];

export interface IConfigEm extends IConfigBase {
  fixPriceInfoBox?: ILanguageSpecific<string>;
  disableResidentialBottomBox?: boolean;
  styleOverridesFileLink?: string;
  useCustomThemeOverrideStylesheet: boolean;
  generalTranslations: IEmTranslations;
  specificTranslations: IEmTranslations;
  enableCustomOfferPreview?: boolean;
}

export interface IAppStateEm extends IAppStateBase {
  collectedData: ICollectedDataEm;
  config: IConfigEm;
}

const INITIAL_STATE_COLLECTED_DATA: ICollectedData = {
  whereToInstallCharger: null,
  numberOfParkingspacesToBeEquippedResidential: null,
  variantIdEm: null,
  parkingspace: null,
  poweroutlet: null,
  connectpv: null,
  fuseBox: null,
  chargingPower: null,
  orderType: NboEmobilityRequest.OrderTypeEnum.FlatRate,
  totalNumberOfParkingspaces: null,
  numberOfParkingspacesToBeEquippedCommercial: null,
  numberOfParkingspacesPreparedToBeElectrified: null,
  distanceFuseboxToChargingstation: null,
  distanceFuseboxToFlatRibbonCable: null,
  nboFetchedEm: [],
  nboLeadIdEm: null,
  selectedTemplateEm: null,
  selectedTemplateIdEm: null,
  numberOfRibbonCables: null,
  meterConnectionBetweenRibbonCable: null,
  parkingSpaceArrangement: null,
  checkType: null,
};

const INITIAL_STATE_CONFIG: IConfigEm = {
  envVariables: {
    appKey: null,
    localeStyle: null,
    enablePhoneHeader: true,
  },
  queryParams: {
    partnerId: null,
    origin: null,
  },
  org: {
    name: null,
    contactPhone: null,
    contactEmail: null,
    logoImageUrl: null,
    logoLinkUrl: {
      de: null,
      fr: null,
      it: null,
      en: null,
    },
    faviconUrl: null,
    successPageUrls: {
      de: null,
      fr: null,
      it: null,
      en: null,
    },
    stylesheetUrl: null,
    contactOfferImageUrls: {
      de: null,
      fr: null,
      it: null,
      en: null,
    },
  },
  styles: null,
  defaultLanguage: null,
  steps: [null],
  configLoaded: false,
  versionNumber: null,
  hubspotTrackingId: null,
  theme: null,
  styleOverridesFileLink: null,
  useCustomThemeOverrideStylesheet: false,
  generalTranslations: null,
  specificTranslations: null,
};

export const collectedDataReducerEm = (
  state: ICollectedData = INITIAL_STATE_COLLECTED_DATA,
  action: { type: any; payload: any }
): ICollectedData => {
  const { payload, type } = action;

  switch (type) {
    case EmStateAction.STORE_WHERE_TO_INSTALL_CHARGER:
      return {
        ...state,
        whereToInstallCharger: payload,
      };

    case EmStateAction.STORE_NUMBER_OF_PARKINGSPACES_TO_BE_EQUIPPED_RESIDENTIAL:
      return {
        ...state,
        numberOfParkingspacesToBeEquippedResidential: payload,
      };

    case EmStateAction.STORE_PARKING_SPACE:
      return {
        ...state,
        parkingspace: payload,
      };

    case EmStateAction.STORE_POWER_OUTLET:
      return {
        ...state,
        poweroutlet: payload,
      };

    case EmStateAction.STORE_FUSE_BOX:
      return {
        ...state,
        fuseBox: payload,
      };

    case EmStateAction.STORE_CONNECT_TO_PV:
      return {
        ...state,
        connectpv: payload,
      };

    case EmStateAction.EM_STORE_NBO_LEAD_ID:
      return {
        ...state,
        nboLeadIdEm: payload,
      };

    case EmStateAction.EM_NBO_SET_DATA:
      return {
        ...state,
        nboFetchedEm: payload,
      };

    case EmStateAction.EM_NBO_FETCHING_STORE:
      return {
        ...state,
        nboFetchedEm: [...state.nboFetchedEm, payload],
      };

    case EmStateAction.EM_NBO_FETCHING_CLEAR:
      return {
        ...state,
        nboFetchedEm: [],
      };

    case EmStateAction.STORE_CHARGING_POWER:
      return {
        ...state,
        chargingPower: payload,
      };

    case EmStateAction.EM_STORE_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplateEm: payload,
        selectedTemplateIdEm: payload?.id,
      };

    case EmStateAction.STORE_TOTALNUMBEROFPARKINGSPACES:
      return {
        ...state,
        totalNumberOfParkingspaces: payload,
      };

    case EmStateAction.STORE_NUMBER_OF_PARKING_SPACES_TO_BE_EQUIPPED_COMMERCIAL:
      return {
        ...state,
        numberOfParkingspacesToBeEquippedCommercial: payload,
      };

    case EmStateAction.STORE_NUMBER_OF_PARKING_SPACES_PREPARED_TO_BE_ELECTRIFIED:
      return {
        ...state,
        numberOfParkingspacesPreparedToBeElectrified: payload,
      };

    case EmStateAction.STORE_DISTANCE_FUSEBOX_TO_CHARGING_STATION:
      return {
        ...state,
        distanceFuseboxToChargingstation: payload,
      };

    case EmStateAction.STORE_DISTANCE_FUSEBOX_TO_FLAT_RIBBON_CABLE:
      return {
        ...state,
        distanceFuseboxToFlatRibbonCable: payload,
      };

    case EmStateAction.EM_SET_CONFIG_SETTINGS:
      return {
        ...state,
        ...payload,
      };

    case EmStateAction.EM_STORE_VARIANT_ID:
      return {
        ...state,
        variantIdEm: payload,
      };

    case EmStateAction.STORE_DEALER_DATA:
      return {
        ...state,
        dealer: payload,
      };

    case EmStateAction.STORE_NUMBER_OF_RIBBON_CABLES:
      return {
        ...state,
        numberOfRibbonCables: action.payload,
      };

    case EmStateAction.STORE_METER_CONNECTION_BETWEEN_RIBBON_CABLE:
      return {
        ...state,
        meterConnectionBetweenRibbonCable: action.payload,
      };

    case EmStateAction.STORE_PARKING_SPACE_ARRANGEMENT:
      return {
        ...state,
        parkingSpaceArrangement: action.payload,
      };

    case EmStateAction.STORE_CHECK_TYPE:
      return {
        ...state,
        checkType: payload,
      };

    default:
      return state;
  }
};
