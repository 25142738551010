import { IOfferPreviewConfig, OFFER_PREVIEW_PV_SWITCHES } from '@theia-cc/pv/core';

export const HELION_OFFER_PREVIEW_CONFIG: IOfferPreviewConfig = {
  switches: OFFER_PREVIEW_PV_SWITCHES,
  bottomBox: {
    enableBottomBoxPvXl: false,
    enableContactOfferImage: true,
  },
  enableCallbackButton: true,
  enableIntroPvx: true,
  enableOptionSwitches: true,
  enableSuccessStory: false,
};
