import { NboEmobilityRequest } from '@api-cc';
import { getLocalizedItemFromILanguageSpecificObject } from '@theia-cc/shared/helpers';
import { ILanguageSpecificStringWithType } from '@theia-cc/shared/models';
import { IAppStateEm, IEmTranslations, TranslationPropertyEm } from './em-state.reducer';

export const isFixPriceSelector = (state: IAppStateEm): boolean => {
  return state.collectedData.orderType === NboEmobilityRequest.OrderTypeEnum.FlatRate;
};

export const enableCustomOfferPreview = (state: IAppStateEm): boolean => {
  return state.config.enableCustomOfferPreview ?? false;
};

export const fixPriceInfoBoxSelector = (state: IAppStateEm): string => {
  const { currentLanguage } = state;
  return (
    isFixPriceSelector(state) &&
    getLocalizedItemFromILanguageSpecificObject(state.config.fixPriceInfoBox, currentLanguage)
  );
};

export const generalTranslations = (state: IAppStateEm): IEmTranslations => {
  return state.config.generalTranslations;
};

// Selectors for translations based on the provided property and field
export const genericTranslationSelector = (
  state: IAppStateEm,
  property: TranslationPropertyEm,
  field: string
): ILanguageSpecificStringWithType => {
  if (
    state.config.specificTranslations &&
    state.config.specificTranslations[property]?.[field]?.overrideDefaultTranslation
  ) {
    return state.config.specificTranslations[property]?.[field];
  }
  return state.config.generalTranslations[property]?.[field];
};
