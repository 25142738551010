import { ICar } from '../..';

export const CARS_VW: ICar[] = [
  {
    value: 'VwEUp',
    caption: 'VW e-up!',
    icon: 'vw-e-up.jpg',
  },
  {
    value: 'VWIDBuzz',
    caption: 'VW ID Buzz',
    icon: 'vw-id-buzz.jpg',
  },
  {
    value: 'VwEGolf',
    caption: 'VW e-Golf',
    icon: 'vw-e-golf.jpg',
  },
  {
    value: 'VwGolf',
    caption: 'VW Golf GTE',
    icon: 'vw-golf.jpg',
  },
  {
    value: 'VWGolf8',
    caption: 'VW Golf 8',
    icon: 'vw-golf-8.jpg',
  },
  {
    value: 'VwPassat',
    caption: 'VW Passat GTE',
    icon: 'vw-passat.jpg',
  },
  {
    value: 'VWID3',
    caption: 'VW ID.3',
    icon: 'vw-id3.jpg',
  },
  {
    value: 'VWID4',
    caption: 'VW ID.4',
    icon: 'vw-id4.jpg',
  },
  {
    value: 'VWID5',
    caption: 'VW ID.5',
    icon: 'vw-id5.jpg',
  },
  {
    value: 'VWECrafter',
    caption: 'VW e-Crafter',
    icon: 'vw-e-crafter.jpg',
  },
];
