import { IConfigBase } from '@theia-cc/shared/models';
import { ConfigAction } from './config.action';

export const INITIAL_CONFIG: IConfigBase = {
  envVariables: {
    appKey: null,
    localeStyle: null,
    enablePhoneHeader: true,
  },
  queryParams: {
    partnerId: null,
    origin: null,
    mode: null,
  },
  org: {
    name: null,
    contactPhone: null,
    contactEmail: null,
    logoImageUrl: null,
    logoLinkUrl: {
      de: null,
      fr: null,
      it: null,
    },
    faviconUrl: null,
    successPageUrls: {
      de: null,
      fr: null,
      it: null,
    },
    stylesheetUrl: null,
    contactOfferImageUrls: {
      de: null,
      fr: null,
      it: null,
    },
  },
  styles: null,
  defaultLanguage: null,
  steps: [null],
  configLoaded: false,
  versionNumber: null,
  hubspotTrackingId: null,
  browserCallback: null,
};

export const configReducer = (state: IConfigBase = INITIAL_CONFIG, action) => {
  switch (action.type) {
    case ConfigAction.QUERY_PARAMS_PARSED:
      const queryParams = { ...state.queryParams, ...action.payload };
      return {
        ...state,
        queryParams,
      };

    case ConfigAction.LOAD_WIZARD_CONFIG:
      return {
        ...state,
        ...action.payload,
      };

    case ConfigAction.WIZARD_CONFIG_LOADED:
      return {
        ...state,
        configLoaded: true,
      };

    case ConfigAction.LOAD_CONFIG_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
      };

    case ConfigAction.STORE_VERSION_NUMBER:
      return {
        ...state,
        versionNumber: action.payload,
      };

    case ConfigAction.STORE_HUBSPOT_TRACKING_ID:
      return {
        ...state,
        hubspotTrackingId: action.payload,
      };

    case ConfigAction.STORE_BROWSER_CALLBACK:
      return {
        ...state,
        browserCallback: action.payload,
      };

    default:
      return state;
  }
};
