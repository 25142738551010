import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, Inject, Injectable, Optional, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { defaultPartnerId } from '@theia-cc/em/core';
import { IConfigEm } from '@theia-cc/em/state';
import {
  HUBSPOT_TRACKING_ID,
  IWizardConfig,
  WIZARD_CONFIG,
  applyConfigStylesheetUrl,
  applyDynamicCmsStyles,
  caseAgnosticQueryParamFromLocation,
  appendCustomFaviconToHead,
} from '@theia-cc/shared/helpers';
import { IConfigBase, IDeviceInfo } from '@theia-cc/shared/models';
import { SanityService } from '@theia-cc/shared/services';
import { ConfigAction, DeviceAction, WizardAction } from '@theia-cc/shared/store';
import lodashIsempty from 'lodash.isempty';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LanguageService } from './language-service';
import { PartnerIdService } from './partner-id.service';

@Injectable({ providedIn: 'root' })
export class AppInitService {
  constructor(
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer,
    private deviceAction: DeviceAction,
    private partnerIdService: PartnerIdService,
    private wizardAction: WizardAction,
    private configAction: ConfigAction,
    private languageService: LanguageService,
    private deviceDetectorService: DeviceDetectorService,
    private sanityService: SanityService,
    // Needs to be optional because em does not provide a wizard config
    @Optional() @Inject(WIZARD_CONFIG) private wizardConfig: IWizardConfig,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public loadConfig = async (
    lastStepDistance,
    useCmsForConfiguration: boolean = false,
    modifyConfig: (config: IConfigBase) => IConfigBase
  ): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      const getCaseAgnosticQueryParam = caseAgnosticQueryParamFromLocation();
      this.partnerIdService.partnerId = getCaseAgnosticQueryParam('partnerId');
      const partnerId = this.partnerIdService.partnerId || 'default';

      if (useCmsForConfiguration) {
        const { config, wizardProps } = await this.sanityService.getEmConfiguration(partnerId);
        this.wizardConfig = config;
        this.wizardAction.setWizardProps(wizardProps);
      }

      if (!this.wizardConfig) {
        reject('invalid wizard config');
      }

      if (!this.wizardConfig[partnerId]) {
        reject('invalid partnerId');
      }

      const productConfig: IConfigBase = this.wizardConfig[partnerId];

      if (productConfig && !productConfig.steps) {
        reject('no steps found in config');
      }

      // check for duplicate step names
      if (productConfig.steps.length > 0) {
        const valueArr = productConfig.steps.map(item => item.name);
        const hasDuplicatedSteps = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
        if (hasDuplicatedSteps) {
          console.warn('duplicated step names found in config - step names must be unique');
        }
      }

      this.configAction.loadWizardConfig(modifyConfig(productConfig));

      if (productConfig.steps && productConfig.steps.length > 1) {
        this.wizardAction.setLastStep(
          productConfig.steps[productConfig.steps.length - lastStepDistance].name
        );
        this.wizardAction.setTotalSteps(productConfig.steps.length);
      }

      const device: IDeviceInfo = this.deviceDetectorService.getDeviceInfo();
      this.deviceAction.setDeviceInfo(device);

      appendCustomFaviconToHead(productConfig.org.faviconUrl, this.domSanitizer);

      if (useCmsForConfiguration) {
        const emProductConfig = productConfig as IConfigEm;
        const partnerId: string = this.partnerIdService.partnerId || defaultPartnerId;

        // Get translations from CMS - general translations and specific translations for the partner
        const generalTranslations = await this.sanityService.getEmGeneralTranslations();
        const partnerSpecificTranslations =
          await this.sanityService.getEmPartnerSpecificTranslations(partnerId);
        emProductConfig.generalTranslations = generalTranslations;
        emProductConfig.specificTranslations = partnerSpecificTranslations;

        this.configAction.loadWizardConfig(modifyConfig(emProductConfig));

        applyDynamicCmsStyles(
          this.domSanitizer,
          emProductConfig.theme,
          emProductConfig.styleOverridesFileLink,
          emProductConfig.useCustomThemeOverrideStylesheet,
          partnerId
        );
      } else {
        applyConfigStylesheetUrl(productConfig.org.stylesheetUrl, this.domSanitizer);
      }

      this.document.body.classList.add(device.browser);
      this.document.body.classList.add(`partner-${this.partnerIdService.partnerId || 'default'}`);
      this.document.body.classList.add('ready');

      // Store the hubspot tracking id
      this.configAction.storeHubspotTrackingId(this.cookieService.get(HUBSPOT_TRACKING_ID));

      this.languageService.setInitialLanguage().then(() => {
        this.configAction.wizardConfigLoaded();
        resolve();
      });
    });
  };
}

export function initializeApp(
  lastStepDistance: number,
  useCmsForConfiguration: boolean = false,
  modifyConfig: (config: IConfigBase) => IConfigBase
) {
  return (appInitService: AppInitService) => (): Promise<any> =>
    appInitService.loadConfig(lastStepDistance, useCmsForConfiguration, modifyConfig);
}

export const getAppInitConfig = (
  lastStepDistance: number,
  useCmsForConfiguration: boolean = false,
  modifyConfig: (config: IConfigBase) => IConfigBase = config => config
) => ({
  provide: APP_INITIALIZER,
  useFactory: initializeApp(lastStepDistance, useCmsForConfiguration, modifyConfig),
  deps: [AppInitService],
  multi: true,
});
