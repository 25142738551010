import { Injectable } from '@angular/core';
import { IConfigEm } from '@theia-cc/em/state';
import { populateCustomComponentStyles } from '@theia-cc/shared/helpers';
import { SanityImageService } from '@theia-cc/shared/services';
import { STEPS_GENERIC } from '../../../../../apps/em/src/app/configs/steps/steps-generic';

@Injectable({ providedIn: 'root' })
export class TransformSanityDataService {
  constructor(private sanityImageService: SanityImageService) {}

  // Transform EM app configuration
  public transformEmAppConfiguration = (
    config: any
  ): { config: { [key: string]: IConfigEm }; wizardProps: Record<string, any> } => {
    const transformedConfiguration: { [key: string]: IConfigEm } = {};

    config.forEach((config: any) => {
      transformedConfiguration[config.key] = {
        org: {
          name: config.name,
          contactPhone: config.contactPhone,
          contactEmail: config.contactEmail,
          logoLinkUrl: config.logoLinkUrl,
          logoImageUrl: this.sanityImageService
            .getImageUrlBuilder(config.logoImageUrl.asset._ref)
            .url(),
          stylesheetUrl: config.stylesheetUrl,
          faviconUrl: config.faviconUrl,
          contactOfferImageUrls: {
            de: this.sanityImageService
              .getImageUrlBuilder(config.contactOfferImageUrls['de'].asset._ref)
              .url(),
            fr: this.sanityImageService
              .getImageUrlBuilder(config.contactOfferImageUrls['fr'].asset._ref)
              .url(),
            it: this.sanityImageService
              .getImageUrlBuilder(config.contactOfferImageUrls['it'].asset._ref)
              .url(),
          },
          successPageUrls: config.successPageUrls,
        },
        envVariables: {
          appKey: config.appKey,
          enablePhoneHeader: config.enablePhoneHeader,
          enableCallbackHeader: config.enableCallbackHeader,
          heroHeaderImageUrl:
            config.heroHeaderImageUrl?.asset &&
            this.sanityImageService.getImageUrlBuilder(config.heroHeaderImageUrl.asset._ref).url(),
          // Always use formal locale style for EM - not configurable via CMS
          localeStyle: 'formal',
          disableLanguageChoice: !config.languageChoice,
          disableSSLPicture: config.disableSSLPicture,
        },
        defaultLanguage: config.defaultLanguage,
        footerLinks: config.footerLinks,
        fixPriceInfoBox: config.fixPriceInfoBox,
        steps: STEPS_GENERIC,
        theme: {
          customComponentStyles: populateCustomComponentStyles(config),
        },
        styleOverridesFileLink: config.styleOverridesFileLink,
        useCustomThemeOverrideStylesheet: config.useCustomThemeOverrideStylesheet,
        // Will be set in the app init service
        generalTranslations: null,
        // Will be set in the app init service
        specificTranslations: null,
        enableCustomOfferPreview: config.enableCustomOfferPreview,
      };
    });

    return {
      config: transformedConfiguration,
      wizardProps: {
        footerSecondaryButtonClass: config[0].customComponentStyle?.footerSecondaryButtonClass,
      },
    };
  };
}
