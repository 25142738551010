import { InjectionToken } from '@angular/core';
import { IConfigBase, ILanguageSpecific } from '@theia-cc/shared/models';

export const GO_TO_SUCCESS_URL = 'GO_TO_SUCCESS_URL';
export const NAVIGATE_NEXT = 'NAVIGATE_NEXT';
export const HUBSPOT_TRACKING_ID = 'hubspotutk';
export const FINISH_CONTACT = 'FINISH_CONTACT';
export const PLUGIN_3D_LOADED = 'PLUGIN_3D_LOADED';
export const PLUGIN_3D_LOADING_FAILED = 'PLUGIN_3D_LOADING_FAILED';

export const LOCATION_API_URL_PART = 'api/nbo';

export enum WizardProductKeyEnum {
  'pv' = 'pv',
  'em' = 'em',
  'ht' = 'ht',
  'primeo' = 'primeo',
  'bac' = 'bac',
}
export interface IConstants {
  // In production, only these steps are allowed as entry points. Everything else redirects to APP_MAIN_ENTRY_POINT
  wizardProductKey: WizardProductKeyEnum;
  APP_MAIN_ENTRY_STEP: string;
  APP_ALLOWED_ENTRY_STEPS: string[];
  [key: string]: any;
}
export const CONSTANTS = new InjectionToken<IConstants>('CONSTANTS');

export interface IEnvironment {
  production: boolean;
  hmr: boolean;
  apiEndpoint: string;
  slackWebHook: string;
  sanity?: {
    projectId: string;
    dataset: string;
    apiVersion: string;
    useCdn: boolean;
  };
  backendNboUrl?: string;
  backendTheiaUrl?: string;
}
export const ENVIRONMENT = new InjectionToken<IEnvironment>('ENVIRONMENT');

export interface ILocaleConfig {
  // tslint:disable-next-line:prefer-array-literal
  readonly LOCALES: Array<keyof ILanguageSpecific<object>>;
  readonly LOCALE_STRINGS_FORMAL: ILanguageSpecific<any>;
  readonly LOCALE_STRINGS_INFORMAL?: ILanguageSpecific<any>;
}
export const LOCALE_CONFIG = new InjectionToken<ILocaleConfig>('LOCALE_CONFIG');

export interface IWizardConfig {
  [key: string]: IConfigBase;
}
export const WIZARD_CONFIG = new InjectionToken<IWizardConfig>('WIZARD_CONFIG');

export const GOOGLE_MAPS_API_KEY = 'AIzaSyC7mk_EyPLGAumjCrLu32r4P08e-0C1v3U';
export const A2C_PLUGIN_API_URL = '//v2.buildingsapi.ch/player';
export const A2C_HAS_GEOMETRY_API_URL = '//v2.buildingsapi.ch/exists';
export const LOCATION_SEARCH_API_URL = '//api3.geo.admin.ch/rest/services/api/SearchServer';

export const ROOF_3D_PLUGIN_COMMON_SETTINGS = {
  minWidth: 300,
  minHeight: 240,
  zoomControls: true,
  rotateControls: true,
  rotateAngle: 0.5,
  compass: true,
  roofColor: '#333333',
  wallColor: '#c0c0c0',
  opacity: 1,
  groundMap: true,
  mapType: 'swisstopo-image',
  terrain: true,
  rotateHorizontal: true,
  rotateVertical: true,
};
