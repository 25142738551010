/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DirectoryCompanyViewModel } from './directoryCompanyViewModel';
import { DirectoryPersonViewModel } from './directoryPersonViewModel';

export interface NboLeadAddressViewModel {
  Person?: DirectoryPersonViewModel;
  Company?: DirectoryCompanyViewModel;
  AddressType?: NboLeadAddressViewModel.AddressTypeEnum;
}
export namespace NboLeadAddressViewModel {
  export type AddressTypeEnum =
    | 'Contact'
    | 'Object'
    | 'Debitor'
    | 'Administration'
    | 'Architect'
    | 'AuthorizedPerson'
    | 'Authorizer'
    | 'BrineWaterDrilling'
    | 'BuildingPhysicist'
    | 'ContactForAccess'
    | 'Delivery'
    | 'Dismantling'
    | 'Electrician'
    | 'ElectroPlanner'
    | 'ExternalAssemblyCompany'
    | 'HeatingEngineer'
    | 'Isolation'
    | 'Janitor'
    | 'LandOwner'
    | 'MasterBuilder'
    | 'Painter'
    | 'Plumber'
    | 'PowerCompany'
    | 'Roofer'
    | 'RoofTenant'
    | 'Scaffolder'
    | 'SiteEngineer'
    | 'SiteOwner'
    | 'SiteSupervisor'
    | 'SubsidyReceiver'
    | 'Tinsmith'
    | 'Supplier'
    | 'Dealer'
    | 'ServiceTechnician'
    | 'CarDealer'
    | 'PlanningOffice'
    | 'VentilationSystemInstaller'
    | 'SapDebtor'
    | 'SiteAddress'
    | 'FormerHeatingEngineer';
  export const AddressTypeEnum = {
    Contact: 'Contact' as AddressTypeEnum,
    Object: 'Object' as AddressTypeEnum,
    Debitor: 'Debitor' as AddressTypeEnum,
    Administration: 'Administration' as AddressTypeEnum,
    Architect: 'Architect' as AddressTypeEnum,
    AuthorizedPerson: 'AuthorizedPerson' as AddressTypeEnum,
    Authorizer: 'Authorizer' as AddressTypeEnum,
    BrineWaterDrilling: 'BrineWaterDrilling' as AddressTypeEnum,
    BuildingPhysicist: 'BuildingPhysicist' as AddressTypeEnum,
    ContactForAccess: 'ContactForAccess' as AddressTypeEnum,
    Delivery: 'Delivery' as AddressTypeEnum,
    Dismantling: 'Dismantling' as AddressTypeEnum,
    Electrician: 'Electrician' as AddressTypeEnum,
    ElectroPlanner: 'ElectroPlanner' as AddressTypeEnum,
    ExternalAssemblyCompany: 'ExternalAssemblyCompany' as AddressTypeEnum,
    HeatingEngineer: 'HeatingEngineer' as AddressTypeEnum,
    Isolation: 'Isolation' as AddressTypeEnum,
    Janitor: 'Janitor' as AddressTypeEnum,
    LandOwner: 'LandOwner' as AddressTypeEnum,
    MasterBuilder: 'MasterBuilder' as AddressTypeEnum,
    Painter: 'Painter' as AddressTypeEnum,
    Plumber: 'Plumber' as AddressTypeEnum,
    PowerCompany: 'PowerCompany' as AddressTypeEnum,
    Roofer: 'Roofer' as AddressTypeEnum,
    RoofTenant: 'RoofTenant' as AddressTypeEnum,
    Scaffolder: 'Scaffolder' as AddressTypeEnum,
    SiteEngineer: 'SiteEngineer' as AddressTypeEnum,
    SiteOwner: 'SiteOwner' as AddressTypeEnum,
    SiteSupervisor: 'SiteSupervisor' as AddressTypeEnum,
    SubsidyReceiver: 'SubsidyReceiver' as AddressTypeEnum,
    Tinsmith: 'Tinsmith' as AddressTypeEnum,
    Supplier: 'Supplier' as AddressTypeEnum,
    Dealer: 'Dealer' as AddressTypeEnum,
    ServiceTechnician: 'ServiceTechnician' as AddressTypeEnum,
    CarDealer: 'CarDealer' as AddressTypeEnum,
    PlanningOffice: 'PlanningOffice' as AddressTypeEnum,
    VentilationSystemInstaller: 'VentilationSystemInstaller' as AddressTypeEnum,
    SapDebtor: 'SapDebtor' as AddressTypeEnum,
    SiteAddress: 'SiteAddress' as AddressTypeEnum,
    FormerHeatingEngineer: 'FormerHeatingEngineer' as AddressTypeEnum,
  };
}
