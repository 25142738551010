import { ICar } from '../..';

export const CARS_CITROEN: ICar[] = [
  {
    value: 'CitroenCZero',
    caption: 'Citroen C-Zero',
    icon: 'citroen-c-zero.jpg',
  },
  {
    value: 'CitroenC5',
    caption: 'Citroen C5 Aircross',
    icon: 'citroen-c5.jpg',
  },
];
