import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  template: `<div class="loading-spinner">
    <div
      class="loading-spinner__spinner"
      [ngClass]="{ 'loading-spinner__spinner--button': facet === 'button' }"></div>
  </div>`,
  encapsulation: ViewEncapsulation.None,
})
export class LoadingSpinnerComponent {
  @Input() facet: string;
}
