import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IDeviceInfo } from '@theia-cc/shared/models';

@Injectable()
export class DeviceAction {
  static readonly SET_DEVICE = 'SET_DEVICE';

  @dispatch()
  setDeviceInfo = (device: IDeviceInfo) => ({
    type: DeviceAction.SET_DEVICE,
    payload: device,
  });
}
