import { ICar } from '../..';

export const CARS_SKODA: ICar[] = [
  {
    value: 'SkodaCitigo',
    caption: 'ŠKODA CITIGOe iV',
    icon: 'skoda-citigo.jpg',
  },
  {
    value: 'SkodaEnyaq',
    caption: 'ŠKODA ENYAQ',
    icon: 'skoda-enyaq.jpg',
  },
  {
    value: 'SkodaOctavia',
    caption: 'ŠKODA OCTAVIA iV',
    icon: 'skoda-octavia.jpg',
  },
  {
    value: 'SkodaSuperb',
    caption: 'ŠKODA SUPERB iV',
    icon: 'skoda-superb.jpg',
  },
];
