import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { CheckTypeEnum } from '@theia-cc/em/core';
import { GTMEvent } from '@theia-cc/shared/models';
import { ICarDealer } from './em-state.reducer';

export interface GTMEventEm extends GTMEvent {
  Company?: string;
}

@Injectable()
export class EmStateAction {
  static readonly STORE_WHERE_TO_INSTALL_CHARGER = 'STORE_WHERE_TO_INSTALL_CHARGER';
  static readonly STORE_NUMBER_OF_PARKINGSPACES_TO_BE_EQUIPPED_RESIDENTIAL =
    'STORE_NUMBER_OF_PARKINGSPACES_TO_BE_EQUIPPED_RESIDENTIAL';
  static readonly EM_NBO_SET_DATA = 'EM_NBO_SET_DATA';
  static readonly EM_NBO_FETCHING_STORE = 'EM_NBO_FETCHING_STORE';
  static readonly EM_NBO_TEMPLATES_FETCHING_STORE = 'EM_NBO_TEMPLATES_FETCHING_STORE';
  static readonly EM_NBO_FETCHING_CLEAR = 'EM_NBO_FETCHING_CLEAR';
  static readonly EM_STORE_NBO_LEAD_ID = 'EM_STORE_NBO_LEAD_ID';
  static readonly STORE_PARKING_SPACE = 'STORE_PARKING_SPACE';
  static readonly STORE_POWER_OUTLET = 'STORE_POWER_OUTLET';
  static readonly STORE_FUSE_BOX = 'STORE_FUSE_BOX';
  static readonly STORE_CONNECT_TO_PV = 'STORE_CONNECT_TO_PV';
  static readonly STORE_CHARGING_POWER = 'STORE_CHARGING_POWER';
  static readonly EM_STORE_SELECTED_TEMPLATE = 'EM_STORE_SELECTED_TEMPLATE';
  static readonly STORE_TOTALNUMBEROFPARKINGSPACES = 'STORE_TOTALNUMBEROFPARKINGSPACES';
  static readonly STORE_NUMBER_OF_PARKING_SPACES_TO_BE_EQUIPPED_COMMERCIAL =
    'STORE_NUMBER_OF_PARKING_SPACES_TO_BE_EQUIPPED_COMMERCIAL';
  static readonly STORE_NUMBER_OF_PARKING_SPACES_PREPARED_TO_BE_ELECTRIFIED =
    'STORE_NUMBER_OF_PARKING_SPACES_PREPARED_TO_BE_ELECTRIFIED';
  static readonly STORE_DISTANCE_FUSEBOX_TO_CHARGING_STATION =
    'STORE_DISTANCE_FUSEBOX_TO_CHARGING_STATION';
  static readonly STORE_DISTANCE_FUSEBOX_TO_FLAT_RIBBON_CABLE =
    'STORE_DISTANCE_FUSEBOX_TO_FLAT_RIBBON_CABLE';
  static readonly EM_SET_CONFIG_SETTINGS = 'EM_SET_CONFIG_SETTINGS';
  static readonly EM_STORE_VARIANT_ID = 'EM_STORE_VARIANT_ID';
  static readonly STORE_DEALER_DATA = 'STORE_DEALER_DATA';
  static readonly STORE_NUMBER_OF_RIBBON_CABLES = 'STORE_NUMBER_OF_RIBBON_CABLES';
  static readonly STORE_METER_CONNECTION_BETWEEN_RIBBON_CABLE =
    'STORE_METER_CONNECTION_BETWEEN_RIBBON_CABLE';
  static readonly STORE_PARKING_SPACE_ARRANGEMENT = 'STORE_PARKING_SPACE_ARRANGEMENT';
  static readonly STORE_CHECK_TYPE = 'STORE_CHECK_TYPE';

  constructor() {}

  @dispatch()
  storeWhereToInstallCharger = (value: string) => ({
    type: EmStateAction.STORE_WHERE_TO_INSTALL_CHARGER,
    payload: value,
  });

  @dispatch()
  storeNumberOfParkingSpacesToBeEquippedResidential = (value: number) => ({
    type: EmStateAction.STORE_NUMBER_OF_PARKINGSPACES_TO_BE_EQUIPPED_RESIDENTIAL,
    payload: value,
  });

  @dispatch()
  storeNboLeadId = value => ({
    type: EmStateAction.EM_STORE_NBO_LEAD_ID,
    payload: value,
  });

  @dispatch()
  storeParkingSpace = (value: string) => ({
    type: EmStateAction.STORE_PARKING_SPACE,
    payload: value,
  });

  @dispatch()
  storePowerOutlet = (value: string) => ({
    type: EmStateAction.STORE_POWER_OUTLET,
    payload: value,
  });

  @dispatch()
  storeFuseBox = (value: string) => ({
    type: EmStateAction.STORE_FUSE_BOX,
    payload: value,
  });

  @dispatch()
  storeConnectToPv = (value: string) => ({
    type: EmStateAction.STORE_CONNECT_TO_PV,
    payload: value,
  });

  @dispatch()
  nboSetData = payload => ({
    payload,
    type: EmStateAction.EM_NBO_SET_DATA,
  });

  @dispatch()
  nboClear = () => ({
    type: EmStateAction.EM_NBO_FETCHING_CLEAR,
  });

  @dispatch()
  nboStoreResult = payload => ({
    payload,
    type: EmStateAction.EM_NBO_FETCHING_STORE,
  });

  @dispatch()
  nboTemplatesStoreResult = payload => ({
    payload,
    type: EmStateAction.EM_NBO_TEMPLATES_FETCHING_STORE,
  });

  @dispatch()
  storeChargingPower = payload => ({
    payload,
    type: EmStateAction.STORE_CHARGING_POWER,
  });

  @dispatch()
  storeSelectedTemplate = payload => ({
    payload,
    type: EmStateAction.EM_STORE_SELECTED_TEMPLATE,
  });

  @dispatch()
  storeTotalNumberOfParkingSpaces = payload => ({
    payload,
    type: EmStateAction.STORE_TOTALNUMBEROFPARKINGSPACES,
  });

  @dispatch()
  storeNumberOfParkingspacesToBeEquippedCommercial = payload => ({
    payload,
    type: EmStateAction.STORE_NUMBER_OF_PARKING_SPACES_TO_BE_EQUIPPED_COMMERCIAL,
  });

  @dispatch()
  storeNumberOfParkingSpacesPreparedToBeElectrified = payload => ({
    payload,
    type: EmStateAction.STORE_NUMBER_OF_PARKING_SPACES_PREPARED_TO_BE_ELECTRIFIED,
  });

  @dispatch()
  storeDistanceFuseboxToChargingstation = payload => ({
    payload,
    type: EmStateAction.STORE_DISTANCE_FUSEBOX_TO_CHARGING_STATION,
  });

  @dispatch()
  storeDistanceFuseboxToFlatRibbonCable = payload => ({
    payload,
    type: EmStateAction.STORE_DISTANCE_FUSEBOX_TO_FLAT_RIBBON_CABLE,
  });

  @dispatch()
  setConfigSettings = payload => ({
    payload,
    type: EmStateAction.EM_SET_CONFIG_SETTINGS,
  });

  @dispatch()
  storeVariantId = (payload: number) => ({
    payload,
    type: EmStateAction.EM_STORE_VARIANT_ID,
  });

  @dispatch()
  storeDealerData = (data: ICarDealer) => ({
    type: EmStateAction.STORE_DEALER_DATA,
    payload: data,
  });

  @dispatch()
  storeNumberOfRibbonCables = (data: number) => ({
    payload: data,
    type: EmStateAction.STORE_NUMBER_OF_RIBBON_CABLES,
  });

  @dispatch()
  storeMeterConnectionBetweenRibbonCable = (data: number) => ({
    payload: data,
    type: EmStateAction.STORE_METER_CONNECTION_BETWEEN_RIBBON_CABLE,
  });

  @dispatch()
  storeParkingSpaceArrangement = (data: string) => ({
    payload: data,
    type: EmStateAction.STORE_PARKING_SPACE_ARRANGEMENT,
  });

  @dispatch()
  storeCheckType = (data: CheckTypeEnum) => ({
    payload: data,
    type: EmStateAction.STORE_CHECK_TYPE,
  });
}
