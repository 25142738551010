import { NgRedux } from '@angular-redux/store';
import { DOCUMENT, Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddNboPvRequest,
  NboAddressViewModel,
  NboPvCalculateRequest,
  NboPvLeadRequest,
  NboPvVariantRequest,
  PatchNboRequest,
  PvVariantSummaryViewModel,
  PvVariantSummaryViewModelLeadSummaryViewModel,
  SaveNboPvRequest,
  SavePersonNboViewModel,
} from '@api-cc';
import { TranslateService } from '@ngx-translate/core';
import {
  BuildingType,
  DOWNLOAD_OFFER_PV,
  FINISH_PV,
  FINISH_PV_AND_DOWNLOAD,
  FINISH_PV_NO_DOWNLOAD,
  FINISH_SHARED_PV,
  GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW,
  OfferStatusEnum,
  REQUEST_CALLBACK,
  ROOF_MATERIAL_DEF,
  SAVE_PV,
  SAVE_PV_AND_NAVIGATE,
  UPDATE_CONTACT_INFO,
} from '@theia-cc/pv/core';
import {
  buildUpLeadComment,
  caseAgnosticQueryParam,
  CONSTANTS,
  FINISH_CONTACT,
  getRoofAreaChf,
  GO_TO_SUCCESS_URL,
  googleTagManagerPush,
  isEmptyValue,
  NAVIGATE_NEXT,
} from '@theia-cc/shared/helpers';
import { IConfigQueryParamsBase, LeadComplexityTypeEnum } from '@theia-cc/shared/models';
import {
  BackendService,
  CantonService,
  ShareLinkService,
  UserInfoService,
} from '@theia-cc/shared/services';
import {
  BaseEffects,
  LeadMetaAction,
  SharedStoreEffect,
  WizardAction,
} from '@theia-cc/shared/store';
import { FormatNumberPipe } from '@theia-cc/shared/util-ui';
import { BsModalService } from 'ngx-bootstrap/modal';
import { APP_NAME } from '../../../../../apps/pv/src/app/configs';
import { CONSTANTS_PV } from '../../../../../apps/pv/src/app/configs/constants';
import { PvStateAction } from './pv-state.action';
import { IAppStatePv, ICollectedDataPv, IConfigPv, IHighDrainSystems } from './pv-state.reducer';
import { isFlatRoofSelector } from './pv-state.selectors';

const defaultVariantTitle = 'CC - Gewählte Dachfläche';

@Injectable()
export class PvStateEffect implements BaseEffects {
  constructor(
    private backend: BackendService,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private store: NgRedux<IAppStatePv>,
    private leadMetaAction: LeadMetaAction,
    private effect: SharedStoreEffect,
    private wizardAction: WizardAction,
    private pvStateAction: PvStateAction,
    private shareLinkService: ShareLinkService,
    private formatNumber: FormatNumberPipe,
    private modalService: BsModalService,
    private userInfoService: UserInfoService,
    private cantonService: CantonService,
    private route: ActivatedRoute,
    @Inject(CONSTANTS) private constants: any,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  personalDataSaved: boolean;

  buttonActions(action) {
    if (!action) return;

    const appState: IAppStatePv = this.store.getState();
    const currentStep = appState.wizard.currentStep;
    const { nboLeadIdPv, variantIdPv } = appState.collectedData;

    const patchRequest = () =>
      this.personalDataSaved ? this.patchNboLead() : this.updatePvNboPersonData();

    switch (true) {
      case currentStep === 'contactemail':
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Email',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'contactcondensed':
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Address',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'contactinformation':
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Address',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'offerpreview' && action === GO_TO_SUCCESS_URL:
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'Request Advise',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'offerpreview' && action === FINISH_PV:
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'Offer Download',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'offerpreviewpvx' && action === GO_TO_SUCCESS_URL:
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'Request Advise',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
    }

    switch (action) {
      case NAVIGATE_NEXT:
        this.effect.navigateNext();
        break;
      case GO_TO_SUCCESS_URL:
        this.goToSuccessURL();
        break;
      case SAVE_PV:
        this.wizardAction.showLoadingSpinner();
        this.createPvNboLead()
          .then((data: PvVariantSummaryViewModelLeadSummaryViewModel) => {
            this.handleGoogleTagsAfterInternalLeadIdIsPresent(
              data.InternalLeadId.toString(),
              data.LeadId
            );
          })
          .then(() => this.wizardAction.hideLoadingSpinner());
        break;
      case SAVE_PV_AND_NAVIGATE:
        this.wizardAction.showLoadingSpinner();
        this.createPvNboLead()
          .then((data: PvVariantSummaryViewModelLeadSummaryViewModel) => {
            this.handleGoogleTagsAfterInternalLeadIdIsPresent(
              data.InternalLeadId.toString(),
              data.LeadId
            );
            this.effect.navigateNext();
          })
          .then(() => this.wizardAction.hideLoadingSpinner());
        break;
      case FINISH_PV:
        this.wizardAction.showLoadingSpinner();
        this.saveAndFinish(
          appState.collectedData.nboLeadIdPv,
          appState.config.queryParams,
          true
        ).then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.goToSuccessURL();
        });
        break;
      case FINISH_PV_AND_DOWNLOAD:
        this.wizardAction.showLoadingSpinner();
        this.saveAndFinish(
          appState.collectedData.nboLeadIdPv,
          appState.config.queryParams,
          true
        ).then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.goToSuccessURL();
        });
        break;
      case FINISH_PV_NO_DOWNLOAD:
        this.wizardAction.showLoadingSpinner();
        this.saveAndFinish(
          appState.collectedData.nboLeadIdPv,
          { mode: 'kiosk' } as IConfigQueryParamsBase,
          true
        ).then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.goToSuccessURL();
        });
        break;
      case FINISH_SHARED_PV:
        this.wizardAction.showLoadingSpinner();
        const finishSharedPvRequest: Promise<unknown> = appState.collectedData.nboLeadIdPv
          ? patchRequest()
          : this.createContactNboLead();
        finishSharedPvRequest
          .then(() =>
            this.saveAndFinish(
              this.store.getState().collectedData.nboLeadIdPv,
              appState.config.queryParams
            )
          )
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          });
        break;
      case GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW:
        const contactStep = appState.config.steps.find(({ name }) => name === 'contactcondensed');
        const isContactcondensed =
          contactStep && SharedStoreEffect.canNavigate(contactStep, appState);
        this.effect.navigateWithoutRestfullOfferPreviewParams(appState)(
          isContactcondensed ? 'contactcondensed' : 'contactinformation'
        );
        break;
      case FINISH_CONTACT:
        this.leadMetaAction.storeLeadStatus(PatchNboRequest.LeadStatusEnum.Callback);
        this.wizardAction.showLoadingSpinner();
        const { offerStatus, selectedTemplatePv } = appState.collectedData;
        const finishContactRequest: Promise<unknown> = nboLeadIdPv
          ? patchRequest()
          : this.createContactNboLead().then(data => {
              this.handleGoogleTagsAfterInternalLeadIdIsPresent(
                data.InternalLeadId.toString(),
                data.LeadId
              );
              return data;
            });
        finishContactRequest
          .then(() => {
            offerStatus !== undefined || (nboLeadIdPv && selectedTemplatePv)
              ? this.addVariantToLead({ isFinalVariant: true })
              : Promise.resolve();
          })
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          });
        break;
      case REQUEST_CALLBACK:
        this.pvStateAction.storeOfferStatus(OfferStatusEnum.REQUEST_CONSULTATION);
        if (appState.collectedData.nboLeadIdPv) {
          this.wizardAction.showLoadingSpinner();
          this.addVariantToLead({ isFinalVariant: false }).then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToContact();
          });
        } else {
          this.goToContact();
        }
        break;
      case UPDATE_CONTACT_INFO:
        this.wizardAction.showLoadingSpinner();
        patchRequest().then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.effect.navigateNext();
        });
        break;
      case DOWNLOAD_OFFER_PV:
        this.wizardAction.showLoadingSpinner();
        this.backend.getPvNboOfferPreviewPdf(nboLeadIdPv, variantIdPv).then(() => {
          this.wizardAction.hideLoadingSpinner();
        });
        break;
      default:
        break;
    }
  }

  // Push the internalLeadId prop to the datalayer as soon as this prop is present
  handleGoogleTagsAfterInternalLeadIdIsPresent(internalLeadId: string, leadId: string) {
    googleTagManagerPush({
      event: 'GAEvent',
      eventCategory: 'UserId',
      eventAction: 'Get TheiaLeadId ',
      eventLabel: internalLeadId,
    });
    googleTagManagerPush({
      event: 'GAEvent',
      eventCategory: 'UserId',
      eventAction: 'Get ExtLeadId',
      eventLabel: leadId,
    });
  }

  async goToSuccessURL() {
    const state: IAppStatePv = this.store.getState();
    const urls = state.config.org.successPageUrls;
    const { browserCallback } = state.config;
    const leadId = state.collectedData.nboLeadIdPv;
    const isAllowed = await this.backend.checkBrowserCallback(browserCallback);
    const url =
      browserCallback && isAllowed
        ? `${browserCallback}${browserCallback.includes('?') ? '&' : '?'}`
        : `${urls[state.currentLanguage]}?`;
    window.location.href = `${url}leadId=${leadId || ''}&${this.getQueryParamString()}`;
  }

  goToContact() {
    this.leadMetaAction.storeLeadTracers(PatchNboRequest.LeadStatusEnum.Callback);

    this.router.navigate(['/contact'], {
      queryParams: this.getQueryParamObject(),
      queryParamsHandling: 'merge',
    });
  }

  getQueryParamString() {
    const params = this.route.snapshot.queryParams;
    const paramSet = [];
    if (Object.keys(params).length > 0) {
      for (const property in params) {
        if (
          property !== 'version' &&
          params.hasOwnProperty(property) &&
          !isEmptyValue(params[property])
        ) {
          paramSet.push(`${property}=${params[property]}`);
        }
      }
    }
    return paramSet.length > 0 ? paramSet.join('&') : '';
  }

  getQueryParamObject() {
    const state: IAppStatePv = this.store.getState();
    const params = state.config.queryParams;
    return Object.keys(params).length > 0 ? params : {};
  }

  public nboClear(): void {
    this.pvStateAction.nboClear();
  }

  calculatePvNbo = (template: any, totalKwp?: number) => {
    const { productline, mountingType, id } = template;
    const state: IAppStatePv = this.store.getState();
    const { config } = state;
    const collectedData: ICollectedDataPv = state.collectedData;
    const isFlatRoof = isFlatRoofSelector(state);
    const kwhYeldPerKwp = Math.round((collectedData.pvEarningsKwhM2 / 180) * 1000);

    // @TODO add initial empty buildingData
    const { buildingData } = collectedData;

    const payload: NboPvCalculateRequest = {
      LeadRequest: {
        LeadId: collectedData.nboLeadIdPv,
        BuildingTypeOfUse: this.setCorrectBuildingTypeOfUseValue(state.collectedData.buildingType),
        BuildingRoofShape:
          buildingData.buildingRoofShape && buildingData.buildingRoofShape.toString(),
        BuildingVolume: +buildingData.buildingVolume,
        BuildingGroundArea: +buildingData.buildingGroundArea,
        BuildingRoofArea: +buildingData.buildingRoofArea,
        BuildingEavesHeight: +buildingData.buildingEavesHeight,
        BuildingRidgeHeight: +buildingData.buildingRidgeHeight,
        BuildingRoofOverhangArea: +buildingData.buildingRoofOverhangArea,
        BuildingWallArea: +buildingData.buildingWallArea,
        BuildingAboveSea: +buildingData.aboveSea,
        LatLonCoordinates:
          collectedData.userAddress.lat && collectedData.userAddress.lon
            ? `${collectedData.userAddress.lat};${collectedData.userAddress.lon}`
            : null,
        BuildingNumberApartments: collectedData.apartmentCount,
        BuildingNumberInhabitants: collectedData.familyMemberCount,
        BuildingLevels: +buildingData.buildingLevels,
        RoofArea: collectedData.selectedRoofArea,
        RoofSlope: collectedData.roofTiltAngle?.toString(),
        RoofOrientation: collectedData.roofOrientation?.toString(),
        IsRoofShadow: collectedData.roofShadow || NboPvLeadRequest.IsRoofShadowEnum.Unknown,
        RoofType: this.getRoofType(isFlatRoof),
        RoofCoverType: this.correctRoofMaterial(
          collectedData.roofMaterial,
          this.getRoofType(isFlatRoof)
        ),
        MountingType: mountingType,
        MajorEnergyConsumers: this.setCorrectMajorEnergyConsumersValue(
          collectedData.highDrainSystems
        ),
        EnergyConsumptionHt:
          0.7 * (collectedData.highDrainSystemsKwh + collectedData.averagePowerUsageKwh),
        EnergyConsumptionLt:
          0.3 * (collectedData.highDrainSystemsKwh + collectedData.averagePowerUsageKwh),
        Egid: collectedData.userAddress && collectedData.userAddress.egid,
      },
      VariantRequest: {
        ProductlineType: productline,
        KwhYieldPerKwp: kwhYeldPerKwp > 0 ? kwhYeldPerKwp : null, // (pvEarningsKwhM2/180)x1000 = KwhYieldPerKwp
        AdditionalSmartEnergyDevices: this.setCorrectAdditionalSmartEnergyDevices(
          collectedData,
          config,
          totalKwp
        ),
        ControllableSmartEnergyDevices:
          this.setCorrectControllableSmartEnergyDevices(collectedData),
      },
    };

    return this.backend.postPvCalculateNbo(payload).then((result: PvVariantSummaryViewModel) => {
      const { TotalKwp } = result;
      if (
        totalKwp === undefined &&
        Number.isFinite(TotalKwp) &&
        TotalKwp < 30 &&
        config.equippedWithEnergyManagementSystemForResidential === true
      ) {
        return this.calculatePvNbo(template, TotalKwp);
      } else {
        this.pvStateAction.nboStoreResult({
          templateId: id,
          ...(result as PvVariantSummaryViewModel),
        });
      }
    });
  };

  private correctRoofMaterial(
    roofMaterial: NboPvLeadRequest.RoofCoverTypeEnum,
    roofType: NboPvLeadRequest.RoofTypeEnum
  ): NboPvLeadRequest.RoofCoverTypeEnum {
    const roofMaterialDef = ROOF_MATERIAL_DEF.find(({ key }) => key === roofMaterial);

    return roofType === NboPvLeadRequest.RoofTypeEnum.SlopedRoof && roofMaterialDef?.flat === true
      ? NboPvLeadRequest.RoofCoverTypeEnum.ClayTiles
      : roofType === NboPvLeadRequest.RoofTypeEnum.FlatRoof &&
        (roofMaterialDef?.flat === false || !roofMaterialDef)
      ? NboPvLeadRequest.RoofCoverTypeEnum.Gravel
      : roofMaterial;
  }

  private getRoofType(isFlatRoof: boolean): NboPvLeadRequest.RoofTypeEnum {
    return isFlatRoof
      ? NboPvLeadRequest.RoofTypeEnum.FlatRoof
      : NboPvLeadRequest.RoofTypeEnum.SlopedRoof;
  }

  async createPvNboLead(status?: PatchNboRequest.LeadStatusEnum) {
    const state: IAppStatePv = this.store.getState();
    const collectedData: ICollectedDataPv = state.collectedData;
    const { hubspotTrackingId, queryParams } = state.config;
    const previewImage = collectedData.previewImage;
    const partnerId = caseAgnosticQueryParam(state.config.queryParams)('partnerId') || '';

    const leadTracers = this.getLeadTracers(state.collectedData);
    const leadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(state.collectedData.leadCallbackTime),
      collectedData,
      queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state),
      collectedData.leadTracers?.includes(PatchNboRequest.LeadStatusEnum.Callback)
    );

    const payload: SaveNboPvRequest = {
      PersonData: {
        Email: collectedData.user.email,
        Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
        Address: {
          Title: collectedData.user.title || NboAddressViewModel.TitleEnum.None,
          FirstName: collectedData.user.firstName || 'unknown',
          LastName: collectedData.user.lastName || 'unknown',
          PhoneNumber: collectedData.user.telephone || 'unknown',
          ZipCode: collectedData.userAddress.zip || 'unknown',
          City: collectedData.userAddress.place || 'unknown',
          Street: collectedData.userAddress.street || 'unknown',
        },
      },
      PlannedImplementationTime: SaveNboPvRequest.PlannedImplementationTimeEnum.Unknown,
      ...collectedData.leadCallbackTime,
      PartnerId: partnerId || '',
      SituationImage3D: previewImage,
      HubspotTrackingId: hubspotTrackingId,
      LeadTag: collectedData.leadTag,
      LeadStatus: status ?? PatchNboRequest.LeadStatusEnum.Incomplete,
      LeadTracers: leadTracers,
      LeadComment: leadComment,
      RelatedLeadPublicId: collectedData.relatedLeadPublicId,
      ThirdPartyCustomerNumber: caseAgnosticQueryParam(queryParams)('ThirdPartyCustomerNumber'),
      ShareUrl: this.shareLinkService.generateShareLink(this.document.location, state),
      LeadComplexityType: this.getLeadComplexityType(collectedData),
    };

    const data: PvVariantSummaryViewModelLeadSummaryViewModel =
      await this.backend.postPvSaveNboData(payload);
    this.pvStateAction.storeNboLeadId(data.LeadId);
    this.pvStateAction.storeInternalLeadId(data.InternalLeadId);
    this.personalDataSaved = false;

    return data;
  }

  async createContactNboLead() {
    const state: IAppStatePv = this.store.getState();
    const collectedData: ICollectedDataPv = state.collectedData;
    const { hubspotTrackingId, queryParams } = state.config;

    const image = collectedData.previewImage;

    const leadTracers = this.getLeadTracers(state.collectedData);
    const leadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(state.collectedData.leadCallbackTime),
      collectedData,
      queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state),
      collectedData.leadTracers?.includes(PatchNboRequest.LeadStatusEnum.Callback)
    );

    const payload: SaveNboPvRequest = {
      PersonData: {
        Email: collectedData.user.email || 'unknown',
        Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
        Address: {
          Title: collectedData.user.title || NboAddressViewModel.TitleEnum.None,
          FirstName: collectedData.user.firstName || 'unknown',
          LastName: collectedData.user.lastName || 'unknown',
          PhoneNumber: collectedData.user.telephone || 'unknown',
          ZipCode: collectedData.userAddress.zip || 'unknown',
          City: collectedData.userAddress.place || 'unknown',
          Street: collectedData.userAddress.street || 'unknown',
        },
      },
      LeadComment: leadComment,
      LeadTag: collectedData.leadTag,
      LeadStatus: PatchNboRequest.LeadStatusEnum.Callback,
      ShareUrl: this.shareLinkService.generateShareLink(this.document.location, state),
      LeadTracers: leadTracers,
      PlannedImplementationTime: SaveNboPvRequest.PlannedImplementationTimeEnum.Unknown,
      ...collectedData.leadCallbackTime,
      SituationImage3D: image,
      HubspotTrackingId: hubspotTrackingId,
      LeadComplexityType: this.getLeadComplexityType(collectedData),
    };

    const data: PvVariantSummaryViewModelLeadSummaryViewModel =
      await this.backend.postPvSaveNboData(payload);
    this.pvStateAction.storeNboLeadId(data.LeadId);
    this.pvStateAction.storeInternalLeadId(data.InternalLeadId);

    await this.userInfoService.sendAdditionalAddresses(data.LeadId);

    return data;
  }

  getLeadComplexityType(collectedData: ICollectedDataPv): LeadComplexityTypeEnum {
    switch (collectedData.buildingType) {
      case BuildingType.FAMILY_HOME:
        return LeadComplexityTypeEnum.Residential;
      case BuildingType.APARTMENT_BUILDING:
        return collectedData.apartmentCount > 9
          ? LeadComplexityTypeEnum.Enterprise
          : LeadComplexityTypeEnum.Residential;
      case BuildingType.COMMERCIAL_BUILDING:
      case BuildingType.OFFICE_BUILDING:
        return LeadComplexityTypeEnum.Commercial;
      default:
        return LeadComplexityTypeEnum.Residential;
    }
  }

  getLeadTracers({ selectedRoofArea, isPvx, leadTracers }: ICollectedDataPv) {
    const mainTracers = leadTracers || undefined;
    const additionalTracers = !isPvx
      ? undefined
      : selectedRoofArea > CONSTANTS_PV.pvxKamLowerThreshold
      ? 'XL-KAM'
      : 'XL-VK';
    const notEmptyTracers = [mainTracers, additionalTracers].filter(
      tag => tag !== undefined && tag !== null
    );
    return (notEmptyTracers.length && notEmptyTracers.toString()) || undefined;
  }

  updatePvNboPersonData = async (LeadStatus?: PatchNboRequest.LeadStatusEnum) => {
    const state: IAppStatePv = this.store.getState();
    const collectedData: ICollectedDataPv = state.collectedData;
    const { nboLeadIdPv } = collectedData;
    const leadTracers = this.getLeadTracers(collectedData);
    const { queryParams } = state.config;

    const payload: PatchNboRequest = {
      LeadTag: collectedData.leadTag,
      LeadTracers: leadTracers,
      Address: {
        Title: collectedData.user.title,
        CompanyName: collectedData.user.companyName,
        FirstName: collectedData.user.firstName,
        LastName: collectedData.user.lastName,
        PhoneNumber: collectedData.user.telephone,
        ZipCode: collectedData.userAddress.zip || 'unknown',
        City: collectedData.userAddress.place || 'unknown',
        Street: collectedData.userAddress.street || 'unknown',
      },
      PlannedImplementationTime: SaveNboPvRequest.PlannedImplementationTimeEnum.Unknown,
      ...collectedData.leadCallbackTime,
      LeadStatus: LeadStatus
        ? PatchNboRequest.LeadStatusEnum.OfferDownload
        : PatchNboRequest.LeadStatusEnum.Complete,
      ThirdPartyCustomerNumber: caseAgnosticQueryParam(queryParams)('ThirdPartyCustomerNumber'),
    };

    const data = this.backend.patchPvNboPersonData(payload);
    this.personalDataSaved = true;

    await this.userInfoService.sendAdditionalAddresses(nboLeadIdPv);

    return data;
  };

  addVariantToLead = (arg: { isFinalVariant: boolean }) => {
    const state: IAppStatePv = this.store.getState();
    const collectedData: ICollectedDataPv = state.collectedData;
    const config: IConfigPv = state.config;
    const isFlatRoof = isFlatRoofSelector(state);
    const { productline, mountingType } = collectedData.selectedTemplatePv;
    const kwhYeldPerKwp = Math.round((collectedData.pvEarningsKwhM2 / 180) * 1000);

    // @TODO add initial empty buildingData
    const { buildingData, nboSelectedTemplateIdPv } = collectedData;
    const payload: AddNboPvRequest = {
      LeadRequest: {
        LeadId: collectedData.nboLeadIdPv,
        BuildingTypeOfUse: this.setCorrectBuildingTypeOfUseValue(state.collectedData.buildingType),
        BuildingRoofShape:
          buildingData.buildingRoofShape && buildingData.buildingRoofShape.toString(),
        BuildingVolume: +buildingData.buildingVolume,
        BuildingGroundArea: +buildingData.buildingGroundArea,
        BuildingRoofArea: +buildingData.buildingRoofArea,
        BuildingEavesHeight: +buildingData.buildingEavesHeight,
        BuildingRidgeHeight: +buildingData.buildingRidgeHeight,
        BuildingRoofOverhangArea: +buildingData.buildingRoofOverhangArea,
        BuildingWallArea: +buildingData.buildingWallArea,
        BuildingAboveSea: +buildingData.aboveSea,
        LatLonCoordinates:
          collectedData.userAddress.lat && collectedData.userAddress.lon
            ? `${collectedData.userAddress.lat};${collectedData.userAddress.lon}`
            : null,
        BuildingNumberApartments: collectedData.apartmentCount,
        BuildingNumberInhabitants: collectedData.familyMemberCount,
        BuildingLevels: +buildingData.buildingLevels,
        RoofArea: collectedData.selectedRoofArea,
        RoofSlope: collectedData.roofTiltAngle,
        RoofOrientation: collectedData.roofOrientation,
        IsRoofShadow: collectedData.roofShadow || NboPvLeadRequest.IsRoofShadowEnum.Unknown,
        RoofType: this.getRoofType(isFlatRoof),
        RoofCoverType: this.correctRoofMaterial(
          collectedData.roofMaterial,
          this.getRoofType(isFlatRoof)
        ),
        MountingType: mountingType,
        MajorEnergyConsumers: this.setCorrectMajorEnergyConsumersValue(
          collectedData.highDrainSystems
        ),
        EnergyConsumptionHt:
          0.7 * (collectedData.highDrainSystemsKwh + collectedData.averagePowerUsageKwh),
        EnergyConsumptionLt:
          0.3 * (collectedData.highDrainSystemsKwh + collectedData.averagePowerUsageKwh),
        Egid: collectedData.userAddress && collectedData.userAddress.egid,
      },
      VariantRequests: [
        {
          VariantTitle: `${defaultVariantTitle} ${arg.isFinalVariant ? 'Requested' : ''}`,
          KwhYieldPerKwp: kwhYeldPerKwp > 0 ? kwhYeldPerKwp : null, // (pvEarningsKwhM2/180)x1000 = KwhYieldPerKwp
          IsSendMail: arg.isFinalVariant,
          ProductlineType: productline,
          AdditionalSmartEnergyDevices: this.setCorrectAdditionalSmartEnergyDevices(
            collectedData,
            config,
            collectedData.nboFetchedPv.find(
              ({ templateId }) => templateId === nboSelectedTemplateIdPv
            )?.TotalKwp
          ),
          ControllableSmartEnergyDevices:
            this.setCorrectControllableSmartEnergyDevices(collectedData),
        },
      ],
    };

    return this.backend.postPvVariantToLead(payload).then(variant => {
      this.pvStateAction.storeVariantId(variant[0].VariantId);

      return this.patchNboLead().then(() => variant);
    });
  };

  saveAndFinish(leadId, { mode }: IConfigQueryParamsBase, isFinalVariant = true) {
    return this.addVariantToLead({ isFinalVariant }).then(
      (val): Promise<HttpResponse<Blob> | void> => {
        const variantId = val[0].VariantId;
        return mode !== 'kiosk'
          ? this.backend.getPvNboOfferPreviewPdf(leadId, variantId)
          : Promise.resolve();
      }
    );
  }

  setCorrectLanguageEnumValue(currentLanguage: string): SavePersonNboViewModel.LanguageEnum {
    switch (currentLanguage) {
      case 'de':
        return SavePersonNboViewModel.LanguageEnum.DE;
      case 'fr':
        return SavePersonNboViewModel.LanguageEnum.FR;
      case 'it':
        return SavePersonNboViewModel.LanguageEnum.IT;
      default:
        return SavePersonNboViewModel.LanguageEnum.DE;
    }
  }

  setCorrectMajorEnergyConsumersValue(
    highDrainSystems: IHighDrainSystems
  ): NboPvLeadRequest.MajorEnergyConsumersEnum[] {
    const result: NboPvLeadRequest.MajorEnergyConsumersEnum[] = [];
    Object.keys(highDrainSystems).forEach(key => {
      if (highDrainSystems[key] && highDrainSystems[key] === true) {
        switch (key) {
          case 'HEATPUMP':
            result.push(NboPvLeadRequest.MajorEnergyConsumersEnum.Heatpump);
            break;
          case 'ELECTRIC_HEATING':
            result.push(NboPvLeadRequest.MajorEnergyConsumersEnum.ElectricHeating);
            break;
          case 'ELECTROBOILER':
            result.push(NboPvLeadRequest.MajorEnergyConsumersEnum.ElectricBoiler);
            break;
          case 'EMOBILITY_CHARGING':
            result.push(NboPvLeadRequest.MajorEnergyConsumersEnum.ElectricVehicle);
            break;
          case 'POOL_SAUNA':
            result.push(NboPvLeadRequest.MajorEnergyConsumersEnum.Sauna);
            break;
          default:
            break;
        }
      }
    });
    return result;
  }

  setCorrectBuildingTypeOfUseValue(buildingType: string): NboPvLeadRequest.BuildingTypeOfUseEnum[] {
    const result: NboPvLeadRequest.BuildingTypeOfUseEnum[] = [];
    switch (buildingType) {
      case BuildingType.FAMILY_HOME:
      case BuildingType.APARTMENT_BUILDING:
        result.push(NboPvLeadRequest.BuildingTypeOfUseEnum.Apartment);
        break;
      case BuildingType.COMMERCIAL_BUILDING:
        result.push(NboPvLeadRequest.BuildingTypeOfUseEnum.Commerce);
        break;
      case BuildingType.OFFICE_BUILDING:
        result.push(NboPvLeadRequest.BuildingTypeOfUseEnum.Office);
        break;
      default:
        break;
    }
    return result;
  }

  setCorrectAdditionalSmartEnergyDevices(
    collectedData: ICollectedDataPv,
    config?: IConfigPv,
    TotalKwp?: number
  ): NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum[] {
    const result: NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum[] = [];

    if (
      Number.isFinite(TotalKwp) &&
      TotalKwp < 30 &&
      config.equippedWithEnergyManagementSystemForResidential === true
    ) {
      result.push(NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum.EnergyManagementSystem);
    }

    if (collectedData.battery) {
      result.push(NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum.Storage);
    }

    if (collectedData.chargingStationEmobility) {
      result.push(NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum.EmobilityChargingStation);
    }

    if (collectedData.heatPumpBoiler) {
      result.push(NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum.HeatpumpBoiler);
    }

    return result;
  }

  setCorrectControllableSmartEnergyDevices(
    collectedData: ICollectedDataPv
  ): NboPvVariantRequest.ControllableSmartEnergyDevicesEnum[] {
    const result: NboPvVariantRequest.ControllableSmartEnergyDevicesEnum[] = [];

    if (collectedData.electroBoiler) {
      result.push(NboPvVariantRequest.ControllableSmartEnergyDevicesEnum.DomesticWaterHeaterRelay);
    }

    if (collectedData.heatPump) {
      result.push(NboPvVariantRequest.ControllableSmartEnergyDevicesEnum.HeatpumpRelay);
    }

    return result;
  }

  getCallBackTime(leadCallbackTime: ICollectedDataPv['leadCallbackTime']): string {
    return Object.values(leadCallbackTime).every(time => !time)
      ? 'unknown'
      : Object.entries(leadCallbackTime).reduce((res, [key, value]) => {
          return value
            ? `${res}${res.length ? ', ' : ''}${key.replace('IsCallbackTime', '')}`
            : res;
        }, '');
  }

  valueFormatted(template, nboResult, isBatteryOptionSelected) {
    const appState = this.store.getState();

    const {
      totalPowerRequirementKwh: collectedTotalPowerRequirementKwh,
      roofAreaKwh,
      selectedRoofArea: collectedSelectedRoofArea,
      roofArea,
      familyMemberCount,
      apartmentCount,
      roofMaterial,
      roofTiltAngle,
      roofAreaLifetimeChf,
      roofAreaChf,
    } = appState.collectedData;

    const {
      powerConsumptionPerPerson,
      basePowerConsumption,
      kwhPerRoofM2,
      defaultHighDrainSystemsKwh,
      pvEarningsKwh,
    } = this.constants;
    const defaultAveragePowerUsageKwh =
      powerConsumptionPerPerson * (familyMemberCount || 0) + basePowerConsumption;

    const selectedRoofArea = collectedSelectedRoofArea || nboResult.CoveredRoofArea;
    const totalPowerProducedKwh = roofAreaKwh || kwhPerRoofM2 * selectedRoofArea;
    const totalPowerRequirementKwh =
      collectedTotalPowerRequirementKwh ||
      (appState.collectedData.averagePowerUsageKwh || defaultAveragePowerUsageKwh) +
        (appState.collectedData.highDrainSystemsKwh || defaultHighDrainSystemsKwh);

    const efficiencyPercentage = (totalPowerProducedKwh * 100) / totalPowerRequirementKwh;

    if (!collectedSelectedRoofArea && Number.isFinite(selectedRoofArea)) {
      const coverageFactor = selectedRoofArea / (roofArea || selectedRoofArea);
      this.pvStateAction.storeSelectedRoofArea(selectedRoofArea);
      this.pvStateAction.storeRoofArea(roofArea || selectedRoofArea);
      this.pvStateAction.storeRoofAreaLifetimeChf(roofAreaLifetimeChf * coverageFactor);
      this.pvStateAction.storeRoofAreaChf(
        (Number.isFinite(roofAreaChf) ? roofAreaChf : getRoofAreaChf(pvEarningsKwh)) *
          coverageFactor
      );
      this.pvStateAction.storeRoofAreaKwh(
        (Number.isFinite(roofAreaKwh) ? roofAreaKwh : selectedRoofArea * kwhPerRoofM2) *
          coverageFactor
      );
      this.pvStateAction.storeAveragePowerUsageKwh(
        appState.collectedData.averagePowerUsageKwh || defaultAveragePowerUsageKwh
      );
      this.pvStateAction.storeHighDrainSystemsKwh(
        appState.collectedData.highDrainSystemsKwh || defaultHighDrainSystemsKwh
      );
    }

    return {
      EfficiencyGraphValue: () => {
        switch (true) {
          case efficiencyPercentage <= 50:
            return 1;
          case efficiencyPercentage > 50 && efficiencyPercentage <= 70:
            return 2;
          case efficiencyPercentage > 70 && efficiencyPercentage <= 95:
            return 3;
          case efficiencyPercentage > 95:
            return 4;
        }
      },
      NetInvestmentChf: this.roundNumber(nboResult.BruttoPrice - nboResult.EivOneOffPayment || 0),
      AilAnnualInvestmentChf:
        caseAgnosticQueryParam(appState.config.queryParams)('partnerId') === 'ail'
          ? this.getAilAnnualInvestmentChf(nboResult)
          : null,
      FederalGrantChf: this.roundNumber(nboResult.EivOneOffPayment || 0),
      TaxSavingsChf: this.roundNumber(nboResult.TaxSaving || 0),
      GrossChf: this.roundNumber(nboResult.BruttoPrice || 0),
      Kwp: this.roundNumber(nboResult.TotalKwp || 0, 1, '.'),
      TotalPowerRequiredKwh: totalPowerRequirementKwh || 0,
      TotalPowerProducedKwh: totalPowerProducedKwh || 0,
      EfficencyFactor: `${this.roundNumber(efficiencyPercentage)}<span>%</span>`,
      ProfitabilityChf: isBatteryOptionSelected
        ? this.roundNumber(nboResult.FinancialLifetimeYieldWithStorage || 0)
        : this.roundNumber(nboResult.FinancialLifetimeYieldWithoutStorage || 0),
      SelectedRoofArea: this.roundNumber(selectedRoofArea),
      RoofArea: this.roundNumber(roofArea || nboResult.CoveredRoofArea),
      FamilyMemberCount: familyMemberCount || 0,
      ApartmentCount: apartmentCount || 0,
      RoofMaterial: ROOF_MATERIAL_DEF.filter(item => item.key === roofMaterial)[0]
        ? ROOF_MATERIAL_DEF.filter(item => item.key === roofMaterial)[0].name
        : null,
      RoofTiltAngle: roofTiltAngle,
      FuseBox: 'PV.OFFERPREVIEW.SUMMARY.FUSE_BOX_OLD',
      NumberOfModules: this.roundNumber(nboResult.NumberOfModules || 0),
    };
  }

  patchNboLead() {
    const state: IAppStatePv = this.store.getState();
    const collectedData: ICollectedDataPv = state.collectedData;
    const { queryParams } = state.config;

    const ShareUrl = this.shareLinkService.generateShareLink(this.document.location, state);
    const LeadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(state.collectedData.leadCallbackTime),
      collectedData,
      queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state),
      collectedData.leadTracers?.includes(PatchNboRequest.LeadStatusEnum.Callback)
    );

    return this.backend.patchNboLead(state.collectedData.nboLeadIdPv, {
      ShareUrl,
      LeadComment,
      LeadTag: collectedData.leadTag,
      LeadTracers: collectedData.leadTracers,
      LeadStatus: collectedData.leadStatus,
    });
  }

  roundNumber(number, decimals = 0, decimalSeparator = "'"): string {
    return this.formatNumber.transform(
      decimals
        ? (Math.round(number * 10 ** decimals) / 10 ** decimals).toFixed(decimals)
        : Math.ceil(number),
      decimalSeparator
    );
  }

  valueFormattedPvx(nboData) {
    const appState: IAppStatePv = this.store.getState();
    const { selectedRoofArea } = appState.collectedData;
    if (!selectedRoofArea && Number.isFinite(nboData.CoveredRoofArea)) {
      this.pvStateAction.storeSelectedRoofArea(nboData.CoveredRoofArea);
    }
    return {
      TotalKwp: this.roundNumber(nboData.TotalKwp || 0, 1, '.'),
      KwpProducedPerYear: this.roundNumber(nboData.KwhProducedPerYear) || 0,
      RoofArea: selectedRoofArea || nboData.CoveredRoofArea || 0,
      GrossChf: this.roundNumber(nboData.BruttoPrice) || 0,
      ProductionCostPerKwh: this.roundNumber(nboData.EnergyProductionCosts * 100, 2, ',') || 0,
      NetInvestmentChf: this.roundNumber(nboData.BruttoPrice - nboData.EivOneOffPayment || 0),
      AilAnnualInvestmentChf:
        caseAgnosticQueryParam(appState.config.queryParams)('partnerId') === 'ail'
          ? this.getAilAnnualInvestmentChf(nboData)
          : null,
      FederalGrantChf: this.roundNumber(nboData.EivOneOffPayment) || 0,
    };
  }

  private getAilAnnualInvestmentChf(nboData): string {
    return this.roundNumber(nboData.BruttoPrice * 0.0618 + 316.4);
  }
}
