/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboComparisonInvestmentViewModel {
  /**
   * Average installation cost in CHF for a new gas heating
   */
  InstallationCostGasHeating?: number;
  /**
   * Average installation cost in CHF for a new oil heating
   */
  InstallationCostOilHeating?: number;
  /**
   * Average installation cost in CHF for a new pellets heating
   */
  InstallationCostPelletsHeating?: number;
}
