import { ICar } from '../..';

export const CARS_VOLVO: ICar[] = [
  {
    value: 'VolvoS60',
    caption: 'Volvo S60',
    icon: 'volvo-s60.jpg',
  },
  {
    value: 'VolvoV60',
    caption: 'Volvo V60',
    icon: 'volvo-v60.jpg',
  },
  {
    value: 'VolvoV90',
    caption: 'Volvo V90',
    icon: 'volvo-v90.jpg',
  },
  {
    value: 'VolvoXC40',
    caption: 'Volvo XC40',
    icon: 'volvo-xc40.jpg',
  },
  {
    value: 'VolvoXC60',
    caption: 'Volvo XC60',
    icon: 'volvo-xc60.jpg',
  },
  {
    value: 'VolvoXC90',
    caption: 'Volvo XC90',
    icon: 'volvo-xc90.jpg',
  },
];
