import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  cleanEmptyProperties,
  ENVIRONMENT,
  IEnvironment,
  isObject,
  LOCATION_API_URL_PART,
  ORIGINS_TO_IGNORE_THAT_SCULLY_USES,
} from '@theia-cc/shared/helpers';
import { IAppStateBase } from '@theia-cc/shared/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const KNOWN_ERRORS_DETAILS = ['The searchText parameter can not contain more than 10 words'];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: NgRedux<IAppStateBase>,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  private static getErrorForSlack(
    req: HttpRequest<any>,
    error: HttpErrorResponse,
    state: IAppStateBase,
    isApiRequest = true
  ): { headers: HttpHeaders; message: object } {
    const color = isApiRequest ? 'danger' : 'warning';
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const collectedDataWithoutEmpty: any = cleanEmptyProperties(state.collectedData);
    collectedDataWithoutEmpty.nboFetched = undefined;
    collectedDataWithoutEmpty.selectedTemplate = undefined;
    collectedDataWithoutEmpty.previewImage = undefined;

    const message = {
      channel: '#theia-stage-prod-cc',
      text: JSON.stringify({ message: error.message, error: error.error }),
      attachments: [
        {
          color,
          author_name: location.href,
          title: 'Time',
          text: new Date().toString(),
        },
        {
          color,
          author_name: '',
          title: 'Request Body',
          text: isObject(req.body) ? JSON.stringify(req.body) : req.body || '',
        },
        {
          color,
          author_name: '',
          title: 'Request method',
          text: req.method,
        },
        {
          color,
          author_name: '',
          title: 'User Agent',
          text: JSON.stringify(state.device, null, 2),
        },
        {
          color,
          author_name: '',
          title: 'Version',
          text: state.config.versionNumber,
        },
        {
          color,
          author_name: '',
          title: 'User History',
          text: isObject(collectedDataWithoutEmpty)
            ? JSON.stringify(cleanEmptyProperties(collectedDataWithoutEmpty), null, 2)
            : '',
        },
      ],
    };

    return { headers, message };
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const isApiRequest: boolean = req.url.indexOf(LOCATION_API_URL_PART) !== -1;

        if (
          this.environment.slackWebHook &&
          ORIGINS_TO_IGNORE_THAT_SCULLY_USES.every(
            url => this.document.location.origin.indexOf(url) === -1
          ) &&
          (isApiRequest ||
            (error?.status === 400 &&
              !KNOWN_ERRORS_DETAILS.some(
                text =>
                  error?.message?.indexOf(text) !== -1 || error?.error?.detail?.indexOf(text) !== -1
              )))
        ) {
          const { message, headers } = HttpErrorInterceptor.getErrorForSlack(
            req,
            error,
            this.store.getState(),
            isApiRequest
          );
          return next.handle(
            req.clone({
              headers,
              url: this.environment.slackWebHook,
              body: message,
              method: 'POST',
            })
          );
        }

        return throwError(error);
      })
    );
  }
}
