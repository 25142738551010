import { IChoiceButton } from '@theia-cc/shared/step-ui';

export const INSULATION_TYPES: IChoiceButton[] = [
  {
    caption: 'HT.INSULATION.WINDOWS',
    icon: 'insulation-windows',
    value: 'WINDOWS',
  },
  {
    caption: 'HT.INSULATION.FACADE',
    icon: 'insulation-facade',
    value: 'FACADE',
  },
  {
    caption: 'HT.INSULATION.ROOF',
    icon: 'insulation-roof',
    value: 'ROOF',
  },
  {
    caption: 'HT.INSULATION.BASEMENT',
    icon: 'insulation-basement',
    value: 'BASEMENT',
  },
];
