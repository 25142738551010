/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SavePersonNboViewModel } from './savePersonNboViewModel';

export interface NboViewModel {
  Id?: number;
  ObjectData?: any;
  EstimatedPrice?: number;
  EstimatedMonthlyPayment?: number;
  /**
   * The comment that will be set on the newly created lead
   */
  LeadComment?: string;
  /**
   * The tag that will be set on the newly created lead
   */
  LeadTag?: string;
  PlannedImplementationTime?: NboViewModel.PlannedImplementationTimeEnum;
  LeadRating?: NboViewModel.LeadRatingEnum;
  /**
   * Whether the customer has selected callback time \"morning\". Default is false.
   */
  IsCallbackTimeMorning?: boolean;
  /**
   * Whether the customer has selected callback time \"afternoon\". Default is false.
   */
  IsCallbackTimeAfternoon?: boolean;
  /**
   * Whether the customer has selected callback time \"evening\". Default is false.
   */
  IsCallbackTimeEvening?: boolean;
  /**
   * Whether the customer has selected callback time \"weekend\". Default is false.
   */
  IsCallbackTimeWeekend?: boolean;
  Person: SavePersonNboViewModel;
  /**
   * The hubspot tracking id, will be set if available
   */
  HubspotTrackingId?: string;
  ThirdPartyCustomerNumber?: string;
}
export namespace NboViewModel {
  export type PlannedImplementationTimeEnum =
    | 'AsSoonAsPossible'
    | 'FourToTwelveMonths'
    | 'OneToTwoYears'
    | 'Unknown';
  export const PlannedImplementationTimeEnum = {
    AsSoonAsPossible: 'AsSoonAsPossible' as PlannedImplementationTimeEnum,
    FourToTwelveMonths: 'FourToTwelveMonths' as PlannedImplementationTimeEnum,
    OneToTwoYears: 'OneToTwoYears' as PlannedImplementationTimeEnum,
    Unknown: 'Unknown' as PlannedImplementationTimeEnum,
  };
  export type LeadRatingEnum = 'Zero' | 'One' | 'Two' | 'Three' | 'Four' | 'Five';
  export const LeadRatingEnum = {
    Zero: 'Zero' as LeadRatingEnum,
    One: 'One' as LeadRatingEnum,
    Two: 'Two' as LeadRatingEnum,
    Three: 'Three' as LeadRatingEnum,
    Four: 'Four' as LeadRatingEnum,
    Five: 'Five' as LeadRatingEnum,
  };
}
