import { DOCUMENT } from '@angular/common';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { AlertConfig } from 'ngx-bootstrap/alert/alert.config';
import { take } from 'rxjs/operators';

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private viewContainerRef: ViewContainerRef;

  public setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  public alert<T>(
    config: AlertConfig & { content: Content<T>; classes?: string },
    viewContainerRef: ViewContainerRef = this.viewContainerRef
  ): ComponentRef<AlertComponent> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AlertComponent);
    const ngContent = this.resolveNgContent(config.content);
    let componentRef: ComponentRef<AlertComponent>;
    let nativeElement: HTMLElement;

    if (!viewContainerRef) {
      componentRef = componentFactory.create(this.injector, ngContent);
      Object.keys(config).forEach(
        key => ((<AlertComponent>componentRef.instance)[key] = config[key])
      );
      componentRef.hostView.detectChanges();
      nativeElement = componentRef.location.nativeElement;
      this.document.body.appendChild(nativeElement);
    } else {
      viewContainerRef.clear();
      componentRef = viewContainerRef.createComponent(
        componentFactory,
        0,
        this.injector,
        ngContent
      );
      Object.keys(config).forEach(
        key => ((<AlertComponent>componentRef.instance)[key] = config[key])
      );
    }

    componentRef.instance.onClosed.pipe(take(1)).subscribe(() => {
      if (componentRef) {
        componentRef.destroy();
      }
      if (nativeElement) {
        this.document.body.removeChild(nativeElement);
      }
    });

    return componentRef;
  }

  private resolveNgContent<T>(content: Content<T>) {
    if (typeof content === 'string') {
      const element = this.document.createTextNode(content);
      return [[element]];
    }

    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(null);
      return [viewRef.rootNodes];
    }

    /** Otherwise it's a component */
    const factory = this.componentFactoryResolver.resolveComponentFactory(content);
    const componentRef = factory.create(this.injector);
    return [[componentRef.location.nativeElement]];
  }
}
