import { IBuildingSummary } from '@theia-cc/shared/models';
import { BuildingAction } from './building.action';

export const INITIAL_BUILDING_SUMMARY = {
  aboveSea: null,
  buildingEavesHeight: null,
  buildingGroundArea: null,
  buildingLevels: null,
  buildingRidgeHeight: null,
  buildingRoofArea: null,
  buildingRoofOverhangArea: null,
  buildingRoofShape: null,
  buildingVolume: null,
  buildingWallArea: null,
};

export const buildingReducer = (state: IBuildingSummary = INITIAL_BUILDING_SUMMARY, action) => {
  switch (action.type) {
    case BuildingAction.STORE_BUILDING_DATA:
      return {
        ...state,
        buildingData: action.payload,
      };
    default:
      return state;
  }
};
