import { ICar } from '../..';

export const CARS_KIA: ICar[] = [
  {
    value: 'KiaSoulEVTrend',
    caption: 'Kia Soul - EV Trend',
    icon: 'kia-soul-ev-trend-style.jpg',
  },
  {
    value: 'KiaSoulEVStyle',
    caption: 'Kia Soul - EV Style',
    icon: 'kia-soul-ev-trend-style.jpg',
  },
  {
    value: 'KiaNiro',
    caption: 'Kia Niro',
    icon: 'kia-niro.jpg',
  },
  {
    value: 'KiaOptima',
    caption: 'Kia Optima',
    icon: 'kia-optima.jpg',
  },
];
