import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IUser, IUserAddress } from '@theia-cc/shared/models';
import { NboPostContactViewModel } from '@api-cc';

@Injectable()
export class UserInfoAction {
  static readonly STORE_USER_ADDRESS = 'STORE_USER_ADDRESS';
  static readonly STORE_SERVICE_TECHNICIAN_ADDRESS = 'STORE_SERVICE_TECHNICIAN_ADDRESS';
  static readonly STORE_DEBITOR_ADDRESS = 'STORE_DEBITOR_ADDRESS';
  static readonly STORE_CAR_DEALER_ADDRESS = 'STORE_CAR_DEALER_ADDRESS';
  static readonly STORE_USER = 'STORE_USER';

  @dispatch()
  storeServiceTechnicianAddress = (
    address: NboPostContactViewModel | Partial<NboPostContactViewModel>
  ) => ({
    type: UserInfoAction.STORE_SERVICE_TECHNICIAN_ADDRESS,
    payload: address,
  });

  @dispatch()
  storeDebitorAddress = (address: NboPostContactViewModel | Partial<NboPostContactViewModel>) => ({
    type: UserInfoAction.STORE_DEBITOR_ADDRESS,
    payload: address,
  });

  @dispatch()
  storeCarDealerAddress = (
    address: NboPostContactViewModel | Partial<NboPostContactViewModel>
  ) => ({
    type: UserInfoAction.STORE_CAR_DEALER_ADDRESS,
    payload: address,
  });

  @dispatch()
  storeUserAddress = (address: IUserAddress | Partial<IUserAddress>) => ({
    type: UserInfoAction.STORE_USER_ADDRESS,
    payload: address,
  });

  @dispatch()
  storeUser = (user: IUser) => ({
    type: UserInfoAction.STORE_USER,
    payload: user,
  });
}
