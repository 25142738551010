import { ICar } from '../..';

export const CARS_RENAULT: ICar[] = [
  {
    value: 'RenaultMeganeETech',
    caption: 'Megane E-Tech Electric',
    icon: 'renault-megane-e-tech.jpg',
  },
  {
    value: 'Zoe',
    caption: 'Zoe E-Tech Electric',
    icon: 'renault-zoe.jpg',
  },
  {
    value: 'RenaultTwingoElectric',
    caption: 'Twingo E-Tech Electric',
    icon: 'renault-twingo-electric.jpg',
  },
  {
    value: 'KangooZe33',
    caption: 'Kangoo E-Tech Electric',
    icon: 'renault-kangoo-z-e.jpg',
  },
  {
    value: 'MasterZe33',
    caption: 'Master E-Tech Electric',
    icon: 'renault-master-z-e.jpg',
  },
];
