/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboEmobilityRequest {
  WhereToInstallCharger: NboEmobilityRequest.WhereToInstallChargerEnum;
  /**
   * Gets or sets the template technical title.
   */
  TemplateTechnicalTitle?: string;
  OrderType?: NboEmobilityRequest.OrderTypeEnum;
  SmartEnergyConnection?: NboEmobilityRequest.SmartEnergyConnectionEnum;
  ParkingType?: NboEmobilityRequest.ParkingTypeEnum;
  /**
   * Defines the number of parking spaces to be equipped in the residential area
   */
  NumberOfParkingspacesToBeEquippedResidential?: number;
  ChargingPower?: NboEmobilityRequest.ChargingPowerEnum;
  AvailablePowerOutlet?: NboEmobilityRequest.AvailablePowerOutletEnum;
  /**
   * Defines the distance from the fuse box to the charging station
   */
  DistanceFuseboxToChargingStation?: number;
  /**
   * Defines the distance from the fuse box to the flat ribbon cable
   */
  DistanceFuseboxToFlatRibbonCable?: number;
  /**
   * Defines the total number of parking spaces at the property
   */
  TotalNumberOfParkingspaces?: number;
  /**
   * Defines the number of parking spaces prepared to be electrified
   */
  NumberOfParkingspacesPreparedToBeElectrified?: number;
  /**
   * Defines the number of parking spaces to be equipped in the commercial area
   */
  NumberOfParkingspacesToBeEquippedCommercial?: number;
  /**
   * Defines the number of ribbon cables
   */
  NumberOfRibbonCables?: number;
  /**
   * Defines the number of meters between the ribbon cables
   */
  MeterConnectionBetweenRibbonCables?: number;
  /**
   * Defines the number of powerline modules
   */
  NumberOfPlc?: number;
  /**
   * The title of the variant that will be created
   */
  VariantTitle: string;
  /**
   * If this is true and the variant is persisted, the user gets an email with the offer
   */
  IsSendMail?: boolean;
}
export namespace NboEmobilityRequest {
  export type WhereToInstallChargerEnum = 'Wtic_IndividualSolution' | 'Wtic_CommunitySolution';
  export const WhereToInstallChargerEnum = {
    IndividualSolution: 'Wtic_IndividualSolution' as WhereToInstallChargerEnum,
    CommunitySolution: 'Wtic_CommunitySolution' as WhereToInstallChargerEnum,
  };
  export type OrderTypeEnum = 'AccordingToCost' | 'FlatRate';
  export const OrderTypeEnum = {
    AccordingToCost: 'AccordingToCost' as OrderTypeEnum,
    FlatRate: 'FlatRate' as OrderTypeEnum,
  };
  export type SmartEnergyConnectionEnum = 'Desired' | 'Undesired';
  export const SmartEnergyConnectionEnum = {
    Desired: 'Desired' as SmartEnergyConnectionEnum,
    Undesired: 'Undesired' as SmartEnergyConnectionEnum,
  };
  export type ParkingTypeEnum = 'Adjacent' | 'NotAdjacent' | 'ParkingOpenSpace' | 'Other';
  export const ParkingTypeEnum = {
    Adjacent: 'Adjacent' as ParkingTypeEnum,
    NotAdjacent: 'NotAdjacent' as ParkingTypeEnum,
    ParkingOpenSpace: 'ParkingOpenSpace' as ParkingTypeEnum,
    Other: 'Other' as ParkingTypeEnum,
  };
  export type ChargingPowerEnum = 'Eleven' | 'TwentyTwo';
  export const ChargingPowerEnum = {
    Eleven: 'Eleven' as ChargingPowerEnum,
    TwentyTwo: 'TwentyTwo' as ChargingPowerEnum,
  };
  export type AvailablePowerOutletEnum =
    | 'T25'
    | 'CEE16_32_400V'
    | 'ChargingStationConsisting'
    | 'None';
  export const AvailablePowerOutletEnum = {
    T25: 'T25' as AvailablePowerOutletEnum,
    CEE1632400V: 'CEE16_32_400V' as AvailablePowerOutletEnum,
    ChargingStationConsisting: 'ChargingStationConsisting' as AvailablePowerOutletEnum,
    None: 'None' as AvailablePowerOutletEnum,
  };
}
