import { ICar } from '../..';

export const CARS_FORD: ICar[] = [
  {
    value: 'FordExplorer',
    caption: 'Ford Explorer PHEV',
    icon: 'ford-explorer.jpg',
  },
  {
    value: 'FordKuga',
    caption: 'Ford Kuga PHEV',
    icon: 'ford-kuga.jpg',
  },
  {
    value: 'FordMustangMachE',
    caption: 'Ford Mustang Mach-E Extended Range',
    icon: 'ford-mustang-mach-e.jpg',
  },
  {
    value: 'FordMustangMachESR',
    caption: 'Ford Mustang Mach-E Standard Range',
    icon: 'ford-mustang-mach-e.jpg',
  },
  {
    value: 'FordTourneo',
    caption: 'Ford Custom (Transit/Tourneo) PHEV',
    icon: 'ford-tourneo.jpg',
  },
  {
    value: 'FordETransit',
    caption: 'Ford E-Transit',
    icon: 'ford-e-transit.jpg',
  },
];
