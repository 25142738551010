import { ThemeProperty } from '@theia-cc/shared/models';

export const THEME_TOKENS: ThemeProperty[] = [
  { property: 'linkColor', cssVariable: '--link-color-cms' },
  { property: 'titleColor', cssVariable: '--title-color-cms' },
  { property: 'iconColor', cssVariable: '--icon-color-cms' },
  { property: 'progressBarActiveColor', cssVariable: '--progress-bar-active-cms' },
  { property: 'progressBarInactiveColor', cssVariable: '--progress-bar-inactive-cms' },
  { property: 'headerPhoneIconColor', cssVariable: '--header-phone-icon-color-cms' },
  { property: 'headerBarBorderBottomColor', cssVariable: '--header-bar-border-bottom-color-cms' },
  { property: 'footerColor', cssVariable: '--footer-color-cms' },
  { property: 'footerBackgroundColor', cssVariable: '--footer-background-color-cms' },
  { property: 'footerArrowColor', cssVariable: '--footer-arrow-color-cms' },
  { property: 'footerShareButtonColor', cssVariable: '--footer-share-button-color-cms' },
  { property: 'choiceButtonColor', cssVariable: '--choice-button-color-cms' },
  {
    property: 'choiceButtonBackgroundColor',
    cssVariable: '--choice-button-background-color-cms',
  },
  { property: 'choiceButtonTextColor', cssVariable: '--choice-button-text-color-cms' },
  { property: 'choiceButtonTextHoverColor', cssVariable: '--choice-button-text-hover-color-cms' },
  { property: 'choiceButtonIconHoverColor', cssVariable: '--choice-button-icon-hover-color-cms' },
  {
    property: 'choiceButtonBackgroundHoverColor',
    cssVariable: '--choice-button-background-hover-color-cms',
  },
  {
    property: 'choiceButtonBorderHoverColor',
    cssVariable: '--choice-button-border-hover-color-cms',
  },
  {
    property: 'customRadioButtonBorderColor',
    cssVariable: '--custom-radio-button-border-color-cms',
  },
  {
    property: 'customRadioButtonActiveColor',
    cssVariable: '--custom-radio-button-active-color-cms',
  },
  { property: 'checkboxActiveColor', cssVariable: '--checkbox-active-color-cms' },
  { property: 'tabButtonColor', cssVariable: '--tab-button-color-cms' },
  { property: 'tabButtonBackgroundColor', cssVariable: '--tab-button-background-color-cms' },
  { property: 'tabButtonActiveColor', cssVariable: '--tab-button-active-color-cms' },
  { property: 'tabButtonLeftBorderColor', cssVariable: '--tab-button-left-border-color-cms' },
  { property: 'modalHeaderColor', cssVariable: '--modal-header-color-cms' },
  { property: 'pricingTableBorderColor', cssVariable: '--pricing-table-border-color-cms' },
  {
    property: 'pricingTableTotalBorderColor',
    cssVariable: '--pricing-table-total-border-color-cms',
  },
  { property: 'templateImageButtonColor', cssVariable: '--template-image-button-color-cms' },
  {
    property: 'offerPreviewSidebarBackgroundColor',
    cssVariable: '--offer-preview-sidebar-background-color-cms',
  },
  { property: 'widgetBoxTextColor', cssVariable: '--widget-box-text-color-cms' },
  { property: 'offerPreviewTitleColor', cssVariable: '--offer-preview-title-color-cms' },
  { property: 'offerPreviewTabTitleColor', cssVariable: '--offer-preview-tab-title-color-cms' },
  {
    property: 'tabButtonActiveBackgroundColor',
    cssVariable: '--tab-button-active-background-color-cms',
  },
  {
    property: 'arrowIconFillColor',
    cssVariable: '--arrow-icon-fill-color-cms',
  },
];
