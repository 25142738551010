import { IAppStateHt } from '@theia-cc/ht/state';
import { ICollectedDataPv } from '@theia-cc/pv/state';
import { caseAgnosticQueryParam, parseFormString } from '@theia-cc/shared/helpers';
import { IAppStatePrimeo } from '../store/reducers';

export function isLargeSystem({ collectedData }: IAppStateHt): boolean {
  return collectedData.largeSystem;
}

export function notPvxRoof({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return !collectedData.isPvx;
}

export function pvxRoof({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.isPvx;
}

export function geometryNotAvailable({
  collectedData,
}: {
  collectedData: ICollectedDataPv;
}): boolean {
  return !collectedData.userAddress || !collectedData.userAddress.hasGeometry;
}

export function geometryAvailable({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.userAddress && collectedData.userAddress.hasGeometry;
}

export function notTrade(tradeTypes: string[]) {
  return ({ config: { trades } }: IAppStatePrimeo): boolean =>
    tradeTypes.every(type => !trades.includes(type));
}

export function identicalStepWithTrade(tradeTypes: string[]) {
  return ({ config: { trades } }: IAppStatePrimeo): boolean =>
    tradeTypes.some(type => trades.includes(type));
}

export function roofAreaPreset({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.roofArea !== null && Number.isFinite(collectedData.roofArea);
}

export function b2bWithPvx({
  config: { queryParams },
  collectedData: { isPvx },
}: IAppStatePrimeo): boolean {
  return !!parseFormString.boolean(caseAgnosticQueryParam(queryParams)('b2b')) && isPvx;
}

export function notB2b({ config: { queryParams } }: IAppStatePrimeo): boolean {
  return !parseFormString.boolean(caseAgnosticQueryParam(queryParams)('b2b'));
}
