import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BlueOfficeCacheManagementService } from './api/blueOfficeCacheManagement.service';
import { BuildingPlayerApiAccessService } from './api/buildingPlayerApiAccess.service';
import { NboEmobilityService } from './api/nboEmobility.service';
import { NboGenericService } from './api/nboGeneric.service';
import { NboHeatingService } from './api/nboHeating.service';
import { NboLeadService } from './api/nboLead.service';
import { NboLeadAddressService } from './api/nboLeadAddress.service';
import { NboLeadNotificationSubscriptionService } from './api/nboLeadNotificationSubscription.service';
import { NboPvService } from './api/nboPv.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    BlueOfficeCacheManagementService,
    BuildingPlayerApiAccessService,
    NboEmobilityService,
    NboGenericService,
    NboHeatingService,
    NboLeadService,
    NboLeadAddressService,
    NboLeadNotificationSubscriptionService,
    NboPvService,
  ],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
