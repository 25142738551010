import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  CONSTANTS,
  ENVIRONMENT,
  getCurrentStepFromUrl,
  IConstants,
  IEnvironment,
  isOfferPreviewStep,
} from '@theia-cc/shared/helpers';

@Injectable({ providedIn: 'root' })
export class EntryStepGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    @Inject(CONSTANTS) private constants: IConstants
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const currentStep = getCurrentStepFromUrl(state.url);

    return (
      !this.environment.production ||
      this.constants.APP_ALLOWED_ENTRY_STEPS.includes(currentStep) ||
      isOfferPreviewStep(currentStep) ||
      this.router.createUrlTree([this.constants.APP_MAIN_ENTRY_STEP], {
        queryParams: route.queryParams,
      })
    );
  }
}
