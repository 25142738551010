import { IDeviceInfo } from '@theia-cc/shared/models';
import { DeviceAction } from './device.action';

export const INITIAL_DEVICE = {
  device: null,
  browser: null,
};

export const deviceReducer = (state: IDeviceInfo = INITIAL_DEVICE, action) => {
  switch (action.type) {
    case DeviceAction.SET_DEVICE:
      const device = action.payload;
      device.isMobile = device.os.includes('ios') || device.os.includes('android');
      return {
        ...state,
        ...device,
      };
    default:
      return state;
  }
};
