import { NboEmobilityRequest } from '@api-cc';

export interface IChargingPowerTypeItem {
  id: NboEmobilityRequest.ChargingPowerEnum;
  name: string;
}

export const CHARGING_POWER_TYPE_ITEMS: IChargingPowerTypeItem[] = [
  {
    id: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    name: 'EM.OFFERPREVIEW.OPTIONS.CHARGING_POWER_11',
  },
  {
    id: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    name: 'EM.OFFERPREVIEW.OPTIONS.CHARGING_POWER_22',
  },
];

export const CHARGING_POWER_TYPE_NUMBERS: {
  [key in NboEmobilityRequest.ChargingPowerEnum]: number;
} = {
  Eleven: 11,
  TwentyTwo: 22,
};

/*
  This type was previously present on the NBO endpoint, but got removed from there.
  EM-CC internally, however, we still use it but we don't send it to NBO anymore.
*/
export type CheckTypeEnum = 'RemoteCheck' | 'OnSiteCheck';
export const CheckTypeEnum = {
  RemoteCheck: 'RemoteCheck' as CheckTypeEnum,
  OnSiteCheck: 'OnSiteCheck' as CheckTypeEnum,
};

/*
  Enums defined in the cms which are used on the template.
  If you add a new enum here and want to use it on the template and make it configurable via cms, you need to add it to CMS -> EM -> Configuration -> Enums inside the cms.
*/
export enum CmsEnumsEm {
  OrderTypeEnum = 'OrderTypeEnum',
}

export const defaultPartnerId = 'default';

export const WHERE_TO_INSTALL_CHARGER_MORE_THAN_60 = 'MORE_THAN_60';
