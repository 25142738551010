import { ICar } from '../..';

export const CARS_PORSCHE: ICar[] = [
  {
    value: 'PorscheCayenne',
    caption: 'Porsche Cayenne',
    icon: 'porsche-cayenne.jpg',
  },
  {
    value: 'PorschePanamera',
    caption: 'Porsche Panamera',
    icon: 'porsche-panamera.jpg',
  },
  {
    value: 'PorscheTaycan',
    caption: 'Porsche Taycan',
    icon: 'porsche-taycan.jpg',
  },
];
