import { ICar } from '../..';

export const CARS_AUDI: ICar[] = [
  {
    value: 'AudiA3',
    caption: 'Audi A3',
    icon: 'audi-a3.jpg',
  },
  {
    value: 'AudiQ5',
    caption: 'Audi Q5 TFSI e',
    icon: 'audi-q5.jpg',
  },
  {
    value: 'AudiEtron55Quattro',
    caption: 'Audi e-tron',
    icon: 'audi-e-tron.jpg',
  },
  {
    value: 'AudiETronSportback',
    caption: 'Audi e-tron Sportback',
    icon: 'audi-e-tron-sportback.jpg',
  },
  {
    value: 'AudiA6',
    caption: 'Audi A6 TFSI e',
    icon: 'audi-a6.jpg',
  },
  {
    value: 'AudiA7',
    caption: 'Audi A7 Sportback TFSI e',
    icon: 'audi-a7.jpg',
  },
  {
    value: 'AudiA8',
    caption: 'Audi A8 TFSI e',
    icon: 'audi-a8.jpg',
  },
  {
    value: 'AudiQ4',
    caption: 'Audi Q4',
    icon: 'audi-q4.jpg',
  },
  {
    value: 'AudiQ7',
    caption: 'Audi Q7',
    icon: 'audi-q7.jpg',
  },
];
