import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { parseFormString } from '@theia-cc/shared/helpers';

export function getFirstStep(trades: string[] = [], b2b: boolean): string {
  return b2b
    ? 'commercialpowerusage'
    : trades.includes('pv') || !trades.length
    ? 'familymembercount-pv'
    : trades.includes('ht') || trades.includes('hp')
    ? 'heatingtype'
    : 'number-of-parkingspaces';
}

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): UrlTree {
    const { trade, b2b } = next.queryParams;

    return this.router.createUrlTree(
      [getFirstStep(parseFormString.array(trade), parseFormString.boolean(b2b))],
      {
        queryParams: next.queryParams,
      }
    );
  }
}
