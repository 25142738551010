/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { NboLeadAddressViewModel } from '../model/nboLeadAddressViewModel';
import { NboPostContactViewModel } from '../model/nboPostContactViewModel';
import { NboPutAddressRequest } from '../model/nboPutAddressRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class NboLeadAddressService {
  protected basePath = 'https://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param leadPublicId
   * @param addressType
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPostPersonAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPostContactViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPostPersonAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPostContactViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPostPersonAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPostContactViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPostPersonAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPostContactViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPostPersonAdditionalAddress.'
      );
    }

    if (addressType === null || addressType === undefined) {
      throw new Error(
        'Required parameter addressType was null or undefined when calling nboLeadAddressPostPersonAdditionalAddress.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (addressType !== undefined && addressType !== null) {
      queryParameters = queryParameters.set('addressType', <any>addressType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain',
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(
        String(leadPublicId)
      )}/addresses/additional`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param leadPublicId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPostPersonDebitorAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPostPersonDebitorAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPostPersonDebitorAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPostPersonDebitorAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPostPersonDebitorAddress.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain',
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(
        String(leadPublicId)
      )}/addresses/debitor`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param leadPublicId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPostPersonObjectAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPostPersonObjectAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPostPersonObjectAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPostPersonObjectAddress(
    leadPublicId: string,
    body?: NboPostContactViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPostPersonObjectAddress.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain',
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(String(leadPublicId))}/addresses/object`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param leadPublicId
   * @param addressType
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPutAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPutAddressRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPutAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPutAddressRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPutAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPutAddressRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPutAdditionalAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    body?: NboPutAddressRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPutAdditionalAddress.'
      );
    }

    if (addressType === null || addressType === undefined) {
      throw new Error(
        'Required parameter addressType was null or undefined when calling nboLeadAddressPutAdditionalAddress.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (addressType !== undefined && addressType !== null) {
      queryParameters = queryParameters.set('addressType', <any>addressType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain',
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(
        String(leadPublicId)
      )}/addresses/additional`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param leadPublicId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPutDebtorAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPutDebtorAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPutDebtorAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPutDebtorAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPutDebtorAddress.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain',
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(
        String(leadPublicId)
      )}/addresses/debitor`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param leadPublicId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPutObjectAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPutObjectAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPutObjectAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPutObjectAddress(
    leadPublicId: string,
    body?: NboPutAddressRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPutObjectAddress.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'text/plain',
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(String(leadPublicId))}/addresses/object`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param leadPublicId
   * @param addressType
   * @param dealer
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboLeadAddressPutPorscheAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    dealer:
      | 'Aargau'
      | 'Basel'
      | 'Bern'
      | 'Genf'
      | 'Lausanne'
      | 'Locarno'
      | 'Lugano'
      | 'Maienfeld'
      | 'ObererZuerichsee'
      | 'Sierre'
      | 'StGallen'
      | 'Winterthur'
      | 'Zug'
      | 'ZuerichRiesbach'
      | 'ZuerichSchlieren',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadAddressViewModel>;
  public nboLeadAddressPutPorscheAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    dealer:
      | 'Aargau'
      | 'Basel'
      | 'Bern'
      | 'Genf'
      | 'Lausanne'
      | 'Locarno'
      | 'Lugano'
      | 'Maienfeld'
      | 'ObererZuerichsee'
      | 'Sierre'
      | 'StGallen'
      | 'Winterthur'
      | 'Zug'
      | 'ZuerichRiesbach'
      | 'ZuerichSchlieren',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadAddressViewModel>>;
  public nboLeadAddressPutPorscheAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    dealer:
      | 'Aargau'
      | 'Basel'
      | 'Bern'
      | 'Genf'
      | 'Lausanne'
      | 'Locarno'
      | 'Lugano'
      | 'Maienfeld'
      | 'ObererZuerichsee'
      | 'Sierre'
      | 'StGallen'
      | 'Winterthur'
      | 'Zug'
      | 'ZuerichRiesbach'
      | 'ZuerichSchlieren',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadAddressViewModel>>;
  public nboLeadAddressPutPorscheAddress(
    leadPublicId: string,
    addressType:
      | 'Contact'
      | 'Object'
      | 'Debitor'
      | 'Administration'
      | 'Architect'
      | 'AuthorizedPerson'
      | 'Authorizer'
      | 'BrineWaterDrilling'
      | 'BuildingPhysicist'
      | 'ContactForAccess'
      | 'Delivery'
      | 'Dismantling'
      | 'Electrician'
      | 'ElectroPlanner'
      | 'ExternalAssemblyCompany'
      | 'HeatingEngineer'
      | 'Isolation'
      | 'Janitor'
      | 'LandOwner'
      | 'MasterBuilder'
      | 'Painter'
      | 'Plumber'
      | 'PowerCompany'
      | 'Roofer'
      | 'RoofTenant'
      | 'Scaffolder'
      | 'SiteEngineer'
      | 'SiteOwner'
      | 'SiteSupervisor'
      | 'SubsidyReceiver'
      | 'Tinsmith'
      | 'Supplier'
      | 'Dealer'
      | 'ServiceTechnician'
      | 'CarDealer'
      | 'PlanningOffice'
      | 'VentilationSystemInstaller'
      | 'SapDebtor'
      | 'SiteAddress'
      | 'FormerHeatingEngineer',
    dealer:
      | 'Aargau'
      | 'Basel'
      | 'Bern'
      | 'Genf'
      | 'Lausanne'
      | 'Locarno'
      | 'Lugano'
      | 'Maienfeld'
      | 'ObererZuerichsee'
      | 'Sierre'
      | 'StGallen'
      | 'Winterthur'
      | 'Zug'
      | 'ZuerichRiesbach'
      | 'ZuerichSchlieren',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadPublicId === null || leadPublicId === undefined) {
      throw new Error(
        'Required parameter leadPublicId was null or undefined when calling nboLeadAddressPutPorscheAddress.'
      );
    }

    if (addressType === null || addressType === undefined) {
      throw new Error(
        'Required parameter addressType was null or undefined when calling nboLeadAddressPutPorscheAddress.'
      );
    }

    if (dealer === null || dealer === undefined) {
      throw new Error(
        'Required parameter dealer was null or undefined when calling nboLeadAddressPutPorscheAddress.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (addressType !== undefined && addressType !== null) {
      queryParameters = queryParameters.set('addressType', <any>addressType);
    }
    if (dealer !== undefined && dealer !== null) {
      queryParameters = queryParameters.set('dealer', <any>dealer);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<NboLeadAddressViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(
        String(leadPublicId)
      )}/addresses/porsche-address`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
