<div class="app-progress-bar">
  <div class="app-progress-bar__bar-container">
    <div class="app-progress-bar__bar">
      <div class="app-progress-bar__bar-inactive"></div>
      <div
        class="app-progress-bar__bar-active"
        [ngStyle]="{
          width: (progress < 100 ? (progress < 60 ? (progress / 60) * 50 : progress) : 100) + '%'
        }"></div>
    </div>
    <div
      [ngClass]="{
        'app-progress-bar__milestone': true,
        'app-progress-bar__milestone-left': true,
        'app-progress-bar__milestone--active': progress > 0
      }"></div>
    <div
      [ngClass]="{
        'app-progress-bar__milestone': true,
        'app-progress-bar__milestone-center': true,
        'app-progress-bar__milestone--active': progress > 60
      }"></div>
    <div
      [ngClass]="{
        'app-progress-bar__milestone': true,
        'app-progress-bar__milestone-right': true,
        'app-progress-bar__milestone--active': progress === 100
      }"></div>
  </div>
  <div class="app-progress-bar__sections">
    <div>{{ 'COMPONENT.PROGRESS_BAR.SECTION_1' | translate }}</div>
    <div>{{ 'COMPONENT.PROGRESS_BAR.SECTION_2' | translate }}</div>
    <div>{{ 'COMPONENT.PROGRESS_BAR.SECTION_3' | translate }}</div>
  </div>
</div>
