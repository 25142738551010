import { ICar } from '../..';

export const CARS_MITSUBISHI: ICar[] = [
  {
    value: 'MitsubishiiMiEV',
    caption: 'Mitsubishi i-MiEV',
    icon: 'mitsubishi-i-miev.jpg',
    bidirectional: true,
  },
  {
    value: 'MitsubishiOutlander',
    caption: 'Mitsubishi Outlander',
    icon: 'mitsubishi-outlander.jpg',
    bidirectional: true,
  },
];
