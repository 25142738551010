import { ICar } from '../..';

export const CARS_NISSAN: ICar[] = [
  {
    value: 'NissanENV200',
    caption: 'Nissan e-NV200',
    icon: 'nissan-e-nv200.jpg',
    bidirectional: true,
  },
  {
    value: 'NissanLeaf',
    caption: 'Nissan Leaf',
    icon: 'nissan-leaf.jpg',
    bidirectional: true,
  },
  {
    value: 'NissanAriya',
    caption: 'Nissan Ariya',
    icon: 'nissan-ariya.jpg',
  },
];
