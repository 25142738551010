import { select } from '@angular-redux/store';
import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CONSTANTS,
  IConstants,
  isOfferPreviewStep,
  WizardProductKeyEnum,
} from '@theia-cc/shared/helpers';
import { IFooterLinks, INavBarBase, INavbarButton } from '@theia-cc/shared/models';
import {
  footerLinksSelector,
  IAppStateBase,
  isFirstStepSelector,
  isShowBackButtonSelector,
  navbarSelector,
  photoProtocolLinkSelector,
  WizardAction,
} from '@theia-cc/shared/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export const isShowOfferPreviewButtonSelector = (state: IAppStateBase, path: string): boolean =>
  isOfferPreviewStep(path) && state.wizard.navbar.showShareButton;

@Component({
  selector: 'app-footer-floating',
  templateUrl: './footer-floating.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FooterFloatingComponent {
  constructor(
    private wizardAction: WizardAction,
    private deviceService: DeviceDetectorService,
    private router: Router,
    @Inject(CONSTANTS) private constants: IConstants
  ) {
    this.defaultButtons();
  }

  @select(photoProtocolLinkSelector)
  readonly photoProtocolLinkSelector$: Observable<{
    href: string;
    action: string;
  }>;

  @select(navbarSelector)
  readonly navbar$: Observable<INavBarBase>;
  // @ts-ignore
  readonly buttons$: Observable<INavbarButton[]> = this.navbar$.pipe(
    tap(navbar => {
      this.primaryButtonDisabled = navbar.primaryButton.disabled;
    }),
    map(navbar => {
      return [navbar.primaryButton, navbar.secondaryButton];
    })
  );

  @select(isFirstStepSelector)
  readonly isFirstStep$: Observable<boolean>;
  primaryButtonDisabled: boolean;

  @select((state: IAppStateBase) => state)
  private state$: Observable<IAppStateBase>;
  // @ts-ignore
  public newDesign$: Observable<boolean> = this.state$.pipe(
    map(
      state =>
        (state?.config?.queryParams?.partnerId === 'helion' ||
          state?.config?.queryParams?.partnerId === 'migrosbank') &&
        this.constants.wizardProductKey === WizardProductKeyEnum.pv
    )
  );
  // @ts-ignore
  public isShowOfferPreviewButton$: Observable<boolean> = this.state$.pipe(
    map((state: IAppStateBase) => isShowOfferPreviewButtonSelector(state, this.router.url))
  );

  @select(isShowBackButtonSelector)
  readonly isShowBackButton$: Observable<boolean>;

  public notDigitalUploadStep$: Observable<boolean> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.urlAfterRedirects),
    map((url: string) => !url.includes('digital-upload'))
  );

  @select(footerLinksSelector)
  readonly footerLinks$: Observable<IFooterLinks>;

  isPhoneDevice: boolean = this.deviceService.isMobile();
  @Output() clickAction: EventEmitter<string> = new EventEmitter<string>();

  // @Input() generateShareLink: () => string = () => '';

  defaultButtons() {
    this.wizardAction.setNavbarPrimaryButtonDefault();
    this.wizardAction.setNavbarSecondaryButtonDefault();
  }

  buttonClick(action: string, disabled) {
    if (disabled) return;
    this.clickAction.emit(action);
  }
}
