import { ICar } from '../..';

export const CARS_HONDA: ICar[] = [
  {
    value: 'HondaE',
    caption: 'Honda E',
    icon: 'honda-e.jpg',
    bidirectional: true,
  },
];
