import { IAppStateHt } from '@theia-cc/ht/state';
import { caseAgnosticQueryParam, isEmptyValue, isOfferPreviewStep } from '@theia-cc/shared/helpers';
import { IFooterLinks } from '@theia-cc/shared/models';
import { IAppStateBase } from './shared-store.model';
import { SharedStoreEffect } from './shared-store.effect';

export const contactPhoneSelector = (state: IAppStateBase) => {
  const language = state.currentLanguage || '';
  return state.config.org.contactPhone ? state.config.org.contactPhone[language] : '';
};

export const isFirstStepSelector = (state: IAppStateBase) => {
  const { currentStep, lastStep } = state.wizard;

  const steps = state.config.steps;
  if (currentStep && lastStep) {
    return steps.findIndex(el => el.name === currentStep) === 0;
  }
  return false;
};

export const isCurrentStepOfferPreviewSelector = (state: IAppStateBase) => {
  return isOfferPreviewStep(state.wizard.currentStep);
};

export const isCurrentStepCallbackSelector = (state: IAppStateBase) => {
  return state.wizard?.currentStep === 'contact';
};

export const goToCallbackDisabledSelector = (state: IAppStateBase) => {
  return (
    isOfferPreviewStep(state.wizard.currentStep) ||
    state.wizard?.currentStep?.indexOf('contact') !== -1 ||
    state.wizard?.currentStep === 'consulting'
  );
};

export const isShowBackButtonSelector = (state: IAppStateBase): boolean => {
  const { isSharedOfferPreview } = state.wizard;
  const isFirstStep = isFirstStepSelector(state);
  return !(isSharedOfferPreview && isCurrentStepOfferPreviewSelector(state)) && !isFirstStep;
};

export const footerLinksSelector = (state: IAppStateBase): IFooterLinks => {
  const {
    config: { footerLinks },
    currentLanguage,
  } = state;
  return currentLanguage && footerLinks ? footerLinks[currentLanguage] : null;
};

export const navbarSelector = (state: IAppStateBase) => state.wizard.navbar;

export const enableCallbackHeaderSelector = (state: IAppStateBase) =>
  state.config.envVariables.enableCallbackHeader !== false;

export const enablePhoneHeaderSelector = (state: IAppStateBase) =>
  state.config.envVariables.enablePhoneHeader;

export const heroHeaderImageUrlSelector = (state: IAppStateBase) =>
  state.config.envVariables.heroHeaderImageUrl;

export const disableLanguageChoiceSelector = (state: IAppStateBase) =>
  state.config.envVariables.disableLanguageChoice;

export const progressStateSelector = (state: IAppStateBase) => {
  const { currentStep, lastStep } = state.wizard;
  const steps = state.config.steps;
  const allAvailableSteps = steps.filter(step =>
    state.collectedData ? SharedStoreEffect.canNavigate(step, state) : true
  );
  if (currentStep && lastStep) {
    const currStepIdx = allAvailableSteps.findIndex(el => el.name === currentStep);
    const lastStepIdx = allAvailableSteps.findIndex(el => el.name === lastStep);

    return currentStep && lastStep && currStepIdx > -1 && lastStepIdx > -1
      ? Math.min(Math.round(((currStepIdx + 1) / (lastStepIdx + 1)) * 100), 100)
      : 0;
  }
  return 0;
};

export const logoSelector = (state: IAppStateBase): string[] => {
  const {
    org: { logoImageUrl },
  } = state.config;
  return Array.isArray(logoImageUrl) ? logoImageUrl : [logoImageUrl];
};

export const logoLinkUrlSelector =
  wizardProductKey =>
  (state: IAppStateBase): string => {
    const { currentLanguage } = state;
    const isLanguageAlreadySet = currentLanguage !== null;
    if (!isLanguageAlreadySet) {
      return '';
    }
    const logoLinkUrl = state.config.org.logoLinkUrl[currentLanguage];
    if (!logoLinkUrl) {
      const { protocol, hostname, port } = window.location;
      const getCaseAgnosticQueryParam = caseAgnosticQueryParam(state.config.queryParams);
      const partnerId = getCaseAgnosticQueryParam('partnerId') || '';
      const origin = getCaseAgnosticQueryParam('origin') || '';
      const step = state.config.steps[0] !== null ? state.config.steps[0].name : '';
      return `${protocol}//${hostname}:${port}/${wizardProductKey}/${step}?partnerId=${partnerId}&origin=${origin}`;
    }
    return logoLinkUrl || '';
  };

export const languageSelector = (state: IAppStateBase) => state.currentLanguage;

export function getQueryParamStringHt(params: Record<string, string>): string {
  const paramSet = [];
  if (Object.keys(params).length > 0) {
    for (const property in params) {
      if (params.hasOwnProperty(property)) {
        const value = params[property];
        if (!isEmptyValue(value)) {
          paramSet.push(`${property}=${params[property]}`);
        }
      }
    }
  }
  return paramSet.length > 0 ? paramSet.join('&') : '';
}

export const photoProtocolLinkSelector = (state: IAppStateHt): { href: string; action: string } => {
  const {
    currentLanguage,
    config: {
      queryParams: { partnerId, origin },
    },
    wizard: {
      currentStep,
      isSharedOfferPreview,
      navbar: { photoProtocolLinkAction },
    },
    collectedData: { nboLeadIdHt: leadId, user: { firstName, lastName, email } } = { user: {} },
  } = state;

  return undefined;

  // return partnerId === 'mt' &&
  //   origin === 'service' &&
  //   leadId &&
  //   (currentStep === 'contactsuccess' ||
  //     currentStep === 'contactlargesystem' ||
  //     isOfferPreviewStep(currentStep) ||
  //     (isSharedOfferPreview && currentStep === 'contactcondensed'))
  //   ? {
  //       href: `https://upload.offerten-rechner.ch/meiertobler/${currentLanguage}/wp.html?leadId=${leadId}&${getQueryParamStringHt(
  //         state,
  //       )}`,
  //       action: photoProtocolLinkAction,
  //     }
  //   : undefined;
};
