import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { PartnerIdService } from './partner-id.service';

@Injectable()
export class TranslateWithPartnerIdParser extends TranslateDefaultParser {
  constructor(private partnerIdService: PartnerIdService) {
    super();
  }

  getValue(target: any, key: string): any {
    const partnerOverwriteKey = `${this.partnerIdService.partnerId?.toUpperCase()}.${key}`;
    const partnerOverwriteValue = super.getValue(target, partnerOverwriteKey);
    return partnerOverwriteValue !== undefined
      ? partnerOverwriteValue
      : super.getValue(target, key);
  }
}
