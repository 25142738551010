/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { FileViewModel } from '../model/fileViewModel';
import { NboConfigurationViewModel } from '../model/nboConfigurationViewModel';
import { NboHeadingViewModel } from '../model/nboHeadingViewModel';
import { NboLeadViewModel } from '../model/nboLeadViewModel';
import { NboViewModel } from '../model/nboViewModel';
import { PatchNboViewModel } from '../model/patchNboViewModel';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class NboGenericService {
  protected basePath = 'https://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericGetNboConfiguration(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboConfigurationViewModel>;
  public nboGenericGetNboConfiguration(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboConfigurationViewModel>>;
  public nboGenericGetNboConfiguration(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboConfigurationViewModel>>;
  public nboGenericGetNboConfiguration(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<NboConfigurationViewModel>(
      `${this.basePath}/api/nbo/configuration`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets the nbo heading by public lead id.
   *
   * @param publicId The public identifier.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericGetNboHeading(
    publicId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboHeadingViewModel>;
  public nboGenericGetNboHeading(
    publicId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboHeadingViewModel>>;
  public nboGenericGetNboHeading(
    publicId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboHeadingViewModel>>;
  public nboGenericGetNboHeading(
    publicId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (publicId === null || publicId === undefined) {
      throw new Error(
        'Required parameter publicId was null or undefined when calling nboGenericGetNboHeading.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<NboHeadingViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(String(publicId))}/heading`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets the nbo lead by public id.
   *
   * @param publicId The public identifier.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericGetNboLead(
    publicId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboLeadViewModel>;
  public nboGenericGetNboLead(
    publicId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboLeadViewModel>>;
  public nboGenericGetNboLead(
    publicId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboLeadViewModel>>;
  public nboGenericGetNboLead(
    publicId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (publicId === null || publicId === undefined) {
      throw new Error(
        'Required parameter publicId was null or undefined when calling nboGenericGetNboLead.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<NboLeadViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(String(publicId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This is a generic endpoint.  Retrieves a specific file of a lead as a string using a file key  If the file or lead do not exist, it returns an empty model
   *
   * @param publicId the public id of the lead
   * @param fileKey specific key referencing a file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericGetPublicLeadFile(
    publicId: string,
    fileKey:
      | 'SalesPicture'
      | 'SalesSignature'
      | 'ProjectManagerPicture'
      | 'ProjectManagerSignature'
      | 'OwnerPicture'
      | 'OwnerSignature'
      | 'CustomerRepPicture'
      | 'CustomerRepSignature'
      | 'SituationImage3D',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FileViewModel>;
  public nboGenericGetPublicLeadFile(
    publicId: string,
    fileKey:
      | 'SalesPicture'
      | 'SalesSignature'
      | 'ProjectManagerPicture'
      | 'ProjectManagerSignature'
      | 'OwnerPicture'
      | 'OwnerSignature'
      | 'CustomerRepPicture'
      | 'CustomerRepSignature'
      | 'SituationImage3D',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FileViewModel>>;
  public nboGenericGetPublicLeadFile(
    publicId: string,
    fileKey:
      | 'SalesPicture'
      | 'SalesSignature'
      | 'ProjectManagerPicture'
      | 'ProjectManagerSignature'
      | 'OwnerPicture'
      | 'OwnerSignature'
      | 'CustomerRepPicture'
      | 'CustomerRepSignature'
      | 'SituationImage3D',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FileViewModel>>;
  public nboGenericGetPublicLeadFile(
    publicId: string,
    fileKey:
      | 'SalesPicture'
      | 'SalesSignature'
      | 'ProjectManagerPicture'
      | 'ProjectManagerSignature'
      | 'OwnerPicture'
      | 'OwnerSignature'
      | 'CustomerRepPicture'
      | 'CustomerRepSignature'
      | 'SituationImage3D',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (publicId === null || publicId === undefined) {
      throw new Error(
        'Required parameter publicId was null or undefined when calling nboGenericGetPublicLeadFile.'
      );
    }

    if (fileKey === null || fileKey === undefined) {
      throw new Error(
        'Required parameter fileKey was null or undefined when calling nboGenericGetPublicLeadFile.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<FileViewModel>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(
        String(publicId)
      )}/files/${encodeURIComponent(String(fileKey))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Patches an existing NBO which previously has been created.
   *
   * @param publicId The id of the lead
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param body The values the user has entered.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericPatchNboLead(
    publicId: string,
    appKey?: string,
    body?: PatchNboViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboGenericPatchNboLead(
    publicId: string,
    appKey?: string,
    body?: PatchNboViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboGenericPatchNboLead(
    publicId: string,
    appKey?: string,
    body?: PatchNboViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboGenericPatchNboLead(
    publicId: string,
    appKey?: string,
    body?: PatchNboViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (publicId === null || publicId === undefined) {
      throw new Error(
        'Required parameter publicId was null or undefined when calling nboGenericPatchNboLead.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(
      `${this.basePath}/api/nbo/leads/${encodeURIComponent(String(publicId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Save an NBO for a specific trade. Relevant information are stored inside a note.
   *
   * @param tradeTypeCode The trade type which this leads belongs to. Valid values are listed in the MasterData
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param leadStatus lead status, initial new
   * @param origin Where this lead comes from. If no value is specified \&quot;Customer Client\&quot; will be used
   * @param body Data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericSaveNbo(
    tradeTypeCode: string,
    appKey?: string,
    leadStatus?: 'New' | 'OfferDownload' | 'Callback' | 'Incomplete' | 'Complete' | 'AutoOffer',
    origin?: string,
    body?: NboViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboViewModel>;
  public nboGenericSaveNbo(
    tradeTypeCode: string,
    appKey?: string,
    leadStatus?: 'New' | 'OfferDownload' | 'Callback' | 'Incomplete' | 'Complete' | 'AutoOffer',
    origin?: string,
    body?: NboViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboViewModel>>;
  public nboGenericSaveNbo(
    tradeTypeCode: string,
    appKey?: string,
    leadStatus?: 'New' | 'OfferDownload' | 'Callback' | 'Incomplete' | 'Complete' | 'AutoOffer',
    origin?: string,
    body?: NboViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboViewModel>>;
  public nboGenericSaveNbo(
    tradeTypeCode: string,
    appKey?: string,
    leadStatus?: 'New' | 'OfferDownload' | 'Callback' | 'Incomplete' | 'Complete' | 'AutoOffer',
    origin?: string,
    body?: NboViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (tradeTypeCode === null || tradeTypeCode === undefined) {
      throw new Error(
        'Required parameter tradeTypeCode was null or undefined when calling nboGenericSaveNbo.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }
    if (leadStatus !== undefined && leadStatus !== null) {
      queryParameters = queryParameters.set('leadStatus', <any>leadStatus);
    }
    if (origin !== undefined && origin !== null) {
      queryParameters = queryParameters.set('origin', <any>origin);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboViewModel>(
      `${this.basePath}/api/nbo/${encodeURIComponent(String(tradeTypeCode))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update an existing NBO which previously has been created.
   *
   * @param tradeTypeCode The trade type to which this lead belongs to
   * @param id The id of the lead
   * @param appKey Appkey which corresponds to the mandant for which this lead is created
   * @param body The values the user has entered.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboGenericUpdateNbo(
    tradeTypeCode: string,
    id: number,
    appKey?: string,
    body?: NboViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboGenericUpdateNbo(
    tradeTypeCode: string,
    id: number,
    appKey?: string,
    body?: NboViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboGenericUpdateNbo(
    tradeTypeCode: string,
    id: number,
    appKey?: string,
    body?: NboViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboGenericUpdateNbo(
    tradeTypeCode: string,
    id: number,
    appKey?: string,
    body?: NboViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (tradeTypeCode === null || tradeTypeCode === undefined) {
      throw new Error(
        'Required parameter tradeTypeCode was null or undefined when calling nboGenericUpdateNbo.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling nboGenericUpdateNbo.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/api/nbo/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(
        String(id)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
