import {
  CONFIG_HELION_TEMPLATE_FLAT_ROOF,
  CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
  HIGH_DRAIN_SYSTEM_ITEMS,
} from '@theia-cc/pv/core';
import { IConfigPv } from '@theia-cc/pv/state';
import { CONFIG_DEFAULT_CONTACT_PHONE } from '@theia-cc/shared/helpers';
import { IEnvVariablesBase, ILanguageSpecific } from '@theia-cc/shared/models';
import { AIL_OFFER_PREVIEW_CONFIG } from './offer-preview-config/offer-preview-config-ail';
import { HELION_OFFER_PREVIEW_CONFIG } from './offer-preview-config/offer-preview-config-helion';
import { STEPS_DEFAULT } from './steps-default';
import {
  CONFIG_IKEA_TEMPLATE_FLAT_ROOF,
  CONFIG_IKEA_TEMPLATE_SLOPED_ROOF,
} from './templates/templates-ikea';

export const APP_NAME = 'Customer Client PV';

const CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS: ILanguageSpecific<string> = {
  de: './assets/img/offerMockup/helion/helion-offer-mockup-de.png',
  fr: './assets/img/offerMockup/helion/helion-offer-mockup-fr.png',
  it: './assets/img/offerMockup/helion/helion-offer-mockup-it.png',
};

const CONFIG_PARTNERS_OFFER_MOCKUP_IMAGE_URLS: ILanguageSpecific<string> = {
  de: './assets/img/offerMockup/partners/mandant-offer-mockup-de.jpg',
  fr: './assets/img/offerMockup/partners/mandant-offer-mockup-fr.jpg',
  it: './assets/img/offerMockup/partners/mandant-offer-mockup-it.jpg',
};

export const CONFIG_PARTNER_KEYS = {
  default: 'DEFAULT',
  brack: 'BRACK',
  hausinfo: 'HAUSINFO',
  em: 'EM',
  ikea: 'IKEA',
  jumbo: 'JUMBO',
  ekz: 'EKZ',
  egb: 'EGB',
  ail: 'AIL',
  helion: 'HELION',
};

const CONFIG_DEFAULT_ENVVARIABLES: IEnvVariablesBase = {
  appKey: '6452E6F74AFF44A4978ED983A8CCB420',
  enablePhoneHeader: true,
  disableLanguageChoice: false,
  localeStyle: 'formal',
};

export const WIZARD_CONFIG_PV: { [key: string]: IConfigPv } = {
  default: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-helion.svg',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    equippedWithEnergyManagementSystemForResidential: true,
  },
  brack: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-brack.png',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/favicon.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  hausinfo: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-hausinfo.png',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'hausinfo.css',
      faviconUrl: './assets/favicon/favicon.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  egb: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo_helion_egb.jpg',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  em: {
    org: {
      name: 'Elektro Material',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-ema.jpg',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '260d69ea05c1494da93d5cdefc688f99',
      enablePhoneHeader: true,
      disableLanguageChoice: false,
      localeStyle: 'formal',
    },
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    equippedWithEnergyManagementSystemForResidential: true,
  },
  ikea: {
    org: {
      name: 'IKEA Solstrale',
      contactPhone: {
        de: '+41 32 677 55 04',
        fr: '+41 32 677 55 04',
        it: '+41 32 677 55 04',
      },
      contactEmail: 'solstrale@helion-solar.ch',
      logoLinkUrl: {
        de: 'https://www.ikea.ch',
        fr: 'https://www.ikea.ch',
        it: 'https://www.ikea.ch',
      },
      logoImageUrl: './assets/img/header/logo-ikea.png',
      contactOfferImageUrls: CONFIG_PARTNERS_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'ikea.css',
      faviconUrl: './assets/favicon/ikea.ico',
      successPageUrls: {
        de: 'https://www.ikea.com/ch/de/campaigns/solarenergie-mit-ikea-vielen-dank-pubf79c1fcf',
        fr: 'https://www.ikea.com/ch/fr/campaigns/energie-solaire-avec-ikea-merci-pubf79c1fcf',
        it: 'https://www.ikea.com/ch/it/campaigns/energia-solare-con-ikea-grazie-pubf79c1fcf',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: 'D3B4BC369D8F46A48AB7468ED37B22B0',
      enablePhoneHeader: false,
      disableLanguageChoice: false,
      localeStyle: 'informal',
    },
    templatesFlat: CONFIG_IKEA_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_IKEA_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    equippedWithEnergyManagementSystemForResidential: true,
  },
  jumbo: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-jumbo.png',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'jumbo.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: '',
        fr: '',
        it: '',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  ekz: {
    org: {
      name: 'EKZ',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.ekz.ch',
        fr: 'https://www.ekz.ch',
        it: 'https://www.ekz.ch',
      },
      logoImageUrl: './assets/img/header/logo-ekz.jpg',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/ekz.ico',
      successPageUrls: {
        de: '',
        fr: '',
        it: '',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '20CE39BBB7E44BF30260938B68AD2BD5',
      enablePhoneHeader: true,
      disableLanguageChoice: false,
      localeStyle: 'formal',
    },

    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  ail: {
    org: {
      name: 'ailSolar Cloud',
      contactPhone: {
        de: '+41 (0)58 470 70 70',
        fr: '+41 (0)58 470 70 70',
        it: '+41 (0)58 470 70 70',
      },
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.ail.ch/comuni/elettricita/prodotti/ailsolar-cloud.html',
        fr: 'https://www.ail.ch/comuni/elettricita/prodotti/ailsolar-cloud.html',
        it: 'https://www.ail.ch/comuni/elettricita/prodotti/ailsolar-cloud.html',
      },
      logoImageUrl: './assets/img/header/logo-ail.jpg',
      contactOfferImageUrls: CONFIG_PARTNERS_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'ail.css',
      faviconUrl: './assets/favicon/ail.ico',
      successPageUrls: {
        de: 'https://www.ail.ch/comuni/elettricita/prodotti/ailsolar-cloud.html',
        fr: 'https://www.ail.ch/comuni/elettricita/prodotti/ailsolar-cloud.html',
        it: 'https://www.ail.ch/comuni/elettricita/prodotti/ailsolar-cloud.html',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '1ca5494fd12149d0aefc74f46dce4ed1',
      enablePhoneHeader: true,
      disableLanguageChoice: true,
      localeStyle: 'formal',
    },
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'it',
    steps: STEPS_DEFAULT,
    offerPreview: AIL_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  test: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-helion.svg',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '2498a14ddfb24f94bccd4983869ffcaf',
      enablePhoneHeader: true,
      disableLanguageChoice: false,
      localeStyle: 'formal',
    },
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    equippedWithEnergyManagementSystemForResidential: true,
  },
  alltron: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-alltron.jpg',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'default.css',
      faviconUrl: './assets/favicon/favicon.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
  },
  helion: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-helion2.png',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'helion.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: 'https://www.helion.ch/pv-danke/',
        fr: 'https://www.helion.ch/fr/pv-merci/',
        it: 'https://www.helion.ch/it/pv-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    equippedWithEnergyManagementSystemForResidential: true,
  },
  migrosbank: {
    org: {
      name: 'Migrosbank',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: [
        './assets/img/header/logo-helion2.png',
        './assets/img/header/logo_migrosbank.png',
      ],
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      stylesheetUrl: 'helion.css',
      faviconUrl: './assets/favicon/helion.ico',
      successPageUrls: {
        de: 'https://www.migrosbank.ch/kampagnen/energetisch-erneuern/abschluss-helion/',
        fr: 'https://www.migrosbank.ch/fr/campagnes/renover-energetiquement/finalisation-hellion/',
        it: 'https://www.migrosbank.ch/it/campagne/rinnovare-energeticamente/finalizzazione-helion/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    offerPreview: HELION_OFFER_PREVIEW_CONFIG,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    equippedWithEnergyManagementSystemForResidential: true,
  },
};
