import { Inject, Injectable } from '@angular/core';
import { ClientConfig, SanityClient, createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ENVIRONMENT, IEnvironment } from '@theia-cc/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class SanityImageService {
  private client: SanityClient;
  private clientConfig: ClientConfig;
  private imageUrlBuilder: ImageUrlBuilder;

  constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {
    if (this.environment.sanity) {
      this.clientConfig = {
        projectId: this.environment.sanity.projectId,
        dataset: this.environment.sanity.dataset,
        apiVersion: this.environment.sanity.apiVersion,
        useCdn: this.environment.sanity.useCdn,
      };
      this.client = this.sanityClient();
      this.imageUrlBuilder = imageUrlBuilder(this.client);
    }
  }

  private sanityClient(): SanityClient {
    return createClient(this.clientConfig);
  }

  getImageUrlBuilder(source: SanityImageSource): ImageUrlBuilder {
    return this.imageUrlBuilder.image(source);
  }
}
