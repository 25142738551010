import { FINISH_PV, IOfferPreviewConfig, OFFER_PREVIEW_PV_SWITCHES } from '@theia-cc/pv/core';

export const AIL_OFFER_PREVIEW_CONFIG: IOfferPreviewConfig = {
  switches: OFFER_PREVIEW_PV_SWITCHES,
  bottomBox: {
    enableBottomBoxPvXl: false,
    enableContactOfferImage: true,
  },
  enableCallbackButton: true,
  enableIntroPvx: false,
  enableOptionSwitches: false,
  enableSuccessStory: true,
  successStoryImage: 'assets/img/offerPreview/ail-solar-team.jpg',
  isGoToSuccessURLAfterFinish: true,
};
