import { NboPvLeadRequest, NboPvVariantRequest } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

// Template-id configs for helion
export const TEMPLATE_HELION_ADVANCED_SLOPEDROOF_STANDARD =
  'TEMPLATE_HELION_ADVANCED_SLOPEDROOF_STANDARD';
export const TEMPLATE_HELION_ADVANCED_SLOPEDROOF_INROOF =
  'TEMPLATE_HELION_ADVANCED_SLOPEDROOF_INROOF';
export const TEMPLATE_HELION_ADVANCED_FLATROOF_STANDARD =
  'TEMPLATE_HELION_ADVANCED_FLATROOF_STANDARD';
export const TEMPLATE_HELION_PVX_ADVANCED_FLATROOF_STANDARD =
  'TEMPLATE_HELION_PVX_ADVANCED_FLATROOF_STANDARD';

// Helion templates for flat roofs
export const TEMPLATES_HELION_FLAT_ROOF = [
  {
    id: TEMPLATE_HELION_ADVANCED_FLATROOF_STANDARD,
    productline: NboPvVariantRequest.ProductlineTypeEnum.Advanced,
    tab: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TITLE'),
    icon: 'assets/img/pv/thumbnail-plus-flat.jpg',
    description: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_DESCRIPTION'),
    data: {},
    images: [
      'assets/img/pv/onroof-flat-1.jpg',
      'assets/img/pv/onroof-flat-2.jpg',
      'assets/img/pv/onroof-flat-3.jpg',
      'assets/img/pv/onroof-flat-4.jpg',
    ],
    mountingType: NboPvLeadRequest.MountingTypeEnum.Standard,
  },
];

// Helion templates for sloped roofs
export const TEMPLATES_HELION_SLOPED_ROOF = [
  {
    id: TEMPLATE_HELION_ADVANCED_SLOPEDROOF_STANDARD,
    productline: NboPvVariantRequest.ProductlineTypeEnum.Advanced,
    tab: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_TITLE'),
    icon: 'assets/img/pv/thumbnail-plus.jpg',
    description: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PLUS_DESCRIPTION'),
    data: {},
    images: [
      'assets/img/pv/onroof-1.jpg',
      'assets/img/pv/onroof-2.jpg',
      'assets/img/pv/onroof-3.jpg',
      'assets/img/pv/onroof-4.jpg',
    ],
    mountingType: NboPvLeadRequest.MountingTypeEnum.Standard,
  },
  {
    id: TEMPLATE_HELION_ADVANCED_SLOPEDROOF_INROOF,
    productline: NboPvVariantRequest.ProductlineTypeEnum.Advanced,
    tab: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_INROOF_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_INROOF_TITLE'),
    icon: 'assets/img/pv/thumbnail-inroof.jpg',
    description: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_INROOF_DESCRIPTION'),
    data: {},
    images: ['assets/img/pv/inroof-1.jpg', 'assets/img/pv/inroof-3.jpg'],
    mountingType: NboPvLeadRequest.MountingTypeEnum.InRoof,
  },
];

// Helion templates for pvx
export const TEMPLATES_HELION_PVX = [
  {
    id: TEMPLATE_HELION_PVX_ADVANCED_FLATROOF_STANDARD,
    productline: NboPvVariantRequest.ProductlineTypeEnum.LargeScale,
    tab: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_LARGE_TAB'),
    title: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_LARGE_TITLE'),
    icon: 'assets/img/pv/thumbnail-pvx.jpg',
    description: _('PV.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_LARGE_DESCRIPTION'),
    data: {},
    images: [
      'assets/img/pv/large-1.jpg',
      'assets/img/pv/large-2.jpg',
      'assets/img/pv/large-3.jpg',
      'assets/img/pv/large-4.jpg',
    ],
    mountingType: NboPvLeadRequest.MountingTypeEnum.Standard,
  },
];

// Templates config Helion for Flat Roof
export const CONFIG_HELION_TEMPLATE_FLAT_ROOF = {
  active: [TEMPLATE_HELION_ADVANCED_FLATROOF_STANDARD],
  default: TEMPLATE_HELION_ADVANCED_FLATROOF_STANDARD,
  inactive: [],
};

// Templates config Helion for Sloped Roof
export const CONFIG_HELION_TEMPLATE_SLOPED_ROOF = {
  active: [
    TEMPLATE_HELION_ADVANCED_SLOPEDROOF_STANDARD,
    TEMPLATE_HELION_ADVANCED_SLOPEDROOF_INROOF,
  ],
  default: TEMPLATE_HELION_ADVANCED_SLOPEDROOF_STANDARD,
  inactive: [],
};
