/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboPvVariantRequest {
  /**
   * The title of the variant that will be created
   */
  VariantTitle?: string;
  NumberOfModules?: number;
  KwhYieldPerKwp?: number;
  ProductlineType: NboPvVariantRequest.ProductlineTypeEnum;
  /**
   * AdditionalSmartEnergyDevices (multiselect)
   */
  AdditionalSmartEnergyDevices?: Array<NboPvVariantRequest.AdditionalSmartEnergyDevicesEnum>;
  /**
   * ControllableSmartEnergyDevices (multiselect)
   */
  ControllableSmartEnergyDevices?: Array<NboPvVariantRequest.ControllableSmartEnergyDevicesEnum>;
  IsNewDistributionBoard?: NboPvVariantRequest.IsNewDistributionBoardEnum;
  /**
   * If this is true and the variant is persisted, the user gets an email with the offerte
   */
  IsSendMail?: boolean;
  readonly LargeScale?: boolean;
}
export namespace NboPvVariantRequest {
  export type ProductlineTypeEnum =
    | 'Basic'
    | 'Advanced'
    | 'HighEnd'
    | 'SingleArticle'
    | 'LargeScale';
  export const ProductlineTypeEnum = {
    Basic: 'Basic' as ProductlineTypeEnum,
    Advanced: 'Advanced' as ProductlineTypeEnum,
    HighEnd: 'HighEnd' as ProductlineTypeEnum,
    SingleArticle: 'SingleArticle' as ProductlineTypeEnum,
    LargeScale: 'LargeScale' as ProductlineTypeEnum,
  };
  export type AdditionalSmartEnergyDevicesEnum =
    | 'EnergyManagementSystem'
    | 'HeatpumpBoiler'
    | 'EmobilityChargingStation'
    | 'Storage'
    | 'NotRequired';
  export const AdditionalSmartEnergyDevicesEnum = {
    EnergyManagementSystem: 'EnergyManagementSystem' as AdditionalSmartEnergyDevicesEnum,
    HeatpumpBoiler: 'HeatpumpBoiler' as AdditionalSmartEnergyDevicesEnum,
    EmobilityChargingStation: 'EmobilityChargingStation' as AdditionalSmartEnergyDevicesEnum,
    Storage: 'Storage' as AdditionalSmartEnergyDevicesEnum,
    NotRequired: 'NotRequired' as AdditionalSmartEnergyDevicesEnum,
  };
  export type ControllableSmartEnergyDevicesEnum =
    | 'DomesticWaterHeaterRelay'
    | 'DomesticWaterHeaterLan'
    | 'HeatpumpBoilerRelay'
    | 'HeatpumpRelay'
    | 'HeatpumpLan'
    | 'EmobilityChargingStationLan'
    | 'NotRequired';
  export const ControllableSmartEnergyDevicesEnum = {
    DomesticWaterHeaterRelay: 'DomesticWaterHeaterRelay' as ControllableSmartEnergyDevicesEnum,
    DomesticWaterHeaterLan: 'DomesticWaterHeaterLan' as ControllableSmartEnergyDevicesEnum,
    HeatpumpBoilerRelay: 'HeatpumpBoilerRelay' as ControllableSmartEnergyDevicesEnum,
    HeatpumpRelay: 'HeatpumpRelay' as ControllableSmartEnergyDevicesEnum,
    HeatpumpLan: 'HeatpumpLan' as ControllableSmartEnergyDevicesEnum,
    EmobilityChargingStationLan:
      'EmobilityChargingStationLan' as ControllableSmartEnergyDevicesEnum,
    NotRequired: 'NotRequired' as ControllableSmartEnergyDevicesEnum,
  };
  export type IsNewDistributionBoardEnum =
    | 'NewTableauWithToggleSwitch'
    | 'OldTableauWithRotationProtection'
    | 'Unknown';
  export const IsNewDistributionBoardEnum = {
    NewTableauWithToggleSwitch: 'NewTableauWithToggleSwitch' as IsNewDistributionBoardEnum,
    OldTableauWithRotationProtection:
      'OldTableauWithRotationProtection' as IsNewDistributionBoardEnum,
    Unknown: 'Unknown' as IsNewDistributionBoardEnum,
  };
}
