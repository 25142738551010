import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';

@Injectable()
export class LeadMetaAction {
  static readonly STORE_LEAD_COMMENT = 'STORE_LEAD_COMMENT';
  static readonly STORE_LEAD_TAG = 'STORE_LEAD_TAG';
  static readonly STORE_LEAD_TRACERS = 'STORE_LEAD_TRACERS';
  static readonly STORE_LEAD_CALLBACK_TIME = 'STORE_LEAD_CALLBACK_TIME';
  static readonly STORE_LEAD_STATUS = 'STORE_LEAD_STATUS';

  @dispatch()
  storeLeadTag = payload => ({
    payload,
    type: LeadMetaAction.STORE_LEAD_TAG,
  });

  @dispatch()
  storeLeadTracers = payload => ({
    payload,
    type: LeadMetaAction.STORE_LEAD_TRACERS,
  });

  @dispatch()
  storeLeadComment = value => ({
    type: LeadMetaAction.STORE_LEAD_COMMENT,
    payload: value,
  });

  @dispatch()
  storeLeadCallbackTime = property => ({
    type: LeadMetaAction.STORE_LEAD_CALLBACK_TIME,
    payload: property,
  });

  @dispatch()
  storeLeadStatus = value => ({
    type: LeadMetaAction.STORE_LEAD_STATUS,
    payload: value,
  });
}
