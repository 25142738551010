import {
  MANUFACTURER,
  ManufacturerEnum,
  NBO_TEMPLATE_HOME_ADVANCED,
  NBO_TEMPLATE_HOME_INTELLIGENT,
} from '@theia-cc/em/core';
import {
  CONFIG_HELION_TEMPLATE_FLAT_ROOF,
  CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
  HIGH_DRAIN_SYSTEM_ITEMS,
  OFFER_PREVIEW_PV_SWITCHES,
  ROOF_AREA_ITEMS,
} from '@theia-cc/pv/core';
import { CONFIG_DEFAULT_CONTACT_PHONE } from '@theia-cc/shared/helpers';
import { EM_TEMPLATES } from '../../../../em/src/app/configs/templates';
import { ALL_TEMPLATES } from '../../../../ht/src/app/configs/templates';
import { IConfigPrimeo } from '../store/reducers';
import { STEPS_DEFAULT } from './steps-default';

export const APP_ALLOWED_ENTRY_OFFER_PREVIEW_STEPS = [
  'offerpreviewpvx-b2b',
  'total',
  'pv',
  'pvx',
  'ht',
  'em',
];

export const APP_NAME = 'Helion │ Photovoltaik │ Heizungsersatz │ E-Ladestation';

export const WIZARD_CONFIG_PRIMEO: { default: IConfigPrimeo } = {
  default: {
    org: {
      name: APP_NAME,
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-helion.svg',
      stylesheetUrl: '',
      faviconUrl: './assets/favicon/helion.ico',
      contactOfferImageUrls: {
        de: './assets/img/offerMockup/partners/mandant-offer-mockup-de.jpg',
        fr: './assets/img/offerMockup/partners/mandant-offer-mockup-fr.jpg',
      },
      successPageUrls: {
        de: 'https://www.helion.ch/em-danke/',
        fr: 'https://www.helion.ch/fr/em-merci/',
      },
    },
    envVariables: {
      appKey: '6452E6F74AFF44A4978ED983A8CCB420',
      enablePhoneHeader: true,
      enableCallbackHeader: true,
      localeStyle: 'formal',
    },
    defaultLanguage: 'de',
    constants: {
      manufacturer: MANUFACTURER,
      carManufacturer: ManufacturerEnum.Generic,
    },
    disableResidentialBottomBox: true,
    templatesEm: EM_TEMPLATES.filter(
      ({ id }) => id !== NBO_TEMPLATE_HOME_ADVANCED && id !== NBO_TEMPLATE_HOME_INTELLIGENT
    ),
    offerPreview: {
      switches: OFFER_PREVIEW_PV_SWITCHES.filter(({ key }) => key !== 'CHARGING_STATION_EMOBILITY'),
      bottomBox: {
        enableBottomBoxPvXl: false,
        enableContactOfferImage: true,
      },
      enableCallbackButton: true,
      enableIntroPvx: true,
      enableOptionSwitches: true,
      enableSuccessStory: false,
    },
    templatesFlat: CONFIG_HELION_TEMPLATE_FLAT_ROOF,
    templatesSloped: CONFIG_HELION_TEMPLATE_SLOPED_ROOF,
    templatesHt: ALL_TEMPLATES,
    steps: STEPS_DEFAULT,
    highDrainSystemItems: HIGH_DRAIN_SYSTEM_ITEMS,
    roofAreaItems: ROOF_AREA_ITEMS,
    trades: [],
  },
};
