import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  caseAgnosticQueryParam,
  CONSTANTS,
  ENVIRONMENT,
  getCurrentStepFromUrl,
  IConstants,
  IEnvironment,
  parseFormString,
} from '@theia-cc/shared/helpers';
import { APP_ALLOWED_ENTRY_OFFER_PREVIEW_STEPS } from '../configs';
import { getFirstStep, RedirectGuard } from './redirect.guard';

@Injectable({ providedIn: 'root' })
export class EntryStepGuard implements CanActivate {
  constructor(
    private redirectGuard: RedirectGuard,
    private router: Router,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    @Inject(CONSTANTS) private constants: IConstants
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const currentStep = getCurrentStepFromUrl(state.url);

    return (
      !this.environment.production ||
      currentStep ===
        getFirstStep(
          parseFormString.array(caseAgnosticQueryParam(route.queryParams)('trade')),
          parseFormString.boolean(caseAgnosticQueryParam(route.queryParams)('b2b'))
        ) ||
      currentStep === 'offerpreviewpvx-b2b' ||
      (APP_ALLOWED_ENTRY_OFFER_PREVIEW_STEPS.slice(1).includes(currentStep) &&
        Object.keys(route.queryParams).some(key => key.indexOf('nboLeadId') !== -1)) ||
      this.redirectGuard.canActivate(route)
    );
  }
}
