import { ICar } from '../..';

export const CARS_TOYOTA: ICar[] = [
  {
    value: 'ToyotaPrius',
    caption: 'Toyota Prius',
    icon: 'toyota-prius.jpg',
  },
  {
    value: 'ToyotabZ4X',
    caption: 'Toyota bZ4X',
    icon: 'toyota-bZ4X.jpg',
  },
  {
    value: 'ToyotaRAV4PHEV',
    caption: 'Toyota RAV4 PHEV',
    icon: 'toyota-rav4.jpg',
  },
  {
    value: 'ToyotaProaceVersoEV75kWh',
    caption: 'Toyota Proace Verso EV 75kWh',
    icon: 'toyota-verso-ev.jpg',
  },
  {
    value: 'ToyotaProaceVersoEV50kWh',
    caption: 'Toyota Proace Verso EV 50kWh',
    icon: 'toyota-verso-ev.jpg',
  },
  {
    value: 'ToyotaProaceCityVersoEV',
    caption: 'Toyota Proace City Verso EV',
    icon: 'toyota-proace-city-verso-ev.jpg',
  },
  {
    value: 'ToyotaProaceCityEV',
    caption: 'Toyota Proace City EV',
    icon: 'toyota-proace-city-ev.jpg',
  },
  {
    value: 'ToyotaProaceEV75kWh',
    caption: 'Toyota Proace EV 75kWh',
    icon: 'toyota-proace-ev.jpg',
  },
  {
    value: 'ToyotaProaceEV50kWh',
    caption: 'Toyota Proace EV 50kWh',
    icon: 'toyota-proace-ev.jpg',
  },
];
