export const errorHandler = store => next => action => {
  if (action.error) {
    alert(action.error.toString());
  }
  next(action);
};

export const persistMiddleware =
  getItemIdFn =>
  ({ getState }) =>
  next =>
  async action => {
    setTimeout(() => {
      // used to simulate async action
      const { collectedData: { previewImage, ...rest } = { previewImage: undefined } } = getState();
      const id = getItemIdFn(rest);
      if (id) {
        localStorage.setItem(`${id}`, JSON.stringify(rest));
      }
    }, 0);
    next(action);
  };
