import { Color, Theme, ThemeProperty } from '@theia-cc/shared/models';

export const injectDynamicThemeTokensIntoHtmlHead = (
  theme: Theme,
  themeProperties: ThemeProperty[]
): void => {
  const cssVars = themeProperties
    .map(({ property, cssVariable }) => {
      const value = getColorValueForSpecifiedProperty(theme, property);
      return value ? `${cssVariable}: ${value};` : '';
    })
    .join('\n');

  const cssString = `
    :root {
        ${cssVars}
    }
  `;

  const styleSheet = document.createElement('style');
  styleSheet.innerText = cssString;
  document.head.appendChild(styleSheet);
};

const getColorValueForSpecifiedProperty = (theme: Theme, propertyName: string): string | null => {
  if (theme.customComponentStyles.hasOwnProperty(propertyName)) {
    return getColorValueBasedOnType(theme.customComponentStyles[propertyName]);
  }

  return null;
};

export const getColorValueBasedOnType = (color: Color): string => {
  /* We only support predefinedColor for now - this is why no color type is currently present */
  return color.predefinedColor ? color.predefinedColor : '';
};

export const populateCustomComponentStyles = (
  cmsData: any
): {
  [key: string]: Color;
} => {
  const customComponentStyle = cmsData?.customComponentStyle;
  const customComponentStylesObject: { [key: string]: Color } = {};

  if (customComponentStyle) {
    Object.keys(customComponentStyle).forEach(key => {
      if (key !== '_type') {
        customComponentStylesObject[key] = customComponentStyle[key];
      }
    });
  }

  return customComponentStylesObject;
};
