import { ICollectedDataPv } from '@theia-cc/pv/state';

export function notPvxRoof({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return !collectedData.isPvx;
}

export function pvxRoof({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.isPvx;
}

export function geometryNotAvailable({
  collectedData,
}: {
  collectedData: ICollectedDataPv;
}): boolean {
  return !collectedData.userAddress || !collectedData.userAddress.hasGeometry;
}

export function geometryAvailable({ collectedData }: { collectedData: ICollectedDataPv }): boolean {
  return collectedData.userAddress && collectedData.userAddress.hasGeometry;
}
