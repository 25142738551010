import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IConfigBase } from '@theia-cc/shared/models';

@Injectable()
export class ConfigAction {
  static readonly LOAD_CONFIG_SUCCEEDED = 'LOAD_CONFIG_SUCCEEDED';
  static readonly LOAD_WIZARD_CONFIG = 'LOAD_WIZARD_CONFIG';
  static readonly QUERY_PARAMS_PARSED = 'QUERY_PARAMS_PARSED';
  static readonly WIZARD_CONFIG_LOADED = 'WIZARD_CONFIG_LOADED';
  static readonly STORE_VERSION_NUMBER = 'STORE_VERSION_NUMBER';
  static readonly STORE_HUBSPOT_TRACKING_ID = 'STORE_HUBSPOT_TRACKING_ID';
  static readonly STORE_BROWSER_CALLBACK = 'STORE_BROWSER_CALLBACK';

  @dispatch()
  parseQParams = qParams => {
    return {
      type: ConfigAction.QUERY_PARAMS_PARSED,
      payload: qParams,
    };
  };

  @dispatch()
  loadWizardConfig = (config: IConfigBase) => ({
    type: ConfigAction.LOAD_WIZARD_CONFIG,
    payload: config,
  });

  @dispatch()
  wizardConfigLoaded = () => ({
    type: ConfigAction.WIZARD_CONFIG_LOADED,
  });

  @dispatch()
  storeVersionNumber = payload => ({
    payload,
    type: ConfigAction.STORE_VERSION_NUMBER,
  });

  @dispatch()
  storeHubspotTrackingId = payload => ({
    payload,
    type: ConfigAction.STORE_HUBSPOT_TRACKING_ID,
  });

  @dispatch()
  storeBrowserCallback = payload => ({
    payload,
    type: ConfigAction.STORE_BROWSER_CALLBACK,
  });
}
