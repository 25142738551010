import {
  CARS_AUDI,
  CARS_BMW,
  CARS_CITROEN,
  CARS_CUPRA,
  CARS_DACIA,
  CARS_DSAUTOMOBILES,
  CARS_FIAT,
  CARS_FORD,
  CARS_HARLEY_DAVIDSON,
  CARS_HONDA,
  CARS_HYUNDAI,
  CARS_JAGUAR,
  CARS_KIA,
  CARS_LAND_ROVER,
  CARS_LEXUS,
  CARS_MERCEDES_BENZ,
  CARS_MINI,
  CARS_MITSUBISHI,
  CARS_NISSAN,
  CARS_OPEL,
  CARS_PEUGEOT,
  CARS_PORSCHE,
  CARS_RENAULT,
  CARS_SEAT,
  CARS_SKODA,
  CARS_SMART,
  CARS_TESLA,
  CARS_TOYOTA,
  CARS_VOLVO,
  CARS_VW,
} from '../cars';
import { ManufacturerEnum } from '../enums';
import { IManufacturer } from '../models';

export const MANUFACTURER: IManufacturer[] = [
  {
    value: ManufacturerEnum.Generic,
    caption: 'EM.MANUFACTURER_SELECTION.GENERIC_CAPTION',
    icon: false,
    cars: [],
  },
  {
    value: ManufacturerEnum.Audi,
    caption: 'Audi',
    icon: 'assets/img/em/logo-audi.jpg',
    cars: CARS_AUDI,
  },
  {
    value: ManufacturerEnum.Bmw,
    caption: 'BMW',
    icon: 'assets/img/em/logo-bmw.png',
    cars: CARS_BMW,
  },
  {
    value: ManufacturerEnum.Citroen,
    caption: 'Citroen',
    icon: 'assets/img/em/logo-citroen.png',
    cars: CARS_CITROEN,
  },
  {
    value: 'Cupra' as any,
    caption: 'Cupra',
    icon: 'assets/img/em/logo-cupra.jpg',
    cars: CARS_CUPRA,
  },
  {
    // value: ManufacturerEnum.Dacia,
    value: 'Dacia' as any,
    caption: 'Dacia',
    icon: 'assets/img/em/logo-dacia.jpg',
    cars: CARS_DACIA,
  },
  {
    value: ManufacturerEnum.DsAutomobiles,
    caption: 'DS Automobiles',
    icon: 'assets/img/em/logo-ds-automobiles.png',
    cars: CARS_DSAUTOMOBILES,
  },
  {
    value: ManufacturerEnum.Fiat,
    caption: 'Fiat',
    icon: 'assets/img/em/logo-fiat.jpg',
    cars: CARS_FIAT,
  },
  {
    value: ManufacturerEnum.Ford,
    caption: 'Ford',
    icon: 'assets/img/em/logo-ford.png',
    cars: CARS_FORD,
  },
  {
    value: ManufacturerEnum.HarleyDavidson,
    caption: 'Harley-Davidson',
    icon: 'assets/img/em/logo-harley-davidson.png',
    cars: CARS_HARLEY_DAVIDSON,
  },
  {
    value: ManufacturerEnum.Honda,
    caption: 'Honda',
    icon: 'assets/img/em/logo-honda.png',
    cars: CARS_HONDA,
  },
  {
    value: ManufacturerEnum.Hyundai,
    caption: 'Hyundai',
    icon: 'assets/img/em/logo-hyundai.png',
    cars: CARS_HYUNDAI,
  },
  {
    value: ManufacturerEnum.Jaguar,
    caption: 'Jaguar',
    icon: 'assets/img/em/logo-jaguar.png',
    cars: CARS_JAGUAR,
  },
  {
    value: ManufacturerEnum.Kia,
    caption: 'Kia',
    icon: 'assets/img/em/logo-kia.png',
    cars: CARS_KIA,
  },
  {
    value: ManufacturerEnum.LandRover,
    caption: 'Land Rover',
    icon: 'assets/img/em/logo-landrover.png',
    cars: CARS_LAND_ROVER,
  },
  {
    value: ManufacturerEnum.Lexus,
    caption: 'Lexus',
    icon: 'assets/img/em/logo-lexus.png',
    cars: CARS_LEXUS,
  },
  {
    value: ManufacturerEnum.Mercedes,
    caption: 'Mercedes-Benz',
    icon: 'assets/img/em/logo-mercedes-benz.png',
    cars: CARS_MERCEDES_BENZ,
  },
  {
    value: ManufacturerEnum.Mini,
    caption: 'Mini',
    icon: 'assets/img/em/logo-mini.png',
    cars: CARS_MINI,
  },
  {
    value: ManufacturerEnum.Mitsubishi,
    caption: 'Mitsubishi',
    icon: 'assets/img/em/logo-mitsubishi.png',
    cars: CARS_MITSUBISHI,
  },
  {
    value: ManufacturerEnum.Nissan,
    caption: 'Nissan',
    icon: 'assets/img/em/logo-nissan.png',
    cars: CARS_NISSAN,
  },
  {
    value: ManufacturerEnum.Opel,
    caption: 'Opel',
    icon: 'assets/img/em/logo-opel.png',
    cars: CARS_OPEL,
  },
  {
    value: ManufacturerEnum.Peugeot,
    caption: 'Peugeot',
    icon: 'assets/img/em/logo-peugeot.png',
    cars: CARS_PEUGEOT,
  },
  {
    value: ManufacturerEnum.Renault,
    caption: 'Renault',
    icon: 'assets/img/em/logo-renault.png',
    cars: CARS_RENAULT,
  },
  {
    value: ManufacturerEnum.Porsche,
    caption: 'Porsche',
    icon: 'assets/img/em/logo-porsche.png',
    cars: CARS_PORSCHE,
  },
  {
    value: ManufacturerEnum.Seat,
    caption: 'SEAT',
    icon: 'assets/img/em/logo-seat.jpg',
    cars: CARS_SEAT,
  },
  {
    value: ManufacturerEnum.Skoda,
    caption: 'ŠKODA',
    icon: 'assets/img/em/logo-skoda.jpg',
    cars: CARS_SKODA,
  },
  {
    value: ManufacturerEnum.Smart,
    caption: 'Smart',
    icon: 'assets/img/em/logo-smart.png',
    cars: CARS_SMART,
  },
  {
    value: ManufacturerEnum.Tesla,
    caption: 'Tesla',
    icon: 'assets/img/em/logo-tesla.png',
    cars: CARS_TESLA,
  },
  {
    value: ManufacturerEnum.Toyota,
    caption: 'Toyota',
    icon: 'assets/img/em/logo-toyota.png',
    cars: CARS_TOYOTA,
  },
  {
    value: ManufacturerEnum.Volkswagen,
    caption: 'Volkswagen',
    icon: 'assets/img/em/logo-vw.png',
    cars: CARS_VW,
  },
  {
    value: ManufacturerEnum.Volvo,
    caption: 'Volvo',
    icon: 'assets/img/em/logo-volvo.png',
    cars: CARS_VOLVO,
  },
];
