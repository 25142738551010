import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BackArrowComponent } from './back-arrow/back-arrow.component';
import { FooterFloatingComponent } from './footer-floating/footer-floating.component';
import { HeaderComponent } from './header/header.component';
import { OfferPreviewLoadingSpinnerComponent } from './offer-preview-loading-spinner/offer-preview-loading-spinner.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ShareButtonModule } from './share-button/share-button.component';

@NgModule({
  declarations: [
    BackArrowComponent,
    HeaderComponent,
    FooterFloatingComponent,
    OfferPreviewLoadingSpinnerComponent,
    ProgressBarComponent,
  ],
  imports: [
    CommonModule,
    NgCircleProgressModule.forRoot({}),
    SharedUtilUiModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    RouterModule,
    ShareButtonModule,
  ],
  exports: [
    BackArrowComponent,
    HeaderComponent,
    FooterFloatingComponent,
    OfferPreviewLoadingSpinnerComponent,
    ProgressBarComponent,
  ],
})
export class SharedRootStepUiModule {}
