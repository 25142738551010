import { NboEmobilityCalculateRequest, NboEmobilityRequest } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AMAG_COMMERCIAL_CHARGING_STATION_ALFEN_SINGLE_EVE_PRO,
  AMAG_COMMERCIAL_CHARGING_STATION_GOE,
  AMAG_COMMERCIAL_CHARGING_STATION_WALLBOX_BASIC,
  AMAG_COMMERCIAL_CHARGING_STATION_WALLBOX_PLUS,
  AMAG_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
  AMAG_RESIDENTIAL_CHARGING_STATION_ALFEN_SINGLE_EVE_PRO,
  AMAG_RESIDENTIAL_CHARGING_STATION_GOE,
  AMAG_RESIDENTIAL_CHARGING_STATION_WALLBOX_BASIC,
  AMAG_RESIDENTIAL_CHARGING_STATION_WALLBOX_PLUS,
  AMAG_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
  BYES_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
  BYES_COMMERCIAL_CHARGING_STATION_WALLBOX_BASIC,
  BYES_COMMERCIAL_CHARGING_STATION_WALLBOX_PLUS,
  BYES_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
  BYES_RESIDENTIAL_CHARGING_STATION_WALLBOX_BASIC,
  BYES_RESIDENTIAL_CHARGING_STATION_WALLBOX_PLUS,
  CARVOL_COMMERCIAL_CHARGING_STATION_ALFEN_EVE,
  CARVOL_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
  CARVOL_COMMERCIAL_CHARGING_STATION_KEBA_P30,
  CARVOL_COMMERCIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
  CARVOL_COMMERCIAL_CHARGING_STATION_MENNEKES,
  CARVOL_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE,
  CARVOL_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
  CARVOL_RESIDENTIAL_CHARGING_STATION_KEBA_P30,
  CARVOL_RESIDENTIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
  CARVOL_RESIDENTIAL_CHARGING_STATION_MENNEKES,
  EATON_COMMERCIAL_CHARGING_STATION_X_CHARGE_IN_HOME,
  EATON_RESIDENTIAL_CHARGING_STATION_X_CHARGE_IN_HOME,
  FORD_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX,
  FORD_COMMERCIAL_CHARGING_STATION_EASEE_HOME_PLUS,
  FORD_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
  FORD_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX,
  FORD_RESIDENTIAL_CHARGING_STATION_EASEE_HOME_PLUS,
  FORD_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
  HELION_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
  HELION_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
  HONDA_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
  HONDA_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
  ITemplateEm,
  ITemplateEmWithoutCms,
  LEXUS_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX,
  LEXUS_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
  LEXUS_COMMERCIAL_CHARGING_STATION_DOUBLE_WALLBOX,
  LEXUS_COMMERCIAL_CHARGING_STATION_ENTRY_WALLBOX,
  LEXUS_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX,
  LEXUS_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
  LEXUS_RESIDENTIAL_CHARGING_STATION_DOUBLE_WALLBOX,
  LEXUS_RESIDENTIAL_CHARGING_STATION_ENTRY_WALLBOX,
  NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_ADVANCED,
  NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_FAST,
  NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_STANDARD,
  NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_ADVANCED,
  NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_FAST,
  NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_STANDARD,
  NISSAN_COMMERCIAL_CHARGING_STATION_PULSAR_PLUS,
  NISSAN_COMMERCIAL_CHARGING_STATION_PULSAR_PLUS_POWERBOOST,
  NISSAN_RESIDENTIAL_CHARGING_STATION_PULSAR_PLUS,
  NISSAN_RESIDENTIAL_CHARGING_STATION_PULSAR_PLUS_POWERBOOST,
  PRIMEO_COMMERCIAL_CHARGING_STATION_ALFEN_EVE,
  PRIMEO_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
  PRIMEO_COMMERCIAL_CHARGING_STATION_KEBA_P30,
  PRIMEO_COMMERCIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
  PRIMEO_COMMERCIAL_CHARGING_STATION_MENNEKES,
  PRIMEO_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE,
  PRIMEO_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
  PRIMEO_RESIDENTIAL_CHARGING_STATION_KEBA_P30,
  PRIMEO_RESIDENTIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
  PRIMEO_RESIDENTIAL_CHARGING_STATION_MENNEKES,
  RENAULT_COMMERCIAL_CHARGING_STATION_KEBA_CONTACT,
  RENAULT_RESIDENTIAL_CHARGING_STATION_KEBA_CONTACT,
  TCS_COMMERCIAL_CHARGING_STATION_ALFEN_EVE_SINGLE,
  TCS_COMMERCIAL_CHARGING_STATION_ALFEN_EVE_SINGLE_DISPLAY,
  TCS_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE_SINGLE,
  TCS_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE_SINGLE_DISPLAY,
  TOYOTA_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX,
  TOYOTA_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
  TOYOTA_COMMERCIAL_CHARGING_STATION_DOUBLE_WALLBOX,
  TOYOTA_COMMERCIAL_CHARGING_STATION_ENTRY_WALLBOX,
  TOYOTA_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX,
  TOYOTA_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
  TOYOTA_RESIDENTIAL_CHARGING_STATION_DOUBLE_WALLBOX,
  TOYOTA_RESIDENTIAL_CHARGING_STATION_ENTRY_WALLBOX,
} from '@theia-cc/em/core';

export const EM_TEMPLATES: ITemplateEmWithoutCms[] = [
  // AMAG_TEMPLATES
  {
    id: AMAG_RESIDENTIAL_CHARGING_STATION_ALFEN_SINGLE_EVE_PRO,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENSINGLEEVEPROLINE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENSINGLEEVEPROLINE_TITLE'),
    icon: 'assets/img/offerPreview/alfen-eve-single-pro-line-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENSINGLEEVEPROLINE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/alfen-eve-single-pro-line-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_ALFENSINGLEEVEPROLINE_CHARGING_STATION',
  },
  {
    id: AMAG_RESIDENTIAL_CHARGING_STATION_GOE,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GOECHARGER_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GOECHARGER_TITLE'),
    icon: 'assets/img/offerPreview/goe-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GOECHARGER_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/goe-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_GOECHARGER_CHARGING_STATION',
  },
  {
    id: AMAG_COMMERCIAL_CHARGING_STATION_ALFEN_SINGLE_EVE_PRO,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENSINGLEEVEPROLINE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENSINGLEEVEPROLINE_TITLE'),
    icon: 'assets/img/offerPreview/alfen-eve-single-pro-line-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENSINGLEEVEPROLINE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/alfen-eve-single-pro-line-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_ALFENSINGLEEVEPROLINE_CHARGING_STATION',
  },
  {
    id: AMAG_COMMERCIAL_CHARGING_STATION_GOE,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GOECHARGER_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GOECHARGER_TITLE'),
    icon: 'assets/img/offerPreview/goe-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GOECHARGER_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/goe-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_GOECHARGER_CHARGING_STATION',
  },
  {
    id: AMAG_RESIDENTIAL_CHARGING_STATION_WALLBOX_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_plus_icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_plus.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_WALLBOX_PLUS_CHARGING_STATION',
  },
  {
    id: AMAG_RESIDENTIAL_CHARGING_STATION_WALLBOX_BASIC,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_basic.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_WALLBOX_BASIC_CHARGING_STATION',
  },
  {
    id: AMAG_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-zaptec-go-basic-icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-zaptec-go-basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ZAPTEC_GO_BASIC_CHARGING_STATION',
  },
  {
    id: AMAG_COMMERCIAL_CHARGING_STATION_WALLBOX_PLUS,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_plus_icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_plus.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_WALLBOX_PLUS_CHARGING_STATION',
  },
  {
    id: AMAG_COMMERCIAL_CHARGING_STATION_WALLBOX_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_basic.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_WALLBOX_BASIC_CHARGING_STATION',
  },
  {
    id: AMAG_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-zaptec-go-basic-icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-zaptec-go-basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ZAPTEC_GO_BASIC_CHARGING_STATION',
  },
  // CARVOLUTION_TEMPLATES
  {
    id: CARVOL_RESIDENTIAL_CHARGING_STATION_KEBA_P30,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_RESIDENTIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_WITH_CABLE_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TITLE'),
    icon: 'assets/img/offerPreview/alfen-eve-pro-line-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/alfen-eve-pro-line-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ALFENEVEPROLINE_CHARGING_STATION',
  },
  {
    id: CARVOL_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_GREEN_MOTION_HOME_TWO_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_RESIDENTIAL_CHARGING_STATION_MENNEKES,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TITLE'),
    icon: 'assets/img/offerPreview/mannekes-amtron-compact-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/mannekes-amtron-compact-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_MENNEKESAMTRONCOMPACT11_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_COMMERCIAL_CHARGING_STATION_KEBA_P30,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_COMMERCIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_WITH_CABLE_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_COMMERCIAL_CHARGING_STATION_ALFEN_EVE,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TITLE'),
    icon: 'assets/img/offerPreview/alfen-eve-pro-line-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/alfen-eve-pro-line-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ALFENEVEPROLINE_CHARGING_STATION',
  },
  {
    id: CARVOL_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_GREEN_MOTION_HOME_TWO_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: CARVOL_COMMERCIAL_CHARGING_STATION_MENNEKES,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TITLE'),
    icon: 'assets/img/offerPreview/mannekes-amtron-compact-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/mannekes-amtron-compact-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_MENNEKESAMTRONCOMPACT11_CHARGING_STATION',
    disabledForFixPrice: true,
  },

  // FORD_TEMPLATES
  {
    id: FORD_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CONNECTED_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CONNECTED_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/Ford_connected_wallbox_icon.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CONNECTED_WALLBOX_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/Ford_connected_wallbox.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_FORD_CONNECTED_WALLBOX_CHARGING_STATION',
  },
  {
    id: FORD_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CONNECTED_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CONNECTED_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/Ford_connected_wallbox_icon.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CONNECTED_WALLBOX_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/Ford_connected_wallbox.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_FORD_CONNECTED_WALLBOX_CHARGING_STATION',
  },
  {
    id: FORD_RESIDENTIAL_CHARGING_STATION_EASEE_HOME_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_FORD_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_plus_icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_plus.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_FORD_EASEE_HOME_PLUS_CHARGING_STATION',
  },
  {
    id: FORD_COMMERCIAL_CHARGING_STATION_EASEE_HOME_PLUS,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_FORD_COMMUNITY_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_plus_icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_COMMUNITY_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_plus.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_FORD_EASEE_HOME_PLUS_COMMUNITY_CHARGING_STATION',
  },
  {
    id: FORD_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-zaptec-go-basic-icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-zaptec-go-basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ZAPTEC_GO_BASIC_CHARGING_STATION',
  },
  {
    id: FORD_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-zaptec-go-basic-icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-zaptec-go-basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ZAPTEC_GO_BASIC_CHARGING_STATION',
  },
  // GENERIC_TEMPLATES
  {
    id: BYES_RESIDENTIAL_CHARGING_STATION_WALLBOX_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_plus_icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_plus.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
  },
  {
    id: BYES_RESIDENTIAL_CHARGING_STATION_WALLBOX_BASIC,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_basic.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_WALLBOX_BASIC_CHARGING_STATION',
  },
  {
    id: BYES_COMMERCIAL_CHARGING_STATION_WALLBOX_PLUS,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_PLUS_COMMUNITY_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_plus_icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_plus.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
  },
  {
    id: BYES_COMMERCIAL_CHARGING_STATION_WALLBOX_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EASEE_HOME_COMMUNITY_TITLE'),
    icon: 'assets/img/offerPreview/AMAG_Wallbox_basic.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_WALLBOX_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/AMAG_Wallbox_basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_AMAG_WALLBOX_BASIC_CHARGING_STATION',
  },
  {
    id: BYES_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_GREEN_MOTION_HOME_TWO_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: BYES_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_GREEN_MOTION_HOME_TWO_CHARGING_STATION',
    disabledForFixPrice: true,
  },

  // EATON_TEMPLATES
  {
    id: EATON_RESIDENTIAL_CHARGING_STATION_X_CHARGE_IN_HOME,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EATON_X_CHARGE_IN_HOME_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EATON_X_CHARGE_IN_HOME_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EATON_X_CHARGE_IN_HOME_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    carId: 'GenericEleven',
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_EATON_X_CHARGE_IN_HOME_CHARGING_STATION',
  },
  {
    id: EATON_COMMERCIAL_CHARGING_STATION_X_CHARGE_IN_HOME,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EATON_X_CHARGE_IN_HOME_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EATON_X_CHARGE_IN_HOME_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_EATON_X_CHARGE_IN_HOME_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    carId: 'GenericEleven',
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_EATON_X_CHARGE_IN_HOME_CHARGING_STATION',
  },
  // HELION_TEMPLATES
  {
    id: HELION_RESIDENTIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-zaptec-go-basic-icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-zaptec-go-basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ZAPTEC_GO_BASIC_CHARGING_STATION',
  },
  {
    id: HELION_COMMERCIAL_CHARGING_STATION_ZAPTEC_GO_BASIC,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-zaptec-go-basic-icon.png',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ZAPTEC_BASIC_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-zaptec-go-basic.png'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.TwentyTwo,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ZAPTEC_GO_BASIC_CHARGING_STATION',
  },
  // HONDA_TEMPLATES
  {
    id: HONDA_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HONDA_HOME_TWO_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    carId: 'GenericEleven',
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GREENMOTION_HOME_TWO_CHARGING_STATION_HONDA',
  },
  {
    id: HONDA_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HONDA_HOME_TWO_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    carId: 'GenericEleven',
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GREENMOTION_HOME_TWO_CHARGING_STATION_HONDA',
  },
  // NEWMOTION_TEMPLATES
  {
    id: NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_STANDARD,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_STANDARD_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_STANDARD_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-newmotion.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_STANDARD_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-newmotion_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericThreePointSeven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_NEW_MOTION_HOME_STANDARD_CHARGING_STATION',
  },
  {
    id: NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_FAST,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_FAST_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_FAST_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-newmotion.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_FAST_DESCRIPTION'),
    recommended: false,
    data: {},
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-newmotion_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_NEW_MOTION_HOME_FAST_CHARGING_STATION',
  },
  {
    id: NEWMOTION_RESIDENTIAL_CHARGING_STATION_HOME_ADVANCED,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_ADVANCED_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_ADVANCED_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-newmotion.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_ADVANCED_DESCRIPTION'),
    recommended: false,
    data: {},
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-newmotion_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_NEW_MOTION_HOME_ADVANCED_CHARGING_STATION',
  },
  {
    id: NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_STANDARD,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_STANDARD_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_STANDARD_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-newmotion.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_STANDARD_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-newmotion_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericThreePointSeven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_NEW_MOTION_HOME_STANDARD_CHARGING_STATION',
  },
  {
    id: NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_FAST,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_FAST_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_FAST_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-newmotion.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_FAST_DESCRIPTION'),
    recommended: false,
    data: {},
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-newmotion_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_NEW_MOTION_HOME_FAST_CHARGING_STATION',
  },
  {
    id: NEWMOTION_COMMERCIAL_CHARGING_STATION_HOME_ADVANCED,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_ADVANCED_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_ADVANCED_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-newmotion.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_NEW_MOTION_HOME_ADVANCED_DESCRIPTION'),
    recommended: false,
    data: {},
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/charging-station-newmotion_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_NEW_MOTION_HOME_ADVANCED_CHARGING_STATION',
  },
  // NISSAN_TEMPLATES
  {
    id: NISSAN_RESIDENTIAL_CHARGING_STATION_PULSAR_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-nissan-pulsar-plus.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-nissan-pulsar-plus_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_PULSAR_PLUS_CHARGING_STATION',
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
  },
  {
    id: NISSAN_COMMERCIAL_CHARGING_STATION_PULSAR_PLUS,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-nissan-pulsar-plus.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-nissan-pulsar-plus_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_PULSAR_PLUS_CHARGING_STATION',
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
  },
  {
    id: NISSAN_RESIDENTIAL_CHARGING_STATION_PULSAR_PLUS_POWERBOOST,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_POWERBOOST_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_POWERBOOST_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-nissan-pulsar-plus.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_POWERBOOST_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-nissan-pulsar-plus_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_PULSAR_PLUS_POWERBOOST_CHARGING_STATION',
  },
  {
    id: NISSAN_COMMERCIAL_CHARGING_STATION_PULSAR_PLUS_POWERBOOST,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_POWERBOOST_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_POWERBOOST_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-nissan-pulsar-plus.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_PULSAR_PLUS_POWERBOOST_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-nissan-pulsar-plus_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_PULSAR_PLUS_POWERBOOST_CHARGING_STATION',
  },
  // PRIMEO_TEMPLATES
  {
    id: PRIMEO_RESIDENTIAL_CHARGING_STATION_KEBA_P30,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_RESIDENTIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_WITH_CABLE_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TITLE'),
    icon: 'assets/img/offerPreview/alfen-eve-pro-line-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/alfen-eve-pro-line-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ALFENEVEPROLINE_CHARGING_STATION',
  },
  {
    id: PRIMEO_RESIDENTIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_GREEN_MOTION_HOME_TWO_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_RESIDENTIAL_CHARGING_STATION_MENNEKES,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TITLE'),
    icon: 'assets/img/offerPreview/mannekes-amtron-compact-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/mannekes-amtron-compact-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_MENNEKESAMTRONCOMPACT11_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_COMMERCIAL_CHARGING_STATION_KEBA_P30,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_COMMERCIAL_CHARGING_STATION_KEBA_P30_WITH_CABLE,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_TITLE'),
    icon: 'assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBAP30CSERIES_WITH_CABLE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
    images: ['assets/img/offerPreview/charging-station_keba_p30_c_series_with_cabel_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_KEBAP30CSERIES_WITH_CABLE_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_COMMERCIAL_CHARGING_STATION_ALFEN_EVE,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_TITLE'),
    icon: 'assets/img/offerPreview/alfen-eve-pro-line-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_ALFENEVEPROLINE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/alfen-eve-pro-line-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_ALFENEVEPROLINE_CHARGING_STATION',
  },
  {
    id: PRIMEO_COMMERCIAL_CHARGING_STATION_GREEN_MOTION,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_TITLE'),
    icon: 'assets/img/offerPreview/home-two.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_GREEN_MOTION_HOME_TWO_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [NboEmobilityRequest.OrderTypeEnum.AccordingToCost],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/home-two_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_GREEN_MOTION_HOME_TWO_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  {
    id: PRIMEO_COMMERCIAL_CHARGING_STATION_MENNEKES,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_TITLE'),
    icon: 'assets/img/offerPreview/mannekes-amtron-compact-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_MENNEKESAMTRONCOMPACT11_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    // Check connectpv in store to display stations base on the connectpv value
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Undesired,
    images: ['assets/img/offerPreview/mannekes-amtron-compact-charging-station_modal.jpg'],
    // The chargingPower prop here must match with the default value from the dropdown in the generic-offer-preview (11)
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_GENERIC_MENNEKESAMTRONCOMPACT11_CHARGING_STATION',
    disabledForFixPrice: true,
  },
  // RENAULT_TEMPLATES
  {
    id: RENAULT_RESIDENTIAL_CHARGING_STATION_KEBA_CONTACT,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-renault-keba.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-renault-keba_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    pricingTableChargingStation: 'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_RENAULT',
    supportsMultipleChargingPowers: true,
  },
  {
    id: RENAULT_COMMERCIAL_CHARGING_STATION_KEBA_CONTACT,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-renault-keba.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-renault-keba_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    pricingTableChargingStation: 'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_RENAULT',
    supportsMultipleChargingPowers: true,
  },
  // TCS_TEMPLATES
  {
    id: TCS_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE_SINGLE,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_CHARGING_STATION_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_CHARGING_STATION_TITLE'),
    icon: 'assets/img/offerPreview/bev-charging-station_tcs.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_CHARGING_STATION_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/bev-charging-station_tcs_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericThreePointSeven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_TCS_HOME_CHARGING_STATION',
  },
  {
    id: TCS_RESIDENTIAL_CHARGING_STATION_ALFEN_EVE_SINGLE_DISPLAY,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_SEMI_PRIVATE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_SEMI_PRIVATE_TITLE'),
    icon: 'assets/img/offerPreview/xev-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_SEMI_PRIVATE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/xev-charging-station_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_TCS_SEMI_PRIVATE_CHARGING_STATION',
  },
  {
    id: TCS_COMMERCIAL_CHARGING_STATION_ALFEN_EVE_SINGLE,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_CHARGING_STATION_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_CHARGING_STATION_TITLE'),
    icon: 'assets/img/offerPreview/bev-charging-station_tcs.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_HOME_CHARGING_STATION_DESCRIPTION'),
    recommended: true,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/bev-charging-station_tcs_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericThreePointSeven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_TCS_HOME_CHARGING_STATION',
  },
  {
    id: TCS_COMMERCIAL_CHARGING_STATION_ALFEN_EVE_SINGLE_DISPLAY,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_SEMI_PRIVATE_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_SEMI_PRIVATE_TITLE'),
    icon: 'assets/img/offerPreview/xev-charging-station.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_SEMI_PRIVATE_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/xev-charging-station_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_TCS_SEMI_PRIVATE_CHARGING_STATION',
  },
  // LEXUS_TEMPLATES
  {
    id: LEXUS_RESIDENTIAL_CHARGING_STATION_ENTRY_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-lexus-entry-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-entry-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_ENTRY_WALLBOX_CHARGING_STATION',
  },
  {
    id: LEXUS_COMMERCIAL_CHARGING_STATION_ENTRY_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-lexus-entry-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-entry-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_ENTRY_WALLBOX_CHARGING_STATION',
  },
  {
    id: LEXUS_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_CHARGING_STATION',
  },
  {
    id: LEXUS_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_CHARGING_STATION',
  },
  {
    id: LEXUS_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TAB'),
    title: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TITLE'
    ),
    icon: 'assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_CHARGING_STATION',
  },
  {
    id: LEXUS_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TAB'),
    title: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TITLE'
    ),
    icon: 'assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_CHARGING_STATION',
  },
  {
    id: LEXUS_RESIDENTIAL_CHARGING_STATION_DOUBLE_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-lexus-double-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-double-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_DOUBLE_WALLBOX_CHARGING_STATION',
  },
  {
    id: LEXUS_COMMERCIAL_CHARGING_STATION_DOUBLE_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-lexus-double-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-lexus-double-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_DOUBLE_WALLBOX_CHARGING_STATION',
  },
  // TOYOTA_TEMPLATES
  {
    id: TOYOTA_RESIDENTIAL_CHARGING_STATION_ENTRY_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-toyota-entry-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-entry-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_ENTRY_WALLBOX_CHARGING_STATION',
  },
  {
    id: TOYOTA_COMMERCIAL_CHARGING_STATION_ENTRY_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-toyota-entry-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_ENTRY_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-entry-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_ENTRY_WALLBOX_CHARGING_STATION',
  },
  {
    id: TOYOTA_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_CHARGING_STATION',
  },
  {
    id: TOYOTA_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_CHARGING_STATION',
  },
  {
    id: TOYOTA_RESIDENTIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TAB'),
    title: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TITLE'
    ),
    icon: 'assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_CHARGING_STATION',
  },
  {
    id: TOYOTA_COMMERCIAL_CHARGING_STATION_CONNECTED_WALLBOX_PLUS,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TAB'),
    title: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_TITLE'
    ),
    icon: 'assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-connected-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_CONNECTED_WALLBOX_PLUS_CHARGING_STATION',
  },
  {
    id: TOYOTA_RESIDENTIAL_CHARGING_STATION_DOUBLE_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-toyota-double-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-double-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_DOUBLE_WALLBOX_CHARGING_STATION',
  },
  {
    id: TOYOTA_COMMERCIAL_CHARGING_STATION_DOUBLE_WALLBOX,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-toyota-double-wallbox.jpg',
    description: _(
      'EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_CHARGING_STATION_DOUBLE_WALLBOX_DESCRIPTION'
    ),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-toyota-double-wallbox.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: false,
    carId: 'GenericEleven',
    pricingTableChargingStation:
      'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_CHARGING_STATION_DOUBLE_WALLBOX_CHARGING_STATION',
  },
];

// const groupBy = (array, key) => {
//   return array.reduce((result, currentValue) => {
//     (result[currentValue[key]] = result[currentValue[key]] || []).push(
//       currentValue,
//     );
//     return result;
//   }, {});
// };
//
// console.log(
//   Object.entries(groupBy(EM_TEMPLATES, 'icon')).reduce(
//     (res, [key, value]: [string, ITemplateEm[]]) => {
//       if (value.length > 2) {
//         res[key] = value;
//       }
//       return res;
//     },
//     {},
//   ),
// );
// console.log(
//   Object.entries(groupBy(EM_TEMPLATES, 'id')).reduce(
//     (res, [key, value]: [string, ITemplateEm[]]) => {
//       if (value.length > 1) {
//         res[key] = value;
//       }
//       return res;
//     },
//     {},
//   ),
// );
