/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AddVariantsToHeatingLeadRequest } from '../model/addVariantsToHeatingLeadRequest';
import { BuildingDetailsNboViewModel } from '../model/buildingDetailsNboViewModel';
import { CalculateNboHeatingRequest } from '../model/calculateNboHeatingRequest';
import { HeatingVariantSummaryViewModel } from '../model/heatingVariantSummaryViewModel';
import { HeatingVariantSummaryViewModelLeadSummaryViewModel } from '../model/heatingVariantSummaryViewModelLeadSummaryViewModel';
import { NboComparisonCo2Request } from '../model/nboComparisonCo2Request';
import { NboComparisonCo2ViewModel } from '../model/nboComparisonCo2ViewModel';
import { NboComparisonCostsRequest } from '../model/nboComparisonCostsRequest';
import { NboComparisonCostsViewModel } from '../model/nboComparisonCostsViewModel';
import { NboComparisonInvestmentRequest } from '../model/nboComparisonInvestmentRequest';
import { NboComparisonInvestmentViewModel } from '../model/nboComparisonInvestmentViewModel';
import { NboEnergyConversionRequest } from '../model/nboEnergyConversionRequest';
import { NboEnergyConversionViewModel } from '../model/nboEnergyConversionViewModel';
import { NboHeatingPowerViewModel } from '../model/nboHeatingPowerViewModel';
import { NboProductlineViewModel } from '../model/nboProductlineViewModel';
import { PatchNboRequest } from '../model/patchNboRequest';
import { SaveNboHeatingRequest } from '../model/saveNboHeatingRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class NboHeatingService {
  protected basePath = 'https://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Adds one or more variants to an existing lead
   *
   * @param leadId The lead id (guid, a string)
   * @param appKey AppKey which corresponds to the mandator for which this variant is created
   * @param body Object and metadata for variants which shall be added to the lead
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddVariantsToHeatingLeadRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<HeatingVariantSummaryViewModel>>;
  public nboHeatingAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddVariantsToHeatingLeadRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<HeatingVariantSummaryViewModel>>>;
  public nboHeatingAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddVariantsToHeatingLeadRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<HeatingVariantSummaryViewModel>>>;
  public nboHeatingAddVariantsToLead(
    leadId: string,
    appKey?: string,
    body?: AddVariantsToHeatingLeadRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboHeatingAddVariantsToLead.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<HeatingVariantSummaryViewModel>>(
      `${this.basePath}/api/nbo/ht/${encodeURIComponent(String(leadId))}/variants`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Calculate co2 emission for heating technologies  given the energy demand in kwh
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingCalculateComparisonCo2Emission(
    body?: NboComparisonCo2Request,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboComparisonCo2ViewModel>;
  public nboHeatingCalculateComparisonCo2Emission(
    body?: NboComparisonCo2Request,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboComparisonCo2ViewModel>>;
  public nboHeatingCalculateComparisonCo2Emission(
    body?: NboComparisonCo2Request,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboComparisonCo2ViewModel>>;
  public nboHeatingCalculateComparisonCo2Emission(
    body?: NboComparisonCo2Request,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboComparisonCo2ViewModel>(
      `${this.basePath}/api/nbo/ht/heating-comparison/co2-emission`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Calculate a cost summary for heating technologies  given the energy demand in kwh as well as some adjustable values regarding costs per energy unit
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingCalculateComparisonCostSummary(
    body?: NboComparisonCostsRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboComparisonCostsViewModel>;
  public nboHeatingCalculateComparisonCostSummary(
    body?: NboComparisonCostsRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboComparisonCostsViewModel>>;
  public nboHeatingCalculateComparisonCostSummary(
    body?: NboComparisonCostsRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboComparisonCostsViewModel>>;
  public nboHeatingCalculateComparisonCostSummary(
    body?: NboComparisonCostsRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboComparisonCostsViewModel>(
      `${this.basePath}/api/nbo/ht/heating-comparison/cost-summary`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Calculate investment costs in CHF for gas, oil and pellets  given Kwh and the investment cost in CHF for a new heat generator
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingCalculateComparisonInvestment(
    body?: NboComparisonInvestmentRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboComparisonInvestmentViewModel>;
  public nboHeatingCalculateComparisonInvestment(
    body?: NboComparisonInvestmentRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboComparisonInvestmentViewModel>>;
  public nboHeatingCalculateComparisonInvestment(
    body?: NboComparisonInvestmentRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboComparisonInvestmentViewModel>>;
  public nboHeatingCalculateComparisonInvestment(
    body?: NboComparisonInvestmentRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboComparisonInvestmentViewModel>(
      `${this.basePath}/api/nbo/ht/heating-comparison/investment`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * A simplified heating power calculation, depending only on the building data of  a heating variant object data.
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingCalculateHeatingPower(
    body?: BuildingDetailsNboViewModel,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboHeatingPowerViewModel>;
  public nboHeatingCalculateHeatingPower(
    body?: BuildingDetailsNboViewModel,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboHeatingPowerViewModel>>;
  public nboHeatingCalculateHeatingPower(
    body?: BuildingDetailsNboViewModel,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboHeatingPowerViewModel>>;
  public nboHeatingCalculateHeatingPower(
    body?: BuildingDetailsNboViewModel,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboHeatingPowerViewModel>(
      `${this.basePath}/api/nbo/ht/heating-power`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Calculates the variant summaries of an NBO given the object data  A summary is created for each matching productline
   *
   * @param appKey AppKey which corresponds to the mandator for which this lead is created
   * @param body Object Data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingCalculateNbo(
    appKey?: string,
    body?: CalculateNboHeatingRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HeatingVariantSummaryViewModel>;
  public nboHeatingCalculateNbo(
    appKey?: string,
    body?: CalculateNboHeatingRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HeatingVariantSummaryViewModel>>;
  public nboHeatingCalculateNbo(
    appKey?: string,
    body?: CalculateNboHeatingRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HeatingVariantSummaryViewModel>>;
  public nboHeatingCalculateNbo(
    appKey?: string,
    body?: CalculateNboHeatingRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<HeatingVariantSummaryViewModel>(
      `${this.basePath}/api/nbo/ht/calculate`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This is a dummy calculation method for search engines
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingGetCalculateNbo(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public nboHeatingGetCalculateNbo(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboHeatingGetCalculateNbo(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboHeatingGetCalculateNbo(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/api/nbo/ht/calculate`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Gets energy conversion values from a heat generator type  &lt;returns&gt;Some data for energy conversion&lt;/returns&gt;
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingGetEnergyConversion(
    body?: NboEnergyConversionRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NboEnergyConversionViewModel>;
  public nboHeatingGetEnergyConversion(
    body?: NboEnergyConversionRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NboEnergyConversionViewModel>>;
  public nboHeatingGetEnergyConversion(
    body?: NboEnergyConversionRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NboEnergyConversionViewModel>>;
  public nboHeatingGetEnergyConversion(
    body?: NboEnergyConversionRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NboEnergyConversionViewModel>(
      `${this.basePath}/api/nbo/ht/energy-conversion`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Given a averagedCurrentEnergyDemandKwh value, it calculates the heating power and  returns a list of tuples of HeatingManufacturer and HeatingType  which do have a matching MaxHeatingPower value.  Be aware that appKey is used to find the correct mandantor.
   *
   * @param appKey
   * @param energyDemandKwh
   * @param currentHeatDissipation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingGetMatchingProductlines(
    appKey: string,
    energyDemandKwh?: number,
    currentHeatDissipation?:
      | 'RadiatorWithFeedBelow60'
      | 'RadiatorWithFeedAbove60'
      | 'FloorHeating'
      | 'RadiatorAndFloorHeating',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<NboProductlineViewModel>>;
  public nboHeatingGetMatchingProductlines(
    appKey: string,
    energyDemandKwh?: number,
    currentHeatDissipation?:
      | 'RadiatorWithFeedBelow60'
      | 'RadiatorWithFeedAbove60'
      | 'FloorHeating'
      | 'RadiatorAndFloorHeating',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<NboProductlineViewModel>>>;
  public nboHeatingGetMatchingProductlines(
    appKey: string,
    energyDemandKwh?: number,
    currentHeatDissipation?:
      | 'RadiatorWithFeedBelow60'
      | 'RadiatorWithFeedAbove60'
      | 'FloorHeating'
      | 'RadiatorAndFloorHeating',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<NboProductlineViewModel>>>;
  public nboHeatingGetMatchingProductlines(
    appKey: string,
    energyDemandKwh?: number,
    currentHeatDissipation?:
      | 'RadiatorWithFeedBelow60'
      | 'RadiatorWithFeedAbove60'
      | 'FloorHeating'
      | 'RadiatorAndFloorHeating',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (appKey === null || appKey === undefined) {
      throw new Error(
        'Required parameter appKey was null or undefined when calling nboHeatingGetMatchingProductlines.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }
    if (energyDemandKwh !== undefined && energyDemandKwh !== null) {
      queryParameters = queryParameters.set('energyDemandKwh', <any>energyDemandKwh);
    }
    if (currentHeatDissipation !== undefined && currentHeatDissipation !== null) {
      queryParameters = queryParameters.set('currentHeatDissipation', <any>currentHeatDissipation);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<NboProductlineViewModel>>(
      `${this.basePath}/api/nbo/ht/productlines`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Generates a PDF preview of a variant
   *
   * @param leadId Id of the lead to which the variant belongs.
   * @param variantId Variant Id of the variant for which the preview is generated
   * @param appKey App key for authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboHeatingGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboHeatingGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboHeatingGetVariantPreview(
    leadId: string,
    variantId: number,
    appKey?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboHeatingGetVariantPreview.'
      );
    }

    if (variantId === null || variantId === undefined) {
      throw new Error(
        'Required parameter variantId was null or undefined when calling nboHeatingGetVariantPreview.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(
      `${this.basePath}/api/nbo/ht/leads/${encodeURIComponent(
        String(leadId)
      )}/variants/${encodeURIComponent(String(variantId))}/preview`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Allows to patch a lead and its contact data. Only works for newly created nbo leads.
   *
   * @param leadId The Id (GUID) of the lead
   * @param appKey AppKey which corresponds to the mandator for which this lead is created
   * @param body Lead and contact data for that lead to be updated
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public nboHeatingPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public nboHeatingPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public nboHeatingPatchNboLead(
    leadId: string,
    appKey?: string,
    body?: PatchNboRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (leadId === null || leadId === undefined) {
      throw new Error(
        'Required parameter leadId was null or undefined when calling nboHeatingPatchNboLead.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(
      `${this.basePath}/api/nbo/ht/${encodeURIComponent(String(leadId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Save an NBO for HT.
   *
   * @param appKey AppKey which corresponds to the mandator for which this lead is created
   * @param body Object and metadata for variants which shall be created and              contact data which the customer has entered
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public nboHeatingSaveNbo(
    appKey?: string,
    body?: SaveNboHeatingRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HeatingVariantSummaryViewModelLeadSummaryViewModel>;
  public nboHeatingSaveNbo(
    appKey?: string,
    body?: SaveNboHeatingRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HeatingVariantSummaryViewModelLeadSummaryViewModel>>;
  public nboHeatingSaveNbo(
    appKey?: string,
    body?: SaveNboHeatingRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HeatingVariantSummaryViewModelLeadSummaryViewModel>>;
  public nboHeatingSaveNbo(
    appKey?: string,
    body?: SaveNboHeatingRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (appKey !== undefined && appKey !== null) {
      queryParameters = queryParameters.set('appKey', <any>appKey);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<HeatingVariantSummaryViewModelLeadSummaryViewModel>(
      `${this.basePath}/api/nbo/ht`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
