import { NboHeatingLeadRequest, NboHeatingVariantRequest } from '@api-cc';

export interface IHeatPumpType {
  id: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum | NboHeatingVariantRequest.HeatingTypeEnum;
  value: NboHeatingVariantRequest.HeatingTypeEnum[];
  label: string;
  itemTitle: string;
  heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum;
  isHideSubsidy?: boolean;
  costSummaryKey?: string;
  installationCostKey?: string;
}

export const ALL_HEAT_PUMP_TYPES: IHeatPumpType[] = [
  {
    id: NboHeatingVariantRequest.HeatingTypeEnum.AirWaterHeatPumpOutside,
    value: [
      NboHeatingVariantRequest.HeatingTypeEnum.AirWaterHeatPumpOutside,
      NboHeatingVariantRequest.HeatingTypeEnum.AirWaterHeatPumpSplit,
    ],
    label: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_AIR_WATER_OUTSIDE',
    itemTitle: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_HEAT_ITEM',
    heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
    costSummaryKey: 'AirWaterHeatpumpCostSummary',
    installationCostKey: 'InstallationCostHeatpumpAir',
  },
  {
    id: NboHeatingVariantRequest.HeatingTypeEnum.AirWaterHeatPumpInside,
    value: [NboHeatingVariantRequest.HeatingTypeEnum.AirWaterHeatPumpInside],
    label: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_AIR_WATER_INSIDE',
    itemTitle: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_HEAT_ITEM',
    heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
  },
  {
    id: NboHeatingVariantRequest.HeatingTypeEnum.BrineWaterHeatPump,
    value: [NboHeatingVariantRequest.HeatingTypeEnum.BrineWaterHeatPump],
    label: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_BRINE',
    itemTitle: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_HEAT_ITEM',
    heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
    costSummaryKey: 'GeothermalHeatpumpCostSummary',
    installationCostKey: 'InstallationCostHeatpumpBrine',
  },
  {
    id: NboHeatingVariantRequest.HeatingTypeEnum.PelletsBoiler,
    value: [NboHeatingVariantRequest.HeatingTypeEnum.PelletsBoiler],
    label: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_PELLETS_BOILER',
    itemTitle: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_PELLETS_BOILER',
    heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
    costSummaryKey: 'PelletsCostSummary',
    installationCostKey: 'InstallationCostPelletsHeating',
  },
  {
    id: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.OilBoiler,
    value: [NboHeatingVariantRequest.HeatingTypeEnum.OilBoiler],
    label: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_OIL',
    itemTitle: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_OIL_ITEM',
    heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.OilBoiler,
    isHideSubsidy: true,
    costSummaryKey: 'OilCostSummary',
    installationCostKey: 'InstallationCostOilHeating',
  },
  {
    id: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.GasBoiler,
    value: [
      NboHeatingVariantRequest.HeatingTypeEnum.GasBoilerFloor,
      NboHeatingVariantRequest.HeatingTypeEnum.GasBoilerWall,
    ],
    label: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_GAS',
    itemTitle: 'HT.OFFERPREVIEW.OPTIONS.HEAT_PUMP_TYPE_GAS_ITEM',
    heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.GasBoiler,
    isHideSubsidy: true,
    costSummaryKey: 'GasCostSummary',
    installationCostKey: 'InstallationCostGasHeating',
  },
];
