import { NgRedux, select } from '@angular-redux/store';
import { Component, ViewEncapsulation } from '@angular/core';
import { IAppStateBase, SharedStoreEffect } from '@theia-cc/shared/store';
import { Observable } from 'rxjs';

export const canGoBackSelector = (state: IAppStateBase) => {
  return state.wizard.currentStepIdx > 0;
};

const progressStateSelector = (state: IAppStateBase) => {
  const { currentStep, lastStep } = state.wizard;
  const steps = state.config.steps;
  if (currentStep && lastStep) {
    const currStepIdx = steps.findIndex(el => el.name === currentStep);
    const lastStepIdx = steps.findIndex(el => el.name === lastStep);
    return currentStep && lastStep && currStepIdx > -1 && lastStepIdx > -1
      ? Math.min(Math.round(((currStepIdx + 1) / (lastStepIdx + 1)) * 100), 100)
      : 0;
  }
  return 0;
};

@Component({
  selector: 'app-back-arrow',
  templateUrl: './back-arrow.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BackArrowComponent {
  @select(canGoBackSelector)
  readonly canGoBack$: Observable<boolean>;

  @select(progressStateSelector)
  readonly progress$: Observable<number>;

  constructor(private effect: SharedStoreEffect, private store: NgRedux<IAppStateBase>) {
    const { backIconName } = this.store.getState().config.envVariables;
    if (backIconName) {
      this.backIconName = backIconName;
    }
  }

  public backIconName: string = 'back';

  navBack() {
    const appState: IAppStateBase = this.store.getState();
    const progress = progressStateSelector(appState);
    const canGoBack = canGoBackSelector(appState);

    // @TODO from ht
    // if (this.currentWizardStep && this.currentWizardStep === this.LARGE_CONTACT_FROM) {
    //   this.actions.storeLargeSystem(false);
    // }

    if (canGoBack && progress < 100) {
      this.effect.navigateBack();
    } else {
      this.effect.resetWizard();
    }
  }
}
