import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IBuildingSummary } from '@theia-cc/shared/models';

@Injectable()
export class BuildingAction {
  static readonly STORE_BUILDING_DATA = 'STORE_BUILDING_DATA';

  @dispatch()
  storeBuildingData = (type: IBuildingSummary) => ({
    type: BuildingAction.STORE_BUILDING_DATA,
    payload: type,
  });
}
