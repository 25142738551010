/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboAddressViewModel {
  Title?: NboAddressViewModel.TitleEnum;
  CompanyName?: string;
  FirstName?: string;
  LastName?: string;
  PhoneNumber?: string;
  ZipCode?: string;
  City?: string;
  Street?: string;
}
export namespace NboAddressViewModel {
  export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
  export const TitleEnum = {
    None: 'None' as TitleEnum,
    Sir: 'Sir' as TitleEnum,
    Madam: 'Madam' as TitleEnum,
    Company: 'Company' as TitleEnum,
    Family: 'Family' as TitleEnum,
  };
}
