import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { combineReducers } from 'redux';

@Injectable()
export class ReducerRegistry<IAppState> {
  private reducers;

  constructor(private store: NgRedux<IAppState>) {}

  registerReducers(reducers) {
    this.reducers = { ...this.reducers, ...reducers };
    this.store.replaceReducer(combineReducers(this.reducers));
  }

  loadState(id: string) {
    try {
      const serializedState = localStorage.getItem(id);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }
}
