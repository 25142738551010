import { Injectable } from '@angular/core';
import { isOfferPreviewStep } from '@theia-cc/shared/helpers';
import { IAppStateBase, WizardAction } from '@theia-cc/shared/store';

export function initAppWizardSettings(wizardAction: WizardAction) {
  return () => {
    return new Promise<void>(resolve => {
      const searchParams = new URLSearchParams(location.search);
      const version = +searchParams.get('version');
      wizardAction.setIsSharedOfferPreview(isOfferPreviewStep(location.pathname));
      if (!isNaN(version)) {
        wizardAction.setVersion(version);
        document.body.classList.add('version-' + version);
      }
      resolve();
    });
  };
}

export interface ISplitQueryParamsResultBase<CollectedData> {
  collectedData: Partial<CollectedData>;
  restQueryParams: any;
  partnerId: string;
  Language: string;
  browserCallback: string;
}

@Injectable()
export abstract class ShareLinkService {
  private offerReviewStepPathName: string;

  public setOfferReviewStepPathName(path: string): void {
    this.offerReviewStepPathName = path;
  }

  generateShareLink(location: Location, state: IAppStateBase): string {
    return encodeURI(
      `${location.origin}${this.generateQueryParamsLink(
        this.shareLinkQueryParamsSelector(state),
        this.offerReviewStepPathName || location.pathname
      )}`
    );
  }

  generateQueryParamsLink(queryParams: any, pathName: string): string {
    const { partnerId, ...data } = queryParams;
    return `${pathName}?${partnerId ? `partnerId=${partnerId}` : ''}${Object.keys(data)
      .map(key => `&${key}=${data[key]}`)
      .join('')
      .slice(partnerId ? 0 : 1)}`;
  }

  abstract shareLinkQueryParamsSelector(state: IAppStateBase): any;

  abstract urlLinkQueryParamsSelector(state: IAppStateBase): any;

  abstract splitQueryParams<T = ISplitQueryParamsResultBase<any>>(queryParams: any): T;
}
