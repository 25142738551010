import { ICar } from '../..';

export const CARS_MINI: ICar[] = [
  {
    value: 'MiniCountryman',
    caption: 'Mini Countryman',
    icon: 'mini-countryman.jpg',
  },
  {
    value: 'Mini3Door',
    caption: 'Mini 3 Door',
    icon: 'mini-3-door.jpg',
  },
];
