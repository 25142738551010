import { ICar } from '../..';

export const CARS_LEXUS: ICar[] = [
  {
    value: 'LexusUX300e',
    caption: 'Lexus UX 300e',
    icon: 'lexus-ux-300e.jpg',
  },
  {
    value: 'LexusNX450Plus',
    caption: 'Lexus NX 450h+',
    icon: 'lexus-nx-450h.jpg',
  },
  {
    value: 'LexusRZ450e',
    caption: 'Lexus RZ 450e',
    icon: 'lexus-rz-450e.jpg',
  },
  {
    value: 'LexusRX450hPlus',
    caption: 'Lexus RX 450h+',
    icon: 'lexus-rx-450h.jpg',
  },
];
