import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ButtonComponent } from './button/button.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { FormatNumberPipe } from './format-number.pipe';
import { IconComponent } from './icon/icon.component';
import { InterpolateStringWithObjectValuesPipe } from './interpolate-string-with-object-values.pipe';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LocalizedTextDirective } from './localized-text.directive';
import { SanityImagePipe } from './sanity-image.pipe';

@NgModule({
  declarations: [
    ButtonComponent,
    ConfirmationModalComponent,
    FormatNumberPipe,
    IconComponent,
    LoadingSpinnerComponent,
    SanityImagePipe,
    InterpolateStringWithObjectValuesPipe,
    LocalizedTextDirective,
  ],
  imports: [CommonModule, ButtonsModule.forRoot()],
  providers: [FormatNumberPipe, SanityImagePipe],
  exports: [
    ButtonComponent,
    ConfirmationModalComponent,
    FormatNumberPipe,
    IconComponent,
    LoadingSpinnerComponent,
    SanityImagePipe,
    InterpolateStringWithObjectValuesPipe,
    LocalizedTextDirective,
  ],
})
export class SharedUtilUiModule {}
