// Run the nbo calculate post request for every template
import { NgRedux } from '@angular-redux/store';
import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmobilityVariantSummaryViewModel,
  EmobilityVariantSummaryViewModelLeadSummaryViewModel,
  NboAddressViewModel,
  NboEmobilityCalculateRequest,
  NboEmobilityLeadRequest,
  NboEmobilityRequest,
  NboLeadAddressViewModel,
  SaveNboEmobilityRequest,
  SavePersonNboViewModel,
} from '@api-cc';
import { TranslateService } from '@ngx-translate/core';
import {
  CHARGING_POWER_TYPE_NUMBERS,
  CheckTypeEnum,
  DOWNLOAD_OFFER_EM,
  FINISH_CALLBACK_CONTACT,
  FINISH_DEALER_CALLBACK_CONTACT,
  FINISH_DEALER_CONTACT,
  FINISH_EM_CONTACT_COMMERCIAL,
  FINISH_EM_CONTACT_PRIVATE,
  GO_TO_CONTACT_FORM,
  ITemplateEm,
  ITemplateEmWithoutCms,
  REQUEST_REMOTE_CHECK,
  UNKNOWN,
  WHERE_TO_INSTALL_CHARGER_MORE_THAN_60,
} from '@theia-cc/em/core';
import {
  ENVIRONMENT,
  GO_TO_SUCCESS_URL,
  IEnvironment,
  NAVIGATE_NEXT,
  buildUpLeadComment,
  caseAgnosticQueryParam,
  cleanEmptyProperties,
  googleTagManagerPush,
  isEmptyValue,
} from '@theia-cc/shared/helpers';
import { IConfigQueryParamsBase, LeadComplexityTypeEnum } from '@theia-cc/shared/models';
import {
  BackendService,
  CantonService,
  ShareLinkService,
  UserInfoService,
} from '@theia-cc/shared/services';
import {
  IAppStateBase,
  LeadMetaAction,
  SharedStoreEffect,
  WizardAction,
} from '@theia-cc/shared/store';
import { FormatNumberPipe, formatChargingStationHardwarePrice } from '@theia-cc/shared/util-ui';
import { environment } from '../../../../../apps/em/src/environments/environment';
import { EmStateAction, GTMEventEm } from './em-state.action';
import { IAppStateEm, ICollectedDataEm } from './em-state.reducer';

const APP_NAME = 'Customer Client EM';

@Injectable()
export class EmStateEffect {
  constructor(
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private effect: SharedStoreEffect,
    private wizardAction: WizardAction,
    private leadMetaAction: LeadMetaAction,
    private backend: BackendService,
    private shareLinkService: ShareLinkService,
    @Inject(DOCUMENT) private readonly document: Document,
    private store: NgRedux<IAppStateEm>,
    private emStateAction: EmStateAction,
    private http: HttpClient,
    private formatNumber: FormatNumberPipe,
    private userInfoService: UserInfoService,
    private route: ActivatedRoute,
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    private cantonService: CantonService
  ) {}

  buttonActions(action) {
    if (!action) return;

    const appState: IAppStateEm = this.store.getState();
    const currentStep = appState.wizard.currentStep;

    switch (true) {
      case currentStep === 'contactemail':
        googleTagManagerPush<GTMEventEm>({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Email',
          eventLabel: APP_NAME,

          eventValue: undefined,
        });
        break;
      case currentStep === 'contactcondensed':
        googleTagManagerPush<GTMEventEm>({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Address',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'contactinformation':
        googleTagManagerPush<GTMEventEm>({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Address',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'offerpreview' && action === GO_TO_SUCCESS_URL:
        googleTagManagerPush<GTMEventEm>({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'Request Advise',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'contact':
        this.handleGoogleTags();
        break;
    }

    const state: IAppStateEm = this.store.getState();
    const { nboLeadIdEm, variantIdEm } = state.collectedData;

    switch (action) {
      case NAVIGATE_NEXT:
        this.effect.navigateNext();
        break;
      case GO_TO_SUCCESS_URL:
        this.goToSuccessURL();
        break;
      case GO_TO_CONTACT_FORM:
        this.effect.navigateNext();
        break;
      case REQUEST_REMOTE_CHECK:
        this.emStateAction.storeCheckType(CheckTypeEnum.RemoteCheck);
        this.effect.navigateNext();
        break;
      case FINISH_CALLBACK_CONTACT:
        this.wizardAction.showLoadingSpinner();
        this.postEmNboRequest(true).then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.goToSuccessURL();
        });
        break;
      case FINISH_EM_CONTACT_COMMERCIAL:
      case FINISH_EM_CONTACT_PRIVATE:
        this.sendEmRequest(state.config.queryParams);
        break;
      case FINISH_DEALER_CALLBACK_CONTACT:
        this.sendEmRequestDealerFlow(state.config.queryParams, true);
        break;
      case FINISH_DEALER_CONTACT:
        this.sendEmRequestDealerFlow(state.config.queryParams);
        break;
      case DOWNLOAD_OFFER_EM:
        this.wizardAction.showLoadingSpinner();
        this.backend.getEmOfferPreviewPDF(nboLeadIdEm, variantIdEm).then(() => {
          this.wizardAction.hideLoadingSpinner();
        });
        break;
      default:
        break;
    }
  }

  sendEmRequest(queryParams: IConfigQueryParamsBase) {
    this.wizardAction.showLoadingSpinner();

    this.postEmNboRequest(false, true).then((result: any) => {
      this.handleGoogleTagsAfterInternalLeadIdIsPresent(result.InternalLeadId, result.LeadId);
      const leadId = result.LeadId;
      const variantId = result.Variants[0].VariantId;
      const downloadPdfRequest =
        queryParams.mode !== 'kiosk'
          ? this.backend.getEmOfferPreviewPDF(leadId, variantId)
          : Promise.resolve();

      downloadPdfRequest.then(() => {
        this.wizardAction.hideLoadingSpinner();
        const state: IAppStateEm = this.store.getState();
        const { appKey } = state.config.envVariables;
        const collectedData: ICollectedDataEm = state.collectedData;
        const { checkType } = collectedData;
        if (checkType === CheckTypeEnum.RemoteCheck) {
          window.location.href = `${environment.uploadDomain}${this.setCorrectLanguageEnumValue(
            state.currentLanguage
          ).toLowerCase()}/em.html?leadId=${result.LeadId}&appKey=${appKey}&f=${
            state.collectedData.user.firstName
          }&l=${state.collectedData.user.lastName}`;
        } else {
          this.goToSuccessURL();
        }
      });
    });
  }

  sendEmRequestDealerFlow(queryParams: IConfigQueryParamsBase, isCallback = false) {
    this.wizardAction.showLoadingSpinner();

    const state: IAppStateEm = this.store.getState();
    const { collectedData } = state;
    const ProviderLeadId = collectedData?.dealer?.vin;
    const ThirdPartyCustomerNumber = collectedData?.dealer?.leaseNumber;
    const DealerThirdPartyCustomerNumber = collectedData?.dealer?.dealershipNumber;
    const {
      firstName: FirstName,
      lastName: LastName,
      telephone: PhoneNumber,
      email: Email,
      companyName: CompanyName,
      street: Street,
      zip: ZipCode,
      place: City,
      comment: Notes,
    } = collectedData?.dealer || {};

    return this.postEmNboRequest(isCallback, true, ProviderLeadId)
      .then((result: any) => {
        const leadId = result.LeadId;
        const variantId = result.Variants?.[0]?.VariantId;
        const downloadPdfRequest =
          queryParams.mode !== 'kiosk' && variantId && leadId
            ? this.backend.getEmOfferPreviewPDF(leadId, variantId)
            : Promise.resolve();

        return this.backend
          .postAdditionalAddressToLead(
            result.LeadId,
            {
              FirstName,
              LastName,
              PhoneNumber,
              Email,
              CompanyName,
              Street,
              ZipCode,
              City,
              Notes,
              Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
              ThirdPartyCustomerNumber: DealerThirdPartyCustomerNumber,
            },
            NboLeadAddressViewModel.AddressTypeEnum.CarDealer
          )
          .then(() =>
            Promise.all([
              this.backend.postDebitorAddressToLead(result.LeadId, {
                Title: collectedData.user.title || NboAddressViewModel.TitleEnum.None,
                CompanyName: collectedData.user.companyName,
                FirstName: collectedData.user.firstName || 'unknown',
                LastName: collectedData.user.lastName || 'unknown',
                Street: collectedData.userAddress.street || 'unknown',
                ZipCode: collectedData.userAddress.zip || 'unknown',
                City: collectedData.userAddress.place || 'unknown',
                Email: collectedData.user.email || 'unknown',
                PhoneNumber: collectedData.user.telephone || 'unknown',
                Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
                ThirdPartyCustomerNumber,
              }),
              downloadPdfRequest,
            ])
          );
      })
      .then(() => {
        this.wizardAction.hideLoadingSpinner();
        this.goToSuccessURL();
      });
  }

  contactFormValidatedAction() {
    this.handleGoogleTags();
  }

  handleGoogleTags() {
    const state: IAppStateEm = this.store.getState();
    const { whereToInstallCharger } = state.collectedData;

    let googleTagObject: GTMEventEm;

    if (
      whereToInstallCharger ===
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution
    ) {
      googleTagObject = {
        event: 'GAEvent',
        eventCategory: 'Conversion',
        eventAction: 'Company Request Support',
        eventLabel: APP_NAME,
        eventValue: undefined,
      };
    } else {
      googleTagObject = {
        event: 'GAEvent',
        eventCategory: 'Conversion',
        eventAction: 'Offer Download',
        eventLabel: APP_NAME,
        eventValue: undefined,
      };
    }

    googleTagManagerPush<GTMEventEm>(googleTagObject);
  }

  // Push the internalLeadId prop to the datalayer as soon as this prop is present
  handleGoogleTagsAfterInternalLeadIdIsPresent(internalLeadId: string, leadId: string) {
    googleTagManagerPush<GTMEventEm>({
      event: 'GAEvent',
      eventCategory: 'UserId',
      eventAction: 'Get TheiaLeadId ',
      eventLabel: internalLeadId,
    });
    googleTagManagerPush({
      event: 'GAEvent',
      eventCategory: 'UserId',
      eventAction: 'Get ExtLeadId',
      eventLabel: leadId,
    });
  }

  async goToSuccessURL() {
    const state: IAppStateEm = this.store.getState();
    const { browserCallback } = state.config;
    const { nboLeadIdEm: leadId } = state.collectedData;
    const urls = state.config.org.successPageUrls;
    const isAllowed = await this.backend.checkBrowserCallback(browserCallback);
    const url =
      browserCallback && isAllowed
        ? `${browserCallback}${browserCallback.includes('?') ? '&' : '?'}`
        : `${urls[state.currentLanguage]}?`;
    window.location.href = `${url}leadId=${leadId || ''}&${this.getQueryParamString()}`;
  }

  getQueryParamString() {
    const params = this.route.snapshot.queryParams;
    const paramSet = [];
    if (Object.keys(params).length > 0) {
      for (const property in params) {
        if (params.hasOwnProperty(property)) {
          const value = params[property];
          if (!isEmptyValue(value)) {
            paramSet.push(`${property}=${params[property]}`);
          }
        }
      }
    }
    return paramSet.length > 0 ? paramSet.join('&') : '';
  }

  getQueryParamObject() {
    const state: IAppStateEm = this.store.getState();
    const params = state.config.queryParams;
    return Object.keys(params).length > 0 ? params : {};
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  async postEmNboRequest(
    isCallback = false,
    IsSendMail = true,
    ProviderLeadId?: string
  ): Promise<EmobilityVariantSummaryViewModelLeadSummaryViewModel> {
    const state: IAppStateEm = this.store.getState();
    const collectedData: ICollectedDataEm = state.collectedData;
    const { hubspotTrackingId, queryParams } = state.config;

    const whereToInstallCharger =
      collectedData.whereToInstallCharger ||
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution;

    const basicLeadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(collectedData.leadCallbackTime),
      // Temporary fix: Empty collectedData
      {},
      queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state),
      true
    );

    const enhancedLeadComment = isCallback
      ? `More than 60 parking spaces / special case. \n \n ${basicLeadComment}`
      : basicLeadComment;

    const leadTracers: string = collectedData.leadTracers || collectedData.orderType;

    const payload: SaveNboEmobilityRequest = {
      ProviderLeadId,
      LeadRequest: {
        FuseboxAge:
          collectedData.fuseBox === UNKNOWN
            ? null
            : (collectedData.fuseBox as NboEmobilityLeadRequest.FuseboxAgeEnum),
      },
      VariantDatas: isCallback
        ? null
        : [
            {
              VariantTitle: 'Customer Client Requested',
              IsSendMail,
              WhereToInstallCharger:
                whereToInstallCharger === WHERE_TO_INSTALL_CHARGER_MORE_THAN_60
                  ? NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution
                  : (whereToInstallCharger as NboEmobilityCalculateRequest.WhereToInstallChargerEnum),
              TemplateTechnicalTitle: collectedData.selectedTemplateIdEm,
              OrderType: collectedData.orderType,
              SmartEnergyConnection:
                collectedData.connectpv ===
                NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired + '_2'
                  ? NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired
                  : collectedData.connectpv,
              ParkingType: collectedData.parkingspace,
              NumberOfParkingspacesToBeEquippedResidential:
                collectedData.numberOfParkingspacesToBeEquippedResidential,
              ChargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
              AvailablePowerOutlet:
                collectedData.poweroutlet === UNKNOWN
                  ? NboEmobilityCalculateRequest.AvailablePowerOutletEnum.None
                  : (collectedData.poweroutlet as NboEmobilityCalculateRequest.AvailablePowerOutletEnum),
              DistanceFuseboxToChargingStation: +collectedData.distanceFuseboxToChargingstation,
              DistanceFuseboxToFlatRibbonCable: +collectedData.distanceFuseboxToFlatRibbonCable,
              TotalNumberOfParkingspaces: collectedData.totalNumberOfParkingspaces,
              NumberOfParkingspacesPreparedToBeElectrified:
                collectedData.numberOfParkingspacesPreparedToBeElectrified,
              NumberOfParkingspacesToBeEquippedCommercial:
                collectedData.numberOfParkingspacesToBeEquippedCommercial,
              NumberOfRibbonCables: collectedData.numberOfRibbonCables,
              MeterConnectionBetweenRibbonCables: collectedData.meterConnectionBetweenRibbonCable,
            },
          ],
      PersonData: {
        Email: collectedData.user.email || 'unknown',
        Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
        Address: {
          Title: collectedData.user.title || NboAddressViewModel.TitleEnum.None,
          CompanyName: collectedData.user.companyName,
          FirstName: collectedData.user.firstName || 'unknown',
          LastName: collectedData.user.lastName || 'unknown',
          PhoneNumber: collectedData.user.telephone || 'unknown',
          ZipCode: collectedData.userAddress.zip || 'unknown',
          City: collectedData.userAddress.place || 'unknown',
          Street: collectedData.userAddress.street || 'unknown',
        },
      },
      LeadTracers:
        collectedData.whereToInstallCharger ===
        NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution
          ? leadTracers
            ? `${leadTracers},Commercial`
            : 'Commercial'
          : leadTracers,
      LeadTag: collectedData.leadTag,
      LeadStatus: collectedData.leadStatus,
      LeadComment: enhancedLeadComment,
      IsCallbackTimeMorning: collectedData.leadCallbackTime.IsCallbackTimeMorning,
      IsCallbackTimeAfternoon: collectedData.leadCallbackTime.IsCallbackTimeAfternoon,
      IsCallbackTimeEvening: collectedData.leadCallbackTime.IsCallbackTimeEvening,
      IsCallbackTimeWeekend: collectedData.leadCallbackTime.IsCallbackTimeWeekend || false,
      HubspotTrackingId: hubspotTrackingId,
      RelatedLeadPublicId: collectedData.relatedLeadPublicId,
      ShareUrl: collectedData.shareUrl,
      ThirdPartyCustomerNumber: caseAgnosticQueryParam(queryParams)('ThirdPartyCustomerNumber'),
      LeadComplexityType:
        collectedData.whereToInstallCharger ===
        NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution
          ? LeadComplexityTypeEnum.Residential
          : LeadComplexityTypeEnum.Commercial,
    };

    const result = await this.backend.postEmNboRequest(payload);
    await this.userInfoService.sendAdditionalAddresses(result.LeadId);

    this.emStateAction.storeNboLeadId(result.LeadId);
    this.emStateAction.storeVariantId(result.Variants[0]?.VariantId);

    this.handleGoogleTagsAfterInternalLeadIdIsPresent(
      result.InternalLeadId.toString(),
      result.LeadId
    );

    return result;
  }

  setCorrectLanguageEnumValue(currentLanguage: string): SavePersonNboViewModel.LanguageEnum {
    switch (currentLanguage) {
      case 'de':
        return SavePersonNboViewModel.LanguageEnum.DE;
      case 'fr':
        return SavePersonNboViewModel.LanguageEnum.FR;
      case 'it':
        return SavePersonNboViewModel.LanguageEnum.IT;
      default:
        return SavePersonNboViewModel.LanguageEnum.DE;
    }
  }

  getCallBackTime(leadCallbackTime: ICollectedDataEm['leadCallbackTime']): string {
    return Object.values(leadCallbackTime).every(time => time !== true)
      ? 'unknown'
      : Object.entries(leadCallbackTime).reduce((res, [key, value]) => {
          return value === true
            ? `${res}${res.length ? ', ' : ''}${key.replace('IsCallbackTime', '')}`
            : res;
        }, '');
  }

  getMatchingTemplateNames() {
    // Get all the collected data we need for template matching
    const { whereToInstallCharger, orderType, connectpv } = this.store.getState().collectedData;

    let updatedWhereToInstallCharger =
      whereToInstallCharger === WHERE_TO_INSTALL_CHARGER_MORE_THAN_60
        ? NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution
        : (whereToInstallCharger as NboEmobilityCalculateRequest.WhereToInstallChargerEnum);

    // We have to remove the _2 from the connectpv value before sending it to the backend
    let updatedConnectpv = connectpv;
    if (connectpv === NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired + '_2') {
      updatedConnectpv = NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired;
    }

    // Send the request to the backend
    return this.backend.getMatchingTemplateNames(
      updatedWhereToInstallCharger,
      orderType,
      updatedConnectpv
    );
  }

  getNboVariants(templates) {
    const collectedData: ICollectedDataEm = this.store.getState().collectedData;

    if (templates.length > 1) {
      this.emStateAction.nboClear();
    } else {
      this.emStateAction.nboSetData(
        collectedData.nboFetchedEm.filter(item => item.templateId !== templates[0].id)
      );
    }

    return Promise.all([
      ...templates.map(template => {
        return this.createEmCalculationRequest(template);
      }),
    ]);
  }

  createEmCalculationRequest(template: ITemplateEm | ITemplateEmWithoutCms) {
    const state: IAppStateEm = this.store.getState();
    const collectedData: ICollectedDataEm = state.collectedData;

    const id = template.id;
    const whereToInstallCharger =
      collectedData.whereToInstallCharger ||
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution;

    const payload: NboEmobilityCalculateRequest = {
      WhereToInstallCharger:
        whereToInstallCharger === WHERE_TO_INSTALL_CHARGER_MORE_THAN_60
          ? NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution
          : (whereToInstallCharger as NboEmobilityCalculateRequest.WhereToInstallChargerEnum),
      TemplateTechnicalTitle: id,
      OrderType: collectedData.orderType,
      SmartEnergyConnection:
        collectedData.connectpv ===
        NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired + '_2'
          ? NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired
          : collectedData.connectpv,
      ParkingType: collectedData.parkingspace,
      NumberOfParkingspacesToBeEquippedResidential:
        collectedData.numberOfParkingspacesToBeEquippedResidential,
      ChargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
      AvailablePowerOutlet:
        collectedData.poweroutlet === UNKNOWN
          ? NboEmobilityCalculateRequest.AvailablePowerOutletEnum.None
          : (collectedData.poweroutlet as NboEmobilityCalculateRequest.AvailablePowerOutletEnum),
      DistanceFuseboxToChargingStation: +collectedData.distanceFuseboxToChargingstation,
      DistanceFuseboxToFlatRibbonCable: +collectedData.distanceFuseboxToFlatRibbonCable,
      TotalNumberOfParkingspaces: collectedData.totalNumberOfParkingspaces,
      NumberOfParkingspacesPreparedToBeElectrified:
        collectedData.numberOfParkingspacesPreparedToBeElectrified,
      NumberOfParkingspacesToBeEquippedCommercial:
        collectedData.numberOfParkingspacesToBeEquippedCommercial,
      NumberOfRibbonCables: collectedData.numberOfRibbonCables,
      MeterConnectionBetweenRibbonCables: collectedData.meterConnectionBetweenRibbonCable,
      LeadRequest: {
        FuseboxAge:
          collectedData.fuseBox === UNKNOWN
            ? null
            : (collectedData.fuseBox as NboEmobilityLeadRequest.FuseboxAgeEnum),
      },
    };

    return this.backend.postEmCalculationRequest(payload).then(result => {
      this.emStateAction.nboStoreResult({
        templateId: id,
        ...(result as EmobilityVariantSummaryViewModel),
      });
    });
  }

  // Method to bring the data of a template in the right format
  valueFormatted(nboResult, chargingPower, partnerId: string) {
    const {
      whereToInstallCharger,
      numberOfParkingspacesToBeEquippedResidential,
      numberOfParkingspacesToBeEquippedCommercial,
    } = this.store.getState().collectedData;

    const numberOfParkingspacesToBeEquipped =
      whereToInstallCharger ===
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution
        ? numberOfParkingspacesToBeEquippedResidential
        : numberOfParkingspacesToBeEquippedCommercial;

    const totalCosts =
      whereToInstallCharger ===
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution
        ? Math.round(nboResult.ChargingStationHardwarePrice) +
            Math.round(nboResult.InstallationPrice) +
            Math.round(nboResult.Vat) -
            this.roundNumberNegative(nboResult.CarManufacturerSubsidy) || 0
        : Math.round(nboResult.TotalPrice);

    const installationPrice = nboResult.InstallationPrice;

    return {
      totalCosts: this.roundNumber(totalCosts),
      timeToFullCharge: this.roundNumber(nboResult.HoursToFullChargeAc) || 0,
      chargingPower: nboResult.ChargingPower || 0,
      chargingStationHardwarePrice: formatChargingStationHardwarePrice(
        Math.round(nboResult.ChargingStationHardwarePrice),
        this.formatNumber
      ),
      installationPrice: this.roundNumber(installationPrice),
      vat: this.roundNumber(nboResult.Vat) || 0,
      carManufacturerSubsidy: this.roundNumber(nboResult.CarManufacturerSubsidy) || 0,
      numberOfParkingspacesToBeEquipped: numberOfParkingspacesToBeEquipped
        ? `${numberOfParkingspacesToBeEquipped} x `
        : '',
      maxChargingPowerAc: nboResult.MaxChargingPowerAc,
      selectedChargingPower: CHARGING_POWER_TYPE_NUMBERS[chargingPower],
      mandator: partnerId && this.capitalizeFirstLetter(partnerId),
    };
  }

  capitalizeFirstLetter(string): string {
    return string[0].toUpperCase() + string.slice(1);
  }

  roundNumber(number) {
    return this.formatNumber.transform(Math.round(number));
  }

  roundNumberNegative(v): number {
    return <any>(v >= 0 || -1) * Math.round(Math.abs(v));
  }

  navigateWithoutRestfullOfferPreviewParams(appState: IAppStateBase) {
    return (step: string): Promise<boolean | void> => {
      const { partnerId, restQueryParams } = this.shareLinkService.splitQueryParams(
        appState.config.queryParams
      );
      const params = cleanEmptyProperties({ ...restQueryParams, partnerId });

      return this.router.navigate([step], { queryParams: params }).catch(err => console.error(err));
    };
  }
}
